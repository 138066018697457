import React, { useCallback } from "react";
import { useSelector } from "@xstate/react";
import { FleetMachineContext as FMC } from "../../App";
import ExpandIco from "../../images/expand_ico.svg";
import { getRoleManager } from "../../hooks/useRoleManager";
import { OffScheduleRequirementInfo } from "../../machines/testSchedules";

const VesselMetrics = React.memo(({ recordId, isSpot }: { recordId: any; isSpot: any }) => {
	const fms = React.useContext(FMC);

	const selectedFilter: any = useSelector(fms, ({ context: { scheduleFilter } }) => scheduleFilter);

	const metrics: any = useSelector(fms, ({ context: { vesselMetrics } }) => vesselMetrics);

	if (!(metrics && !isSpot && recordId in metrics)) return <div />;

	let mode = 0;
	switch (selectedFilter) {
		case "Actual":
			mode++;
			break;
		case "Planned":
			mode--;
			break;
		default:
	}

	const { actual, planned } = metrics[recordId];
	return (
		<div style={{ padding: 2, marginBottom: 10 }}>
			{(!mode || mode > 0) && actual && (
				<div
					key={recordId + "_actual"}
					style={{
						color: "black",
						fontSize: 13,
						fontWeight: "bold",
					}}
				>
					A: {(actual.utilization * 100).toFixed(0)}%
				</div>
			)}
			{(!mode || mode < 0) && planned && (
				<div
					key={recordId + "_planned"}
					style={{
						color: "#606060",
						fontSize: 13,
						fontWeight: "bold",
					}}
				>
					P: {(planned.utilization * 100).toFixed(0)}%
				</div>
			)}
		</div>
	);
});

export const VesselColumn = React.memo(
	({
		record,
		send,
		offScheduleRequirements,
	}: {
		record: any;
		send: any;
		offScheduleRequirements: OffScheduleRequirementInfo[];
	}) => {
		const circleColor = record.clean === "C" ? "#80BF9B" : record.clean === "D" ? "#A9A9A9" : "#fff";

		const handleVesselClick = useCallback(() => {
			const { can } = getRoleManager();
			const canManagePRUT = can("manage pr/ut");
			if (!record.isSpot && canManagePRUT)
				send({
					type: "vesselClicked",
					vesselId: record?.id,
				});
		}, [send, record]);
		const vesselId = record?.id;
		const isOffSchedule = offScheduleRequirements.some((o) => o.vesselId === vesselId);

		return (
			<div
				style={{
					width: "100%",
					height: "100%",
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "around",
				}}
				onClick={handleVesselClick}
			>
				<div
					style={{
						width: 12,
						height: 12,
						background: circleColor,
						border: `1.5px solid ${circleColor}`,
						borderRadius: 25,
						marginRight: 10,
					}}
				/>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						flexGrow: 1,
						marginTop: 13,
					}}
				>
					<div style={{ padding: 1, fontSize: 16, fontWeight: "bold" }}>
						{record.isSpot ? record.name.split("_")[0] : record.name}
						{isOffSchedule && (
							<span style={{ color: "red", fontSize: 32, fontWeight: "bold", paddingLeft: "5px" }}>
								*
							</span>
						)}
					</div>
					<div style={{ padding: 1, fontSize: 13, color: "#59b5d9" }}>
						{record.isSpot ? record.id.split("_")[0] : record.id}
					</div>
					<VesselMetrics recordId={record.id} isSpot={record.isSpot} />
				</div>

				<img height={12} width={12} src={ExpandIco} alt="expand-ico" />
			</div>
		);
	}
);
