import { Epic, ofType } from "redux-observable";
import { pipe, toObservable } from "wonka";
import { combineLatest, EMPTY, from, of, merge, Subscribable } from "rxjs";
import { switchMap, withLatestFrom, mapTo, catchError, mergeMap, map } from "rxjs/operators";

import {
	PERSIST_REQUIREMENTS,
	DELETE_REQUIREMENT_MUTATION,
	UPDATE_SHIPMENT_ID,
	RECONSIGN_REQUIREMENT_GQL,
} from "../../queries/requirements";
import UserContext from "../../context/UserContext";
import {
	BATCH_PERSIST_FAILED,
	BATCH_PERSIST_SUCCESS,
	DELETE_REQUIREMENT,
	DELETE_REQUIREMENT_FAILURE,
	DELETE_REQUIREMENT_SUCCESS,
	IRequirementReducerActions,
	IUpdateRequirementSuccessAction,
	IUpdateRequriementFailureAction,
	PERSIST_BATCH_REQUIREMENT_LIST,
	PERSIST_REQUIREMENT,
	PERSIST_REQUIREMENT_FAILURE,
	PERSIST_REQUIREMENT_SUCCESS,
	UPDATE_REQUIREMENT,
	UPDATE_REQUIREMENT_FAILED,
	UPDATE_REQUIREMENT_SUCCESS,
	RECONSIGN_REQUIREMENT,
	RECONSIGN_REQUIREMENT_FAILED,
	RECONSIGN_REQUIREMENT_SUCCESS,
	IReconsignRequriementFailureAction,
	IReconsignRequirementSuccessAction,
} from "../actions/requirements.actions";
import type { AllActions } from "../configure-store";
import type { IState } from "../reducers";
import { OperationResult, TypedDocumentNode } from "@urql/core";
import { requirementsClient } from "../../api/requirementsClient";
import { vesselScheduleLifecycleClient } from "../../api/vesselScheduleLifecycleClient";
import { RequirementAsInput } from "../../types/generated/q-fanar-requirements.types";
import { VESSEL_SCHEDULE_LIFECYCLE_URL } from "../../api/UrqlClientProvider";
import { recursivelyDeleteTypenameProperty } from "../util/clean-props";
import { I_ActualVesselScheduleTuple } from "../../types/generated/q-vessel-schedule-lifecycle-v6.types";

export type GqlQueryOperationResult<N extends TypedDocumentNode> = N extends TypedDocumentNode<
	infer Data,
	infer Variables
>
	? OperationResult<Data, Variables>
	: never;

const gqlClient$ = UserContext.token$.pipe(mapTo(requirementsClient));

export const requirementsEpic: Epic<AllActions, IRequirementReducerActions, IState> = (action$, state$) => {
	const batchPersist$ = combineLatest([
		gqlClient$,
		action$.pipe(ofType(PERSIST_BATCH_REQUIREMENT_LIST), withLatestFrom(state$)),
	]).pipe(
		switchMap(([client, [, state]]) =>
			state.requirements.batchUploadState === null
				? EMPTY
				: from(
						pipe(
							client.mutation(
								PERSIST_REQUIREMENTS,
								recursivelyDeleteTypenameProperty({
									requirements: state.requirements.batchUploadState.requirements.map(
										({ item }) => item
									),
								})
							),
							toObservable
						) as Subscribable<GqlQueryOperationResult<typeof PERSIST_REQUIREMENTS>>
				  ).pipe(
						map(
							({ error }): IRequirementReducerActions =>
								error
									? {
											type: BATCH_PERSIST_FAILED,
											payload: error.message,
									  }
									: { type: BATCH_PERSIST_SUCCESS }
						),
						catchError((e) =>
							of<IRequirementReducerActions>({
								type: BATCH_PERSIST_FAILED,
								payload: e.message,
							})
						)
				  )
		)
	);

	const singleItemUpdate$ = combineLatest([
		gqlClient$,
		action$.pipe(
			ofType<
				AllActions,
				Extract<
					AllActions,
					{
						type: typeof UPDATE_REQUIREMENT;
					}
				>,
				typeof UPDATE_REQUIREMENT
			>(UPDATE_REQUIREMENT)
		),
	]).pipe(
		mergeMap(([client, action]) =>
			from(
				pipe(
					client.mutation(UPDATE_SHIPMENT_ID, {
						actualScheduleID: action.payload.actualScheduleID,
						plannedScheduleID: action.payload.plannedScheduleID,
						updatedRequirement: action.payload.updatedRequirement as RequirementAsInput,
					}),
					toObservable
				) as Subscribable<GqlQueryOperationResult<typeof UPDATE_SHIPMENT_ID>>
			).pipe(
				map((ev) => {
					if (ev.error?.message) {
						return {
							type: UPDATE_REQUIREMENT_FAILED,
							payload: {
								requirement: action?.payload?.updatedRequirement?.id,
								message: ev.error.message,
							},
						} as IUpdateRequriementFailureAction;
					}
					try {
						action?.fleetService?.send("UPDATE_SHIPMENT_ID", {
							...action.payload,
							responseData: ev?.data?.updateShipmentIDOnRequirementAndUpdateSchedule ?? null,
						});
					} catch (e) {
						return {
							type: UPDATE_REQUIREMENT_FAILED,
							payload: {
								requirement: action?.payload?.updatedRequirement?.id,
								message: "Web page redraw failed after requirement update. Please reload the page.",
							},
						} as IUpdateRequriementFailureAction;
					}
					return {
						type: UPDATE_REQUIREMENT_SUCCESS,
						payload: action.payload,
					} as IUpdateRequirementSuccessAction;
				}),
				catchError((e) => {
					return of<IRequirementReducerActions>({
						type: UPDATE_REQUIREMENT_FAILED,
						payload: e.message,
					});
				})
			)
		)
	);

	const reconsignRequirement$ = combineLatest([
		gqlClient$,
		action$.pipe(
			ofType<
				AllActions,
				Extract<
					AllActions,
					{
						type: typeof RECONSIGN_REQUIREMENT;
					}
				>,
				typeof RECONSIGN_REQUIREMENT
			>(RECONSIGN_REQUIREMENT)
		),
	]).pipe(
		mergeMap(([client, action]) =>
			from(
				pipe(
					vesselScheduleLifecycleClient.mutation(RECONSIGN_REQUIREMENT_GQL, {
						vesselID: action.payload.vesselID,
						modifiedRequirementToReconsign: action.payload
							.modifiedRequirementToReconsign as RequirementAsInput,
					}),
					toObservable
				) as Subscribable<GqlQueryOperationResult<typeof RECONSIGN_REQUIREMENT_GQL>>
			).pipe(
				map((ev) => {
					if (ev.error?.message) {
						return {
							type: RECONSIGN_REQUIREMENT_FAILED,
							payload: {
								requirement: "",
								message: ev.error.message,
							},
						} as IReconsignRequriementFailureAction;
					}
					try {
						action?.fleetService?.send("RECONSIGN_REQUIREMENT_DONE", {
							...action.payload,
							responseData: ev?.data,
						});
					} catch (e) {
						return {
							type: RECONSIGN_REQUIREMENT_FAILED,
							payload: {
								requirement: "",
								message: "Data reload failed after requirement update. Please reload the page.",
							},
						} as IReconsignRequriementFailureAction;
					}
					return {
						type: RECONSIGN_REQUIREMENT_SUCCESS,
						payload: action.payload,
					} as IReconsignRequirementSuccessAction;
				}),
				catchError((e) => {
					return of<IRequirementReducerActions>({
						type: RECONSIGN_REQUIREMENT_FAILED,
						payload: {
							requirement: "",
							message: e.message,
						},
					});
				})
			)
		)
	);

	const singleItemPersist$ = combineLatest([
		gqlClient$,
		action$.pipe(
			ofType<
				AllActions,
				Extract<
					AllActions,
					{
						type: typeof PERSIST_REQUIREMENT | typeof DELETE_REQUIREMENT;
					}
				>,
				typeof PERSIST_REQUIREMENT | typeof DELETE_REQUIREMENT
			>(PERSIST_REQUIREMENT, DELETE_REQUIREMENT)
		),
	]).pipe(
		mergeMap(([client, action]) =>
			action.type === PERSIST_REQUIREMENT
				? from(
						pipe(
							client.mutation(
								PERSIST_REQUIREMENTS,
								recursivelyDeleteTypenameProperty({
									requirements: [action.payload as RequirementAsInput],
								})
							),
							toObservable
						) as Subscribable<GqlQueryOperationResult<typeof PERSIST_REQUIREMENTS>>
				  ).pipe(
						map(
							({ data, error }): IRequirementReducerActions => {
								const result =
									data && Object.keys(data).includes("NEW_validateAndPersistRequirements")
										? (data["NEW_validateAndPersistRequirements"] as I_ActualVesselScheduleTuple[])
										: [];
								action.fleetService.send("UPDATE_SCHEDULE", {
									schedule: result,
								});
								action.fleetService.send("UPDATE_REQUIREMENT", {
									requirement: action.payload,
								});
								action.fleetService.send("UPDATE_REQUIREMENT_DETAILS", {
									requirement: action.payload,
								});

								return error
									? {
											type: PERSIST_REQUIREMENT_FAILURE,
											payload: {
												message: error.message,
												requirement: action.payload,
											},
									  }
									: {
											type: PERSIST_REQUIREMENT_SUCCESS,
											payload: action.payload,
											schedule: result,
									  };
							}
						),
						catchError((err) =>
							of<IRequirementReducerActions>({
								type: PERSIST_REQUIREMENT_FAILURE,
								payload: {
									requirement: action.payload,
									message: err.message,
								},
							})
						)
				  )
				: from(
						pipe(
							client.mutation(
								DELETE_REQUIREMENT_MUTATION,
								{
									requirementID: action.payload,
								},
								{ url: VESSEL_SCHEDULE_LIFECYCLE_URL }
							),
							toObservable
						) as Subscribable<GqlQueryOperationResult<typeof DELETE_REQUIREMENT_MUTATION>>
				  ).pipe(
						mapTo({
							type: DELETE_REQUIREMENT_SUCCESS,
							payload: action.payload,
						} as const),
						catchError((e) =>
							of<IRequirementReducerActions>({
								type: DELETE_REQUIREMENT_FAILURE,
								payload: e.message,
							})
						)
				  )
		)
	);

	return merge(batchPersist$, singleItemPersist$, singleItemUpdate$, reconsignRequirement$);
};
