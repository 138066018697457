/** Used to reject sign symbols and only allow positive numbers in a form input */
export function normalizePositiveNumber(
	digit_limit: number = 10,
	mantissa_limit: number = 2
) {
	return function (number: string): string {
		if (isNaN(number as any) || +number < 0) return "0";
		if (!/^\d+\.?(\d+)?$/.test(number)) return "";
		const [num, dig] = number.split(".");
		if (dig && dig.length > mantissa_limit)
			return `${num.slice(0, digit_limit)}.${dig.slice(0, 2)}`;

		return `${num.slice(0, digit_limit)}${
			dig !== undefined ? `.${dig}` : ""
		}`;
	};
}
