import { useSelector } from "@xstate/react";
import { Box } from "grommet";
import React, { memo, useContext, useMemo } from "react";
import { FleetMachineContext } from "../../../App";

interface Props {}

export const OffScheduleRequirementInfo: React.FC<Props> = memo(() => {
	const fleetMachineService = useContext(FleetMachineContext);

	const offScheduleRequirements = useSelector(
		fleetMachineService,
		({ context: { offScheduleRequirements } }) => offScheduleRequirements
	);

	const selectedRequirementId = useSelector(
		fleetMachineService,
		({ context: { selectedRequirementId } }) => selectedRequirementId
	);

	const offScheduleRequirementMessages: string[] = useMemo(() => {
		if (!selectedRequirementId?.id || !offScheduleRequirements) return [];
		return offScheduleRequirements
			.filter((o) => o.requirementId === selectedRequirementId.id)
			.map((o) => o.message);
	}, [offScheduleRequirements, selectedRequirementId]);

	if (!offScheduleRequirementMessages.length) return null;

	return (
		<Box style={{ paddingBottom: "18px", color: "red" }}>
			{offScheduleRequirementMessages.map((message, index) => (
				<div style={{ paddingBottom: "2px" }} key={index}>
					{message}
				</div>
			))}
		</Box>
	);
});
