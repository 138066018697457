import { useWatch, Control } from "react-hook-form";
import { useSelector } from "react-redux";
import { portsSelector } from "../../selectors/ports.selectors";
import {
	existingRequirementsWithBatchStateSelector,
	formValuesToRequirement,
	RequirementFormValues,
	validateRequirement,
} from "../../selectors/requirement.selectors";

export function useFormErrors(control: Control | undefined) {
	const data = useWatch({
		defaultValue: { loading: true },
		control,
	});

	const ports = useSelector(portsSelector);

	const existingRequirements = useSelector(
		existingRequirementsWithBatchStateSelector
	);

	if (data.loading) return null;

	const errors = validateRequirement(
		formValuesToRequirement(data as RequirementFormValues),
		ports,
		existingRequirements
	);

	return errors;
}
