import { all, call, spawn } from "redux-saga/effects";

import { watchForLoadPortsSaga } from "./loadPorts.saga";
import { watchForLoadRequirementsSaga } from "./loadRequirements.saga";
import { watchForNavigationChangeSaga } from "./navigation.saga";
import { waitForAuthSaga } from "./waitForAuth.saga";

/**
 * Root Saga
 *
 * Pattern from: https://redux-saga.js.org/docs/advanced/RootSaga.html#keeping-everything-alive
 */
export function* rootSaga() {
	const sagas = [
		waitForAuthSaga,
		watchForLoadRequirementsSaga,
		watchForLoadPortsSaga,
		watchForNavigationChangeSaga,
	];

	yield all(
		sagas.map((saga) =>
			spawn(function* () {
				while (true) {
					try {
						yield call(saga);
						break;
					} catch (error) {
						console.log(error);
						console.log("restarting saga...");
					}
				}
			})
		)
	);
}
