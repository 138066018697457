import moment from "moment";
import { ChangeEvent } from "react";

export function floatDateToString(value: number): string {
	return moment(value).format("YYYY-MM-DDTHH:mm");
}

export function parseDateValue(value: number | { value: string }): string {
	console.log("parseDateValue: ", value);

	if (typeof value === "number") {
		return new Date(value / 1000).toISOString();
	}

	if (typeof value === "object") {
		return value.value;
	}

	throw new Error(
		"Got value that was not of type number or object: " + typeof value
	);
}

/**
 * for use with Grommet's DateInput
 */
export function getDateAsFloatOnChangeHandler(
	parentOnChange: (...event: any[]) => void
) {
	return function innerOnChange(
		event: string | { value: string | string[] }
	): void {
		let parsedTime: number;
		if (typeof event === "string") {
			parsedTime = new Date(event).getTime() / 1000;
		} else if (typeof event === "object") {
			if (Array.isArray(event.value)) {
				throw new Error("Why did we get an array of values?");
			}

			parsedTime = new Date(event.value).getTime() / 1000;
		} else {
			throw new Error("Could not parse value as date: " + event);
		}

		parentOnChange(parsedTime);
	};
}

/**
 * for use with a native <input type="datetime" /> element
 */
export function getDateAsFloatOnChangeHandlerForHtmlInput(
	parentOnChange: (...event: any[]) => void
) {
	return function htmlInputOnDateChange(
		event: ChangeEvent<HTMLInputElement>
	): void {
		const parsedTime = moment(event.target.value).unix() * 1000;

		parentOnChange(parsedTime);
	};
}
