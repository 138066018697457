/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	ApolloClient,
	DocumentNode,
	useMutation,
	FetchResult,
} from "@apollo/client";
import {
	DeepPartial,
	FieldValues,
	FormProvider,
	SubmitHandler,
	UnpackNestedValue,
	useForm,
	useWatch,
} from "@maana-io/react-hook-form";
import { Box, Button, Form, Stack } from "grommet";
import { isEmpty, get, fromPairs } from "lodash";
import React, { useMemo } from "react";
import GraphQLBridge from "uniforms-bridge-graphql";

import { makeGetSchema } from "../../api/apolloClient";
import { FormStateContext } from "../../context/FormStateContext";
import { RecursiveFormFields } from "./_RecursiveFormFields";
import { getSchemaBridgeForType } from "./GraphQLBridge";
import { LoadingIndicator } from "../LoadingIndicator";
import Alert from "@material-ui/lab/Alert";
import flatObject from "../../util/flatObject";

export interface OverrideItem<T = any> {
	label: string;
	value: T;
}

export interface ExternalGraphqlSchemaFormValidator<T extends FieldValues> {
	(data: T | UnpackNestedValue<T>): Record<string, string> | void;
}

type GQLFieldType =
	| "String"
	| "Number"
	| "Boolean"
	| "Date"
	| "DateTime"
	| "Time"
	| "JSON"
	| "Object"
	| "Array";

export type GraphQLSchemaFormProps<T extends FieldValues = FieldValues> = {
	kindName: string;
	mutation: DocumentNode;
	defaultValues: UnpackNestedValue<DeepPartial<T>>;
	flat?: boolean;
	recurseDepth?: number;
	client: ApolloClient<any>;
	getSchema: ReturnType<typeof makeGetSchema>;

	// * array of dot-separated paths to disable
	disableFields?: string[];

	// * array of dot-separated paths to hide from form
	hideFields?: string[];

	// * `key` is a dot-separated path to field
	// * `value` is an OverrideTuple with a list of items to use in the <select /> field,
	// *     along with a key to use as the display value
	nestedKindData?: Record<
		string,
		OverrideItem[] | ((formState: T) => OverrideItem[])
	>;

	// * flag to enable/disable recursion
	// * default: false
	recurse?: boolean;

	// * `key` is a dot-separated path to field
	// * `value` is a field type to use as an override
	fieldTypeOverrides?: Record<string, GQLFieldType>;

	// * used to "clean" data before it's submitted
	onBeforeSubmit?: (data: UnpackNestedValue<T>) => T;
	/** Called when the mutation succeeds */
	onSuccess?: (data: T | UnpackNestedValue<T>) => void;
	/** Called when the mutation fails */
	onFailure?: (err: any) => void;
	/** Allows doing extra validation before submit; Returning undefined means there were no errors -
	 * otherwise return an object where key is name of the field with error, and value is the error message */
	externalValidator?: ExternalGraphqlSchemaFormValidator<T>;
	/**
	 * Allows specifying extra restrictions/mappings for the input values - such as accepting only alpha-numeric values, or converting all input to uppercase etc
	 * @example
	 * { extraFieldNormalizers: { "foo.bar": (text) => text.toUpperCase() }}
	 */
	extraFieldNormalizers?: Record<string, (value: string) => unknown>;
	/** An array of manually specified keys for fields that should be required */
	requiredFields?: string[];
	overrideFieldLabels?: object | undefined;
	fieldValidationRules?: object | undefined;
	onCancel?: () => void;
	onChange?: (newValues: any) => void;
	watchFields?: string[];
};
export const FORM_PORTAL_ROOT_NODE_ID = "formPortalRoot";

export function GraphQLSchemaForm<T>({
	kindName,
	mutation,
	defaultValues,
	flat,
	recurseDepth,
	client,
	getSchema,
	disableFields,
	hideFields,
	nestedKindData,
	fieldTypeOverrides,
	onBeforeSubmit,
	onSuccess,
	onFailure,
	onCancel,
	externalValidator,
	extraFieldNormalizers,
	requiredFields,
	overrideFieldLabels,
	onChange,
	watchFields,
	fieldValidationRules,
}: GraphQLSchemaFormProps<T>) {
	const [schemaBridge, setSchemaBridge] = React.useState<GraphQLBridge>();
	const [schemaString, setSchemaString] = React.useState<string>();
	const [modelErrors, setModelErrors] = React.useState<Record<
		string,
		string
	> | void>(undefined);
	const [validationErrors, setValidationErrors] = React.useState<any[]>([]);
	const [requestErrors, setRequestErrors] = React.useState<string[]>();
	const formMethods = useForm<T>({
		defaultValues,
	});

	const fields = useWatch({
		control: formMethods.control as any,
		name: watchFields || [],
	});

	React.useEffect(() => {
		if (onChange !== null && onChange !== undefined) {
			onChange(fields);
		}
	}, [onChange, fields]);

	const [saveData, { data, loading, error }] = useMutation(mutation, {
		client,
	});

	React.useEffect(() => {
		const formattedErrors = get(error, "networkError.result.errors", []);
		setRequestErrors(formattedErrors.map((err: any) => err?.message));
	}, [error]);

	React.useEffect(() => {
		const fetchSchema = async () => {
			const schema = await getSchema();
			setSchemaBridge(getSchemaBridgeForType(schema, kindName));
			setSchemaString(schema);
		};
		fetchSchema();
	}, [getSchema, kindName]);

	const onSubmit: SubmitHandler<T> = React.useCallback(
		(data) => {
			try {
				console.group("Form Submit");
				console.log("original form data: ", data);

				setModelErrors({});
				setValidationErrors([]);

				// * check form errors
				if (isEmpty(modelErrors)) {
					const cleanedData = onBeforeSubmit
						? onBeforeSubmit(data)
						: data;
					if (externalValidator) {
						const errors = externalValidator(cleanedData);
						// console.log("errors: ", errors);
						if (errors) {
							setModelErrors(errors);
							return;
						}
					}

					console.log("cleaned form data: ", cleanedData);

					saveData({
						variables: { input: cleanedData },
					}).then(
						() => {
							onSuccess?.(cleanedData);
						},
						(error) => {
							console.error("Got error during mutation request");
							console.log(error);
							setRequestErrors(error.message);
							onFailure?.(error);
						}
					);
				} else {
					console.log(
						"did not save form data due to errors: ",
						modelErrors
					);
				}
			} catch (e) {
				console.error("Got error while saving form");
				console.error(e);
			} finally {
				console.groupEnd();
			}
		},
		[
			modelErrors,
			onBeforeSubmit,
			saveData,
			onSuccess,
			onFailure,
			externalValidator,
		]
	);

	const handleErrors = React.useCallback(
		(errors: Record<string, { message: string; type: string }>) => {
			const flatErrors: any = flatObject(errors);
			const errorMessages = Object.keys(flatErrors)
				.filter((ek) => ek.endsWith(".type"))
				.map((e) => {
					const validationType = flatErrors[e];
					const fieldKey = e.substr(0, e.lastIndexOf("."));
					const message = flatErrors[fieldKey + ".message"];
					return { field: fieldKey, message, type: validationType };
				});

			// console.log("flatObject: ", flatObject(errors));
			// console.log("abc: ", errorMessages);

			console.groupCollapsed("GQLSchemaForm errors");
			console.log("Errors: ", errors);
			console.log("ErrorMessages: ", errorMessages);
			console.log("State: ", formMethods.getValues());
			console.groupEnd();

			setValidationErrors(errorMessages);

			// setModelErrors(
			// 	fromPairs(
			// 		Object.entries(errors).map(([key, { type, message }]) => [
			// 			key,
			// 			type === "required"
			// 				? `${key} is required`
			// 				: `${key}: ${message}`,
			// 		])
			// 	)
			// );
		},
		[formMethods]
	);

	// * uncomment for debugging form values
	// const allFields = formMethods.watch();
	// console.log({ allFields });

	const submitHandler = React.useMemo(
		() => formMethods.handleSubmit(onSubmit, handleErrors as any),
		[formMethods, handleErrors, onSubmit]
	);

	const fieldNames = React.useMemo(
		() => (schemaBridge && schemaBridge.getSubfields()) || [],
		[schemaBridge]
	);

	const validationErrorObjects = React.useMemo(
		() =>
			validationErrors.reduce((acc: any, curr: any) => {
				if (curr.field) {
					acc[curr.field] = curr;
				}
				return acc;
			}, {}),
		[validationErrors]
	);

	const validationErrorMessages = useMemo(
		() => (modelErrors ? Object.entries(modelErrors) : []),
		[modelErrors]
	);

	return (
		<>
			<Box fill align="center" justify="center" key={`${kindName}-form`}>
				{!isEmpty(data) ? <Alert>Saved Successfully!</Alert> : null}
				{error ? <Alert severity="error">{error.message}</Alert> : null}
				{error && error.graphQLErrors.length > 0
					? error.graphQLErrors.map((error, errKey) => (
							<Alert
								key={errKey}
								severity="error"
								style={{ marginTop: 15 }}
							>
								{error.message}
							</Alert>
					  ))
					: null}
				{requestErrors && requestErrors.length ? (
					<Alert severity="error" style={{ marginTop: 15 }}>
						{requestErrors}
					</Alert>
				) : null}
				{validationErrorMessages.length > 0 &&
					validationErrorMessages.map(([field, message]) => (
						<Alert
							key={field}
							severity="error"
							style={{ marginTop: 15 }}
						>
							<strong>{field}:</strong>
							{message}
						</Alert>
					))}
				{
					// validationErrors.length > 0 &&
					// 	validationErrors.map(({ field, message }) => (
					// 		<Alert key={field} severity="error" style={{ marginTop: 15 }}>
					// 			<strong>{field}:</strong>
					// 			{message}
					// 		</Alert>
					// 	))
				}
				{schemaBridge && schemaString ? (
					<FormProvider {...formMethods}>
						<FormStateContext.Provider value={{ defaultValues }}>
							<Form
								key={`${kindName}-autoform`}
								onSubmit={submitHandler}
								style={{ padding: 10 }}
							>
								<Stack>
									<Box>
										<RecursiveFormFields
											parentFieldName="root"
											fieldNames={fieldNames}
											schemaBridge={schemaBridge}
											schemaString={schemaString}
											flat={Boolean(flat)}
											nestedKindData={
												nestedKindData as any
											}
											disableFields={disableFields}
											hideFields={hideFields}
											fieldTypeOverrides={
												fieldTypeOverrides
											}
											extraFieldNormalizers={
												extraFieldNormalizers
											}
											requiredFields={requiredFields}
											overrideFieldLabels={
												overrideFieldLabels
											}
											fieldValidationRules={
												fieldValidationRules
											}
											validationErrorObjects={
												validationErrorObjects
											}
										/>

										<div id={FORM_PORTAL_ROOT_NODE_ID} />

										<Box
											direction="row"
											gap="small"
											pad="small"
											alignContent="center"
											justify="center"
											style={{
												marginTop: 16,
												width: "100%",
											}}
										>
											<Button
												disabled={loading}
												label="Cancel"
												secondary
												onClick={() => {
													if (onCancel) onCancel();
												}}
											/>

											<Button
												type="submit"
												primary
												color={
													error
														? "status-error"
														: "blue1"
												}
												label="Submit"
												disabled={loading}
											/>
										</Box>
									</Box>

									{loading ? (
										<Box
											fill
											background="dark-1"
											style={{ opacity: 0.2 }}
											justify="center"
											align="center"
										>
											<LoadingIndicator
												size={42}
												color="white"
											/>
										</Box>
									) : null}
								</Stack>
							</Form>

							{/* <pre>data: {JSON.stringify(data, null, 2)}</pre> */}

							{/* <DevTool control={formMethods.control} /> */}
						</FormStateContext.Provider>
					</FormProvider>
				) : (
					<div>Loading schema...</div>
				)}
			</Box>
		</>
	);
}
