import React, { memo, useCallback } from "react";
import { useSelector as useReduxSelector } from "react-redux";
import { allTerminals as allTerminalSelector, portsSelector } from "../../../selectors/ports.selectors";
import { Product } from "../../../types/generated/q-vessel-schedule-lifecycle-v6.types";
import { Trade } from "../../../types/generated/q-fanar-requirements.types";
import { useMachineSelector } from "../../../selectors/machine.selectors";
import { ColumnConfig, Text, DataTable, TextInput, DateInput } from "grommet";
import InputDropdown from "../../InputDropdown";
import dayjs from "dayjs";
import { Maybe } from "graphql/jsutils/Maybe";
import { DATE_FORMAT, toDateFormat } from "../../../constants";

export interface TradeRow extends Trade {
	terminal?: string;
	terminalName?: string; //for display purposes only
	portName?: string; //for display purposes only
	index?: number;
	arrayLength?: number;
	isLong?: boolean;
}

type TradeTableFieldName = "port" | "terminal" | "startDate" | "endDate" | "productId" | "productQuantity";
type TradeTableRowEditabilityOptions = {
	[key in TradeTableFieldName]?: boolean;
};
export interface RequirementEditabilityOptions {
	longs: Array<TradeTableRowEditabilityOptions>;
	shorts: Array<TradeTableRowEditabilityOptions>;
}

interface Props {
	validate: (row: TradeRow) => Promise<void>;
	tradeRows?: TradeRow[];
	editabilityOptions: Array<TradeTableRowEditabilityOptions>;
}

export const TradesTable: React.FC<Props> = memo(({ validate, tradeRows, editabilityOptions }) => {
	const ports = useReduxSelector(portsSelector);

	const allProducts: Product[] = useMachineSelector((context) =>
		context.products.allIds.map((id) => context.products.byId[id])
	);

	const getPortName = useCallback((id: string) => ports.byId[id]?.name ?? id, [ports]);
	const allTerminals = allTerminalSelector(ports);

	const getTerminalName = useCallback(
		(portId?: string | null, terminalId?: string | null): string | undefined => {
			if (!terminalId || !portId) return;
			const port = ports.byId[portId];
			if (!port) return;
			const terminal = port.terminals?.find(({ terminalID }) => terminalID === terminalId);
			if (!terminal) return;
			return terminal.name ?? undefined;
		},
		[ports]
	);

	const reqColumns = (
		data: TradeRow[] | undefined,
		editabilityOptions: Array<TradeTableRowEditabilityOptions>
	): ColumnConfig<TradeRow>[] => {
		return [
			{
				property: "port",
				header: "Port",
				// primary: true,
				search: false,
				sortable: false,
				render: ({ id, port, portName, index }) => {
					return (
						<InputDropdown
							editable={!!editabilityOptions[index || 0]?.port}
							defaultValue={{ id: port, name: portName }}
							data={ports.allIds.map((value) => {
								return {
									id: ports.byId[value]?.id,
									name: `${ports.byId[value]?.id}: (${ports.byId[value]?.name})`,
								};
							})}
							onChange={(selection: any) => {
								const find = data?.find((f) => f.id === id);

								if (find) {
									validate({
										...find,
										port: selection?.id,
										portName: getPortName(selection?.id),
										terminal: undefined,
										terminalName: undefined,
									});
								}
							}}
						/>
					);
				},
			},
			{
				property: "terminal",
				header: "Terminal",
				search: false,
				sortable: false,
				render: ({ id, port, terminal, terminalName, index }) => {
					const terminals = allTerminals.filter((f) => f.port.id === port);
					return (
						<InputDropdown
							editable={!!editabilityOptions[index || 0]?.terminal}
							defaultValue={{ id: terminal, name: terminalName }}
							data={terminals.map((f) => {
								return {
									id: f.terminal.id,
									name: `${f.terminal.id}: (${f.terminal.name})`,
								};
							})}
							onChange={(selection: any) => {
								const find = data?.find((f) => f.id === id);
								if (find) {
									validate({
										...find,
										terminal: selection?.id,
										terminalName: getTerminalName(find.port, selection?.id),
									});
								}
							}}
						/>
					);
				},
			},
			{
				property: "startDate",
				header: "Start Date",

				render: ({ id, startDate, index }) =>
					renderDate(
						new Date(startDate * 1000),
						(value: string) => {
							const find = data?.find((f) => f.id === id);

							if (find) {
								validate({
									...find,
									startDate: dayjs(value).toDate().getTime() / 1000,
								});
							}
						},
						!!editabilityOptions[index || 0]?.startDate
					),
				// primary: true,
				search: false,
				sortable: false,
			},
			{
				property: "endDate",
				header: "End Date",
				render: ({ id, endDate, index }) =>
					renderDate(
						new Date(endDate * 1000),
						(value: string) => {
							const find = data?.find((f) => f.id === id);

							if (find) {
								validate({
									...find,
									endDate: dayjs(value).toDate().getTime() / 1000,
								});
							}
						},
						!!editabilityOptions[index || 0]?.endDate
					),
				// primary: true,
				search: false,
				sortable: false,
			},
			{
				property: "product.id",
				header: "Product",
				// primary: true,
				search: false,
				sortable: false,
				render: (tradeRow) => {
					let { id, product, index } = tradeRow;
					return (
						<InputDropdown
							editable={!!editabilityOptions[index || 0]?.productId}
							defaultValue={{ id: product.id, name: product.id }}
							data={allProducts.map((value) => {
								return { id: value.id, name: value.id };
							})}
							onChange={(selection: any) => {
								const find = data?.find((f) => f.id === id);
								if (find) {
									const findProduct = allProducts?.find((f) => f.id === selection.id);

									if (findProduct) {
										validate({
											...find,
											product: findProduct,
										});
									}
								}
							}}
						/>
					);
				},
			},
			{
				property: "productQuantity",
				header: "Quantity, bbl",
				render: ({ id, productQuantity, index }) =>
					renderEditableRow(
						`${productQuantity}`,
						(newText: string) => {
							const find = data?.find((f) => f.id === id);
							if (find) {
								validate({
									...find,
									productQuantity: parseInt(newText),
								});
							}
						},
						!!editabilityOptions[index || 0]?.productQuantity
					),
				// primary: true,
				search: false,
				sortable: false,
			},
		];
	};

	const renderEditableRow = (text: string | undefined | Maybe<string>, setValue: any, editable: boolean) => {
		if (editable) {
			return (
				<div className={"fleet-req-text-input"}>
					<TextInput
						type={"number"}
						placeholder={text}
						defaultValue={text || ""}
						onChange={(event) => {
							if (event.target.value && event.target.value.length > 0) {
								setValue(event.target.value);
							}
						}}
						onBlur={(event) => {
							if (event.target.value && event.target.value.length > 0) {
								setValue(event.target.value);
							}
						}}
					/>
				</div>
			);
		}
		return <Text>{text}</Text>;
	};

	const renderDate = (date: Date | undefined, onChange: any, editable: boolean) => {
		if (editable) {
			return (
				<div className={"fleet-req-date-input"}>
					<DateInput
						format={DATE_FORMAT}
						value={date?.toISOString()}
						onChange={({ value }) => onChange(value)}
					/>
				</div>
			);
		}
		return <Text>{toDateFormat(date) || " - "}</Text>;
	};

	if (!tradeRows?.length) {
		return null;
	}

	return (
		<DataTable<TradeRow>
			primaryKey="id"
			columns={reqColumns(tradeRows, editabilityOptions)}
			data={tradeRows}
			pad={{
				horizontal: "medium",
				vertical: "xsmall",
			}}
			background={{
				header: {
					color: "blue1",
				},
				body: ["light-1", "light-3"],
				footer: "dark-3",
			}}
		/>
	);
});
