import { gql } from "urql";
import { Query } from "../types/generated/q-vessel-schedule-lifecycle-v6.types";

export const GET_PORTS = gql<Pick<Query, "getAllPortsFromCache">>`
	query getAllPortsFromCache {
		getAllPortsFromCache {
			id
			name
			terminals {
				id
				terminalID
				name
				berths {
					id
					productsHandled
				}
			}
		}
	}
`;
