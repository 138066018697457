import { connectRouter, RouterState } from "connected-react-router";
import { History } from "history";
import { AnyAction, combineReducers, Reducer } from "redux";
import { IPortsState, portsReducer } from "./ports";
import { IRequirementReducerState, requirementReducer } from "./requirements";

const rootReducer = (history: History): Reducer<State, AnyAction> =>
	combineReducers<State>({
		router: connectRouter(history),
		requirements: requirementReducer,
		ports: portsReducer,
	});

interface State {
	router: RouterState;
	requirements: IRequirementReducerState;
	ports: IPortsState;
}

export type IState = State;

export default rootReducer;
