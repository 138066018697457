import type { ISchedulerItemAction } from "../mapIScheduleTuplesToBryntumEvent";
// import { Product } from "../../types/generated/q-fanar-requirements.types";

export type ActionType = "clean" | "wait" | "load" | "unload" | "voyage" | "refuel" | "";

export function getEventColorForAction(action: ISchedulerItemAction): string {
	const actionType = action.vesselAction?.actionType.id;
	let color = "";
	switch (actionType) {
		case "clean":
			color = "yellow";
			break;
		case "wait":
			color = "#454242";
			break;
		case "load":
			color = "#FF6B00";
			break;
		case "voyage":
			color = "#C4C4C4";
			break;
		case "unload":
			color = "#00761E";
			break;
		case "refuel":
			color = "black";
			break;
		default:
			color = "red";
			break;
	}
	return color;
}

export function getEventIconForAction(action: ISchedulerItemAction): string {
	let icon = "";
	const actionType = action.vesselAction?.actionType.id;
	switch (actionType) {
		case "clean":
			icon = "cleaning_services";
			break;
		case "wait":
			icon = "anchor";
			break;
		case "load":
			icon = "upload";
			break;
		case "voyage":
			icon = "arrow_right_alt";
			break;
		case "unload":
			icon = "download";
			break;
		case "refuel":
			icon = "battery_charging_full";
			break;
		default:
			icon = "";
			break;
	}
	return icon;
}

export function getEventLabelForAction(action: ISchedulerItemAction): string {
	let label = "";
	const actionType = action.vesselAction?.actionType.id;
	switch (actionType) {
		case "clean":
			label = "cleaning";
			break;
		case "wait":
			label = "waiting";
			break;
		case "load":
			label = "loading";
			break;
		case "voyage":
			label = "voyage";
			break;
		case "unload":
			label = "discharge";
			break;
		case "refuel":
			label = "refuel";
			break;
		default:
			label = "unknown";
			break;
	}
	return label;
}

export function getEventColorForProduct(id: string): string {
	switch (id) {
		case "FUEL OIL":
			return "#D1D1D1";
		case "VGO":
			return "#D1D1D1";
		case "GASOIL":
			return "#f2deaa";
		case "GASOLINE":
			return "#a0c486";
		case "GASOLINE 91":
			return "#a0c486";
		case "GASOLINE 95":
			return "#a0c486";
		case "NAPHTHA":
			return "#C592D3";
		case "MTBE":
			return "#F5B084";
		case "JET":
			return "#b7d3eb";
		default:
			return "#C0C0C0";
	}
}

interface EventColor {
	active: {
		base: string;
		shade: string;
	};
	disabled: {
		base: string;
		shade: string;
	};
}

export function getEventColorForProductNew(id: string): EventColor {
	let base = "",
		shade = "";
	let baseDisabled = "",
		shadeDisabled = "";

	switch (id) {
		case "FUEL OIL":
			base = "#D1D1D1";
			shade = "#ededed";

			baseDisabled = "#ededed";
			shadeDisabled = "#f8f8f8";
			break;
		case "VGO":
			base = "#D1D1D1";
			shade = "#ededed";

			baseDisabled = "#ededed";
			shadeDisabled = "#f8f8f8";
			break;
		case "GASOIL":
			base = "#f2deaa";
			shade = "#faf2dd";

			baseDisabled = "#faf2dd";
			shadeDisabled = "#fdfaf1";
			break;
		case "GASOLINE":
			base = "#a0c486";
			shade = "#d9e7cf";

			baseDisabled = "#d9e7cf";
			shadeDisabled = "#f0f5ec";
			break;
		case "GASOLINE 91":
			base = "#a0c486";
			shade = "#d9e7cf";

			baseDisabled = "#d9e7cf";
			shadeDisabled = "#f0f5ec";
			break;
		case "GASOLINE 95":
			base = "#a0c486";
			shade = "#d9e7cf";

			baseDisabled = "#d9e7cf";
			shadeDisabled = "#f0f5ec";
			break;
		case "NAPHTHA":
			base = "#C592D3";
			shade = "#e8d3ed";

			baseDisabled = "#e8d3ed";
			shadeDisabled = "#f6edf8";
			break;
		case "MTBE":
			base = "#F5B084";
			shade = "#fbdfce";

			baseDisabled = "#fbdfce";
			shadeDisabled = "#fdf2eb";
			break;
		case "JET":
			base = "#b7d3eb";
			shade = "#e2edf7";

			baseDisabled = "#e2edf7";
			shadeDisabled = "#f3f8fc";
			break;
		default:
			base = "#C0C0C0";
			shade = "#e6e6e6";

			baseDisabled = "#e6e6e6";
			shadeDisabled = "#f5f5f5";
			break;
	}

	return {
		active: {
			base: base,
			shade: shade,
		},
		disabled: {
			base: baseDisabled,
			shade: shadeDisabled,
		},
	};
}
