import React from "react";
import { useLocation } from "react-router-dom";

import { Stack, Box, Text } from "grommet";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";

import { useAuthContext } from "../context/AuthContext";

export function CallbackRoute() {
	return (
		<Stack anchor="center">
			<Box pad="large">
				<Text>
					<h1>
						<HourglassEmptyIcon
							style={{
								fontSize: "1em",
								float: "left",
								margin: "-8px 10px 0",
							}}
						/>{" "}
						<span>Preparing user experience...</span>
					</h1>
				</Text>
			</Box>
		</Stack>
	);
}

export function LoginRoute() {
	const location = useLocation();
	// @ts-ignore
	const { authClient } = useAuthContext();

	React.useEffect(() => {
		const startingUrl =
			// @ts-ignore
			location && location.state ? location.state.from : "/";
		authClient.login(startingUrl);
	}, [authClient, location]);

	return <div />;
}

export function LogoutRoute() {
	// @ts-ignore
	const { authClient } = useAuthContext();
	React.useEffect(() => {
		authClient.logout();
	});

	return <div />;
}
