import { Box, Button, Text } from "grommet";
import React, { useEffect } from "react";
import { Control, Resolver, ResolverSuccess, SubmitHandler, useFieldArray, useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import { useRoleManager } from "../../hooks/useRoleManager";
import { portsSelector } from "../../selectors/ports.selectors";
import {
	existingRequirementsWithBatchStateSelector,
	formValuesToRequirement,
	RequirementFormValues,
	requirementToFormValues,
	TradeFormValues,
	validateRequirement,
} from "../../selectors/requirement.selectors";
import { IPortsState } from "../../store/reducers/ports";
import { Requirement, RequirementAsInput, TradeAsInput } from "../../types/generated/q-fanar-requirements.types";
import { ControlledTextInput } from "../forms/ControlledTextInput";
import { RequirementErrors } from "./RequirementErrors";
import { SingleTradeFields } from "./SingleTradeFields";

const MAX_DIGITS_FOR_SPOT_COST = 15;

interface RequirementEditFormProps extends React.FormHTMLAttributes<HTMLFormElement> {
	requirement: RequirementAsInput;
	onSubmitRequirement: SubmitHandler<RequirementAsInput>;
	onCancel: any;
}

/** A function to connect the react hook form validation to our requirement validation function, and map form values to values to be persisted on BE */
const requirementResolver = (
	ports: IPortsState,
	allRequirements: Requirement[]
): Resolver<RequirementAsInput | RequirementFormValues> => (values) => {
	const requirement = formValuesToRequirement(values as RequirementFormValues);
	// console.log(requirement);
	const errors = validateRequirement(requirement, ports, allRequirements);
	// console.log("errors: ", errors);
	if (errors.length > 0) {
		return {
			errors: {
				/** For now all errors attached to id prop - but it would be ideal to assign them to the exact keys (returned by the validator) */
				id: {
					type: "validate",
					types: {
						validate: errors.map(({ message }) => message),
					},
				},
			},
			values: {},
		};
	}
	return {
		errors: {},
		values: requirement,
	} as ResolverSuccess<RequirementAsInput>;
};

requirementResolver.cache = new WeakMap();

/** Rejects negative values entered in spot cost and trims decimals after 2 */
function limitSpotCost(cost: string): string {
	if (isNaN(cost as any) || +cost < 0) return "0";
	if (!/^\d+\.?(\d+)?$/.test(cost)) return "";
	const [num, dig] = cost.split(".");
	if (dig && dig.length > 2) return `${num.slice(0, MAX_DIGITS_FOR_SPOT_COST)}.${dig.slice(0, 2)}`;

	return `${num.slice(0, MAX_DIGITS_FOR_SPOT_COST)}${dig !== undefined ? `.${dig}` : ""}`;
}

export const RequirementEditForm: React.FC<RequirementEditFormProps> = ({
	requirement,
	onSubmitRequirement,
	onCancel,
	...rest
}) => {
	const ports = useSelector(portsSelector);
	const existingRequirements = useSelector(existingRequirementsWithBatchStateSelector);

	const { control, register, handleSubmit, reset, formState, setValue } = useForm<
		RequirementFormValues | RequirementAsInput
	>({
		mode: "all",
		resolver: requirementResolver(ports, existingRequirements),
		shouldFocusError: false,
		criteriaMode: "all",
	});

	const data = useWatch({
		defaultValue: { loading: true },
		control,
	});

	//perhaps shouldnt do this here as it auto creates errors?
	const errors = validateRequirement(
		formValuesToRequirement(data as RequirementFormValues),
		ports,
		existingRequirements
	);

	const { isValid, isDirty } = formState;

	const { fields: longFields, append: appendLong, remove: removeLong } = useFieldArray<
		TradeAsInput | TradeFormValues,
		"keyId",
		Control<RequirementAsInput | RequirementFormValues>
	>({
		name: "longs",
		control,
		keyName: "keyId",
	});

	const { fields: shortFields, append: appendShort, remove: removeShort } = useFieldArray<
		TradeAsInput | TradeFormValues,
		"keyId",
		Control<RequirementAsInput | RequirementFormValues>
	>({
		name: "shorts",
		control,
		keyName: "keyId",
	});

	// Reset the form whenever the requirement is updated
	useEffect(() => {
		if (requirement) {
			reset(requirementToFormValues(requirement), {
				isDirty: true,
				isValid: true,
			});
		}
	}, [requirement, reset]);

	useEffect(() => {}, [formState]);

	const { can } = useRoleManager();
	// const canView = can("view requirements");
	const canEdit = can("manage requirements");
	if (!canEdit) return <div />;

	return (
		<form {...rest} onSubmit={handleSubmit(onSubmitRequirement)}>
			<input type="hidden" name="status.id" ref={register} />
			<input type="hidden" name="locked" ref={register} />
			<input type="hidden" name="createdBy" ref={register} />
			<input type="hidden" name="createdAt" ref={register} />

			<Box gap="small">
				{/* Requirement Details */}
				<Text weight="bold">Requirement Details</Text>

				<Box gap="small" pad={{ horizontal: "small" }} border={{ color: "brand" }}>
					<ControlledTextInput
						name="id"
						//label="Requirement ID"
						//placeholder="Requirement ID"
						defaultValue={requirement.id || ""}
						disabled
						control={control}
						type="hidden"
					/>
					<Box direction="row" justify="between" pad="small" gap="small">
						<ControlledTextInput
							name="shipmentId"
							label="Shipment ID"
							placeholder="Shipment ID"
							defaultValue={requirement.shipmentId || ""}
							control={control}
						/>
						<ControlledTextInput
							label="Estimated Spot Cost $"
							name="estimatedCostToSpotCharter"
							placeholder="Enter Spot Cost"
							type="number"
							min={0}
							defaultValue={requirement.estimatedCostToSpotCharter || 0}
							control={control}
							resolver={limitSpotCost}
						/>
					</Box>
				</Box>

				{/* Longs */}
				<Text weight="bold">Longs</Text>
				{longFields.map((long, index) => (
					<SingleTradeFields
						key={long.keyId}
						control={control}
						tradeType="longs"
						trade={long}
						id={index}
						setValue={setValue}
					/>
				))}
				<Box fill direction="row" align="center" justify="between" margin={{ bottom: "small" }}>
					<Button
						primary
						onClick={() => {
							const date = new Date().toISOString();
							appendLong({
								id: `${requirement.id}-long-${uuid()}`,
								dateRange: [date, date],
							});
						}}
						label="Add New Long"
					/>
					<Button secondary onClick={() => removeLong(longFields.length - 1)} label="Remove Long" />
				</Box>

				{/* Shorts */}
				<Text weight="bold">Shorts</Text>
				{shortFields.map((short, index) => (
					<SingleTradeFields
						key={short.keyId}
						id={index}
						control={control}
						trade={short}
						tradeType="shorts"
						setValue={setValue}
					/>
				))}

				<Box fill direction="row" align="center" justify="between">
					<Button
						onClick={() => {
							const date = new Date().toISOString();
							appendShort({
								id: `${requirement.id}-short-${uuid()}`,
								dateRange: [date, date],
							});
						}}
						primary
						label="Add New Short"
					/>
					<Button secondary onClick={() => removeShort(shortFields.length - 1)} label="Remove Short" />
				</Box>

				{/* Errors */}
				<RequirementErrors control={control} />

				{/* Submit button */}
				<Box
					direction="row"
					gap="small"
					pad="small"
					alignContent="end"
					justify="end"
					style={{
						width: "100%",
					}}
				>
					<Button
						label="Cancel"
						secondary
						onClick={() => {
							onCancel();
						}}
					/>

					<Button type="submit" disabled={!(isValid && isDirty) || !!errors.length} label="Submit" primary />
				</Box>
			</Box>
		</form>
	);
};
