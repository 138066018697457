import React, { memo, useCallback, useMemo, useState } from "react";
import moment from "moment";
import { Box, Button, Layer, Text } from "grommet";
import { useService } from "@xstate/react";

import { FleetMachineContext } from "../App";
import { FleetRequirementDetails } from "../components/Fleet/RequirementDetails/RequirementDetails";
import { FleetPanelActions } from "../components/FleetPanelActions";
import { PortRestrictionForm } from "../components/forms/PortRestrictionForm";
import { UnavailableTimeForm } from "../components/forms/UnavailableTimeForm";
import { LoadingIndicator } from "../components/LoadingIndicator";
import {
	mapFleetMachineStateToVessels,
	mapIScheduleTuplesToBryntumEvents,
} from "../components/mapIScheduleTuplesToBryntumEvent";
import SchedulerPage from "./Scheduler.page";
import { Requirement, VesselWithQ88PRandUtOutput } from "../types/generated/q-vessel-schedule-lifecycle-v6.types";
import CommonErrorModal from "../components/CommonErrorModal";
import { OffScheduleRequirementInfo } from "../machines/testSchedules";

/** Just a shorthand for matching details panel states */
const dpPath = "idle.ready.detailsPanel";

export const FleetPage = React.memo(() => {
	// * get machine
	const fleetMachineService = React.useContext(FleetMachineContext);
	const [current, send] = useService(fleetMachineService);
	const [openForm, setOpenForm] = useState<"pr" | "ut" | null>(null);

	const onClose = useCallback(() => {
		setOpenForm(null);
		send("CLOSE");
	}, [setOpenForm, send]);

	const { context } = current;
	// console.log("fleetMachineService current context:", context);
	let {
		schedule: allSchedules = [],
		selectedVesselId,
		vessels = [],
		errrorMessage,
		scheduleFilter,
		selectedDateRange: [startDate, endDate],
		requirements,
		missingFleetRequirements,
		offScheduleRequirements,
	} = context;
	offScheduleRequirements = offScheduleRequirements || [];

	const vesselsWithActiveContract = useMemo(
		() =>
			vessels.filter((v) => {
				if (!v?.vessel?.details?.contractExpiration || v.vessel.isParked) return false;

				return moment(v?.vessel?.details?.contractExpiration) > moment(startDate);
			}),
		[vessels, startDate]
	);

	const vessel = useMemo(() => vesselsWithActiveContract.find(({ vessel: { id } }) => id === selectedVesselId), [
		vesselsWithActiveContract,
		selectedVesselId,
	]);

	React.useEffect(() => {
		(window as any).current = current;
		(window as any).vessel = vessel;
	}, [current, vessel]);

	const detailsPanelOpen = current.matches(dpPath);

	const hasLoadingError = React.useMemo(
		() =>
			current.matches("idle.loading.loadingSchedule.failure") ||
			current.matches("idle.loading.loadingVessels.failure"),
		[current]
	);

	// TODO!! maybe add here the code for refetching current schedule
	const isLoadingFleetData = React.useMemo(
		() =>
			!current.matches("idle.ready") ||
			current.matches("idle.ready.requirementAssignmentInProgress") ||
			current.matches("idle.ready.calculatingGetFleet") ||
			current.matches("idle.ready.calculatingGetCalculate") ||
			current.matches("idle.ready.promotionInProgress") ||
			current.matches("idle.ready.gettingFleet.loading") ||
			current.matches("idle.ready.finishingSpotSchedule"),

		[current]
	);

	const isLoadingDetails = useMemo<boolean>(() => current.matches(`${dpPath}.requirementClicked.loading`), [current]);

	const isCalculateInprogress = React.useMemo(
		() =>
			current.matches("idle.ready.calculatingGetFleet") ||
			current.matches("idle.ready.calculatingGetCalculate"),
		[current]
	);


	const reqs = useMemo<Requirement[]>(() => {
		const combinedRequirements: {
			real: Requirement[];
			missing: Requirement[];
		} = {
			real: [],
			missing: [],
		};
		if (requirements?.allIds?.length) {
			const realReqs = requirements.allIds.map((id) => requirements?.byId[id]);
			if (realReqs.length) {
				combinedRequirements.real = realReqs;
			}
		}

		if (missingFleetRequirements?.allIds?.length) {
			const missingReqs = missingFleetRequirements.allIds?.map((id) => missingFleetRequirements?.byId[id]);
			if (missingReqs.length) {
				combinedRequirements.missing = missingReqs;
			}
		}

		return [...combinedRequirements.real, ...combinedRequirements.missing];
	}, [requirements, missingFleetRequirements]);

	const items = useMemo(() => {
		const bits = mapIScheduleTuplesToBryntumEvents(
			allSchedules,
			scheduleFilter,
			vesselsWithActiveContract,
			reqs,
			offScheduleRequirements || []
		);
		return bits;
	}, [allSchedules, scheduleFilter, vesselsWithActiveContract, reqs, offScheduleRequirements]);

	const allVessels = useMemo(() => mapFleetMachineStateToVessels(vesselsWithActiveContract, allSchedules), [
		vesselsWithActiveContract,
		allSchedules,
	]);
	return (
		<Box fill="vertical">
			<Box>
				<FleetPanelActions requirements={reqs} machineService={fleetMachineService} />
			</Box>
			<Box fill>
				<Box fill="vertical">
					{isCalculateInprogress ? (<Box align="center" pad="xlarge">Calculating optimal schedule...</Box>) : (
						<SchedulerPage
							machineService={fleetMachineService}
							resources={allVessels}
							events={items}
							send={send}
							isLoading={isLoadingFleetData && !hasLoadingError}
							startDate={startDate}
							endDate={endDate}
							offScheduleRequirements={offScheduleRequirements || []}
						/>
					)}
				</Box>
			</Box>

			<CommonErrorModal
				isOpen={Boolean(errrorMessage)}
				errorMessage={errrorMessage}
				onClose={() => send("DISMISS_SNACKBAR")}
			/>

			{detailsPanelOpen && (
				<Layer
					position="right"
					full="vertical"
					modal
					responsive
					onClickOutside={(evt: any) => {
						if (
							evt.target &&
							"classList" in evt.target &&
							evt.target.classList.value.includes("StyledLayer")
						) {
							onClose();
						}
					}}
					onEsc={onClose}
				>
					<Box fill="vertical" overflow="auto" width="xlarge" pad="medium">
						<Box flex={false} direction="row" justify="between">
							<Button secondary onClick={() => send("CLOSE")} label="Close"></Button>
						</Box>
						<Box flex="grow" overflow="auto" pad={{ vertical: "medium" }}>
							{
								isLoadingDetails ? (
									<Box alignSelf="center" align="center" justify="center" gap="medium">
										<Text>Fetching details...</Text>
										<LoadingIndicator size={42} color="var(--primary)" />
									</Box>
								) : !!current.matches(`${dpPath}.vesselClicked`) ? (
									<VesselDetailsPanel
										openForm={openForm}
										setOpenForm={setOpenForm}
										offScheduleRequirements={offScheduleRequirements}
										vessel={vessel}
									/>
								) : !!current.matches(`${dpPath}.requirementClicked.requirementDetailsLoaded`) ? (
									<FleetRequirementDetails />
								) : (
									<Box>oops</Box>
								) //end of isloading condition
							}
						</Box>
					</Box>
				</Layer>
			)}
		</Box>
	);
});

interface VesselDetailsPanelProps {
	openForm: "pr" | "ut" | null;
	setOpenForm: React.Dispatch<React.SetStateAction<"pr" | "ut" | null>>;
	offScheduleRequirements: OffScheduleRequirementInfo[];
	vessel: VesselWithQ88PRandUtOutput | undefined;
}

const VesselDetailsPanel = memo(
	({ openForm, setOpenForm, offScheduleRequirements, vessel }: VesselDetailsPanelProps) => {
		const newPR = openForm === "pr";
		const newUT = openForm === "ut";
		return (
			<Box>
				{vessel && (
					<Box pad="small" gap="small">
						<Box direction="row" gap="xsmall">
							<div
								style={{
									fontSize: 20,
									fontWeight: 700,
								}}
							>
								{vessel?.vessel.name} -
							</div>
							<div
								style={{
									color: "#59b5d9",
								}}
							>
								{vessel.id}
							</div>
						</Box>
						<Box
							style={{
								padding: "18px",
								color: "red",
							}}
						>
							{offScheduleRequirements
								.filter((o) => o.vesselId === vessel.id)
								.map((o, index) => (
									<div
										style={{
											padding: "2px",
										}}
										key={index}
									>
										{o.message}
									</div>
								))}
						</Box>
						<Box>
							<Box direction="row" gap="small" pad="small">
								<Button onClick={() => setOpenForm("pr")} primary label="Add Port Restriction" />
								<Button onClick={() => setOpenForm("ut")} primary label="Add Unavailable Time" />
							</Box>

							{/* New Port Restriction */}
							{newPR && vessel.id && (
								<Box gap="small" pad="small" border="all">
									<Text size="large">New Port Restriction</Text>
									<PortRestrictionForm vesselId={vessel.id} onCancel={() => setOpenForm(null)} />
								</Box>
							)}

							{newUT && vessel.id && (
								<Box gap="small" pad="small" border="all">
									<Text size="large">New Unavailable Time</Text>

									<UnavailableTimeForm vesselId={vessel.id} onCancel={() => setOpenForm(null)} />
								</Box>
							)}
						</Box>
					</Box>
				)}
			</Box>
		);
	}
);
