import Alert from "@material-ui/lab/Alert";
import { Text } from "grommet";
import React from "react";
import { Control, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";

import { portsSelector } from "../../selectors/ports.selectors";
import {
	existingRequirementsWithBatchStateSelector,
	formValuesToRequirement,
	RequirementFormValues,
	validateRequirement,
} from "../../selectors/requirement.selectors";

interface RequirementErrorsProps {
	children?: never;
	control: Control;
}

const style = { marginBottom: 4 };

export const RequirementErrors: React.FC<RequirementErrorsProps> = ({
	control,
}) => {
	const data = useWatch({
		defaultValue: { loading: true },
		control,
	});
	const ports = useSelector(portsSelector);
	const existingRequirements = useSelector(
		existingRequirementsWithBatchStateSelector
	);

	if (data.loading) return null;
	const errors = validateRequirement(
		formValuesToRequirement(data as RequirementFormValues),
		ports,
		existingRequirements
	);
	if (errors.length === 0) return null;
	return (
		<>
			{errors.map(({ message }) => (
				<Alert key={message} style={style} severity="error">
					<Text size="medium">{message}</Text>
				</Alert>
			))}
		</>
	);
};
