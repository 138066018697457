import React, { useCallback, useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Heading } from "grommet";

const useStyles = makeStyles((theme) => ({
	overlayBackdrop: {
		display: "flex",
		position: "fixed",
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		background: "rgba(255, 255, 255, 0.4)",
		backdropFilter: "blur(3px)",
		padding: "30px",
		animation: "300ms ease-in-out 0s 1 $appearance",
	},
	overlayContainer: {
		display: "flex",
		flexGrow: 1,
		borderRadius: "8px",
		borderWidth: "4px",
		borderColor: theme.palette.divider,
		borderStyle: "dashed",
		alignItems: "center",
		justifyContent: "center",
	},
	"@keyframes appearance": {
		from: {
			opacity: 0,
		},
		to: {
			opacity: 1,
		},
	},
}));

interface FileDropProps extends React.HTMLAttributes<HTMLDivElement> {
	/** A callback called when user drops a file in this component */
	onFileDropped?: (files: FileList) => void;
	/** Content to show while user is dragging over the window to indicate he can drop the file here */
	labelWhileDragging?: React.ReactNode;
	disabled?: boolean;
}

/**
 * A component that turns its children in a drop area (for file upload), and calls the provided callback, when a file has been dropped
 */
export const FileDrop: React.FC<FileDropProps> = ({
	children,
	labelWhileDragging = <Heading>You can drop here</Heading>,
	onFileDropped,
	disabled,
	...rest
}) => {
	const { overlayBackdrop, overlayContainer } = useStyles();
	const [draggingPhase, setPhase] = useState<
		"off" | "dragging" | "waitBeforeHide"
	>("off");

	// Unfortunately some "dragLeave" events need to be ignored - therefore we'll hide the overlay only 100ms after receiving "leave" event
	useEffect(() => {
		// whenever we enter the "wait" phase - start a timer to update it to "off" in 100ms
		const timeout =
			draggingPhase === "waitBeforeHide"
				? window.setTimeout(() => {
						setPhase("off");
				  }, 100)
				: 0;

		if (timeout) {
			return () => {
				window.clearTimeout(timeout);
			};
		}
	}, [draggingPhase]);

	const onLeave = useCallback<React.DragEventHandler>(() => {
		setPhase("waitBeforeHide");
	}, []);

	const onDrag = useCallback<React.DragEventHandler>((e) => {
		e.preventDefault();
		if (e.dataTransfer) {
			e.dataTransfer.dropEffect = "move";
		}
		setPhase("dragging");
	}, []);

	const onDrop = useCallback<React.DragEventHandler>(
		(e) => {
			e.preventDefault();
			setPhase("off");
			if (e.dataTransfer) {
				e.dataTransfer.dropEffect = "move";
				onFileDropped?.(e.dataTransfer.files);
			}
		},
		[onFileDropped]
	);

	/** Overlay is shown while drag is active PLUS 100ms after leave gets dispatched */
	const showOverlay = !disabled && draggingPhase !== "off";

	return (
		<div
			{...rest}
			onDragOver={(event) => !disabled && onDrag(event)}
			onDragLeave={(event) => !disabled && onLeave(event)}
			onDrop={(event) => !disabled && onDrop(event)}
		>
			{children}
			{showOverlay && (
				<div className={overlayBackdrop}>
					<div className={overlayContainer}>{labelWhileDragging}</div>
				</div>
			)}
		</div>
	);
};
