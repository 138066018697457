import { Requirement } from "../../../types/generated/q-fanar-requirements.types";
import { useSelector } from "@xstate/react";
import { useSelector as useReduxSelector } from "react-redux";
import { ColumnConfig, Text, Box, DataTable } from "grommet";
import React, { memo, useCallback, useContext, useMemo } from "react";
import { FleetMachineContext } from "../../../App";
import { capitalCase } from "capital-case";
import { formatCurrency, formatNumber } from "../../../util/numberFormatter";
import { FanarDate } from "../../../util/fanarDate";
import { DATE_TIME_FORMAT } from "../../../constants";
import { portsSelector } from "../../../selectors/ports.selectors";
import {
	I_ActualScheduledRequirement,
	I_ActualVesselSchedule,
	I_PlannedScheduledRequirement,
	I_PlannedVesselSchedule,
} from "../../../types/generated/q-vessel-schedule-lifecycle-v6.types";
import { sumBy } from "lodash";

interface RenderedAction {
	actionType: string;
	isActual: boolean;
	cost: number;
	portName: string;
	fuelRemaining: number;
	startDate?: any;
	endDate?: any;
	status?: any;
	speed?: any;
}

function renderActionType(type?: string): string {
	if (type?.toLowerCase() === "unload") return "Discharge";
	return capitalCase(type ?? "");
}

const columns: ColumnConfig<RenderedAction>[] = [
	{
		property: "vesselAction.actionType.id",
		header: "Action",
		render: ({ actionType, status }) => {
			let statusColor = "#bcbcbc";
			switch (status) {
				case "In Progress":
					statusColor = "#d67900";
					break;
				case "Complete":
					statusColor = "#67a777";
					break;
				default:
			}
			return (
				<Box>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							fontSize: 14,
							fontWeight: 500,
						}}
					>
						{status && (
							<div
								style={{
									display: "inline-block",
									width: 15,
									height: 15,
									position: "relative",
									top: "4px",
									left: "-10px",
									borderRadius: "50%",
									cursor: "help",
									background: statusColor,
								}}
								title={status}
							/>
						)}
						<span>{renderActionType(actionType)}</span>
					</div>
				</Box>
			);
		},
		search: false,
		sortable: false,
	},
	{
		property: "isActual",
		header: "A / P",
		render: ({ isActual }) => (
			<Box align="center">
				{isActual ? (
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							fontSize: 14,
							fontWeight: 500,
						}}
					>
						A
					</div>
				) : (
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							fontSize: 14,
							fontWeight: 500,
						}}
					>
						P
					</div>
				)}
			</Box>
		),
		search: false,
		sortable: false,
	},
	{
		property: "cost",
		header: "Cost",
		render: ({ cost }) => (
			<Box>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						fontSize: 14,
						fontWeight: 500,
					}}
				>
					{formatCurrency(cost)}
				</div>
			</Box>
		),
		search: false,
		sortable: false,
	},
	{
		property: "portName",
		header: "Port",
		search: false,
		sortable: false,
		render: (datum) => (
			<Box>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						fontSize: 14,
						fontWeight: 500,
					}}
				>
					{datum.portName}
				</div>
			</Box>
		),
	},
	{
		property: "startDate",
		header: "Start",
		render: (datum) => (
			<Box>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						fontSize: 14,
						fontWeight: 500,
					}}
				>
					{datum?.startDate
						? FanarDate(datum?.startDate).tz("UTC").format(DATE_TIME_FORMAT)
						: //toDateTimeFormat(	moment.unix(datum.startDate).toDate())
						  " - "}
				</div>
			</Box>
		),
		// primary: true,
		search: false,
		sortable: false,
	},
	{
		property: "endDate",
		header: "End",
		render: (datum) => (
			<Box>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						fontSize: 14,
						fontWeight: 500,
					}}
				>
					{datum?.endDate
						? FanarDate(datum?.endDate).tz("UTC").format(DATE_TIME_FORMAT)
						: //toDateTimeFormat(	moment.unix(datum.startDate).toDate())
						  " - "}
				</div>
			</Box>
		),
		// primary: true,
		search: false,
		sortable: false,
	},
	{
		property: "speed",
		header: "Speed",
		render: ({ speed }) => (
			<Box>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						fontSize: 14,
						fontWeight: 500,
					}}
				>
					{speed} kts
				</div>
			</Box>
		),
		search: false,
		sortable: false,
	},
	{
		property: "fuelRemaining",
		header: "Fuel Remaining",
		render: ({ fuelRemaining }) => (
			<Box>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						fontSize: 14,
						fontWeight: 500,
					}}
				>
					{formatNumber(Number(fuelRemaining?.toFixed(2)))} bbl
				</div>
			</Box>
		),
		search: false,
		sortable: false,
	},
];

interface Props {
	selectedRequirement: Requirement | undefined;
}

export const RequirementActionsInfo: React.FC<Props> = memo(({ selectedRequirement }) => {
	const fleetMachineService = useContext(FleetMachineContext);

	const ports = useReduxSelector(portsSelector);
	const getPortName = useCallback((id: string) => ports.byId[id]?.name ?? id, [ports]);
	type RequirementSheduleTuple =
		| {
				requirement: I_ActualScheduledRequirement;
				schedule: I_ActualVesselSchedule;
		  }
		| {
				requirement: I_PlannedScheduledRequirement;
				schedule: I_PlannedVesselSchedule;
		  };

	// TODO check if needed in original component
	const { requirement, schedule } = useSelector(
		fleetMachineService,
		({ context: { selectedRequirementId, schedule } }): Partial<RequirementSheduleTuple> => {
			if (!selectedRequirementId) return {};
			const { id, isActual } = selectedRequirementId;
			return (
				schedule
					.flatMap<RequirementSheduleTuple>(({ plannedSchedule, actualSchedule }) =>
						isActual
							? (actualSchedule?.requirements ?? []).map((requirement) => ({
									requirement,
									schedule: actualSchedule!,
							  }))
							: plannedSchedule.requirements.map((requirement) => ({
									requirement,
									schedule: plannedSchedule,
							  }))
					)
					.find(({ requirement: { requirementId } }) => requirementId === id) ?? {}
			);
		},
		(a, b) => a.requirement === b.requirement && a.schedule === b.schedule
	);

	const actions = useMemo<RenderedAction[] | undefined>(
		() =>
			requirement &&
			("actualVesselActions" in requirement
				? (requirement.actualVesselActions || []).map<RenderedAction>(
						({
							vesselAction: {
								actionType: { id: actionType },
							},
							actualCost: cost,
							endState: { port, fuelRemaining },
							actualStartDate,
							actualEndDate,
							actionStatus: { id: actionStatusType },
							speed,
						}) => ({
							actionType,
							isActual: true,
							cost,
							portName: getPortName(port) || port || " - ",
							fuelRemaining,
							startDate: actualStartDate,
							endDate: actualEndDate,
							status: actionStatusType,
							speed: speed,
						})
				  )
				: (requirement.plannedVesselActions || []).map<RenderedAction>(
						({
							vesselAction: {
								actionType: { id: actionType },
							},
							estimatedCost: cost,
							optimalEndState: { port, fuelRemaining },
							estimatedStartDate,
							estimatedEndDate,
							speed,
						}) => ({
							actionType,
							isActual: false,
							cost,
							portName: getPortName(port) || port || " - ",
							fuelRemaining,
							startDate: estimatedStartDate,
							endDate: estimatedEndDate,
							status: null,
							speed: speed,
						})
				  )),
		[requirement, getPortName]
	);

	const scheduleCost = useMemo<number>(() => {
		if (!schedule) return 0;
		if (schedule.isSpot) return selectedRequirement?.estimatedCostToSpotCharter ?? 0;
		if (!actions) return 0;
		return sumBy(actions, ({ cost }) => +cost);
	}, [schedule, actions, selectedRequirement?.estimatedCostToSpotCharter]);

	return (
		<Box>
			<Box direction="row" gap="medium" pad="xsmall" background="light-1">
				<Text weight="bold" size="large">
					Requirement:
				</Text>
				<Text size="large"> {requirement?.requirementId}</Text>
				<Text weight="bold" size="large">
					Shipment:{" "}
				</Text>
				<Text size="large">{selectedRequirement?.shipmentId}</Text>
			</Box>

			<Box direction="row" gap="medium" margin={{ top: "medium" }} pad="xsmall" background="dark-2">
				<Text weight="bold" size="large">
					Total Cost of Schedule:{" "}
				</Text>
				<Text size="large">{formatCurrency(scheduleCost)}</Text>
			</Box>

			<Box margin={{ top: "medium", bottom: "medium" }}>
				<Box margin={{ bottom: "small" }}>
					<Text size="large" weight="bold">
						Schedule Actions
					</Text>
				</Box>

				{actions && actions.length > 0 && (
					<DataTable<RenderedAction>
						// primaryKey={false}
						primaryKey="id"
						columns={columns}
						data={actions}
						pad={{
							horizontal: "medium",
							vertical: "xsmall",
						}}
						background={{
							header: {
								color: "blue1",
							},
							body: ["light-1", "light-3"],
							footer: "dark-3",
						}}
					/>
				)}
			</Box>
		</Box>
	);
});
