import { useService } from "@xstate/react";
import React from "react";
import { FleetMachineContext } from "../App";
import { IFleetMachineContext } from "../machines/fleetMachine";

export const useMachineSelector = (
	cb: (context: IFleetMachineContext) => any
) => {
	const fleetMachineService = React.useContext(FleetMachineContext);
	const [current] = useService(fleetMachineService);

	return cb(current.context);
};
