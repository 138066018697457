import cx from "classnames";
import { useQuery } from "urql";
import {
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	makeStyles,
	Popover,
} from "@material-ui/core";
import { Box, Button, Text, TextInput } from "grommet";
import { CircleInformation } from "grommet-icons";
import React, { useState } from "react";

import { LoadingIndicator } from "../LoadingIndicator";
import { CHECK_VESSEL } from "../../queries/vessels";
import { VESSEL_SCHEDULE_LIFECYCLE_URL } from "../../api/UrqlClientProvider";
import { useHistory } from "react-router";
import { Q88Vessel } from "../../types/generated/q-vessel-schedule-lifecycle-v6.types";

const useStyles = makeStyles({
	hasError: {
		borderColor: "red",
	},
});

interface Q88ModalProps {
	isOpen: boolean;
	onClose: () => void;
	// onSubmit: (event: React.FormEvent) => void;
}

type Q88VesselStatus = "clean" | "valid" | "invalid" | "incomplete";
type ButtonTextStates = "Check" | "Next" | "Invalid" | "Incomplete";

type Q88VesselStatusMap = {
	[key in Q88VesselStatus]: ButtonTextStates;
};

const Q88VesselStatusButtonMap: Q88VesselStatusMap = {
	clean: "Check",
	invalid: "Invalid",
	valid: "Next",
	incomplete: "Incomplete",
};

const requestContext = {
	url: VESSEL_SCHEDULE_LIFECYCLE_URL,
};

export interface Q88VesselInHistory {
	q88vessel?: Q88Vessel;
	imo?: string;
}

export const Q88Modal = ({ isOpen, onClose }: Q88ModalProps) => {
	const classes = useStyles();
	const [checkedVesselImo, setCheckedVesselImo] = useState<null | string>(
		null
	);
	const [{ fetching: loading, data, error }] = useQuery({
		query: CHECK_VESSEL,
		pause: checkedVesselImo === null,
		context: requestContext,
		variables: { imo: checkedVesselImo },
	});
	const history = useHistory<Q88VesselInHistory>();

	const [
		q88VesselStatus,
		setQ88VesselStatus,
	] = React.useState<Q88VesselStatus>("clean");

	const [vesselData, setVesseldata] = React.useState<{
		id: string;
		scnt: string | number;
	}>({ id: "", scnt: "" });
	const handleSubmit = React.useCallback((event) => {
		event.preventDefault();
		setCheckedVesselImo(event.target.id.value);
	}, []);

	// * this function will run every time `data` changes
	React.useEffect(() => {
		if (error) {
			setQ88VesselStatus("invalid");
			return;
		}

		//* data isn't there, skip it
		if (data === null || data === undefined) {
			console.log("falsy");
			return;
		}

		// * if it's falsy, skip it
		if (!data) {
			console.log("falsy");
			return;
		}

		const vesselId = data?.vesselExistsInQ88?.id;

		//* no vessel returned from BE, skip it
		if (vesselId === null || vesselId === undefined) {
			console.log("falsy");
			return;
		}

		//* dummy vessel returned from BE, skip it
		if (vesselId === "does_not_exist") {
			console.log("falsy");
			return;
		}

		// * do stuff with `data` such as checking validity
		// console.log(data);

		if (
			vesselId &&
			data.vesselExistsInQ88.scnt &&
			data.vesselExistsInQ88.carryingCapacity.fuelCapacity
		) {
			// * valid entry,
			// console.log("got result from q88: ", data);

			//set more data here
			setVesseldata({
				id: vesselId,
				scnt: data.vesselExistsInQ88.scnt,
			});
			// console.log(vesselData);
			setQ88VesselStatus("valid");
			history.push("/vessels/edit/new", {
				q88vessel: data.vesselExistsInQ88,
				imo: checkedVesselImo ?? undefined,
			});
		} else {
			if (vesselId) {
				setVesseldata({
					id: vesselId,
					scnt: data.vesselExistsInQ88.scnt,
				});
				setQ88VesselStatus("incomplete");
			} else {
				console.log("completey failed");
				setVesseldata({ id: "error", scnt: "" });
				setQ88VesselStatus("invalid");
			}
		}

		// * we can navigate to a new page with:
		// dispatch(push(`/vessels/edit/${data.vesselExistsInQ88.id}`));
	}, [
		checkedVesselImo,
		data,
		error,
		history,
		setQ88VesselStatus,
		vesselData,
	]);

	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null
	);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	return (
		<Dialog open={isOpen} onClose={onClose} maxWidth="xs" fullWidth={true}>
			<DialogTitle id="check-vessel-dialog-title">
				<Box justify="between" direction="row" align="center">
					<Text>Check Vessel in Q88</Text>
					<Button
						plain
						color="blue1"
						onClick={handleClick}
						icon={<CircleInformation size="medium" />}
					/>

					<Popover
						id={id}
						open={open}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{
							vertical: "center",
							horizontal: "right",
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "left",
						}}
					>
						<Box pad="small" gap="small" width={{ max: "small" }}>
							<>
								<Text size="small">
									This checks for the existence of a vessel on{" "}
									<a href="https://corp.q88.com/q88com/">
										Q88.com
									</a>
									. If a vessel is not found in Q88, you
									cannot create a vessel in Fanar.
								</Text>
							</>
						</Box>
					</Popover>
				</Box>
			</DialogTitle>

			<DialogContent dividers>
				<form onSubmit={handleSubmit}>
					<TextInput
						defaultValue=""
						name="id"
						id="id"
						placeholder="IMO Number"
						onFocus={() => setQ88VesselStatus("clean")}
						disabled={loading}
						className={cx({
							[classes.hasError]: q88VesselStatus === "invalid",
						})}
					/>

					<Box
						pad="small"
						align="center"
						justify={loading ? "between" : "end"}
						direction="row"
					>
						{loading ? (
							<LoadingIndicator color="#fd6fff" />
						) : undefined}
						<Button
							type="submit"
							color="blue1"
							disabled={q88VesselStatus === "invalid" || loading}
							label={Q88VesselStatusButtonMap[q88VesselStatus]}
						/>
					</Box>
					{q88VesselStatus === "invalid" && (
						<Box>
							<Text size="small">Vessel not found q88</Text>
							<Text size="small">
								Please contact the vessel owner
							</Text>
						</Box>
					)}
					{q88VesselStatus === "incomplete" && (
						<Box>
							<Text size="small">IMO: {vesselData.id}</Text>
							<Text size="small">SCNT: {vesselData.scnt}</Text>
							<Divider />
							<Text size="small">
								Please contact the vessel owner
							</Text>
						</Box>
					)}
				</form>
			</DialogContent>
		</Dialog>
	);
};
