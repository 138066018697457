/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback } from "react";
import "../components/utility/CustomSchedulerRequirementTooltip";
import "../styles.css";
import "@bryntum/schedulerpro/schedulerpro.stockholm.css";

import { SchedulerResourceModel, SchedulerPro } from "@bryntum/schedulerpro";
import { connect } from "react-redux";
import { escapeRegExp, throttle } from "lodash";

import {
	BeforeEventDropFinalizeEvent,
	BryntumSchedulerPro,
	BryntumSchedulerProps,
} from "../components/utility/BryntumSchedulerWrapper";

import type { FleetMachineEvent } from "../machines/fleetMachine.events";
import { from, interval, race, Subject, Subscribable } from "rxjs";
import type { EventListener } from "xstate/lib/interpreter";
import { first, mapTo, delay, take, takeUntil } from "rxjs/operators";
import { IFleetInterpreter, IFleetMachineState } from "../App";
import type { ISchedulerItem, ItemOrUT } from "../components/mapIScheduleTuplesToBryntumEvent";
import { PayloadSender } from "@xstate/react/lib/types";
import { LOCK_REQUIREMENT_TO_SPOT, LOCK_REQUIREMENT_TO_VESSEL, UNLOCK_REQUIREMENT } from "../queries/requirements";
import { vesselScheduleLifecycleClient } from "../api/vesselScheduleLifecycleClient";
import { Dispatch } from "redux";
import {
	IRequirementReducerActions,
	LOCK_REQUIREMENT_TO_VESSEL_ACTION,
	UNLOCK_REQUIREMENT_ACTION,
	LOCK_REQUIREMENT_TO_SPOT_ACTION,
} from "../store/actions/requirements.actions";
import { getRoleManager } from "../hooks/useRoleManager";
import CommonErrorModal from "../components/CommonErrorModal";
import { eventBodyTemplate, eventRenderer } from "./Scheduler/EventRender";
import { VesselColumn } from "./Scheduler/VesselColumn";
import { horizontalEventSorter } from "./Scheduler/eventUtils";
import { OffScheduleRequirementInfo } from "../machines/testSchedules";

/** Not sure what other props are passed to events when interacting with events within scheduler, but the record object is at `eventRecord` */
interface SchedulerEventEvent {
	eventRecord: ISchedulerItem;
}

interface Props {
	resources: Partial<SchedulerResourceModel>[];
	events: ItemOrUT[];
	send: PayloadSender<FleetMachineEvent>;
	/** The service of the state machine used, to be able to subscribe to particular events */
	machineService: IFleetInterpreter;
	isLoading?: boolean;
	startDate: Date;
	endDate: Date;
	dispatch: Dispatch<IRequirementReducerActions>;
	offScheduleRequirements: OffScheduleRequirementInfo[];
}

class SchedulerPage extends React.Component<Props> {
	state = {
		error: null,
	};

	private scheduler = React.createRef<BryntumSchedulerPro>();
	private unsubscribe$ = new Subject<void>();

	listeners: Record<string, (event: any) => void> = {
		eventClick: throttle((event: { eventRecord: ItemOrUT }) => {
			const { can } = getRoleManager();
			if (can("view requirements")) {
				const { eventRecord } = event;
				if (eventRecord.eventType !== "ut")
					this.props.send({
						type: "eventClick",
						eventId: eventRecord.requirementId as string,
						isActual: eventRecord.isActual,
					});
			}
		}, 100),
		presetChange: ({ from, to }) => {
			const { presetCombo, zoomInButton, zoomOutButton } = this.scheduler.current?.schedulerInstance
				?.widgetMap as any;

			if (presetCombo && presetCombo.value) {
				presetCombo.value = to;

				// To disable buttons based on zoom levels use this code:
				// zoomOutButton.disabled = level <= 0;
				// zoomInButton.disabled = level >= this.presets.length - 1;

				// To disable buttons based on presets in combo use this code:
				const index = presetCombo.store.indexOf(presetCombo.record);
				zoomOutButton.disabled = index === 0;
				zoomInButton.disabled = index === presetCombo.store.count - 1;
			}
		},
		selectionChange: throttle(({ selection, mode }) => {
			// if (mode === "cell" && selection?.[0]?.data?.id)
			//  this.props.send({
			//      type: "vesselClicked",
			//      vesselId: selection[0].data.id,
			//  });
		}, 100),
		catchAll: throttle((event) => {
			//console.log(event.type);
		}, 100),

		beforeEventDropFinalize: async ({ context }: BeforeEventDropFinalizeEvent) => {
			context.async = true;

			const machine$ = from(this.props.machineService as Subscribable<IFleetMachineState>);
			const requirement = context.draggedRecords[0];
			if (!requirement) return context.finalize(false);

			this.props.send({
				type: "eventDrop",
				requirementId: requirement.requirementId,
				vesselId: context.newResource.id.toString(),
			});

			// console.log("eventDrop sent");

			//publish requirement re assign here
			var result = await race(
				machine$.pipe(
					first(({ event: { type } }) => type === "error.platform.assign-requirement-to-vessel"),
					mapTo(false)
				),
				machine$.pipe(
					first(({ event: { type } }) => type === "done.invoke.assign-requirement-to-vessel"),
					mapTo(true)
				)
			).toPromise();
			// console.log("result received", result);

			context.finalize(result);
			//publish re assign finished or failed here
		},
	};

	/** Handles events where changes in state machine needs to update rendered state of scheduler componen */
	machineEventHandler: EventListener<FleetMachineEvent> = (e) => {
		// * deselect the vessel that was focused to show the PR/UT panel
		if (e.type === "CLOSE") {
			this.scheduler.current?.schedulerInstance?.deselectAll();
		}
		if (e.type === "PROMOTE_FINISHED") {
			// TODO!!! move this handling to state machine
			// console.log("Fleet Machine Event: promote finished");
			this.props.machineService.send("DATE_RANGE_RELOAD");
		}
		// console.log("Fleet Machine Event:", e);
	};

	async componentDidMount() {
		// Setup rerender whenever receiving failure event
		this.props.machineService.onEvent(this.machineEventHandler as EventListener);

		//MIKE
		if (this.props.events && this.props.events.length)
			this.scheduler.current?.schedulerInstance?.project.eventStore.loadDataAsync(this.props.events);
		interval(300)
			.pipe(take(10), takeUntil(this.unsubscribe$))
			.subscribe(() => {
				if (this.scheduler.current?.schedulerInstance) {
					this.scheduler.current.schedulerInstance.mask(
						this.props.isLoading ? "Fetching schedules" : (null as any)
					);
				}
			});

		// Scroll to current date
		if (this.props.machineService.state?.matches("idle.ready")) {
			// If data has been loaded, we can scroll immediately
			this.scrollToNow();
		} else {
			await from(this.props.machineService as Subscribable<IFleetMachineState>)
				.pipe(
					first(({ matches }) => matches("idle.ready")),
					delay(500)
				)
				.toPromise();
			this.scrollToNow();
		}

		//no idea why but this breaks things
		this.props.machineService.send("SET_SCHEDULER_INSTANCE", {
			schedulerInstance: this.scheduler.current?.schedulerInstance,
		});
	}

	private scrollToNow() {
		this.scheduler.current?.schedulerInstance?.scrollToDate(new Date(), {
			highlight: false,
			animate: {
				easing: "easeFromTo",
				duration: 500,
			},
			block: "center",
		});
	}

	private showLoadingMessage(message: string | null) {
		this.scheduler?.current?.schedulerInstance?.mask(message as any);
	}

	private hideLoadingMessage() {
		// setTimeout(() => {
		this.scheduler?.current?.schedulerInstance?.mask(null as any);
		// }, 1000);
	}

  // static getDerivedStateFromProps({ isLoading, events, resources }: Props, state: any) {
	// 	console.log("getDerivedStateFromProps isLoading", isLoading); ////
	// 	console.log("getDerivedStateFromProps events", events); ////
	// 	console.log("getDerivedStateFromProps resources", resources); ////
	// 	console.log("getDerivedStateFromProps state", state); ////
	// 	return state;
  // }


	componentDidUpdate({ isLoading, events, resources }: Props) {
		if (isLoading !== this.props.isLoading && this.scheduler.current?.schedulerInstance) {
			let loadingMessage = "";
			const currentState: string | null | undefined = this.props.machineService.state
				?.toStrings()
				?.slice(-1)
				?.join();

			console.log("componentDidUpdate currentState", currentState);
			// console.log("componentDidUpdate resources", resources); ////

			switch (currentState) {
				case "idle.ready.calculatingGetFleet":
					loadingMessage = "Fetching latest schedules and calculating";
					break;
				case "idle.ready.calculatingGetCalculate":
					loadingMessage = "Calculating optimal schedule";
					break;
				case "idle.ready.promotionInProgress":
					loadingMessage = "Promoting schedule";
					break;
				case "idle.ready.requirementAssignmentInProgress":
					loadingMessage = "Re-assigning requirement";
					break;
				default:
					loadingMessage = "Fetching data";
			}

			this.scheduler.current.schedulerInstance.mask(this.props.isLoading ? loadingMessage : (null as any));
		}
		// if (JSON.stringify(events) !== JSON.stringify(this.props.events)) {
		// 	// console.log("updating events:", this.props.events);
		// 	this.scheduler.current?.schedulerInstance?.project.eventStore.loadDataAsync(this.props.events);
		// }
		this.scheduler.current?.schedulerInstance?.project.eventStore.loadDataAsync(this.props.events);
	}

	componentWillUnmount() {
		this.props.machineService.off(this.machineEventHandler);
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	private schedulerProps: BryntumSchedulerProps = {
		ref: this.scheduler,
		eventStore:
			// Additional fields added to SchedulerEventModel
			{
				fields: [
					"eventType",
					"reason",
					"startPort",
					"endport",
					"actions",
					"isActual",
					"requirementId",
					"isLocked",
				],
			},
		eventRenderer: eventRenderer,
		features: {
			filterBar: true,
			stripe: false,
			search: true,
			tree: true,
			eventResize: false,
			scheduleTooltip: false,
			dependencies: false,
			taskEdit: false,
			eventMenu: {
				items: {
					// Add extra items shown for each event
					// Edit a built in item
					editEvent: false,
					// Hide a built in item
					deleteEvent: false,

					lock: {
						text: "Lock Requirement",
						icon: "b-fa b-fa-fw b-fa-lock",
						cls: "b-separator",
						weight: 503,
						onItem: async ({ eventRecord }: SchedulerEventEvent) => {
							this.showLoadingMessage("Locking Requirement");
							if (!eventRecord.originalData?.isSpot) {
								const lockToVesselRequest = await vesselScheduleLifecycleClient
									.mutation(LOCK_REQUIREMENT_TO_VESSEL, {
										requirementID: eventRecord.requirementId,
										vesselID: eventRecord.originalData!.resourceId,
									})
									.toPromise();

								if (!lockToVesselRequest.error && lockToVesselRequest.data) {
									/*this.props.dispatch({
										type: LOCK_REQUIREMENT_TO_VESSEL_ACTION,
										payload: {
											requirementID: `${eventRecord.requirementId}`,
											vesselID: `${
												eventRecord.originalData!
													.resourceId
											}`,
											lock: !!`${
												eventRecord.originalData!
													.resourceId
											}`,
										},
									});*/

									const updateRequirementState = () => {
										this.props.machineService.send("LOCK_REQUIREMENT_TO_VESSEL", {
											requirementId: `${eventRecord.requirementId}`,
											lock: eventRecord.originalData!.resourceId || null,
										});
										this.props.dispatch({
											type: LOCK_REQUIREMENT_TO_VESSEL_ACTION,
											payload: {
												requirementID: `${eventRecord.requirementId}`,
												vesselID: `${eventRecord.originalData!.resourceId}`,
												lock: eventRecord.originalData!.resourceId,
											},
										});
									};

									if (this.props.machineService.state?.matches("idle.ready")) {
										updateRequirementState();
									} else {
										await from(this.props.machineService as Subscribable<IFleetMachineState>)
											.pipe(
												first(({ matches }) => matches("idle.ready")),
												delay(500)
											)
											.toPromise();
										updateRequirementState();
									}
								} else {
									console.error(lockToVesselRequest?.error);
									this.setState({
										error: lockToVesselRequest?.error?.message,
									});
									// this.props.dispatch({
									//  type: LOCK_REQUIREMENT_TO_VESSEL_FAILED,
									//  payload: {
									//      errorMessage:
									//          lockToVesselRequest.error?.message,
									//  },
									// });
								}
							} else {
								const lockToSpotRequest = await vesselScheduleLifecycleClient
									.mutation(LOCK_REQUIREMENT_TO_SPOT, {
										requirementID: eventRecord.requirementId,
									})
									.toPromise();

								if (!lockToSpotRequest.error && lockToSpotRequest.data) {
									/*this.props.dispatch({
										type: LOCK_REQUIREMENT_TO_SPOT_ACTION,
										payload: {
											requirementID: `${eventRecord.requirementId}`,
											vesselID: `${
												eventRecord.originalData!
													.resourceId
											}`,
											lock: !!`${
												eventRecord.originalData!
													.resourceId
											}`,
										},
									});*/

									const updateRequirementState = () => {
										this.props.machineService.send("LOCK_REQUIREMENT_TO_SPOT", {
											requirementId: `${eventRecord.requirementId}`,
											lock: !!`${eventRecord.originalData!.resourceId}`,
										});
										this.props.dispatch({
											type: LOCK_REQUIREMENT_TO_SPOT_ACTION,
											payload: {
												requirementID: `${eventRecord.requirementId}`,
												vesselID: `${eventRecord.originalData!.resourceId}`,
												lock: eventRecord.originalData!.resourceId,
											},
										});
									};

									if (this.props.machineService.state?.matches("idle.ready")) {
										updateRequirementState();
									} else {
										await from(this.props.machineService as Subscribable<IFleetMachineState>)
											.pipe(
												first(({ matches }) => matches("idle.ready")),
												delay(500)
											)
											.toPromise();
										updateRequirementState();
									}
								} else {
									console.error(lockToSpotRequest?.error);
									this.setState({
										error: lockToSpotRequest?.error?.message,
									});
									// this.props.dispatch({
									//  type: LOCK_REQUIREMENT_TO_SPOT_FAILED,
									//  payload: {
									//      errorMessage:
									//          lockToSpotRequest.error?.message,
									//  },
									// });
								}
							}
							this.hideLoadingMessage();
						},
					},
					unlock: {
						text: "Unlock Requirement",
						icon: "b-fa b-fa-fw b-fa-lock",
						cls: "b-separator",
						weight: 503,
						disabled: true,
						onItem: async ({ eventRecord }: SchedulerEventEvent) => {
							this.showLoadingMessage("Unlocking Requirement");
							console.log(eventRecord);
							const unlockRequest = await vesselScheduleLifecycleClient
								.mutation(UNLOCK_REQUIREMENT, {
									requirementID: eventRecord.requirementId,
								})
								.toPromise();

							if (!unlockRequest.error && unlockRequest.data) {
								console.log("updating unlock status");
								/*this.props.dispatch({
									type: UNLOCK_REQUIREMENT_ACTION,
									payload: {
										requirementID: `${eventRecord.id}`,
										lock: false,
									},
								});*/

								const updateRequirementState = () => {
									//console.log("updating unlock status");
									this.props.machineService.send("UNLOCK_REQUIREMENT", {
										requirementId: `${eventRecord.requirementId}`,
										lock: null,
									});
									this.props.dispatch({
										type: UNLOCK_REQUIREMENT_ACTION,
										payload: {
											requirementID: `${eventRecord.requirementId}`,
											lock: null,
										},
									});
								};

								if (this.props.machineService.state?.matches("idle.ready")) {
									updateRequirementState();
								} else {
									await from(this.props.machineService as Subscribable<IFleetMachineState>)
										.pipe(
											first(({ matches }) => matches("idle.ready")),
											delay(500)
										)
										.toPromise();
									updateRequirementState();
								}
							} else {
								console.error(unlockRequest?.error);
								this.setState({
									error: unlockRequest?.error?.message,
								});
								// this.props.dispatch({
								//  type: UNLOCK_REQUIREMENT_FAILED,
								//  payload: {
								//      errorMessage:
								//          ,
								//  },
								// });
							}
							this.hideLoadingMessage();
						},
					},
					assignToSpot: {
						text: "Assign To Spot",
						icon: "b-fa b-fa-fw b-fa-lock",
						cls: "b-separator",
						weight: 503,
						onItem: ({ eventRecord: { requirementId } }: SchedulerEventEvent) => {
							this.props.send("assignVesselToSpot", {
								requirementId,
							});
						},
					},
					finishSpot: {
						text: "Finish Spot",
						icon: "b-fa b-fa-fw b-fa-check",
						cls: "b-separator",
						weight: 503,
						onItem: ({ eventRecord: { requirementId } }: SchedulerEventEvent) => {
							this.props.send({
								type: "finishSpotSchedule",
								requirementId,
							});
						},
					},
				},

				// Process items before context menu is shown, add or remove or prevent it
				processItems({ eventRecord, items }: { eventRecord: any; items: any }) {
					const actions = eventRecord.actions || [];
					const firstAction = actions.length > 0 ? actions[0] : null;
					const isCompleted =
						firstAction && "actionStatus" in firstAction && firstAction.actionStatus.id === "Complete"
							? true
							: false;

					if (eventRecord.eventType === "ut") return false;
					//default show dont show unlock

					const isSpot = (eventRecord?.originalData?.resourceId as string).includes("spot");
					const tupleHasActualSchedule = eventRecord.tupleHasActualSchedule;

					//if the event is locked show unlock item
					if (eventRecord.isLocked) {
						//if its a vessel
						if (!isSpot) {
							//remove lock item
							items.unlock.disabled = false;
							items.lock = false;
							items.finishSpot = false;
							items.assignToSpot = false;
						} else {
							//its a spot

							//remove lock item
							items.unlock.disabled = false;

							items.lock = false;

							items.assignToSpot = false;
						}
					} else {
						if (!isSpot) {
							//its a vessel
							//items.finishSpot.disabled = true;

							items.finishSpot = false;
						} else {
							//its a spot

							items.finishSpot.disabled = isCompleted || !tupleHasActualSchedule;
							items.assignToSpot = false;
						}
					}

					// ACCESS CONTROL
					const { can } = getRoleManager();

					const canLockUnlockRequirement = can("lock/unlock requirements");
					if (!canLockUnlockRequirement) {
						items.lock = null;
						items.unlock = null;
					}

					const canAssignRequirementToSpot = can("assign requirement to spot");
					if (!canAssignRequirementToSpot) {
						items.assignToSpot = null;
					}

					const canFinishSpot = can("finish spots");
					if (!canFinishSpot) {
						items.finishSpot = null;
					}

					const hasAccess = canLockUnlockRequirement || canAssignRequirementToSpot || canFinishSpot;
					// ACCESS CONTROL END

					const showContextualMenu =
						!eventRecord.isActual &&
						!eventRecord?.originalData?.hasStartedActions &&
						hasAccess &&
						((eventRecord.isLocked && !eventRecord.draggable) || !eventRecord.isLocked);

					// Prevent menu for "actual" and show the menu for actual spots, but hide for planned spot
					return showContextualMenu;
				},
			},
			scheduleMenu: {
				items: {
					addEvent: false,
				},
			},

			eventDragCreate: false,
			eventDrag: {
				constrainDragToResource: false,
				constrainDragToTimeSlot: true,
			},
			eventEdit: false,
			timeRanges: {
				showCurrentTimeLine: true,
			},
			cellMenu: false,
			eventTooltip: false,
			customSchedulerRequirementTooltip: true,
			stickyEvents: false,
		},
		eventBodyTemplate,
		viewPreset: "weekAndDay",
		rowHeight: 80,
		selectionMode: {
			row: false,
			cell: false,
		},
		createEventOnDblClick: false,
		// contextMenuTriggerEvent: "menu1",
		listeners: this.listeners,
		horizontalEventSorterFn: horizontalEventSorter,
		useInitialAnimation: false,
		columns: [
			{
				text: "Vessels",
				field: "name",
				htmlEncode: false,
				renderer: ({ record }: any) => (
					<VesselColumn
						key={record.id}
						record={record}
						send={this.props.send}
						offScheduleRequirements={this.props.offScheduleRequirements}
					/>
				),
				width: 200,
				groupable: false,
				sortable: false,
				editor: false,
				filterable: ({ value, record }: any) => {
					if (value === "time") {
						return record.isSpot;
					}
					const re = new RegExp(escapeRegExp("" + value), "i");
					return re.test(record.name);
				},
				headerRenderer: ({ column, headerElement }: any) => {
					headerElement.style.backgroundColor = "#FFF";
					headerElement.style.fontWeight = "1000";
					return `<div align="center">${column.text}</div>`;
				},
			},
			{
				text: "Size",
				field: "size",
				align: "center",
				groupable: false,
				sortable: false,
				editor: false,
				hidden: true,
				width: 100,
				htmlEncode: false,
				renderer: ({ record }: any) => `<span class="darkText">${record.size ?? ""}</span>`,
				headerRenderer: ({ column, headerElement }: any) => {
					headerElement.style.backgroundColor = "#FFF";
					headerElement.style.fontWeight = "1000";
					return `<div align="center">${column.text}</div>`;
				},
			},
			{
				text: "C/D",
				field: "clean",
				hidden: true,
				align: "center",
				sortable: false,
				groupable: false,
				editor: false,
				headerRenderer: ({ column, headerElement }: any) => {
					headerElement.style.backgroundColor = "#FFF";
					headerElement.style.fontWeight = "1000";
					return `<div align="center">${column.text}</div>`;
				},
				htmlEncode: false,
				renderer: ({ record }: any) => {
					if (record.clean !== undefined)
						if (record.clean === "C") {
							return '<span class="brandText">C</span>';
						} else {
							return '<span class="darkText">D</span>';
						}
					else {
						return "";
					}
				},
			},
		],
	};

	render() {
		return (
			<>
				<BryntumSchedulerPro
					startDate={this.props.startDate}
					endDate={this.props.endDate}
					{...this.schedulerProps}
					resources={this.props.resources}
					events={undefined} // Events are updated via `componentDidMount` and `componentDidUpdate` instead to work with the EventStore directly
				/>

				<CommonErrorModal
					isOpen={Boolean(this.state.error)}
					errorMessage={this.state.error}
					onClose={() => this.setState({ error: null })}
				/>
			</>
		);
	}
}

export default connect(undefined, (dispatch) => ({
	dispatch,
}))(SchedulerPage);
