import {
	dedupExchange,
	cacheExchange,
	fetchExchange,
	CombinedError,
	Operation,
} from "urql";
import { retryExchange } from "@urql/exchange-retry";

//add query methods here
//NOTE: need to add exchangesWithRetry or commonUrlqRetry to a specific clientsClient
//for retries to work
const retryableMethods = [
	"NEW_validateAndPersistRequirements",
	"computeFleetScheduleMetrics",
	"promotePlannedVesselSchedulesToActuals",
	"assignRequirementFromVesselToSpot",
	"assignRequirementFromVesselToVessel",
	"assignRequirementFromSpotToVessel",
	"NEW_calculate",
	"getFleetSchedule",
];

//add error strings here to be retryable
const retryableErrors = ["socket hang up", "ERR_TIMED_OUT", "ECONNRESET"];

const options = {
	initialDelayMs: 1000,
	maxDelayMs: 10000,
	randomDelay: true,
	maxNumberAttempts: 3,
	retryIf: (error: CombinedError, operation: Operation) => {
		let retry = false;

		// console.log(
		// 	"error.message :: " + JSON.stringify(error.message, null, 4)
		// );

		for (let i = 0; i < retryableErrors.length; i++) {
			const errorString = retryableErrors[i];
			if (error.message.includes(errorString)) {
				if (operation.query.loc) {
					for (let i = 0; i < retryableMethods.length; i++) {
						const method = retryableMethods[i];

						//this thing check agains a specific method
						if (operation.query.loc?.source.body.includes(method)) {
							retry = true;
							break;
						}
					}
				}
			}
		}
		return retry;
	},
};

export const commonUrlqRetry = retryExchange(options);

export const exchangesWithRetry = [
	dedupExchange,
	cacheExchange,
	commonUrlqRetry,
	fetchExchange,
];
