import React from "react";
import BoatIco from "../../images/boat_ico.svg";
import LockIco from "../../images/lock_ico.svg";

type FleetActionVoyageProps = {
	actionStatusClass?: string;
	id?: string;
	width?: number;
	color?: string;
	left?: number;
	isLaden?: boolean;
	isLocked?: boolean;
	isActual?: boolean;
	icon?: string;
	position?: any;
};
const FleetActionVoyage: React.FC<FleetActionVoyageProps> = ({
	actionStatusClass,
	id,
	width = "auto",
	left,
	color = "#ddd",
	isLaden,
	icon,
	isLocked,
	isActual,
	position = "absolute",
}) => {
	return (
		<div
			className={actionStatusClass || ""}
			data-id={id}
			style={{
				width,
				height: 60,
				fontWeight: 600,
				transform: `translate(${left}px, 0)`,
				position,
				top: 0,
				bottom: 0,
				fontSize: "0.7em",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				color,
			}}
		>
			<div
				style={{
					height: 1,
					width: width,
					border: `1px ${isLaden ? "solid" : "dashed"} ${
						isActual ? "#C4C4C4" : "#e6e6e6"
					}`,
				}}
			></div>
			{isLocked && isLocked === true && (
				<div style={{ marginLeft: 0, opacity: isActual ? 1 : 0.4 }}>
					<img
						height={24}
						width={24}
						src={LockIco}
						alt="expand-ico"
					/>
				</div>
			)}
			<div style={{ marginLeft: 0, opacity: isActual ? 1 : 0.4 }}>
				<img height={24} width={24} src={BoatIco} alt="expand-ico" />
			</div>
			<div
				className={"material-icons"}
				style={{
					marginLeft: isLaden ? "-4.5px" : "0",
					opacity: isActual ? 1 : 0.4,
				}}
			>
				{icon}
			</div>
			<div className="status-indicator" />
		</div>
	);
};

export default FleetActionVoyage;
