import mqtt from "mqtt";
import { useState, useEffect, createContext, useContext } from 'react';
import { Configuration } from '../util/config';


export const FLEET_TOPIC = "@fanar/fleetChart";
export const FLEET_CALC_TOPIC = "@fanar/fleetChart/calculate";
export const CWF_TOPIC = "@fanar/cwf";
export const GET_FLEET_TOPIC = "@fanar/fleetChart/getFleet";
export const REASSIGN_REQ_TOPIC = "@fanar/fleetChart/reassignReq";

export const PROMOTE_IDLE = "promote-idle";
export const PROMOTE_IN_PRORFESS = "promote-in-progress";
export const CALCULATE_IDLE = "calculate-idle";
export const CALCULATE_IN_PRORFESS = "calculate-in-progress";
export const GET_FLEET_IN_PROGRESS = "get-fleet-in-progress";
export const GET_FLEET_IDLE = "get-fleet-idle";
export const REASSIGN_REQ_TO_VESSEL_IN_PROGRESS = "reassign-req-to-vessel-in-progress";
export const REASSIGN_REQ_TO_SPOT_IN_PROGRESS = "reassign-req-to-spot-in-progress";
export const REASSIGN_REQ_IDLE = "reassign-req-idle";
export const CWF_IDLE = "cwf-idle";
export const CWF_IN_PRORFESS = "cwf-in-progress";

const url = Configuration.MQTT_ENDPOINT;

export const mqttClient = mqtt.connect(url, { reconnectPeriod: 0 })
const StoreContext = createContext(mqttClient);

const useMqttClient = () => useContext(StoreContext);

export const useMqtt = () => {
	const client = useMqttClient();

	const [cwfStatus, setCWFStatus] = useState(CWF_IDLE);
	const [promotionStatus, setPromotionStatus] = useState(PROMOTE_IDLE);
	const [calculationStatus, setCalculationStatus] = useState(CALCULATE_IDLE);
	const [getFleetStatus, setGetFleetStatus] = useState(GET_FLEET_IDLE);
	const [reAssignRequirementStatus, setReAssignRequirementStatus] = useState(REASSIGN_REQ_IDLE)

	useEffect(() => {
		client.subscribe(CWF_TOPIC, { qos: 1 });
		client.subscribe(FLEET_TOPIC, { qos: 1 });
		client.subscribe(FLEET_CALC_TOPIC, { qos: 1 });
		client.subscribe(GET_FLEET_TOPIC, { qos: 1 });
		client.subscribe(REASSIGN_REQ_TOPIC, { qos: 1 });

		client.on('connect', () => {
			console.log("MQTT :: CONNECT");
		});
		client.on('error', (err) => {
			console.error('MQTT :: Connection error: ', err);
			client.end();
		});
		client.on('reconnect', () => {
			console.log("MQTT :: RECONNECT");
		});
		client.on('message', (topic, message) => {

			//now expecting JSON
			const data = JSON.parse(message.toString());
			const state = data?.state;
			// const userName = data?.user;

			console.error('MQTT :: Connection topic: ', topic);
			console.error('MQTT :: Connection message: ', JSON.parse(message.toString()));

			// const payload = { topic, message: message.toString() };
			// setPayload(payload);
			if (topic === CWF_TOPIC) {
				setCWFStatus(state)
			}

			if (topic === FLEET_TOPIC) {
				setPromotionStatus(state)
			}

			if (topic === FLEET_CALC_TOPIC) {
				setCalculationStatus(state)
			}

			if (topic === GET_FLEET_TOPIC) {
				setGetFleetStatus(state)
			}
			if (topic === REASSIGN_REQ_TOPIC) {
				setReAssignRequirementStatus(state)
			}
		});

		return () => {
			client.unsubscribe(CWF_TOPIC);
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps


	return {
		client,
		cwfStatus,
		promotionStatus,
		calculationStatus,
		getFleetStatus,
		reAssignRequirementStatus
	};
};
