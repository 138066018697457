import { makeStyles } from "@material-ui/core/styles";
import { Accordion, AccordionPanel, Box, Grid, List, Button, Text } from "grommet";
import { Select, Trash } from "grommet-icons";
import React, { useContext, memo } from "react";
import { useDispatch } from "react-redux";
import { push } from "react-router-redux";
import { useSelector } from "@xstate/react";

import { useMutation } from "urql";
import { VESSEL_WS } from "../api/UrqlClientProvider";
import { Dispatch } from "redux";
import { RouterAction } from "connected-react-router";
import { FleetMachineContext } from "../App";
import { usePortIdToName, useTerminalIdToName } from "./utility/portIdToName";
import { AccessActionsKeys } from "../hooks/useRoleManager";
import { toDateFormat } from "../constants";

const useStyles = makeStyles({
	panelContainer: {
		padding: 15,
		marginTop: 25,
		width: "90%",
		//boxSizing: "border-box",

		// tableLayout: "fixed",
	},
	labelSize: {
		fontSize: 15,
		color: "#59B5D9",
	},
	dividerPadding: {
		padding: 20,
	},
});

interface IProps {
	vesselId: string;
	can?: (action: AccessActionsKeys) => boolean;
	// vessel: object;
}

const DELETE_UT = `#graphql
		mutation deleteV2UnavailableTime($id: ID!) {
			deleteV2UnavailableTime(id: $id) {
				id
			}
		}
	`;
const DELETE_PR = `#graphql
	mutation deleteV2PortRestriction($id: ID!) {
		deleteV2PortRestriction(id: $id) {
			id
		}
	}
`;

// const UPDATE_VESSEL = `#graphql
// 		mutation updateVessel($input: UpdateVesselInput!) {
// 			updateVessel(input: $input) {
// 				id
// 			}
// 		}
// 	`;

const fromUnixToDate = (timestamp: any) => {
	return new Date(timestamp * 1000).toLocaleDateString();
};

const getFormattedDateRange = (start: any, end: any) => {
	return `${start && toDateFormat(fromUnixToDate(start))} - ${end && toDateFormat(fromUnixToDate(end))}`;
};

export const VesselDetails = memo(function VesselDetails({ vesselId, can }: IProps) {
	const dispatch = useDispatch<Dispatch<RouterAction>>();

	const fleetMachineService = useContext(FleetMachineContext);

	const vesselWithQ88PRandUT = useSelector(fleetMachineService, ({ context: { vessels } }) =>
		vessels.find(({ vessel: { id } }) => id === vesselId)
	);
	const classes = useStyles();

	const portName = usePortIdToName();
	const terminalName = useTerminalIdToName();

	const vesselReportClick = React.useCallback(
		(id, name) => {
			console.log(" vessel report button click" + id);
			dispatch(push(`/vessels/report/${id}/${name}`));
		},
		[dispatch]
	);

	//delete the UT and update the vessel
	const [
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		{ fetching: deletingUT, error: deleteUTError },
		deleteUT,
	] = useMutation<unknown, { id: string }>(DELETE_UT);

	//delete PR
	const [
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		{ fetching: deletingPR, error: deletePRError },
		deletePR,
	] = useMutation<unknown, { id: string }>(DELETE_PR);

	const utButtonClick = React.useCallback(
		(id?: string): void => {
			if (!id) return;
			// console.log(id);
			deleteUT({ id }, { url: VESSEL_WS }).then(() => {
				// Update xstate context
				fleetMachineService.send({
					type: "UT_DELETED",
					utId: id!,
					vesselId,
				});
			});
		},
		[deleteUT, vesselId, fleetMachineService]
	);
	const prButtonClick = React.useCallback(
		(id?: string): void => {
			if (!id) return;
			// console.log(id);
			deletePR({ id }, { url: VESSEL_WS }).then(() => {
				// Update xstate context
				fleetMachineService.send({
					type: "PR_DELETED",
					prId: id!,
					vesselId,
				});
			});
			//deleteUT(id).then(() => callSomeOtherMutation()).catch(err => console.error("Oh noes", err));
		},
		[deletePR, vesselId, fleetMachineService]
	);
	if (vesselWithQ88PRandUT) {
		const details = [
			"Contract Expiration: " + toDateFormat(vesselWithQ88PRandUT.vessel.details.contractExpiration) || " - ",

			"Chartering Cost: $" + vesselWithQ88PRandUT.vessel.details.charteringCost,
			"Size Category: " + vesselWithQ88PRandUT.vessel.details.sizeCategory,
		];

		const dimensions = [
			"Beam: " + vesselWithQ88PRandUT.q88Vessel.dimensions.beam + " m",
			"Overall Length: " + vesselWithQ88PRandUT.q88Vessel.dimensions.overallLength + " m",
			"Aft Parallel Body Distance: " + vesselWithQ88PRandUT.q88Vessel.dimensions.aftParallelBodyDistance + " m",
			"Forward Parallel Body Distance: " +
				vesselWithQ88PRandUT.q88Vessel.dimensions.forwardParallelBodyDistance +
				" m",
		];

		const carryingCapacity = [
			"Fuel Capacity: " + vesselWithQ88PRandUT.q88Vessel.carryingCapacity.fuelCapacity + " m3",
			"Diesal Capacity: " + vesselWithQ88PRandUT.q88Vessel.carryingCapacity.dieselCapacity + " m3",
			"Gasoil Capacity: " + vesselWithQ88PRandUT.q88Vessel.carryingCapacity.gasOilCapacity + " m3",
		];

		const speedCapabilitiues = [
			"Laden Eco Speed: " + vesselWithQ88PRandUT.q88Vessel.speedCapabilities.ladenEconomicSpeed + " kts",
			"Laden Max Speed: " + vesselWithQ88PRandUT.q88Vessel.speedCapabilities.ladenMaxSpeed + " kts",
			"Ballast Eco Speed: " + vesselWithQ88PRandUT.q88Vessel.speedCapabilities.ballastEconomicSpeed + " kts",
			"Ballast Max Speed: " + vesselWithQ88PRandUT.q88Vessel.speedCapabilities.ballastMaxSpeed + " kts",
		];

		const canManageVessel = !can || can("manage vessels");
		const canViewVesselReport = !can || can("view vessel report") || canManageVessel;
		const canManagePRUT = !can || can("manage pr/ut") || canManageVessel;

		return (
			<Box className={classes.panelContainer} border={{ color: "blue1" }}>
				<h4>
					<label className={classes.labelSize}>{vesselWithQ88PRandUT.vessel.name}</label>{" "}
					{vesselWithQ88PRandUT.id}
				</h4>
				{canViewVesselReport && (
					<Button
						//color="blue1"
						primary
						size="small"
						onClick={() =>
							vesselReportClick(vesselWithQ88PRandUT.vessel.id, vesselWithQ88PRandUT.vessel.name)
						}
						label="View Vessel Report"
					/>
				)}
				<Box>
					<Grid
						fill
						columns={["2/3", "1/3"]}
						areas={[
							{ name: "vessel", start: [0, 0], end: [0, 0] },
							{ name: "q88", start: [1, 0], end: [1, 0] },
						]}
						rows={["flex"]}
						gap="small"
					>
						<Box gridArea="vessel">
							<div>
								<h5>
									Status :{" "}
									{vesselWithQ88PRandUT.vessel.isParked.toString() === "true" ? (
										<label className={classes.labelSize}>Laid Up</label>
									) : (
										<label className={classes.labelSize}>Active</label>
									)}
								</h5>
								<h5>
									Clean :{" "}
									{vesselWithQ88PRandUT.vessel.isClean.toString() === "true" ? (
										<label className={classes.labelSize}>C</label>
									) : (
										<label className={classes.labelSize}>D</label>
									)}
								</h5>
								<h5>
									IMO Class:{" "}
									<label className={classes.labelSize}>
										{vesselWithQ88PRandUT.q88Vessel.imoClass}
									</label>
								</h5>
							</div>
						</Box>
						<Box gridArea="q88">
							<div>
								<h5>
									Product Capacity: <br></br>
									<label className={classes.labelSize}>
										{vesselWithQ88PRandUT.q88Vessel.totalProductCapacity}
										{" M3"}
									</label>
								</h5>
								<h5>
									SCNT: <br></br>
									<label className={classes.labelSize}>
										{vesselWithQ88PRandUT.q88Vessel.scnt} {" T "}
									</label>
								</h5>
								<h5>
									Cargo Pump Rate: <br></br>
									<label className={classes.labelSize}>
										{vesselWithQ88PRandUT.q88Vessel.cargoPumpingRateM3PerS}
										{" M3/h"}
									</label>
								</h5>
							</div>
						</Box>
					</Grid>
				</Box>

				<Accordion animate={true}>
					<AccordionPanel label="Details">
						<Box background="light-2">
							<List background={["white", "light-2"]} data={details} />
						</Box>
					</AccordionPanel>

					<AccordionPanel label="Dimensions">
						<Box background="light-1">
							<List background={["white", "light-2"]} data={dimensions} />
						</Box>
					</AccordionPanel>
					<AccordionPanel label="Carrying Capacity">
						<Box background="light-1">
							<List background={["white", "light-2"]} data={carryingCapacity} />
						</Box>
					</AccordionPanel>
					<AccordionPanel label="Speed Capabilties">
						<Box background="light-1">
							<List background={["white", "light-2"]} data={speedCapabilitiues} />
						</Box>
					</AccordionPanel>
					{!!vesselWithQ88PRandUT.portRestrictions?.length && (
						<AccordionPanel label="Port Restrictions">
							<Box background="light-2">
								{vesselWithQ88PRandUT.portRestrictions?.map((pr: any, key) => {
									return (
										<Box key={pr.id || key} pad="small" gap="small" direction="row" align="center">
											<Text>{pr?.reason || ""}</Text>
											<Text>{terminalName(pr?.terminal) || portName(pr?.port)}</Text>
											{pr?.startDate && pr?.endDate && (
												<Text>{getFormattedDateRange(pr.startDate, pr.endDate)}</Text>
											)}

											{canManagePRUT && (
												<Button
													size="small"
													//key={ut.id}
													//value={ut}
													icon={<Trash color="status-error" />}
													onClick={() => prButtonClick(pr?.id)}
												/>
											)}
										</Box>
									);
								})}
							</Box>
						</AccordionPanel>
					)}
					{!!vesselWithQ88PRandUT.unavailableTimes?.length && (
						<AccordionPanel label="Unavailable Time">
							<Box>
								{vesselWithQ88PRandUT.unavailableTimes?.map((ut: any, key) => {
									return (
										<Box key={ut.id || key} pad="small" gap="small" direction="row" align="center">
											<Text>{ut?.reason || ""}</Text>
											<Text>{portName(ut?.startPort) || ut?.startPort || " - "}</Text>
											<Text>{portName(ut?.endPort) || ut?.endPort || " - "}</Text>
											{ut?.startDate && ut?.endDate && (
												<Text>{getFormattedDateRange(ut.startDate, ut.endDate)}</Text>
											)}

											{canManagePRUT && (
												<Button
													size="small"
													icon={<Trash color="status-error" />}
													onClick={() => utButtonClick(ut?.id)}
												/>
											)}
										</Box>
									);
								})}
							</Box>
						</AccordionPanel>
					)}
				</Accordion>
			</Box>
		);
	} else {
		return (
			<Box justify="center" gap="large" pad="large" align="center">
				<h3>
					Select a Vessel To View Details
					<hr></hr>
				</h3>
				<Select color="blue1" size="xlarge"></Select>
			</Box>
		);
	}
});
