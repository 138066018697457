import Fuse from "fuse.js";
import { Box, Button, FormField, Select, Stack, Text, Tip } from "grommet";
import { CircleInformation } from "grommet-icons";
import React, { memo, useCallback, useMemo, useState } from "react";
import { useController, UseControllerOptions } from "react-hook-form";

import { useFormErrors } from "../Requirements/useFormErrors";

const RENDERED_ITEM_LIMIT = 40;

export interface ControlledAutocompleteProps<T>
	extends Pick<
		UseControllerOptions,
		"control" | "name" | "defaultValue" | "rules"
	> {
	options: T[];
	label: string;
	errorMessage?: string;

	/** Object keys where to lookup the text user is entering in the search input */
	searchKeys?: readonly (keyof T)[];
	/** Provides a possibility to specify a custom content for rendering each option in the list */
	optionRenderer: (
		option: T,
		index: number,
		options: object,
		state: { active: boolean; disabled: boolean; selected: boolean }
	) => React.ReactElement;
	/**
	 * The object of each option might contain values of only informative nature -
	 * and you might intend only the `ID` field to be included in the actual form value
	 * This allows you to specify the provided option to the actual value it maps to in the form
	 */
	mapSelectedOptionToFormValue: (option: T) => unknown;
	children?: never;
}

interface ControlledAutocompleteComponent {
	<T>(props: ControlledAutocompleteProps<T>): React.ReactElement;
}

export const ControlledAutocomplete = memo(function PortAutocomplete<T>({
	label,
	options,
	searchKeys,
	optionRenderer,
	mapSelectedOptionToFormValue,
	...props
}: ControlledAutocompleteProps<T>) {
	const {
		field: { name, onChange, value },
	} = useController(props);
	const fuse = useMemo(
		() =>
			new Fuse(options, {
				caseSensitive: false,
				shouldSort: true,
				includeMatches: false,
				includeScore: false,
				keys: searchKeys as any,
				minMatchCharLength: 2,
			}),
		[options, searchKeys]
	);
	const [searchInput, setSearchInput] = useState<string>("");
	const shownOptions = useMemo(
		() =>
			searchInput.length
				? fuse.search(searchInput).slice(0, RENDERED_ITEM_LIMIT)
				: options.slice(0, RENDERED_ITEM_LIMIT),
		[searchInput, fuse, options]
	);
	const handleSearch = useCallback((text: string) => {
		setSearchInput(text);
	}, []);

	const handleChange = useCallback(
		({ value }: { value: T }) => {
			onChange(mapSelectedOptionToFormValue(value));
		},
		[onChange, mapSelectedOptionToFormValue]
	);

	const renderedName = useMemo(() => {
		const selectedOption = options.find(
			(option) => mapSelectedOptionToFormValue(option) === value
		);
		return selectedOption !== undefined
			? optionRenderer(selectedOption, -1, options, {
					active: false,
					disabled: false,
					selected: true,
			  })
			: "";
	}, [options, value, mapSelectedOptionToFormValue, optionRenderer]);

	const errors = useFormErrors(props.control);

	const thisFieldsErrors = errors?.filter((err) => err.keys?.includes(name));

	const errorMessage = thisFieldsErrors?.[0]?.message || props.errorMessage;

	const labelMarkup = (
		<Stack anchor="top-right">
			<Box flex justify="start" direction="row" align="center">
				<Text
					margin="none"
					color={!!errorMessage ? "status-error" : "dark-1"}
				>
					{label}
				</Text>
			</Box>
			{!!errorMessage ? (
				<Tip
					content="Required"
					dropProps={{ align: { right: "left" } }}
				>
					<Button
						plain
						size="small"
						icon={<CircleInformation color="status-error" />}
					/>
				</Tip>
			) : null}
		</Stack>
	);

	return (
		<FormField label={labelMarkup}>
			<Select
				name={name}
				options={shownOptions as any}
				onSearch={handleSearch}
				onChange={handleChange}
				value={
					<Text style={{ minHeight: 25 }} margin="10px">
						{renderedName}
					</Text>
				}
			>
				{optionRenderer}
			</Select>
		</FormField>
	);
}) as ControlledAutocompleteComponent;
