import { SchedulerPro } from "@bryntum/schedulerpro";
import type { ISchedulerItem, ISchedulerItemAction, ItemOrUT } from "../../components/mapIScheduleTuplesToBryntumEvent";
import {
	ActionType,
	getEventColorForAction,
	getEventColorForProductNew,
	getEventIconForAction,
} from "../../components/utility/EventActionStyles";
import { renderToString } from "react-dom/server";
import FleetActionSmall from "../../components/Fleet/FleetActionSmall";
import FleetAction from "../../components/Fleet/FleetAction";
import FleetActionVoyage from "../../components/Fleet/FleetActionVoyage";
import FleetActionWaiting from "../../components/Fleet/FleetActionWaiting";
import { portOrTerminalInAction } from "../../components/utility/portOrTerminalInAction";
import CleanIco from "../../images/clean_ico.svg";
import RefuelIco from "../../images/refuel_ico.svg";

// eventBodyTemplate is used to render markup inside an event. It is populated using data from eventRenderer()
type RenderedActionData = ISchedulerItemAction & {
	dateToPx: any;
	eventRecord: ISchedulerItem;
};

export const eventBodyTemplate = ({ eventRecord, dateToPx }: RenderedActionData): string => {
	const offScheduleRequirements = eventRecord?.offScheduleRequirements || [];
	var values = (eventRecord?.actions || [])
		.map((action: ISchedulerItemAction) => {
			const left = dateToPx(action.startDate);
			const right = dateToPx(action.endDate);

			return {
				...action,
				left,
				width: right - left,
			};
		})
		.filter(({ width }) => width > 0);

	const actionsTemplate = values
		.map((value: ISchedulerItemAction | any, index: any) => {
			const trade = value.trade;
			let productColor = getEventColorForProductNew(trade?.product?.id ?? "");
			const color = getEventColorForAction(value);
			const icon = getEventIconForAction(value);
			const actionType = value.vesselAction?.actionType.id as ActionType;

			const isOffSchedule = offScheduleRequirements.some(
				(o) => o.vesselId === value.vesselId && o.requirementId === value.scheduledRequirementId
			);
			if (isOffSchedule) {
				productColor.active = {
					base: "#e04040",
					shade: "#f69696",
				};
			}

			let actionStatusClass = "";
			if ("actionStatus" in value) {
				switch (value.actionStatus.id) {
					case "Complete":
						actionStatusClass = "completeAction"; // "finishedAction";

						break;
					case "In Progress":
						actionStatusClass = "inProgressAction"; // "startedAction";
						break;
					default:
				}
			}

			const previousAction = index > 0 ? values[index - 1] : null;
			const isLaden =
				(actionType === "voyage" && previousAction === null) ||
				previousAction?.vesselAction?.actionType.id === undefined ||
				(actionType === "voyage" && previousAction?.vesselAction?.actionType.id === "clean");

			switch (actionType) {
				case "voyage":
					return renderToString(
						<FleetActionVoyage
							key={value.id || index}
							actionStatusClass={actionStatusClass}
							id={value.id}
							width={value.width}
							color={color}
							left={value.left}
							isLaden={isLaden}
							icon={icon}
							isLocked={eventRecord.isLocked}
							isActual={eventRecord.isActual}
						/>
					);
				case "unload":
				case "load":
					return renderToString(
						<FleetAction
							key={value.id || index}
							actionStatusClass={actionStatusClass}
							id={value.id}
							width={value.width}
							color={productColor}
							left={value.left}
							//port={portIdToName(value.endState.port)}
							port={portOrTerminalInAction(value.endState.port, trade?.terminal)}
							product={` ${trade?.product?.id} `}
							productQty={`${trade?.productQuantity || 0} bbl`}
							actionType={value.vesselAction?.actionType.id}
							fromDate={value.startDate}
							toDate={value.endDate}
							isActual={eventRecord.isActual}
						/>
					);
				case "wait":
					return renderToString(
						<FleetActionWaiting
							key={value.id || index}
							id={value.id}
							actionStatusClass={actionStatusClass}
							width={value.width}
							left={value.left}
							port={portOrTerminalInAction(value.endState.port, trade?.terminal)}
							isActual={eventRecord.isActual}
						/>
					);
				case "refuel":
					return renderToString(
						<FleetActionSmall
							key={value.id || index}
							id={value.id}
							ico={RefuelIco}
							isActual={eventRecord.isActual}
							left={value.left}
							actionStatusClass={actionStatusClass}
						/>
					);
				case "clean":
					return renderToString(
						<FleetActionSmall
							key={value.id || index}
							id={value.id}
							ico={CleanIco}
							left={value.left}
							isActual={eventRecord.isActual}
							actionStatusClass={actionStatusClass}
						/>
					);
				default:
					return "";
			}
		})
		.join("");
	return `${actionsTemplate}`;
};

export function eventRenderer(
	this: SchedulerPro,
	{
		eventRecord,
		renderData,
	}: {
		eventRecord: ItemOrUT;
		renderData: any;
	}
) {
	if (eventRecord.eventType === "ut") {
		renderData.cls.add("UTEvent");
		return [];
	}

	renderData.cls.add("schedulerEventNoMargin");

	if (eventRecord.isLocked) {
		renderData.cls.add("isLockedIcon");
		renderData.cls.add("isLocked");
	}

	if (!eventRecord.isActual) {
		renderData.cls.add("plannedRequirement");
	} else {
		renderData.cls.add("actualRequirement");
	}

	const dateToPx = (date: Date): number => this.getCoordinateFromDate(date) - renderData.left;

	return {
		eventRecord: eventRecord,
		dateToPx: dateToPx,
	};
}
