import { reduce } from "lodash";
import { put, take } from "redux-saga/effects";
import { vesselScheduleLifecycleClient } from "../../api/vesselScheduleLifecycleClient";

import { GET_PORTS } from "../../queries/ports";
import {
	BerthOutput,
	PortOutput,
	TerminalOutput,
} from "../../types/generated/q-vessel-schedule-lifecycle-v6.types";
import { INITIALIZE } from "../actions/init.actions";
import { LOAD_PORTS_SUCCESS } from "../actions/ports.actions";

import type { IPortsState } from "../reducers/ports";

export type TerminalSubSelection = Pick<
	TerminalOutput,
	"id" | "name" | "terminalID"
> & {
	berths?: Pick<BerthOutput, "id" | "productsHandled">[];
};

export type IPort = Pick<PortOutput, "id" | "name"> & {
	terminals?: TerminalSubSelection[];
};

interface PortsResponse {
	data: {
		getAllPortsFromCache: IPort[];
	};
}

export function* watchForLoadPortsSaga() {
	while (true) {
		yield take(INITIALIZE);

		const portsResult: PortsResponse = yield vesselScheduleLifecycleClient
			.query(GET_PORTS, undefined, {
				requestPolicy: "network-only",
			})
			.toPromise();

		const normalizedData = reduce<IPort, IPortsState>(
			portsResult?.data?.getAllPortsFromCache?.sort((a, b) =>
				a.name.localeCompare(b.name)
			),
			(acc, current) => {
				acc.byId[current.id] = current;
				acc.allIds.push(current.id);
				return acc;
			},
			{ byId: {}, allIds: [] }
		);

		yield put({
			type: LOAD_PORTS_SUCCESS,
			payload: normalizedData,
		});
	}
}
