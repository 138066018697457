import Alert from "@material-ui/lab/Alert";
import { Box, Button, Grid, Stack } from "grommet";
import { map } from "lodash";
import moment from "moment";
import React, { memo, useCallback, useContext } from "react";
import { ErrorOption, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { DeepNullable } from "ts-essentials";
import { useMutation } from "urql";
import { FleetMachineContext } from "../../../App";
import { useSelector as useXSelector } from "@xstate/react";

import { PERSIST_UNAVAILABLE_TIME } from "../../../queries/vessels";
import { portsSelector } from "../../../selectors/ports.selectors";
import { IPort } from "../../../store/sagas/loadPorts.saga";
import { VesselWithQ88PRandUt } from "../../../types/generated/q-v2-vessels.types";
import { V2UnavailableTimeAsInput } from "../../../types/generated/q-vessel-schedule-lifecycle-v6.types";
import { LoadingIndicator } from "../../LoadingIndicator";
import {
	ControlledAutocomplete,
	ControlledAutocompleteProps,
} from "../ControlledAutocomplete";
import { ControlledTextInput } from "../ControlledTextInput";
import useForceUpdate from "../../../hooks/useForceUpdate";

export interface PortDropdownOption {
	id: string;
	name: string;
}

type OptionalUnavailableTime = DeepNullable<
	Omit<V2UnavailableTimeAsInput, "startDate" | "endDate">
> & {
	endDate: string;
	startDate: string;
};

const defaultValues: OptionalUnavailableTime = {
	id: "",
	endDate: moment().startOf("day").add(1, "day").format("YYYY-MM-DDThh:mm"),
	startDate: moment().startOf("day").format("YYYY-MM-DDThh:mm"),
	startPort: "",
	endPort: "",
	vesselId: "",
	reason: "",
};

function mapSelectedOptionToFormValue({
	id: value,
}: PortDropdownOption): string {
	return value;
}

const renderPortDropdownOption: ControlledAutocompleteProps<PortDropdownOption>["optionRenderer"] = (
	{ name: label, id: value },
	_options,
	_state,
	{ selected }
) =>
	selected ? (
		<span>{label}</span>
	) : (
		<Box pad="small" direction="row">
			<strong>{value}:</strong> ({label})
		</Box>
	);

const searchKeys = ["id", "name"] as const;

interface IUnavailableTimeFormProps {
	vesselId: string;
	onCancel: () => void;
}

type CustomError = ErrorOption & {
	name: keyof V2UnavailableTimeAsInput;
};

function validateUt(
	{
		id,
		startDate,
		endDate,
		startPort,
		endPort,
		reason,
		vesselId,
	}: V2UnavailableTimeAsInput,
	vessel?: VesselWithQ88PRandUt
): CustomError[] {
	const errors: CustomError[] = [];

	if (!id) {
		errors.push({
			name: "id",
			type: "manual",
			message: "ID must be specified",
		});
	}

	if (!startDate) {
		errors.push({
			name: "startDate",
			type: "manual",
			message: "Start Date must be specified",
		});
	}

	if (!endDate) {
		errors.push({
			name: "startDate",
			type: "manual",
			message: "End Date must be specified",
		});
	}

	if (!vessel) {
		errors.push({
			name: "vesselId",
			type: "manual",
			message: `The vessel chosen ${vesselId} was not found`,
		});
	}

	if (!startDate || !endDate || !vessel) return errors;

	if (startDate > endDate) {
		// * set specific field error
		errors.push({
			name: "endDate",
			type: "manual",
			message: "End Date must occur after Start Date",
		});
		errors.push({
			name: "startDate",
			type: "manual",
		});
	}
	// Let's try to find an existing UT for this vessel that overlaps with the new one
	/** https://maanainc.atlassian.net/browse/AR-1110 */

	//only compare with UT not
	const overlappingExistingUTorPR = [
		...(vessel.unavailableTimes || []),
		//...(vessel.portRestrictions || []),
	].find((utOrPr) => {
		if (!utOrPr || !utOrPr.startDate || !utOrPr.endDate) return false;
		return (
			Math.min(endDate, utOrPr.endDate) -
				Math.max(startDate, utOrPr.startDate) >=
			0
		);
	});
	if (overlappingExistingUTorPR)
		errors.push({
			name: "startDate",
			type: "manual",
			message: `The time range for Unavailable Time overlaps with ${`Other unavailable time: ${overlappingExistingUTorPR.reason}`}; At dates ${new Date(
				overlappingExistingUTorPR.startDate! * 1000
			).toLocaleDateString()} → ${new Date(
				overlappingExistingUTorPR.endDate! * 1000
			).toLocaleDateString()}`,
		});

	if (!startPort) {
		errors.push({
			name: "startPort",
			type: "manual",
			message: "Start Port must be specified",
		});
	}

	if (!endPort) {
		errors.push({
			name: "endPort",
			type: "manual",
			message: "End Port must be specified",
		});
	}

	if (!reason) {
		errors.push({
			name: "reason",
			type: "manual",
			message: "Reason cannot be empty",
		});
	}
	return errors;
}

function normalizeFormValues(
	data: OptionalUnavailableTime
): DeepNullable<V2UnavailableTimeAsInput> {
	return {
		...data,
		startDate: moment(data.startDate).unix(),
		endDate: moment(data.endDate).unix(),
	};
}

export const UnavailableTimeForm = memo(function UnavailableTimeForm({
	vesselId,
	onCancel,
}: IUnavailableTimeFormProps) {
	const fleetMachine = useContext(FleetMachineContext);

	const vessel = useXSelector(fleetMachine, ({ context: { vessels } }) =>
		vessels.find(({ vessel: { id } }) => id === vesselId)
	);

	const {
		clearErrors,
		control,
		errors,
		formState,
		handleSubmit,
		setError,
	} = useForm<OptionalUnavailableTime>({
		defaultValues: {
			...defaultValues,
			vesselId,
			id: `UT-${vesselId}-${Math.floor(Date.now() / 1000)}`,
		},
		criteriaMode: "all",
	});

	// * mutation for saving Unavailable Time
	const [
		{
			fetching: loading,
			error: savePortRestrictinError,
			data: unavailableTimeResult,
		},
		saveUnavailableTime,
	] = useMutation<
		{ persistUnavailableTime: string },
		{ input: V2UnavailableTimeAsInput }
	>(PERSIST_UNAVAILABLE_TIME);

	// * get port data
	const ports = useSelector(portsSelector);
	const allPorts = React.useMemo(() => Object.values(ports.byId), [ports]);
	const forceUpdate = useForceUpdate();

	const onSubmit = useCallback(
		(data: OptionalUnavailableTime) => {
			clearErrors();

			// * validate data
			const normalizedData = normalizeFormValues(
				data
			) as V2UnavailableTimeAsInput;
			const fieldErrors = validateUt(normalizedData, vessel);

			if (fieldErrors.length > 0) {
				fieldErrors.forEach(({ name, ...rest }) =>
					setError(name, rest)
				);
				return;
			}

			saveUnavailableTime({
				input: normalizedData,
			}).then((response) => {
				if (!response?.error) {
					fleetMachine.send({
						type: "UT_ADDED",
						data: normalizedData,
					});
				} else {
					setTimeout(() => forceUpdate(), 200);
					// 	if (response?.error?.graphQLErrors) {
					// 		console.log(
					// 			"response?.error?.graphQLErrors: ",
					// 			response?.error?.graphQLErrors
					// 		);
					// 		response?.error?.graphQLErrors?.forEach(
					// 			({ name, message }) => true // setOtherError({ name, message }) //setError(name, rest)
					// 		);
					// 	}
				}
			});
			// .catch((e) => {
			// 	console.log("e: ", e);
			// });
		},
		[
			clearErrors,
			saveUnavailableTime,
			setError,
			forceUpdate,
			fleetMachine,
			vessel,
		]
	);

	const hasSuccessfulSave = React.useMemo(
		() =>
			(unavailableTimeResult?.persistUnavailableTime?.length ?? -1 > 0) &&
			formState.isSubmitSuccessful &&
			formState.isSubmitted,
		[
			formState.isSubmitSuccessful,
			formState.isSubmitted,
			unavailableTimeResult?.persistUnavailableTime?.length,
		]
	);

	const combinedErrors = [...[savePortRestrictinError], ...map(errors)];

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="form">
			{combinedErrors?.map((err, index) =>
				err?.message ? (
					<Alert severity="error" key={index}>
						{err?.message}
					</Alert>
				) : null
			)}

			{hasSuccessfulSave ? (
				<Alert severity="success" onClose={onCancel}>
					Unavailable Time saved successfully!
				</Alert>
			) : (
				<Stack>
					<Grid
						fill
						rows={["auto", "auto", "auto", "auto"]}
						columns={["auto", "auto"]}
						gap="small"
						areas={[
							["vesselId", "unavailableTimeId"],
							["port", "terminal"],
							["startDate", "endDate"],
							["reason", "reason"],
							["submit", "cancel"],
						]}
					>
						<Box fill gridArea="vesselId">
							<ControlledTextInput
								name="vesselId"
								label="Vessel ID"
								control={control}
								disabled
							/>
						</Box>

						<Box fill gridArea="unavailableTimeId">
							<ControlledTextInput
								name="id"
								label="Unavailable Time ID"
								control={control}
								disabled
							/>
						</Box>

						{/* Start Port */}
						<Box fill gridArea="port">
							<ControlledAutocomplete<IPort>
								name="startPort"
								label="Start Port"
								errorMessage={
									errors.startPort ? "Required" : undefined
								}
								options={allPorts}
								control={control}
								mapSelectedOptionToFormValue={
									mapSelectedOptionToFormValue
								}
								optionRenderer={renderPortDropdownOption}
								searchKeys={searchKeys}
							/>
						</Box>

						{/* End Port */}
						<Box
							fill
							gridArea="terminal"
							flex="grow"
							alignSelf="stretch"
						>
							<ControlledAutocomplete
								name="endPort"
								label="End Port"
								errorMessage={
									errors.endPort ? "Required" : undefined
								}
								options={allPorts}
								control={control}
								mapSelectedOptionToFormValue={
									mapSelectedOptionToFormValue
								}
								optionRenderer={renderPortDropdownOption}
								searchKeys={searchKeys}
							/>
						</Box>

						<Box fill gridArea="startDate">
							<ControlledTextInput
								name="startDate"
								label="Start Date"
								type="datetime-local"
								control={control}
							/>
						</Box>

						<Box fill gridArea="endDate">
							<ControlledTextInput
								name="endDate"
								label="End Date"
								type="datetime-local"
								control={control}
							/>
						</Box>

						<Box fill gridArea="reason">
							{/* Reason */}
							<ControlledTextInput
								name="reason"
								label="Reason"
								control={control}
							/>
						</Box>

						<Box fill gridArea="cancel">
							<Button
								type="button"
								secondary
								label="Cancel"
								disabled={loading}
								onClick={onCancel}
							/>
						</Box>
						<Box fill gridArea="submit">
							<Button
								type="submit"
								primary
								label="Submit"
								disabled={loading}
							/>
						</Box>
					</Grid>

					{loading ? (
						<Box
							fill
							background="dark-1"
							style={{ opacity: 0.2 }}
							justify="center"
							align="center"
						>
							<LoadingIndicator size={42} color="white" />
						</Box>
					) : null}
				</Stack>
			)}
		</form>
	);
});
