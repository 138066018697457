import { SchedulerEventModel, SchedulerResourceModel } from "@bryntum/schedulerpro";
import { uniqBy } from "lodash";
import {
	I_ActualScheduledRequirement,
	I_PlannedAndActualVesselScheduleTuple,
	I_PlannedScheduledRequirement,
	Requirement,
	S_ActualVesselAction,
	S_ActualVesselActionOutput,
	S_PlannedVesselActionOutput,
	Trade,
	VesselActionStateOutput,
	VesselWithQ88PRandUtOutput,
} from "../types/generated/q-vessel-schedule-lifecycle-v6.types";
import { getRoleManager } from "../hooks/useRoleManager";
import { OffScheduleRequirementInfo } from "../machines/testSchedules";

export type ISchedulerItemAction = (S_PlannedVesselActionOutput | S_ActualVesselAction) & {
	startDate: Date;
	endDate: Date;
	endState: VesselActionStateOutput;
	trade: Trade | undefined;
};

export interface ISchedulerItem extends Partial<SchedulerEventModel> {
	startDate: Date;
	endDate: Date;
	actions: ISchedulerItemAction[];
	isActual: boolean;
	requirementId: string;
	isLocked: boolean;
	isSpot?: boolean;
	originalData?: ISchedulerItem;
	eventType?: "requirement";
	hasStartedActions?: boolean;
	offScheduleRequirements?: OffScheduleRequirementInfo[];
}

interface IUTItem extends Partial<SchedulerEventModel> {
	startPort?: string;
	endPort?: string;
	reason: string;
	startDate: Date;
	endDate: Date;
	eventType: "ut";
}

export type ItemOrUT = ISchedulerItem | IUTItem;

function dateFromUnix(unix: number): Date {
	return new Date(unix * 1000);
}

const loackStatusMap: any = {
	"Not Started": false,
	"In Progress": true,
	Complete: true,
};

const findStartedActions = (requirementId: string, actualSchedule: any): boolean => {
	return !!actualSchedule?.requirements
		?.find((requirement: any) => requirement.requirementId === requirementId)
		?.actualVesselActions.some((action: S_ActualVesselActionOutput) => {
			const { actionStatus } = action;
			return actionStatus && loackStatusMap[actionStatus.id];
		});
};

export function mapIScheduleTuplesToBryntumEvents(
	schedules: I_PlannedAndActualVesselScheduleTuple[],
	scheduleFilter: "All" | "Actual" | "Planned",
	vessels: VesselWithQ88PRandUtOutput[],
	requirements: Requirement[],
	offScheduleRequirements: OffScheduleRequirementInfo[]
): ItemOrUT[] {
	const { can } = getRoleManager();
	const canDragRequirement = can("assign requirement to vessel");

	const getAdjustedSchedules = (
		array: I_ActualScheduledRequirement[] | I_PlannedScheduledRequirement[],
		isSpot: boolean,
		hasStartedActionsCallback: (requirementId: string) => boolean,
		postFix: string,
		tupleHasActualSchedule: boolean
	) => {
		return array.map<ISchedulerItem[]>((item: any) => {
			const isActual = postFix === "actual";

			const actions = isActual ? item.actualVesselActions : item.plannedVesselActions;

			const items = [] as any[];

			const longs: Trade[] = requirements?.find((f) => f.id === item.requirementId)?.longs || [];

			const shorts: Trade[] = requirements?.find((f) => f.id === item.requirementId)?.shorts || [];

			const hasStartedActions = hasStartedActionsCallback(item.requirementId);

			const canDrag = !isActual && !hasStartedActions && canDragRequirement && !item.isLocked;

			const endActions: any[] = [];

			let loadCount = 0;
			let unloadCount = 0;

			actions.forEach((a: any) => {
				var startDate = isActual ? a.actualStartDate : a.estimatedStartDate;

				var endDate = isActual ? a.actualEndDate : a.estimatedEndDate;

				const endState = isActual ? a.endState : a.optimalEndState;

				const vesselAction = a.vesselAction?.actionType.id;

				let tradeItem = null;

				//id: "ATC-G-2021-9-19_long_1nmsbpm_f6817f76-d7e0-4a8c-b244-39f6c59d8a34
				if (vesselAction === "load") {
					if (loadCount <= longs.length) {
						tradeItem = longs[loadCount];
						loadCount = loadCount + 1;
					}

					/*if (longs.length > 1) {
							startDate = tradeItem.startDate;
							endDate = tradeItem.endDate;
						}*/
				} else if (vesselAction === "unload") {
					if (unloadCount <= shorts.length) {
						tradeItem = shorts[unloadCount];
						unloadCount = unloadCount + 1;
					}

					/*if (vesselAction === "unload" && shorts.length > 1) {
							startDate = tradeItem.startDate;
							endDate = tradeItem.endDate;
						}*/
				}

				endActions.push({
					...a,
					startDate: dateFromUnix(startDate),
					endDate: dateFromUnix(endDate),
					endState: endState,
					trade: tradeItem,
				});
			});

			items.push({
				id: `${item.id}_${postFix}`,
				draggable: canDrag,
				isActual,
				tupleHasActualSchedule,
				hasStartedActions,
				startDate: dateFromUnix(item.startDate),
				endDate: dateFromUnix(item.endDate),
				resourceId: item.vesselId,
				requirementId: item.requirementId,
				isLocked: !!item.isLocked,
				isSpot,
				actions: endActions.flatMap((f: any) => f),
				offScheduleRequirements,
			});
			return items;
		});
	};

	//const latestSchedules = getOnlyLatestSchedules(
	//	schedules
	//)
	const latestSchedules = schedules.flatMap<ISchedulerItem>((scheduleTuple) => {
		const scheduleArray =
			scheduleTuple.actualSchedule && (scheduleFilter === "All" || scheduleFilter === "Actual")
				? scheduleTuple.actualSchedule.requirements
				: [];

		const scheduleArrayPlanned =
			scheduleTuple.plannedSchedule && (scheduleFilter === "All" || scheduleFilter === "Planned")
				? scheduleTuple.plannedSchedule.requirements
				: [];

		const newActualSchedules = getAdjustedSchedules(
			scheduleArray,
			scheduleTuple.actualSchedule?.isSpot || false,
			(requirementId: string): boolean => findStartedActions(requirementId, scheduleTuple.actualSchedule),
			"actual",
			true
		);

		const newPlannedSchedules = getAdjustedSchedules(
			scheduleArrayPlanned,
			scheduleTuple.plannedSchedule?.isSpot || false,
			(requirementId: string): boolean => findStartedActions(requirementId, scheduleTuple.actualSchedule),
			"planned",
			!!scheduleTuple.actualSchedule
		);

		return [...newActualSchedules.flatMap((f) => f), ...newPlannedSchedules.flatMap((f) => f)];
	});

	return [
		...latestSchedules,
		...vessels.flatMap(({ vessel: { id }, unavailableTimes }) =>
			(unavailableTimes ?? []).map<IUTItem>((ut) => ({
				...ut,
				reason: ut?.reason ?? "no reason provided",
				resourceId: id,
				eventType: "ut",
				startDate: new Date((ut?.startDate ?? 0) * 1000),
				endDate: new Date((ut?.endDate ?? 0) * 1000),
				draggable: false,
				locked: true,
			}))
		),
	];
}

const empty: never[] = [];

export function mapFleetMachineStateToVessels(
	vessels: VesselWithQ88PRandUtOutput[],
	schedules: I_PlannedAndActualVesselScheduleTuple[]
): Partial<SchedulerResourceModel>[] {
	if (!vessels.length) return empty;
	return [
		// Vessels
		...vessels.map<Partial<SchedulerResourceModel>>(
			({
				vessel: {
					id,
					isClean,
					name,
					details: { sizeCategory },
				},
			}) => ({
				id,
				name,
				size: sizeCategory,
				clean: isClean.toString() === "true" ? "C" : "D",
				stackItems: false,
				isSpot: false,
			})
		),
		// Spots
		...uniqBy(
			[
				...schedules
					.filter(({ plannedSchedule: { isSpot } }) => isSpot)
					.map(({ plannedSchedule }) => plannedSchedule),
				...schedules
					.filter(({ actualSchedule }) => actualSchedule && actualSchedule.isSpot)
					.map(({ actualSchedule }) => actualSchedule!),
			],
			({ vesselId }) => vesselId
		).map(({ vesselId, isSpot }) => ({
			id: vesselId,
			name: vesselId,
			size: "N/A",
			clean: "N/A",
			stackItems: false,
			isSpot,
		})),
	];
}
