import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { Action, applyMiddleware, compose, createStore, Store } from "redux";
import { createEpicMiddleware } from "redux-observable";
import createSagaMiddleware from "redux-saga";
import type { APP_RENDERED, INITIALIZE } from "./actions/init.actions";
import { IportReducerActions } from "./actions/ports.actions";
import { IRequirementReducerActions } from "./actions/requirements.actions";
import { rootEpic } from "./epics";

import createRootReducer, { IState } from "./reducers";
import { rootSaga } from "./sagas";

export const history = createBrowserHistory();

export type AllActions =
	| Action<typeof INITIALIZE>
	| Action<typeof APP_RENDERED>
	| IportReducerActions
	| IRequirementReducerActions;

export function configureStore(
	initialState?: IState
): Store<IState, AllActions> {
	// console.log({ initialState });
	const composeEnhancers: typeof compose =
		(window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

	const rootReducer = createRootReducer(history);

	const sagaMiddleware = createSagaMiddleware();
	const epicMiddleware = createEpicMiddleware<
		AllActions,
		AllActions,
		IState
	>();

	const store = createStore(
		rootReducer,
		initialState,
		composeEnhancers(
			applyMiddleware(
				routerMiddleware(history),
				sagaMiddleware,
				epicMiddleware
			)
		)
	);

	sagaMiddleware.run(rootSaga);
	epicMiddleware.run(rootEpic);

	return store;
}
