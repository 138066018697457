import { buildASTSchema, GraphQLNamedType, parse } from "graphql";
import { GraphQLBridge } from "uniforms-bridge-graphql";

import { Maybe } from "../../types/generated.types";

const schemaData = {
	// id: {
	// 	allowedValues: [1, 2, 3],
	// },
	// title: {
	// 	options: [
	// 		{ label: 1, value: "a" },
	// 		{ label: 2, value: "b" },
	// 	],
	// },
};

export const getSchemaBridgeForType = (
	schemaString: string,
	typeName: string
) => {
	if (!typeName) {
		throw new Error(`Arg 'typeName' is required; got: '${typeName}'`);
	}
	const schemaTypeA: Maybe<GraphQLNamedType> =
		buildASTSchema(parse(schemaString)).getType(typeName) || null;

	const schemaValidator = (model: any) => {
		return null;
	};

	if (!schemaTypeA) {
		throw new Error(`Could not get schema for '${typeName}'`);
	}

	const bridge = new GraphQLBridge(schemaTypeA, schemaValidator, schemaData);

	return bridge;
};

(window as any).getSchemaBridgeForType = getSchemaBridgeForType;
