import type { NormalizedData } from "../../types/common";
import {
	IportReducerActions,
	LOAD_PORTS_SUCCESS,
} from "../actions/ports.actions";
import { IPort } from "../sagas/loadPorts.saga";

export type IPortsState = NormalizedData<IPort>;

export function portsReducer(
	state: IPortsState = {
		allIds: [],
		byId: {},
	},
	action: IportReducerActions
): IPortsState {
	if (action.type === LOAD_PORTS_SUCCESS) {
		return action.payload;
	}
	return state;
}
