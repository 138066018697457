import React from "react";

type FleetActionSmallProps = {
	id?: string;
	ico: string;
	isActual: boolean;
	actionStatusClass?: string;
	left?: number;
};
const FleetActionSmall: React.FC<FleetActionSmallProps> = ({
	id,
	ico,
	actionStatusClass = "",
	left,
}) => {
	return (
		<div
			data-id={id}
			style={{
				position: "absolute",
				transform: `translate(${left}px, 0)`,
				zIndex: 3,
				top: 0,
				bottom: 0,
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
			className={actionStatusClass}
		>
			<div
				data-id={id}
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: 35,
					width: 35,
					color: "#000",
					backgroundColor: "#EAEAEA",
					zIndex: 3,
					borderRadius: 6,
				}}
				className={actionStatusClass}
			>
				<img height={24} width={24} src={ico} alt="fleet-ico" />
				<div className="status-indicator" />
			</div>
		</div>
	);
};

export default FleetActionSmall;
