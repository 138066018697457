import CircularProgress from "@material-ui/core/CircularProgress";
import { Box, Button, Grid, GridProps, Heading } from "grommet";
import { Save } from "grommet-icons";
import React, {
	Dispatch,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { BatchEditLeftPane } from "../components/Requirements/BatchEditLeftPane";
import { BatchEditRightPane } from "../components/Requirements/BatchEditRightPane";
import { CSVParseWarnings } from "../components/Requirements/CSVParsingWarnings";
import { ProductsContext } from "../context/ProductsContext";
import {
	isBatchEditInProgressSelector,
	isSubmitPossibleSelector,
	requirementBatchState,
} from "../selectors/requirement.selectors";
import {
	IRequirementReducerActions,
	PERSIST_BATCH_REQUIREMENT_LIST,
} from "../store/actions/requirements.actions";
import { IState } from "../store/reducers";
import { NormalizedData } from "../types/common";
import { Product } from "../types/generated/q-fanar-requirements.types";
import { Dialog, DialogContent } from "@material-ui/core";

export function RequirementBatchEditPage() {
	// todo: get products from machine state
	const normalizedProducts: NormalizedData<Product> = {
		allIds: [],
		byId: {},
	};

	const dispatch: Dispatch<IRequirementReducerActions> = useDispatch();
	const history = useHistory();
	const [focusedRequirementId, focusRequirement] = useState<null | string>(
		null
	);
	const isSubmitPossible = useSelector(isSubmitPossibleSelector);
	const isBatchEditInProgress = useSelector(isBatchEditInProgressSelector);
	const isSubmitting = useSelector(
		(state: IState) => requirementBatchState(state)?.isSubmitting
	);

	useEffect(() => {
		if (!isBatchEditInProgress) {
			// After form is submittted (or if this page was opened without uploading a file) we can navigate user back to Requirements page
			history.push("/requirements");
		}
	}, [isBatchEditInProgress, history]);
	const gridProps = useMemo<GridProps>(
		() => ({
			fill: true,
			columns: ["1/2", "1/2"],
			areas: [
				{ name: "batchLeftPane", start: [0, 0], end: [0, 0] },
				{ name: "batchDetails", start: [1, 0], end: [1, 0] },
			],
			rows: ["fill"],
			gap: "none",
		}),
		[]
	);

	const persistBatchRequirements = useCallback(() => {
		dispatch({ type: PERSIST_BATCH_REQUIREMENT_LIST });
	}, [dispatch]);

	return (
		<>
			<Box pad="medium">
				<Box direction="row" justify="between">
					<Heading level="2" size="medium" margin="small">
						Batch-add Requirements
					</Heading>
					<Button
						color="blue1"
						disabled={!isSubmitPossible}
						title={
							isSubmitPossible
								? undefined
								: "Please ensure all Requirements are valid before submitting"
						}
						icon={
							isSubmitting ? (
								<CircularProgress size="24px" />
							) : (
								<Save />
							)
						}
						label="Save"
						onClick={
							isSubmitPossible
								? persistBatchRequirements
								: undefined
						}
					/>
				</Box>
				<CSVParseWarnings />
			</Box>
			<Grid {...gridProps}>
				<ProductsContext.Provider value={normalizedProducts}>
					<BatchEditLeftPane
						gridArea="batchLeftPane"
						onSelectRequirement={focusRequirement}
						margin="small"
					/>
					<BatchEditRightPane
						gridArea="batchDetails"
						selectedRequirementId={focusedRequirementId}
						onCancel={() => {
							focusRequirement(null);
						}}
						margin="small"
					/>
				</ProductsContext.Provider>
			</Grid>

			<Dialog open={Boolean(isSubmitting)} maxWidth="xs">
				<DialogContent dividers>
					<Box direction="row" margin="small">
						<CircularProgress size="24px" />
						<div style={{ alignSelf: "center", marginLeft: 10 }}>
							Uploading
						</div>
					</Box>
				</DialogContent>
			</Dialog>
		</>
	);
}
