import { Box, FormField } from "grommet";
import { capitalize } from "lodash";
import React, { useEffect, useMemo } from "react";
import { ArrayField, Control, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";

import {
	allTerminals as allTerminalSelector,
	portsSelector,
	TerminalWithPort,
} from "../../selectors/ports.selectors";
import { IPort } from "../../store/sagas/loadPorts.saga";
import {
	RequirementAsInput,
	TradeAsInput,
} from "../../types/generated/q-fanar-requirements.types";
import {
	ControlledAutocomplete,
	ControlledAutocompleteProps,
} from "../forms/ControlledAutocomplete";
import { ControlledDateInput } from "../forms/ControlledDateInput";
import { ControlledSelect } from "../forms/ControlledSelect";
import { ControlledTextInput } from "../forms/ControlledTextInput";
import { BoxGrowingChildren } from "../utility/BoxGrowingChildren";

import type { RequirementFormValues } from "../../selectors/requirement.selectors";
import type { IPortsState } from "../../store/reducers/ports";
import { useMachineSelector } from "../../selectors/machine.selectors";
import { DATE_FORMAT } from "../../constants";

export function getTerminalsOfPort(
	portId: string | null | undefined,
	allTerminals: TerminalWithPort[],
	ports: IPortsState
): NonNullable<IPort["terminals"]>[number][] {
	if (!portId) return allTerminals.map(({ terminal }) => terminal);
	const port = ports.byId[portId];
	if (!port || !port.terminals) {
		return allTerminals.map(({ terminal }) => terminal);
	}
	return port.terminals;
}

interface DropdownOption {
	id: string;
	name: string;
}

const renderPortDropdownOption: ControlledAutocompleteProps<DropdownOption>["optionRenderer"] = (
	{ id, name },
	_options,
	_state,
	{ selected }
) =>
	selected ? (
		<span>{name}</span>
	) : (
		<Box pad="small" direction="row">
			<strong>{id}:</strong> ({name})
		</Box>
	);

function mapSelectedOptionToFormValue({ id }: DropdownOption) {
	return id;
}

function normalizeQuantityInput(q: string): string {
	if (isNaN(q as any)) return "";
	return `${Math.max(0, parseFloat(q))}`.slice(0, 10);
}

const searchKeys = ["name", "id"] as const;

interface SingleTradeFieldsProps {
	children?: never;
	control: Control<RequirementFormValues | RequirementAsInput>;
	setValue(name: string, value?: string): void;
	tradeType: "longs" | "shorts";
	trade: Partial<ArrayField<TradeAsInput & { dateRange: [string, string] }>>;
	/** The id in the shorts/longs array */
	id: number;
}

/** Renders the fields for a single short/long in the Requirement edit form */
export const SingleTradeFields: React.FC<SingleTradeFieldsProps> = function ({
	control,
	tradeType,
	trade,
	id,
	setValue,
}) {
	const availableProducts = useMachineSelector(
		(context) => context.products.allIds
	);
	const ports = useSelector(portsSelector);
	const allPorts = useMemo(() => Object.values(ports.byId), [ports]);
	const allTerminals = allTerminalSelector(ports);
	// If a port is selected, then terminal list can be narrowed down
	const portKey = `${tradeType}[${id}].port`;
	const terminalKey = `${tradeType}[${id}].terminal`;
	const port = useWatch<string>({
		name: portKey,
		control,
	});
	const selectedTerminal = useWatch<string>({
		name: terminalKey,
		control,
	});
	const terminalMatchingSelection = useMemo(
		() =>
			selectedTerminal
				? allTerminals.find(
						({ terminal: { id } }) => id === selectedTerminal
				  )
				: undefined,
		[selectedTerminal, allTerminals]
	);
	useEffect(() => {
		// Auto populate port, if user selected a random terminal from all terminal list
		if (!port && !!terminalMatchingSelection) {
			setValue(portKey, terminalMatchingSelection.port.id);
		}
	}, [terminalMatchingSelection]); // eslint-disable-line react-hooks/exhaustive-deps

	const shouldResetSelectedTerminal =
		!!port &&
		!!terminalMatchingSelection &&
		terminalMatchingSelection?.port.id !== port;
	useEffect(() => {
		// Reset the terminal if as a result of upload
		if (shouldResetSelectedTerminal) setValue(terminalKey, undefined);
	}, [shouldResetSelectedTerminal, setValue, terminalKey]);

	const terminalSuggestions = useMemo(
		() => getTerminalsOfPort(port, allTerminals, ports),
		[port, allTerminals, ports]
	);

	const tradeIdLabel = `${capitalize(tradeType.slice(0, -1))} ID`;

	return (
		<Box gap="xxsmall" pad="small" border={{ color: "brand" }}>
			<ControlledTextInput
				//label={tradeIdLabel}
				name={`${tradeType}[${id}].id`}
				placeholder={tradeIdLabel}
				defaultValue={trade.id}
				control={control}
				disabled
				type="hidden"
			/>

			<BoxGrowingChildren
				// background="#F7F7F7"
				fill
				direction="row"
				pad="small"
				justify="stretch"
			>
				<FormField
					name={`${tradeType}[${id}].dateRange`}
					label="Laycan Date Range"
				>
					<ControlledDateInput
						control={control}
						name={`${tradeType}[${id}].dateRange`}
						defaultValue={trade.dateRange}
						format={`${DATE_FORMAT.toLowerCase()} - ${DATE_FORMAT.toLowerCase()}`}
						isRangeInput
					/>
				</FormField>
			</BoxGrowingChildren>

			<BoxGrowingChildren direction="row" justify="stretch" pad="small">
				<ControlledAutocomplete<IPort>
					label="Port"
					name={`${tradeType}[${id}].port`}
					options={allPorts}
					control={control}
					defaultValue={trade.port ?? ""}
					mapSelectedOptionToFormValue={mapSelectedOptionToFormValue}
					optionRenderer={renderPortDropdownOption}
					searchKeys={searchKeys}
				/>
				<ControlledAutocomplete
					name={`${tradeType}[${id}].terminal`}
					label="Terminal"
					defaultValue={trade.terminal || ""}
					options={
						terminalSuggestions as { id: string; name: string }[]
					}
					control={control}
					mapSelectedOptionToFormValue={mapSelectedOptionToFormValue}
					optionRenderer={renderPortDropdownOption}
					searchKeys={searchKeys}
				/>
			</BoxGrowingChildren>

			<BoxGrowingChildren direction="row" justify="stretch" pad="small">
				<ControlledSelect
					name={`${tradeType}[${id}].product.id`}
					label="Product Type"
					defaultValue={trade.product?.id}
					options={availableProducts}
					control={control}
				/>
				<ControlledTextInput
					defaultValue={trade.productQuantity}
					name={`${tradeType}[${id}].productQuantity`}
					label="Quantity (bbl)"
					placeholder="Quantity"
					step={1}
					dynamicSteps
					type="number"
					control={control}
					resolver={normalizeQuantityInput}
				/>
			</BoxGrowingChildren>
		</Box>
	);
};
