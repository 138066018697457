import { Configuration } from "../util/config";
import { createApolloClient, makeGetSchema } from "./apolloClient";
// import { createClient } from "urql";
import { APIClientConfig } from "./types";

const VESSELS_V2_URL = Configuration.VESSELS_V2_URL;

const vesselsClientConfig: APIClientConfig = {
	cacheKey: "fanar-vessels-client",
	uri: VESSELS_V2_URL,
	fetchPolicy: "network-only",
};

export const vesselsClient = createApolloClient(vesselsClientConfig);

export const getVesselsSchema = makeGetSchema(vesselsClientConfig);
