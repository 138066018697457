// Apollo imports
import {
	ApolloClient,
	ApolloLink,
	createHttpLink,
	InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { introspectSchema, wrapSchema } from "@graphql-tools/wrap";
import { fetch } from "cross-fetch";
import { print, printSchema } from "graphql";

import UserContext from "../context/UserContext";
import { getUserAuthClient } from "../util/auth";
import { APIClientConfig } from "./types";

/**
 * Taken from the React Knowledge App template
 */
export const authClient = getUserAuthClient();

// Client setup
// - allow this service to be a client of a remote service

// @ts-ignore
const makeExecutor = (uri: string): AsyncExecutor => async ({
	//@ts-ignore
	document,
	//@ts-ignore
	variables,
	//@ts-ignore
	context,
}) => {
	const query = print(document);
	//@ts-ignore
	const fetchResult = await fetch(uri, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			// Authorization: `Bearer ${context.authKey}`,
			...UserContext.getAuthHeader(),
		},
		body: JSON.stringify({ query, variables }),
	});
	return fetchResult.json();
};

/**
 * Create getSchema function for GraphQLSchemaForm using an APIClientConfig
 */
export const makeGetSchema = (config: APIClientConfig) => {
	const executor = makeExecutor(config.uri);
	const getSchema = async () => {
		const schema = wrapSchema({
			schema: await introspectSchema(executor),
			executor,
		});

		return printSchema(schema);
	};

	return getSchema;
};

/**
 * Create an Apollo Client from an APIClientConfig
 */
export function createApolloClient(config: APIClientConfig) {
	if (!config.uri) {
		throw new Error("ApolloClient must have a URI provided.");
	}

	const _httpLink = createHttpLink({ uri: config.uri, fetch });

	const authLink = setContext((_, { headers }) => {
		console.log("header config: ", UserContext.getAuthHeader());
		// return the headers to the context so httpLink can read them
		return {
			headers: {
				...headers,
				...UserContext.getAuthHeader(),
			},
		};
	});

	return new ApolloClient({
		link: ApolloLink.from([authLink, _httpLink]),
		defaultOptions: {
			query: {
				fetchPolicy: "network-only",
				errorPolicy: "all",
			},
		},
		cache: new InMemoryCache().restore(
			(window as any)[`__APOLLO_STATE__${config.cacheKey}__`]
		),
	});
}
