export function getCleanTimeMultiplierFromSizeCategory(size: string): number {
	switch (size) {
		case "MR":
			return 1.0;
		case "LR1":
		case "LR2":
		case "PANAMAX":
		case "AFRAMAX":
			return 1.5;
		default:
			return 1.5;
	}
}
