import {
	Controller,
	// FieldError,
	FieldValues,
	useFormContext,
} from "@maana-io/react-hook-form";
import { makeStyles, styled, TextField, Typography } from "@material-ui/core";
import { capitalCase } from "capital-case";
import { GraphQLInputObjectType } from "graphql";
import { Box, CheckBox, DateInput, FormField, Text } from "grommet";
import { get } from "lodash";
import moment from "moment";
import React from "react";
import ReactDOM from "react-dom";
import GraphQLBridge from "uniforms-bridge-graphql";

// import { Maybe } from "../../types/common";
import { FieldArray } from "./_FieldArray";
import { FieldArrayOfScalars } from "./_FieldArrayOfScalars";
import { getSchemaBridgeForType } from "./GraphQLBridge";
import {
	FORM_PORTAL_ROOT_NODE_ID,
	GraphQLSchemaFormProps,
	OverrideItem,
} from "./GraphQLSchemaForm";
import { ConstructorStore } from "./helpers/ConstructorStore";
import { findTypeOfField } from "./helpers/findTypeOfField";
import {
	floatDateToString,
	getDateAsFloatOnChangeHandlerForHtmlInput,
} from "./helpers/floatAsDate.helpers";
import { ControlledAutocomplete } from "./ControlledAutocomplete";
import { /*DATE_FORMAT,*/ toDateFormat } from "../../constants";

export const getFieldName = (
	parentFieldName: string,
	currentFieldName: string,
	index?: number
): string => {
	if (parentFieldName === "root") {
		return currentFieldName;
	}

	// `${parentFieldName}.${currentFieldName}` +
	return (
		`${parentFieldName}.${currentFieldName}` +
		(typeof index === "number" ? `[${index}]` : "")
	);
};

function getTextInputType(
	fieldType: string
): React.InputHTMLAttributes<unknown>["type"] {
	if (fieldType === "DateTime") {
		return "datetime-local";
	}

	if (fieldType === "Date") {
		return "date";
	}

	if (fieldType === "Time") {
		return "time";
	}

	if (fieldType === "String") {
		return "text";
	}

	if (fieldType === "Number") {
		return "number";
	}

	console.log("didnt find it: ", fieldType);
	return "text";
}

function mapSelectedOptionToFormValue<T>({ value }: OverrideItem<T>): T {
	return value;
}

const parseDateFromString = (dateValue: any) => {
	const parsedDate = moment(
		dateValue && typeof dateValue === "object"
			? dateValue.value
			: dateValue || ""
	)
		.startOf("day")
		.format("YYYY-MM-DDThh:mm");
	return parsedDate;
};

const dropdownSearchKeys = ["label", "value"] as const;

function DropdownOptionRenderer<T>(
	{ label, value }: OverrideItem<T>,
	_: unknown,
	_1: unknown,
	{ selected }: { selected: boolean }
): React.ReactElement {
	return selected ? (
		<span>{label}</span>
	) : (
		<Box pad="small" flex={false} direction="row" align="baseline">
			{label}
			{typeof value === "string" && value !== label && (
				<>
					: <Text size="small">({value})</Text>
				</>
			)}
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		//border: "1px solid black",
		marginBottom: theme.spacing(2),
		display: "flex",
		flexWrap: "wrap",
		// maxWidth: 600,
	},
	inputField: {
		flex: "1 0 50%",
	},
}));

const FieldWrapper = React.memo(
	styled(Box)((props) => ({
		margin: props.theme.spacing(2),
	}))
);

type RecursiveFormFieldsProps = Pick<
	GraphQLSchemaFormProps,
	| "disableFields"
	| "hideFields"
	| "nestedKindData"
	| "recurse"
	| "fieldTypeOverrides"
	| "extraFieldNormalizers"
	| "requiredFields"
> & {
	schemaBridge: GraphQLBridge;
	schemaString: string;
	fieldNames: string[];
	parentFieldName: string;
	flat: boolean;
	overrideFieldLabels?: any | undefined;
	fieldValidationRules?: any | undefined;
	validationErrorObjects?: any | undefined;
};

export const RecursiveFormFields = ({
	schemaBridge,
	schemaString,
	fieldNames,
	parentFieldName,
	flat,
	disableFields = [],
	hideFields = [],
	nestedKindData,
	recurse,
	fieldTypeOverrides,
	extraFieldNormalizers,
	requiredFields,
	overrideFieldLabels,
	fieldValidationRules,
	validationErrorObjects,
}: RecursiveFormFieldsProps) => {
	const classes = useStyles();
	const {
		register,
		control,
		/*errors,*/
		watch,
		getValues,
	} = useFormContext();

	// * save a reference to whether we're on the root node
	// * if we are: respect the `flat` property for nested objects (portal them)
	// * if we are not: do not flatten nested objects (do not portal them, nest them inside their parent obj)
	const isRoot = React.useMemo(() => parentFieldName === "root", [
		parentFieldName,
	]);

	// * use a Portal to render sub-forms as siblings to the main set of fields
	// * this prevents crazy nesting of fieldsets within fieldsets
	const [portalRootNode, setPortalRootNode] = React.useState<HTMLElement>();

	// * we use useEffect here to check to see if the portal root node has been rendered yet
	// * since the portal root is within the form component itself, it won't render until
	// * the rest of the form renders; thus we need to wait for it to appear before
	// * trying to render these other fields/inputs within it
	React.useEffect(() => {
		let node = document.getElementById(FORM_PORTAL_ROOT_NODE_ID);
		if (node) {
			setPortalRootNode(node);
		}
	}, []);

	const getFieldLabel = React.useCallback(
		(fName: string, fallbackName: any = null) => {
			return (
				(overrideFieldLabels && overrideFieldLabels[fName]) ||
				capitalCase(fallbackName || fName)
			);
		},
		[overrideFieldLabels]
	);

	const getFieldValidators = React.useCallback(
		(fName: string) =>
			fieldValidationRules && fieldValidationRules[fName]
				? fieldValidationRules[fName](getValues(fName), getValues)
				: undefined,
		[fieldValidationRules, getValues]
	);

	const getFieldError = React.useCallback(
		(fName: string) =>
			validationErrorObjects && validationErrorObjects[fName]
				? validationErrorObjects[fName]
				: undefined,
		[validationErrorObjects]
	);

	return (
		<Box width="xlarge" border={{ color: "blue1" }}>
			<FieldWrapper data-id="RecursiveFormFields">
				<>
					{fieldNames.map((fieldName, index) => {
						const field = schemaBridge.getField(fieldName);
						const fieldType = schemaBridge.getType(fieldName).name;

						// * path of property field within object; can be used with lodash.get
						const dotSeparatedPath = getFieldName(
							parentFieldName,
							field.name
						);

						// * now that we have the dot-separated path, let's check if this field is disabled, hidden, and/or required
						const isDisabled =
							disableFields.indexOf(dotSeparatedPath) > -1;
						const isHidden =
							hideFields.indexOf(dotSeparatedPath) > -1;

						const isRequired = Boolean(
							requiredFields?.includes(dotSeparatedPath)
						);
						// TODO: consider field.astNode?.type.kind === "NonNullType" ?

						const fieldTypeOverride = get(
							fieldTypeOverrides,
							dotSeparatedPath
						);

						// * check to see if we were provided data for this field
						const overrideListOrFn:
							| OverrideItem[]
							| ((state: FieldValues) => OverrideItem[])
							| null = get(
							nestedKindData,
							dotSeparatedPath,
							null
						);

						const overrideList =
							typeof overrideListOrFn === "function"
								? overrideListOrFn(watch())
								: overrideListOrFn;

						// const fieldError: Maybe<FieldError> = get(
						// 	errors,
						// 	dotSeparatedPath
						// );

						const fieldError = getFieldError(dotSeparatedPath);

						const key = `${index}-${fieldType}`;

						if (isHidden) {
							return (
								<input
									key={key}
									type="hidden"
									ref={register}
									name={dotSeparatedPath}
								/>
							);
						}

						const typeOfField = findTypeOfField(field);

						const overrideNormalizer = get(
							extraFieldNormalizers,
							dotSeparatedPath,
							null
						);

						// * parse Floats first, check if they need to be used as dates
						if (
							typeOfField === "Float" &&
							(fieldTypeOverride === "Date" ||
								fieldTypeOverride === "DateTime")
						) {
							// * we have a float that should render a date field
							return (
								<Controller
									key={key}
									control={control}
									name={dotSeparatedPath}
									rules={{
										...getFieldValidators(dotSeparatedPath),
										required: isRequired,
									}}
									render={(props) => {
										return (
											<FieldWrapper>
												<FormField
													name={props.name}
													required={isRequired}
													label={getFieldLabel(
														props.name ||
															field.name,
														field.name
													)}
													error={fieldError?.message}
												>
													{fieldTypeOverride ===
													"Date" ? (
														<TextField
															inputRef={props.ref}
															name={props.name}
															type="date"
															value={floatDateToString(
																props.value
															)}
															onChange={getDateAsFloatOnChangeHandlerForHtmlInput(
																props.onChange
															)}
															// format={DATE_FORMAT}
														/>
													) : fieldTypeOverride ===
													  "DateTime" ? (
														<TextField
															inputRef={props.ref}
															name={props.name}
															type="datetime-local"
															value={floatDateToString(
																props.value
															)}
															onChange={getDateAsFloatOnChangeHandlerForHtmlInput(
																props.onChange
															)}
														/>
													) : null}
												</FormField>
											</FieldWrapper>
										);
									}}
								/>
							);
						}

						// * when fields are IDs the consumer can provide a list of items to use in an autocomplete form
						if (
							Array.isArray(overrideList) &&
							(typeOfField === "ID" ||
								fieldType === "Object" ||
								fieldType === "String")
						) {
							// * display drop down for user to choose from
							return (
								<FieldWrapper key={key}>
									<ControlledAutocomplete<
										OverrideItem<unknown>
									>
										control={control}
										rules={{
											...getFieldValidators(
												dotSeparatedPath
											),
											required: isRequired,
										}}
										name={dotSeparatedPath}
										defaultValue={overrideList[0]?.value}
										options={overrideList}
										label={
											getFieldLabel(field.name)
											// (field.name)
										}
										// error={fieldError?.message}
										mapSelectedOptionToFormValue={
											mapSelectedOptionToFormValue
										}
										searchKeys={dropdownSearchKeys}
										optionRenderer={DropdownOptionRenderer}
									/>
								</FieldWrapper>
							);
						}

						switch (fieldType) {
							case "String":
							case "Number":
								return (
									<Controller
										key={key}
										control={control}
										rules={{
											...getFieldValidators(
												dotSeparatedPath
											),
											required: isRequired,
										}}
										name={dotSeparatedPath}
										error={fieldError?.message}
										render={(props) => (
											<FieldWrapper>
												<FormField
													disabled={isDisabled}
													onChange={(ev) => {
														if (
															overrideNormalizer
														) {
															// console.log(
															// 	"It's here",
															// 	overrideNormalizer.toString()
															// );
															props.onChange(
																overrideNormalizer(
																	ev.target
																		.value
																)
															);
														} else if (
															fieldType ===
															"Number"
														) {
															props.onChange(
																parseFloat(
																	ev.target
																		.value
																)
															);
														} else {
															props.onChange(
																ev.target.value
															);
														}
													}}
													value={props.value}
													label={getFieldLabel(
														props.name ||
															field.name,
														field.name
													)}
													error={fieldError?.message}
													type={getTextInputType(
														fieldType
													)}
												/>
											</FieldWrapper>
										)}
									/>
								);
							case "Boolean":
								return (
									<Controller
										name={dotSeparatedPath}
										control={control}
										key={key}
										rules={{
											...getFieldValidators(
												dotSeparatedPath
											),
											required: isRequired,
										}}
										render={(props) => (
											<FieldWrapper>
												<FormField
													name={props.name}
													htmlFor={props.name}
													disabled={isDisabled}
													error={fieldError?.message}
												>
													<CheckBox
														color="blue1"
														label={getFieldLabel(
															props.name ||
																field.name,
															field.name
														)}
														name={props.name}
														checked={props.value}
														onChange={(event) =>
															props.onChange(
																event.target
																	.checked
															)
														}
													/>
												</FormField>
											</FieldWrapper>
										)}
									/>
								);
							case "Date":
								return (
									<Controller
										key={key}
										name={dotSeparatedPath}
										control={control}
										rules={{
											...getFieldValidators(
												dotSeparatedPath
											),
											required: isRequired,
										}}
										render={(props) => (
											<FieldWrapper>
												<FormField
													name={props.name}
													required={isRequired}
													label={getFieldLabel(
														props.name ||
															field.name,
														field.name
													)}
													disabled={isDisabled}
													error={fieldError?.message}
												>
													<DateInput
														// @ts-ignore
														disabled={isDisabled}
														calendarProps={{
															locale:
																navigator.language,
														}}
														value={parseDateFromString(
															props?.value
														)}
														name={props.name}
														onChange={({
															value,
														}) => {
															if (value)
																props.onChange(
																	value
																);
														}}
														// format={DATE_FORMAT.toLowerCase()} <-- this is not working for some reason
														buttonProps={{
															style: {
																padding: "15px",
															},
															alignSelf: "start",
															label: `${
																(props?.value &&
																	toDateFormat(
																		parseDateFromString(
																			props?.value
																		)
																	)) ||
																"not selected"
															}
															`,
														}}
													/>
												</FormField>
											</FieldWrapper>
										)}
									/>
								);
							case "DateTime":
								return (
									<Controller
										key={key}
										control={control}
										name={dotSeparatedPath}
										rules={{
											...getFieldValidators(
												dotSeparatedPath
											),
											required: isRequired,
										}}
										render={(props) => {
											return (
												<FieldWrapper>
													<FormField>
														<TextField
															inputRef={props.ref}
															name={props.name}
															type="datetime-local"
															value={floatDateToString(
																props.value
															)}
															onChange={getDateAsFloatOnChangeHandlerForHtmlInput(
																props.onChange
															)}
														/>
													</FormField>
												</FieldWrapper>
											);
										}}
									/>
								);
							case "Time":
								return (
									<Controller
										key={key}
										name={dotSeparatedPath}
										control={control}
										rules={{
											...getFieldValidators(
												dotSeparatedPath
											),
											required: isRequired,
										}}
										error={fieldError?.message}
										render={({
											onChange,
											value,
											name,
											ref,
										}) => (
											<FieldWrapper>
												<TextField
													variant="outlined"
													className={
														classes.inputField
													}
													// defaultValue={value}
													value={((value) => {
														const parsedDate = moment(
															value &&
																typeof value ===
																	"object"
																? value.value
																: value || ""
														)
															.startOf("day")
															.format(
																"YYYY-MM-DDThh:mm"
															);

														return parsedDate;
													})(value)}
													error={!!fieldError}
													helperText={
														fieldError?.message
													}
													InputLabelProps={{
														shrink: true,
													}}
													inputRef={ref}
													label={getFieldLabel(
														name || field.name,
														field.name
													)}
													name={name}
													onChange={onChange}
													required={isRequired}
													type={getTextInputType(
														fieldType
													)}
													disabled={isDisabled}
												/>
											</FieldWrapper>
										)}
									/>
								);
							case "JSON":
								console.error(
									"JSON Field type: Not Yet Implemented"
								);
								return null;
							case "Object":
								const subTypeSchemaBridge = getSchemaBridgeForType(
									schemaString,
									// @ts-ignore
									findTypeOfField(field)
								);

								const subFieldNames = subTypeSchemaBridge.getSubfields();

								// * to resolve TypeScript errors, check if the node exists
								// * (can't render a portal within an empty node
								if (
									!portalRootNode ||
									portalRootNode === null
								) {
									// * root node not yet rendered, delay the rendering until it appears
									return null;
								}

								const nestedObjectMarkup = (
									<div
										key={key}
										data-component-id="RecursiveFormFields-Object"
									>
										<Typography variant="h6">
											{getFieldLabel(
												findTypeOfField(field) || ""
											)}
										</Typography>
										<RecursiveFormFields
											flat={isRoot && flat}
											fieldNames={subFieldNames}
											parentFieldName={dotSeparatedPath}
											schemaBridge={subTypeSchemaBridge}
											schemaString={schemaString}
											disableFields={disableFields}
											hideFields={hideFields}
											nestedKindData={nestedKindData}
											fieldTypeOverrides={
												fieldTypeOverrides
											}
											extraFieldNormalizers={
												extraFieldNormalizers
											}
											requiredFields={requiredFields}
											recurse={recurse}
											overrideFieldLabels={
												overrideFieldLabels
											}
											fieldValidationRules={
												fieldValidationRules
											}
											validationErrorObjects={
												validationErrorObjects
											}
										/>
									</div>
								);

								return flat
									? ReactDOM.createPortal(
											nestedObjectMarkup,
											portalRootNode
									  )
									: nestedObjectMarkup;
							case "Array":
								// * determine the type of the array fields
								const typeOfArray = findTypeOfField(field);

								// * to resolve TypeScript errors, check if the node exists
								// * (can't render a portal within an empty node
								if (
									!portalRootNode ||
									portalRootNode === null
								) {
									// * root node not yet rendered, delay the rendering until it appears
									return null;
								}

								// todo: handle other scalar types here
								if (
									typeOfArray === "String" ||
									typeOfArray === "Float"
								) {
									const typoH6Label = `${getFieldLabel(
										field.name
									)}:`;
									const scalarOutputMarkup = (
										<div
											key={key}
											data-component-id="RecursiveFormFields-ArrayOfScalars"
										>
											<Typography variant="h6">
												{typoH6Label}
											</Typography>
											<FieldArrayOfScalars
												control={control}
												field={field}
												name={field.name}
												parentFieldName={
													dotSeparatedPath
												}
												register={register}
												schemaString={schemaString}
												newItemConstructor={() => ({
													value: "",
												})}
											/>
										</div>
									);

									return flat
										? ReactDOM.createPortal(
												scalarOutputMarkup,
												portalRootNode
										  )
										: scalarOutputMarkup;
								}

								if (!typeOfArray) {
									throw new Error(
										"Cannot render type of Array of undefined"
									);
								}

								const arrayOfObjMarkup = (
									<div
										key={key}
										data-component-id="RecursiveFormFields-ArrayOfObjects"
									>
										<Typography variant="h6">
											{
												(field.type as GraphQLInputObjectType)
													.name
											}
										</Typography>
										<FieldArray
											flat={isRoot && flat}
											parentFieldName={dotSeparatedPath}
											name={field.name}
											control={control}
											register={register}
											schemaString={schemaString}
											field={field}
											newItemConstructor={
												ConstructorStore[typeOfArray]
											}
											typeOfArray={typeOfArray}
										/>
									</div>
								);

								return flat
									? ReactDOM.createPortal(
											arrayOfObjMarkup,
											portalRootNode
									  )
									: arrayOfObjMarkup;
							default:
								console.log(
									"caught unhandled field type",
									fieldType
								);
								throw new Error(
									`RecursiveFormFields: no handler for ${fieldType}`
								);
						}
					})}
				</>
			</FieldWrapper>
		</Box>
	);
};
