import { Link } from "react-router-dom";
import { Stack, Box, Text } from "grommet";
import { Button } from "@material-ui/core";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import WarningIcon from "@material-ui/icons/Warning";

export function UnauthorizedPage() {
	return (
		<Stack anchor="center">
			<Box pad="large">
				<Text>
					<h1 className="color dark-red">
						<WarningIcon
							style={{
								fontSize: "1em",
								float: "left",
								margin: "-8px 10px 0",
							}}
						/>{" "}
						<span>
							You don't have permissions to access this page!
						</span>
					</h1>
					<Button>
						<KeyboardReturnIcon />
						<Link to="/">Return to main page</Link>
					</Button>
				</Text>
			</Box>
		</Stack>
	);
}
