import { Action } from "redux";
import { IFleetInterpreter } from "../../App";
import {
	RequirementAsInput,
	Requirement,
	I_ActualVesselScheduleTuple,
} from "../../types/generated/q-fanar-requirements.types";
import { Product } from "../../types/generated/q-vessel-schedule-lifecycle-v6.types";
import { IPortsState } from "../reducers/ports";

interface ActionWithPayload<A, P> extends Action<A> {
	payload: P;
}

export const LOAD_REQUIREMENTS = "LOAD_REQUIREMENTS";
export interface ILoadREquirementsAction {
	type: typeof LOAD_REQUIREMENTS;
}
export const LOAD_REQUIREMENTS_SUCCESS = "LOAD_REQUIREMENTS_SUCCESS";
export interface ILoadRequirementsSuccessAction {
	type: typeof LOAD_REQUIREMENTS_SUCCESS;
	payload: {
		byId: Record<string, Requirement>;
		allIds: string[];
		vesselById: Record<string, string>;
	};
}

export const UPDATE_REQUIREMENT = "UPDATE_REQUIREMENT";
export interface IUpdateRequirementAction {
	type: typeof UPDATE_REQUIREMENT;
	payload: any;
	fleetService: IFleetInterpreter;
}

export const UPDATE_REQUIREMENT_SUCCESS = "UPDATE_REQUIREMENT_SUCCESS";
export interface IUpdateRequirementSuccessAction {
	type: typeof UPDATE_REQUIREMENT_SUCCESS;
	payload: any;
}
export const UPDATE_REQUIREMENT_FAILED = "UPDATE_REQUIREMENT_FAILED";
export interface IUpdateRequriementFailureAction {
	type: typeof UPDATE_REQUIREMENT_FAILED;
	payload: { requirement: any; message: string };
}

export const RECONSIGN_REQUIREMENT = "RECONSIGN_REQUIREMENT";
export interface IReconsignRequirementAction {
	type: typeof RECONSIGN_REQUIREMENT;
	payload: any;
	fleetService: IFleetInterpreter;
}

export const RECONSIGN_REQUIREMENT_SUCCESS = "RECONSIGN_REQUIREMENT_SUCCESS";
export interface IReconsignRequirementSuccessAction {
	type: typeof RECONSIGN_REQUIREMENT_SUCCESS;
	payload: any;
}
export const RECONSIGN_REQUIREMENT_FAILED = "RECONSIGN_REQUIREMENT_FAILED";
export interface IReconsignRequriementFailureAction {
	type: typeof RECONSIGN_REQUIREMENT_FAILED;
	payload: { requirement: any; message: string };
}

export const PERSIST_REQUIREMENT = "PERSIST_REQUIREMENT";

export const PERSIST_REQUIREMENT_SUCCESS = "PERSIST_REQUIREMENT_SUCCESS";
export interface IPersistRequirementSuccessAction {
	type: typeof PERSIST_REQUIREMENT_SUCCESS;
	payload: Requirement;
	schedule?: I_ActualVesselScheduleTuple[];
}

export interface IPersistRequirementAction {
	type: typeof PERSIST_REQUIREMENT;
	payload: Requirement;
	fleetService: IFleetInterpreter;
}

export const PERSIST_REQUIREMENT_FAILURE = "PERSIST_REQUIREMENT_FAILURE";
export interface IPersistRequriementFailureAction {
	type: typeof PERSIST_REQUIREMENT_FAILURE;
	payload: { requirement: Requirement; message: string };
}

export const PROMPT_BEFORE_DELETE_REQUIREMENT = "PROMPT_BEFORE_DELETE_REQUIREMENT";
export const DISMISS_DELETE_PROMPT = "DISMISS_DELETE_PROMPT";
export const DELETE_REQUIREMENT = "DELETE_REQUIREMENT";
export const DELETE_REQUIREMENT_SUCCESS = "DELETE_REQUIREMENT_SUCCESS";
export const DELETE_REQUIREMENT_FAILURE = "DELETE_REQUIREMENT_FAILURE";

export const PROVIDE_REQUIREMENT_CSV = "PROVIDE_REQUIREMENT_CSV";
export interface IProvideRequirementCSV {
	type: typeof PROVIDE_REQUIREMENT_CSV;
	payload: {
		/** The entire contents of the CSV file as a string */
		csv: string;
		creator: string;
		ports: IPortsState;
		products: Product[];
	};
}

export const DISMISS_PARSE_WARNING = "DISMISS_PARSE_WARNING";
export const DISMISS_OVERRIDE_WARNING = "DISMISS_OVERRIDE_WARNING";

interface IDismissParseWarning {
	type: typeof DISMISS_PARSE_WARNING;
	payload: number;
}

interface IDismissOverrideWarning {
	type: typeof DISMISS_OVERRIDE_WARNING;
}

export const DISMISS_REQ_WARNING = "DISMISS_REQ_WARNING";

interface IDismissReqWarning {
	type: typeof DISMISS_REQ_WARNING;
	payload: { requirementId: number; warningId: number };
}

/** Updates a requirement in the local batch state */
export const UPDATE_A_BATCH_REQUIREMENT = "UPDATE_A_BATCH_REQUIREMENT";
interface IUpdateABatchRequirement {
	type: typeof UPDATE_A_BATCH_REQUIREMENT;
	payload: { id: number; requirement: RequirementAsInput };
}

/** Removes a requirement from the local batch editor state */
export const REMOVE_A_BATCH_REQUIREMENT = "REMOVE_A_BATCH_REQUIREMENT";

/** Sends the current batch state to backend */
export const PERSIST_BATCH_REQUIREMENT_LIST = "PERSIST_BATCH_REQUIREMENT_LIST";

export const BATCH_PERSIST_SUCCESS = "BATCH_PERSIST_SUCCESS";
export const BATCH_PERSIST_FAILED = "BATCH_PERSIST_FAILED";

export const LOCK_REQUIREMENT_TO_VESSEL_ACTION = "LOCK_REQUIREMENT_TO_VESSEL";
// export const LOCK_REQUIREMENT_TO_VESSEL_FAILED =
// 	"LOCK_REQUIREMENT_TO_VESSEL_FAILED";

export const LOCK_REQUIREMENT_TO_SPOT_ACTION = "LOCK_REQUIREMENT_TO_SPOT";
// export const LOCK_REQUIREMENT_TO_SPOT_FAILED =
// 	"LOCK_REQUIREMENT_TO_SPOT_FAILED";

export const UNLOCK_REQUIREMENT_ACTION = "UNLOCK_REQUIREMENT";
// export const UNLOCK_REQUIREMENT_FAILED = "UNLOCK_REQUIREMENT_FAILED";

export const DISMISS_SNACKBAR = "DISMISS_SNACKBAR";

export type IRequirementReducerActions =
	| ILoadREquirementsAction
	| ILoadRequirementsSuccessAction
	| IPersistRequirementAction
	| IUpdateRequirementAction
	| IPersistRequirementSuccessAction
	| IPersistRequriementFailureAction
	| IUpdateRequirementSuccessAction
	| IUpdateRequriementFailureAction
	| IReconsignRequirementAction
	| IReconsignRequirementSuccessAction
	| IReconsignRequriementFailureAction
	| IProvideRequirementCSV
	| IDismissParseWarning
	| IDismissOverrideWarning
	| IDismissReqWarning
	| IUpdateABatchRequirement
	| ActionWithPayload<typeof REMOVE_A_BATCH_REQUIREMENT, number>
	| Action<typeof PERSIST_BATCH_REQUIREMENT_LIST>
	| Action<typeof BATCH_PERSIST_SUCCESS>
	| ActionWithPayload<typeof BATCH_PERSIST_FAILED, string>
	| ActionWithPayload<typeof DELETE_REQUIREMENT, string>
	| ActionWithPayload<typeof DELETE_REQUIREMENT_FAILURE, string>
	| ActionWithPayload<typeof DELETE_REQUIREMENT_SUCCESS, string>
	| ActionWithPayload<typeof PROMPT_BEFORE_DELETE_REQUIREMENT, string>
	| Action<typeof DISMISS_DELETE_PROMPT>
	| Action<typeof DISMISS_SNACKBAR>
	| ActionWithPayload<
			typeof LOCK_REQUIREMENT_TO_VESSEL_ACTION,
			{
				requirementID: string;
				vesselID: string;
				lock: string | number | undefined;
			}
	  >
	// | ActionWithPayload<
	// 		typeof LOCK_REQUIREMENT_TO_VESSEL_FAILED,
	// 		{ errorMessage: string | undefined }
	//   >
	| ActionWithPayload<
			typeof LOCK_REQUIREMENT_TO_SPOT_ACTION,
			{ requirementID: string; lock: string | number | undefined }
	  >
	// | ActionWithPayload<
	// 		typeof LOCK_REQUIREMENT_TO_SPOT_FAILED,
	// 		{ errorMessage: string | undefined }
	//   >
	| ActionWithPayload<typeof UNLOCK_REQUIREMENT_ACTION, { requirementID: string; lock: string | number | null }>;
// | ActionWithPayload<
// 		typeof UNLOCK_REQUIREMENT_FAILED,
// 		{ errorMessage: string | undefined }
//   >
