import { Maybe } from "../types/common";

export const currencyFormatter = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "USD",
});

export const formatCurrency = (amount: Maybe<number>): string =>
	currencyFormatter.format(amount || 0);

const numberFormatter = new Intl.NumberFormat("en-US");

export const formatNumber = (value: Maybe<number>): string =>
	numberFormatter.format(value || 0);
