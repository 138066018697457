// import { useHistory } from "react-router";
import React, { useMemo } from "react";
import { withRouter } from "react-router-dom";
import { Header as GHeader, Box } from "grommet";
import { Navigation } from "../Navigation/Navigation";
import FanarLogo from "../../images/fanar-icon.png";
import { ActionMenu } from "../ActionMenu/ActionMenu";
import { UrqlReportingClientProvider } from "../../api/UrqlClientProvider";
import { Link } from "react-router-dom";
import { FleetMetrics } from "../Fleet/Metrics";
import { LegendMenu } from "../LegendMenu/LegendMenu";
import { Menu, MenuItem, Tooltip } from "@material-ui/core";
import { Roles, UserRole } from "../../hooks/useRoleManager";
import { Configuration } from "../../util/config";

type UserRoleTest = UserRole & { auth0: "auth0" };

export const Header = withRouter(({ history }) => {
	const {
		location: { pathname },
	} = history;

	const showMetrics = useMemo(() => pathname?.substring(1) === "fleet", [
		pathname,
	]);

	const defaultRole: UserRoleTest =
		(localStorage.getItem("defaultRole") as UserRoleTest) || Roles.FFA;
	const [currentRole, setCurrentRole] = React.useState<UserRoleTest>(
		defaultRole
	);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const handleRoleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (role: UserRoleTest | null) => {
		if (role) {
			localStorage.setItem("defaultRole", role);
			setCurrentRole(role);
			setTimeout(() => {
				window?.location?.reload();
			}, 300);
		}
		setAnchorEl(null);
	};

	const roles: any = Object.values(Roles).map((role) => ({
		name: role,
		key: role,
	}));

	roles.push({
		name: <strong>Real API</strong>,
		key: "auth0",
	});

	const showRolesMenu = Configuration.REACT_APP_SHOW_ROLE_SWITCH || false;

	return (
		<GHeader
			background="light-2"
			align="center"
			direction="row"
			flex={false}
			justify="between"
			gap="small"
			fill="horizontal"
			className="main-header"
		>
			<Box align="center" justify="stretch" direction="row">
				<Link to="/">
					<img height={42} width={42} src={FanarLogo} alt="Logo" />
				</Link>
				<h2 style={{ position: "static" }}>
					<span style={{ position: "relative" }}>
						Fanar
						{showRolesMenu && (
							<sub
								style={{
									position: "absolute",
									color: "red",
									bottom: -20,
									left: "15%",
									fontStyle: "italic",
									fontSize: 11,
									whiteSpace: "nowrap",
								}}
							>
								<Tooltip
									title="Only used for testing purposes"
									aria-label="testing-purposes"
								>
									<span>Viewing as Role: </span>
								</Tooltip>
								<button
									onClick={handleRoleClick}
									aria-controls="role-menu"
									aria-haspopup="true"
									style={{ fontSize: 11, cursor: "pointer" }}
								>
									{
										roles.find(
											(role: any) =>
												role.key === currentRole
										)?.name
									}
								</button>
								<Menu
									id="role-menu"
									anchorEl={anchorEl}
									keepMounted
									open={Boolean(anchorEl)}
									onClose={() => handleClose(null)}
								>
									{roles.map((role: any) => (
										<MenuItem
											key={role.key}
											onClick={() =>
												handleClose(role.key)
											}
											selected={currentRole === role.key}
										>
											{role.name}
										</MenuItem>
									))}
								</Menu>
							</sub>
						)}
					</span>
				</h2>
				<Box pad="medium" />
				<Box
					pad="10px 10px"
					justify="center"
					align="center"
					direction="row"
				>
					<Navigation />
				</Box>
			</Box>
			<Box direction="row" justify="center" align="end">
				<Box
					pad="10px 10px"
					justify="center"
					align="center"
					direction="row"
				>
					{showMetrics && <FleetMetrics />}
					<UrqlReportingClientProvider>
						<ActionMenu />
						<LegendMenu />
					</UrqlReportingClientProvider>
				</Box>
			</Box>
		</GHeader>
	);
});
