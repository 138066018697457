import "./styles.css";

import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useMachine } from "@xstate/react";
import { ConnectedRouter } from "connected-react-router/immutable";
import { Box, Grommet } from "grommet";
import { grommet, ThemeType } from "grommet/themes";
import { defaultsDeep } from "lodash";
import { Provider } from "react-redux";
import { interpret, Interpreter, Machine, State, Typestate } from "xstate";

import { Header } from "./components/Header";
import {
	fleetScheduleMachine,
	IFleetMachineContext,
	IFleetScheduleSchema,
} from "./machines/fleetMachine";
import type { FleetMachineEvent } from "./machines/fleetMachine.events";
import { Routes } from "./routes";
import { history, store } from "./store";
import { APP_RENDERED } from "./store/actions/init.actions";

// * put redux store on window for debugging
(window as any).store = store;

// * colors generated from Fanar logo on color.adobe.com
// * Color Mood: "Colorful"
// ? blue 1: #59B5D9
const BLUE_1 = "#59B5D9";
// ? blue 2: #5FC2D9
const BLUE_2 = "#5FC2D9";
// ? green 1: #80BF9B
const GREEN_1 = "#80BF9B";
// ? green 2: #9BBF65
const GREEN_2 = "#9BBF65";
// ? black: #0D0D0D
const BLACK = "#0D0D0D";
// ? black: #0D0D0D
const WHITE = "#fff";

const theme: ThemeType = defaultsDeep(
	{
		global: {
			colors: {
				brand: BLUE_1,
				blue1: BLUE_1,
				blue2: BLUE_2,
				green1: GREEN_1,
				green2: GREEN_2,
				black1: BLACK,
				white: WHITE,
			},
		},
		formField: {
			label: {
				weight: 600,
			},
			border: false,
		},
		button: {
			default: {},

			secondary: {
				color: "#59B5D9",
				border: { color: "brand", width: "2px" },
			},
			primary: {
				background: { color: "brand" },

				color: "white",
				font: { weight: "bold" },
			},
		},
		dataTable: {
			header: {
				gap: "xsmall",
				color: {
					light: "white",
					dark: "white",
				},
				font: {
					weight: "bold",
					size: "17px",
				},
			},
		},
	},
	grommet
);

export type IFleetInterpreter = Interpreter<
	IFleetMachineContext,
	IFleetScheduleSchema,
	FleetMachineEvent,
	Typestate<IFleetMachineContext>
>;

export type IFleetMachineState = State<
	IFleetMachineContext,
	FleetMachineEvent,
	IFleetScheduleSchema,
	Typestate<IFleetMachineContext>
>;

export const FleetMachineContext = React.createContext<IFleetInterpreter>(
	interpret(
		Machine({
			initial: "idle",
			states: { idle: {} },
			context: {
				selectedDateRange: [new Date(), new Date()],
			} as IFleetMachineContext,
		})
	)
);

export default function App() {
	// * dispatch the initialization action once the app has rendered once
	React.useEffect(() => {
		store.dispatch({ type: APP_RENDERED });
	}, []);

	const [, , service] = useMachine(fleetScheduleMachine, {
		devTools: true,
	});

	return (
		<Grommet full theme={theme} cssVars={true}>
			<FleetMachineContext.Provider value={service}>
				<CssBaseline />
				<Provider store={store}>
					<ConnectedRouter history={history}>
						<Box direction="column" fill="vertical">
							<Box
								flex={{ shrink: 0 }}
								justify="between"
								direction="row"
								align="center"
								background="light-2"
								height="xxsmall"
								className="main-header-wrapper"
							>
								<Header />
							</Box>
							<Box flex="grow">
								<Routes />
							</Box>
						</Box>
					</ConnectedRouter>
				</Provider>
			</FleetMachineContext.Provider>
		</Grommet>
	);
}
