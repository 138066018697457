import * as React from "react";
import ReactDOM from "react-dom";

import { authClient } from "./api/apolloClient";
import App from "./App";
import { AuthContextProvider } from "./context/AuthContext";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

if (process.env.NODE_ENV === "development") {
	const whyDidYouRender = require("@welldone-software/why-did-you-render");
	// @ts-ignore
	whyDidYouRender(React, {
		include: [/Page/, /Vessel/, /Fleet/, /Requirement/, /Navigation/],
		exclude: [/Styled/, /PageControl/],
		collapseGroups: true,
		// trackAllPureComponents: true,
	});
}

// * set up debug configuration
window.FANAR_DEBUG = (() => {
	let fillRequirementForm = false;

	try {
		fillRequirementForm =
			Boolean(
				JSON.parse(
					window.localStorage.getItem(
						"DEBUG.FILL_REQUIREMENT_FORM"
					) || ""
				)
			) || false;
	} catch (e) {}

	var output = {
		fillRequirementForm,
	};

	return output;
})();

const rootElement = document.getElementById("root");
const render = () => {
	ReactDOM.render(
		<AuthContextProvider authClient={authClient}>
			<App />
		</AuthContextProvider>,
		rootElement
	);
};

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (
	process.env.NODE_ENV === "development" &&
	!process.env.REACT_APP_MUTE_WEB_VITALS_LOGS
) {
	reportWebVitals(console.log);
}

// @ts-ignore
if (module.hot) {
	// @ts-ignore
	module.hot.accept("./App", () => {
		render();
	});
}
