import { Box, Button, FormField, Select, Stack, Text, Tip } from "grommet";
import { CircleInformation } from "grommet-icons";
import React, { memo, useCallback } from "react";
import { useController, UseControllerOptions } from "react-hook-form";

import { useFormErrors } from "../Requirements/useFormErrors";

interface ControlledSelectProps
	extends Pick<UseControllerOptions, "control" | "name" | "defaultValue"> {
	options: string[];
	label: string;
	children?: never;
}

export const ControlledSelect = memo(function ControlledSelect({
	options,
	label,
	...props
}: ControlledSelectProps) {
	const {
		field: { name, onChange, value },
	} = useController(props);

	const handleChange = useCallback(
		({ value }) => {
			onChange(value);
		},
		[onChange]
	);

	const errors = useFormErrors(props.control);

	const thisFieldsErrors =
		errors?.filter((err) => err.keys?.includes(name)) ?? [];

	const errorMessage = thisFieldsErrors?.[0]?.message;

	const labelMarkup = (
		<Stack anchor="top-right">
			<Box flex>
				<Text color={!!errorMessage ? "status-error" : "dark-1"}>
					{label}
				</Text>
			</Box>

			{!!errorMessage ? (
				<Tip
					content="Required"
					dropProps={{ align: { right: "left" } }}
				>
					<Button
						plain
						size="small"
						icon={<CircleInformation color="status-error" />}
					/>
				</Tip>
			) : null}
		</Stack>
	);

	return (
		<FormField label={labelMarkup}>
			<Select
				name={name}
				options={options}
				value={value}
				onChange={handleChange}
			/>
		</FormField>
	);
});
