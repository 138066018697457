import { Link } from "react-router-dom";
import { Stack, Box, Text } from "grommet";
import { Button } from "@material-ui/core";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import FindInPageIcon from "@material-ui/icons/FindInPage";

export function PageNotFound() {
	return (
		<Stack anchor="center">
			<Box pad="large">
				<Text>
					<h1>
						<FindInPageIcon
							style={{
								fontSize: "1em",
								float: "left",
								margin: "-8px 10px 0",
							}}
						/>{" "}
						<span>Page not found!</span>
					</h1>
					<Button>
						<KeyboardReturnIcon />
						<Link to="/">Return to main page</Link>
					</Button>
				</Text>
			</Box>
		</Stack>
	);
}
