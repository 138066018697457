export const recursivelyDeleteKeysFromObject = <T>(
	obj: T,
	keys: string[]
): T => {
	return JSON.parse(
		JSON.stringify(obj, (key, value) =>
			keys.indexOf(key) > -1 ? undefined : value
		)
	);
};

export const recursivelyDeleteTypenameProperty = <T>(obj: T): T => {
	return JSON.parse(JSON.stringify(obj), (key, value) =>
		key === "__typename" ? undefined : value
	);
};
