import React from "react";
import { Heading, Main, Box, Text } from "grommet";
import { VESSEL_SCHEDULE_LIFECYCLE_URL } from "../api/UrqlClientProvider";
import { useQuery } from "urql";
import { LoadingIndicator } from "../components/LoadingIndicator";
import { useMemo } from "react";

const context = {
	url: VESSEL_SCHEDULE_LIFECYCLE_URL,
};

//todo increase take to all (-1)
const GET_USER_ACTIVITY_SIMPLE = `#graphql
	query {
		allActivityLogs (take:-1){
			id
			functionName
			time
		}
	}
`;

export const UserActivityPage: React.FC = () => {
	const [{ data, fetching }] = useQuery({
		query: GET_USER_ACTIVITY_SIMPLE,
		context,
		requestPolicy: "network-only",
	});

	const activityData = useMemo(() => {
		const logs = data?.allActivityLogs;

		logs?.sort((log1: any, log2: any) => {
			//return log2.time - log1.time;
			return log1.time.localeCompare(log2.time);
		});
		logs?.reverse();

		return logs || [];
	}, [data]);

	if (fetching)
		return (
			<Box pad="medium">
				Fetching user activity.. <LoadingIndicator />
			</Box>
		);

	return (
		<Main pad="large">
			<Heading level="2">User Activity</Heading>

			{activityData.map((a: any, index: Number) => {
				return (
					<Box
						key={index.toString()}
						pad="small"
						border={{ color: "blue1" }}
					>
						<Text>{a.id}</Text>
						<Text weight="bold">
							{new Date(Date.parse(a.time)).toUTCString()}
						</Text>
						<Text>{a.functionName}</Text>
					</Box>
				);
			})}
		</Main>
	);
};
