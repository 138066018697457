import React, { memo } from "react";
import { gql, TypedDocumentNode, useQuery } from "urql";
import { Box, Heading, Stack, Text } from "grommet";
import { Anchor } from "grommet-icons";
import { useParams } from "react-router";

import {
	Query,
	QueryGetLatestActualVesselScheduleByVesselIdArgs,
} from "../types/generated/q-vessel-schedule-lifecycle-v6.types";
import { VESSEL_SCHEDULE_LIFECYCLE_URL } from "../api/UrqlClientProvider";
import { allActualScheduleFields } from "../queries/schedules";

const context = {
	url: VESSEL_SCHEDULE_LIFECYCLE_URL,
};

const GET_ACTUAL_SCHEDULE: TypedDocumentNode<
	Pick<Query, "getLatestActualVesselScheduleByVesselId">,
	QueryGetLatestActualVesselScheduleByVesselIdArgs
> = gql`
	query getLatestActual($vesselId: ID!) {
		getLatestActualVesselScheduleByVesselId(vesselId: $vesselId) {
			${allActualScheduleFields}
		}
	}
`;

export const VesselReportPage = memo(function VesselReportPage() {
	const { id, name } = useParams<{ id: string; name: string }>();
	// hardcode to test for now
	// const idToTtry = "9688336";
	const [{ fetching: loading, data, error }] = useQuery({
		query: GET_ACTUAL_SCHEDULE,
		variables: { vesselId: id },
		context,
	});
	if (error) return <Text>Error! {error.message}</Text>;
	if (loading || !data)
		return (
			<Box pad="small" align="center">
				<Text>Loading Report</Text>
			</Box>
		);

	if (data.getLatestActualVesselScheduleByVesselId != null) {
		return (
			<div style={{ position: "relative" }}>
				<Box pad="medium" gap="medium">
					<Heading level={2} size="medium" margin="small">
						Vessel Report
					</Heading>
				</Box>
				<Box align="center" fill>
					<Box pad="medium" align="center" fill>
						<Stack>
							<Box gap="small" pad="small">
								{
									// this box holds the schedule details
									<Box
										id={
											data
												.getLatestActualVesselScheduleByVesselId
												.id
										}
										pad="xsmall"
										gap="xsmall"
										border={{ color: "blue1" }}
									>
										<Text size="xlarge">
											{name} {id}
										</Text>{" "}
										<Text color="brand">
											Schedule Start{": "}
											{new Date(
												(data!
													.getLatestActualVesselScheduleByVesselId
													.actualStartDate || 0) *
													1000
											).toLocaleDateString()}{" "}
										</Text>
										<Text color="brand">
											Schedule End{": "}
											{new Date(
												(data
													.getLatestActualVesselScheduleByVesselId
													.actualEndDate || 0) * 1000
											).toLocaleDateString()}{" "}
										</Text>
									</Box>
								}

								{data.getLatestActualVesselScheduleByVesselId.requirements.map(
									(r, rIdx) => {
										return (
											// this box will hold the requirement
											<Box
												id={r.id}
												key={r.id}
												pad="xsmall"
												gap="xsmall"
												border={{ color: "blue1" }}
												align="center"
											>
												<Box
													pad="xsmall"
													direction="row"
													gap="xsmall"
													align="center"
												>
													<Text>
														Requirement: {r.id}
													</Text>

													<Text color="accent-4">
														Start{" "}
														{new Date(
															r.startDate * 1000
														).toLocaleDateString()}{" "}
													</Text>
													<Text color="accent-4">
														End{" "}
														{new Date(
															r.endDate * 1000
														).toLocaleDateString()}{" "}
													</Text>
												</Box>
												{r.actualVesselActions.map(
													(a, aIdx) => (
														// This box holds the actions
														<Box
															id={a.id}
															key={a.id}
															pad="xsmall"
															gap="xsmall"
															border={{
																color: "blue1",
															}}
															fill
														>
															<Box
																gap="small"
																direction="row"
															>
																<Anchor
																	color="blue1"
																	size="large"
																></Anchor>

																<Heading
																	level={4}
																	color="blue1"
																>
																	{" "}
																	{a.vesselAction.actionType.id.toUpperCase()}{" "}
																</Heading>
																{
																	a
																		.actionStatus
																		.id
																}
															</Box>

															<Box
																direction="row"
																gap="small"
																pad="medium"
															>
																<Box justify="center">
																	<Text color="accent-4">
																		A Cost $
																		{a.actualCost.toFixed(
																			2
																		)}{" "}
																	</Text>

																	<Text color="blue1">
																		P Cost $
																		{a.plannedAction.estimatedCost.toFixed(
																			2
																		)}
																	</Text>
																	<Text color="neutral-3">
																		Diff $
																		{a.actualCost -
																			a
																				.plannedAction
																				.estimatedCost}
																	</Text>
																</Box>
																<Box
																	width="small"
																	height="small"
																	alignSelf="center"
																	justify="center"
																	pad="small"
																>
																	<Text color="accent-4">
																		A Start{" "}
																		{new Date(
																			a.actualStartDate *
																				1000
																		).toLocaleDateString()}{" "}
																	</Text>
																	<Text color="blue1">
																		P Start{" "}
																		{new Date(
																			a
																				.plannedAction
																				.estimatedStartDate *
																				1000
																		).toLocaleDateString()}{" "}
																	</Text>
																	<Text color="neutral-3">
																		Diff
																		{a.actualCost -
																			a
																				.plannedAction
																				.estimatedCost}
																	</Text>
																</Box>
																<Box
																	width="small"
																	height="small"
																	alignSelf="center"
																	justify="center"
																	pad="small"
																>
																	<Text color="accent-4">
																		A End{" "}
																		{new Date(
																			a.actualEndDate *
																				1000
																		).toLocaleDateString()}{" "}
																	</Text>
																	<Text color="blue1">
																		P End{" "}
																		{new Date(
																			a
																				.plannedAction
																				.estimatedEndDate *
																				1000
																		).toLocaleDateString()}{" "}
																	</Text>
																	<Text color="neutral-3">
																		Diff{" "}
																		{a.actualCost -
																			a
																				.plannedAction
																				.estimatedCost}
																	</Text>
																</Box>

																<Box
																	width="small"
																	height="small"
																	alignSelf="center"
																	justify="center"
																	pad="small"
																>
																	<Box>
																		<Text color="neutral-3">
																			Port:{" "}
																			{
																				a
																					.endState
																					.port
																			}
																		</Text>
																		<Text color="neutral-3">
																			Fuel:{" "}
																			{a.endState.fuelRemaining.toFixed(
																				2
																			)}{" "}
																		</Text>
																		<Text color="neutral-3">
																			Delay:{" "}
																			{a.actualDuration -
																				a
																					.plannedAction
																					.estimatedDuration}{" "}
																			days
																		</Text>
																	</Box>
																</Box>
															</Box>
														</Box>
													)
												)}
											</Box>
										);
									}
								)}
							</Box>
						</Stack>
						{/*
						<Box
						style={{ marginTop: 11 }}
						pad="small"
						gap="small"
						border={{ color: "accent-4" }}
					>
						<Text>Detailed Schedule Metrics Will Go Here</Text>
						<Text>Total Cost</Text>
						<Text>Diffences etc</Text>
					</Box>
					*/}
					</Box>
				</Box>
			</div>
		);
	} else {
		return (
			<div style={{ position: "relative" }}>
				<Box pad="medium" gap="medium">
					<Heading level={2} size="medium" margin="small">
						Vessel Report
					</Heading>
				</Box>
				<Box pad="medium">
					<Text>No schedule found for {name}</Text>
				</Box>
			</div>
		);
	}
});
