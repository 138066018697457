import React, { useContext, useState } from "react";
import Auth0UserAuth from "../util/auth/Auth0UserAuth";
import KeyCloakUserAuth from "../util/auth/KeyCloakUserAuth";
import UserContext from "./UserContext";

function buildDefaultAuthContext() {
	return {
		token: UserContext.getAccessToken(),
		expiresAt: UserContext.getIdTokenExipiry(),
	};
}

interface AuthContextType {
	token: string;
	expiresAt: string;
	authClient?: Auth0UserAuth | KeyCloakUserAuth; //this could be of type Auth0UserAuth or KeyCloakUserAuth
	authHeader?: { authorization: string };
}

/**
 * The React Context for the Authentication information
 */
const AuthContext = React.createContext<AuthContextType | null>(null);

/**
 * The provider used to set the updated Authentication information in the
 * context.
 */
// @ts-ignore
export function AuthContextProvider({ children, authClient }) {
	const [authContext, setAuthContext] = useState(buildDefaultAuthContext());
	authClient.addTokenChangeListener(setAuthContext);

	return (
		<AuthContext.Provider
			// @ts-ignore
			value={{
				...authContext,
				authClient,
				authHeader: UserContext.getAuthHeader(),
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}

/**
 * React hook to access the auth context.
 */
export function useAuthContext() {
	return useContext(AuthContext);
}
