import { put, take } from "@redux-saga/core/effects";
import UserContext from "../../context/UserContext";
import { APP_RENDERED, INITIALIZE } from "../actions/init.actions";
import { first } from "rxjs/operators";

export function* waitForAuthSaga() {
	while (true) {
		yield take(APP_RENDERED);

		yield UserContext.token$.pipe(first()).toPromise();
		yield put({ type: INITIALIZE });
	}
}
