import { Dialog, DialogContent, Snackbar } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import { Box, Button, DataTableProps, Grid, Heading } from "grommet";
import React, {
	Dispatch,
	useCallback,
	useMemo,
	useContext,
	useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { push, RouterAction } from "react-router-redux";
import { FileDrop } from "../components/forms/FileDrop";
import { RequirementDetails } from "../components/RequirementDetailsPanel";
import { ConfirmDeleteDialog } from "../components/Requirements/ConfirmDeleteDialog";
import { CSVRequirementsUpload } from "../components/Requirements/CSVRequirementsUpload";
import { RequirementsTable } from "../components/RequirementsTable";
import { useRoleManager } from "../hooks/useRoleManager";
import userContext from "../context/UserContext";
import { useMachineSelector } from "../selectors/machine.selectors";
import { portsSelector } from "../selectors/ports.selectors";
import {
	errorMessageSelector,
	isLoadingSelector,
	// isPersistingSelector,
} from "../selectors/requirement.selectors";
import {
	DISMISS_SNACKBAR,
	IRequirementReducerActions,
	PROVIDE_REQUIREMENT_CSV,
} from "../store/actions/requirements.actions";
import { IState } from "../store/reducers";
import {
	I_ActualScheduledRequirement,
	Product,
	VesselWithQ88PRandUtOutput,
} from "../types/generated/q-vessel-schedule-lifecycle-v6.types";
import { FleetMachineContext } from "../App";
import { useSelector as xStateSelector } from "@xstate/react";
import { useService } from "@xstate/react";
// import { useQuery } from "urql";
// import { PERSIST_REQUIREMENTS } from "../queries/requirements";

export const RequirementsPage = React.memo(() => {
	const dispatch: Dispatch<
		IRequirementReducerActions | RouterAction
	> = useDispatch();

	// todo: get products from machine state
	const products: Product[] = useMachineSelector((context) =>
		context.products?.allIds.map((id) => context.products.byId[id])
	);
	// const [requirementPersistResult] = useQuery({
	// 	query: PERSIST_REQUIREMENTS,
	// });

	const vessels: VesselWithQ88PRandUtOutput[] = useMachineSelector(
		(context) => context.vessels
	);

	const fleetMachineService = useContext(FleetMachineContext);
	const [current] = useService(fleetMachineService);

	const schedules = xStateSelector(
		fleetMachineService,
		({ context: { schedule } }) => schedule
	);

	const isSomethingLoading = useSelector(isLoadingSelector);
	// const isPersisting = useSelector(isPersistingSelector);

	const isLoadingFleetData = React.useMemo(
		() => !current.matches("idle.ready"),
		[current]
	);

	const ports = useSelector(portsSelector);

	const requirements = useSelector((state: IState) =>
		state.requirements?.allIds?.map((id) => state.requirements.byId[id])
	);

	const requirementsNew = useSelector((state: IState) => state.requirements);

	useEffect(() => {
		if (isSomethingLoading || isLoadingFleetData || !vessels) {
			return;
		}

		console.log("Testing requirement schedules...");

		const lockedVesselMatchesAssigned = (
			lockedVessel: string | null | undefined,
			assignedVessel: string | null | undefined
		): boolean => {
			const lockedToSpot = lockedVessel === "Locked to spot";
			const assignedToSpot = assignedVessel?.startsWith("spot");
			const lockOK =
				!lockedVessel ||
				assignedVessel === lockedVessel ||
				(lockedToSpot === true && assignedToSpot === true);
			return lockOK;
		};

		let schedulesOK = true;
		if (!isSomethingLoading && !isLoadingFleetData && vessels) {
			let actualScheduledRequirements: {
				[key: string]: I_ActualScheduledRequirement;
			} = {};
			if (schedules.length) {
				schedules.forEach((s) => {
					if (s.actualSchedule?.requirements?.length) {
						s.actualSchedule.requirements.forEach(
							(r) =>
								(actualScheduledRequirements[
									r.requirementId
								] = r)
						);
					}
				});
			}
			const byId = requirementsNew.byId;
			const vesselById = requirementsNew.vesselById;

			for (const [key, value] of Object.entries(byId)) {
				const lockedVessel = value.locked;
				const vessel = vesselById[key];
				if (!lockedVesselMatchesAssigned(lockedVessel, vessel)) {
					console.log(
						`Locked vessel "${lockedVessel}" does not match "${vessel}"`,
						value
					);
					schedulesOK = false;
				}

				if (value.status.id !== "Not Started") {
					const actualScheduled = actualScheduledRequirements[key];
					const vesselName =
						vessels.find((f) => f.id === vessel)?.vessel?.name ||
						"-";
					if (!actualScheduled) {
						console.log(
							`Missing actual schedule for requirement ${
								value.status.id
							}, vessel: ${vessel} (${vesselName}), locked: ${
								lockedVessel || "none"
							},`,
							value
						);
						schedulesOK = false;
					} else {
						const scheduleVessel =
							actualScheduledRequirements[key]?.vesselId;
						if (vessel !== scheduleVessel) {
							console.log(
								`${key}: ${vessel} (${vesselName}) / ${lockedVessel} // ${scheduleVessel}`
							);
							schedulesOK = false;
						}
					}
				}
			}
		}
		if (schedulesOK) {
			console.log("All requirement schedules OK");
		} else {
			console.log("Requirement schedule test FAILED");
		}
	}, [
		isSomethingLoading,
		isLoadingFleetData,
		schedules,
		requirementsNew,
		vessels,
	]);

	const getVesselNameMap = useCallback((): any[] => {
		return requirementsNew?.allIds?.map((requirementId) => {
			if (isLoadingFleetData) {
				return {
					id: requirementId,
					name: "fetching data",
				};
			}

			const foundSchedule = schedules?.find((f) => {
				return !!f.actualSchedule?.requirements.find(
					(ar) => ar.requirementId === requirementId
				);
			});

			if (!foundSchedule) {
				return {
					id: requirementId,
					name: "unscheduled",
				};
			}
			const vesselId = requirementsNew.vesselById[requirementId];

			let name = "spot";
			let find = vessels?.find((f) => f.id === vesselId);

			if (find && find.vessel) {
				name = find?.vessel?.name;
			} else if (vessels.length === 0) {
				name = "-";
			}

			return {
				id: requirementId,
				name: name,
			};
		});
	}, [schedules, isLoadingFleetData, requirementsNew, vessels]);

	const history = useHistory();

	const addNewRequirementsClick = useCallback(() => {
		history.push("/requirements/New Requirement/edit");
	}, [history]);

	const { id: requirementId } = useParams<{
		id?: string;
	}>();

	const onRequirementClick = useCallback<
		NonNullable<DataTableProps<{ id: string }>["onClickRow"]>
	>(
		({ datum: { id } }) => {
			history.push(`/requirements/${id}`);
		},
		[history]
	);

	// * We're unable to validate ports & set them from terminal id, if ports haven't been loaded
	const canStartBatchEdit = React.useMemo(
		() => ports?.allIds?.length > 0 && (products?.length ?? -1) > 0,
		[ports, products]
	);

	const errorMessage = useSelector(errorMessageSelector);

	const onFileAdded = useMemo(
		() =>
			canStartBatchEdit
				? (files: FileList) => {
						Array.from(files)
							.filter((f) => /csv$/i.test(f.name))
							.slice(0, 1)
							.forEach((f) =>
								f.text().then((csv) => {
									const uid = userContext.getUserId();
									if (!uid) return;
									dispatch({
										type: PROVIDE_REQUIREMENT_CSV,
										payload: {
											csv,
											creator: uid,
											ports,
											products,
										},
									});
									dispatch(push("/requirements/batchEdit"));
								})
							);
				  }
				: undefined,
		[canStartBatchEdit, dispatch, ports, products]
	);

	const { can } = useRoleManager();
	const canManageRequirement = can("manage requirements");

	return (
		<FileDrop
			style={{ position: "relative" }}
			onFileDropped={onFileAdded}
			disabled={!canManageRequirement}
		>
			<Box direction="row" pad="medium" justify="between">
				<Heading level={2} size="medium" margin="small">
					Requirements Administration
				</Heading>
				{canManageRequirement && (
					<Box
						direction="row"
						margin="medium"
						gap="medium"
						align="center"
					>
						<Button
							primary
							onClick={addNewRequirementsClick}
							label="Add New Requirement"
						/>

						<CSVRequirementsUpload
							disabled={!canStartBatchEdit}
							onFileAdded={onFileAdded}
							title={
								canStartBatchEdit
									? "You can also just drop the file here to upload"
									: "Please wait for the app to finish initialization to do batch edit"
							}
						/>
						{isSomethingLoading && <CircularProgress size="24px" />}
					</Box>
				)}
			</Box>

			<Grid
				fill
				columns={["1/2", "1/2"]}
				areas={[
					{ name: "reqTable", start: [0, 0], end: [0, 0] },
					{ name: "reqDetails", start: [1, 0], end: [1, 0] },
				]}
				rows={["fill"]}
				gap="none"
			>
				<Box gridArea="reqTable" margin="small">
					<RequirementsTable
						onRowClick={onRequirementClick}
						requirements={requirements}
						vesselNameMap={getVesselNameMap()}
						selectedId={requirementId}
					/>
				</Box>

				<Box gridArea="reqDetails" margin="small">
					<RequirementDetails vesselNameMap={getVesselNameMap()} />
				</Box>
			</Grid>

			<Snackbar
				open={Boolean(errorMessage)}
				anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
			>
				<Alert
					onClose={() => dispatch({ type: DISMISS_SNACKBAR })}
					severity="warning"
				>
					{errorMessage}
				</Alert>
			</Snackbar>

			<ConfirmDeleteDialog />

			<Dialog
				open={isSomethingLoading /*|| requirementPersistResult.fetching*/}
				maxWidth="xs"
			>
				<DialogContent dividers>
					<Box direction="row" margin="small">
						<CircularProgress size="24px" />
						<div style={{ alignSelf: "center", marginLeft: 10 }}>
							{/*requirementPersistResult.operation &&
							requirementPersistResult.error
								? `Retrying for ${requirementPersistResult.operation.context.retryCount} times`
								:*/ "Uploading"}
						</div>
					</Box>
				</DialogContent>
			</Dialog>
		</FileDrop>
	);
});
