// import React from "react";
// import { useSelector } from "react-redux";
// import { gql } from "urql";

// import {
// 	getVSLSchema,
// 	vesselScheduleLifecycleClient_apollo,
// } from "../api/vesselScheduleLifecycleClient";
// import { GraphQLSchemaForm } from "../components/forms/GraphQLSchemaForm";
import { PortRestrictionForm } from "../components/forms/PortRestrictionForm";
// import { IState } from "../store/reducers";
// import { GraphQlSchemaFormTest } from "../types/generated/q-vessel-schedule-lifecycle-v6.types";

// const PERSIST_PR = gql`
// 	mutation persistPortRestriction($input: PortRestrictionAsInput!) {
// 		persistPortRestriction(portRestriction: $input)
// 	}
// `;

// const PERSIST_TEST = gql`
// 	mutation addGraphQLSchemaFormTest($input: AddGraphQLSchemaFormTestInput!) {
// 		addGraphQLSchemaFormTest(input: $input)
// 	}
// `;

export const DebugPage = () => {
	// const ports = useSelector((state: IState) =>
	// 	state.ports.allIds.map((id) => state.ports.byId[id])
	// );

	return (
		<div>
			<PortRestrictionForm vesselId="NCC Test" onCancel={() => null} />
		</div>
	);
};
