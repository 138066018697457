import React, { useMemo, useState } from "react";
import { useMutation, useQuery } from "urql";
import {
	Box,
	DataTable,
	ColumnConfig,
	Button,
	Form,
	FormField,
	TextInput,
} from "grommet";
import { Trash } from "grommet-icons";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

import { LoadingIndicator } from "./LoadingIndicator";
import { REPORTING_WS } from "../api/UrqlClientProvider";

const ALL_RECIPIENTS_QUERY = `#graphql
	query allFleetReportRecipients {
		allFleetReportRecipients {
			id
			email
		}
	}
`;

const DELETE_RECIPIENT_MUTATION = `#graphql
	mutation deleteFleetReportRecipient($id: ID!) {
		deleteFleetReportRecipient(id: $id) {
			id
		}
	}
`;

const ADD_RECIPIENT_MUTATION = `#graphql
	mutation addFleetReportRecipient($input: AddFleetReportRecipientInput!) {
		addFleetReportRecipient(input: $input)
	}
`;

interface Recipient {
	id: string;
	email: string;
}

const context = {
	additionalTypenames: ["FleetReportRecipient"],
	url: REPORTING_WS,
};

// TODO: Add optimistic updates via graph cache
export const FleetReportEmailRecpientsEditor: React.FC = () => {
	const [{ data, fetching, error }] = useQuery<
		{ allFleetReportRecipients: Recipient[] },
		object
	>({
		query: ALL_RECIPIENTS_QUERY,
		requestPolicy: "cache-and-network",
		context,
	});

	const [formInput, setFormInput] = useState({ email: "" });

	const [
		{ fetching: deleting, error: deleteError },
		deleteRecipient,
	] = useMutation<unknown, { id: string }>(DELETE_RECIPIENT_MUTATION);

	const [{ fetching: adding, error: addError }, addEmail] = useMutation<
		unknown,
		{ input: { email: string } }
	>(ADD_RECIPIENT_MUTATION);

	const columns = useMemo<ColumnConfig<Recipient>[]>(
		() => [
			{ property: "email", search: true, sortable: true },
			{
				property: "id",
				header: "Delete",
				render({ id }) {
					return (
						<Button
							primary
							alignSelf="center"
							color="status-warning"
							size="small"
							icon={<Trash />}
							onClick={() =>
								deleteRecipient({ id }, { url: REPORTING_WS })
							}
						/>
					);
				},
			},
		],
		[deleteRecipient]
	);

	if (error)
		return (
			<Alert severity="warning">
				<AlertTitle>Fetching recipients failed..</AlertTitle>
				{error.message}
			</Alert>
		);
	if (deleteError)
		return (
			<Alert severity="warning">
				<AlertTitle>Failed to delete</AlertTitle>
				{deleteError.message}
			</Alert>
		);
	if (addError)
		return (
			<Alert severity="warning">
				<AlertTitle>Failed to add</AlertTitle>
				{addError.message}
			</Alert>
		);

	return (
		<Box width="medium" fill>
			{(fetching || deleting || adding) && <LoadingIndicator />}
			{(data?.allFleetReportRecipients?.length as number) > 0 && (
				<Box align="start">
					{data?.allFleetReportRecipients && (
						<DataTable<Recipient>
							data={data.allFleetReportRecipients}
							columns={columns}
							margin="medium"
							step={15}
							paginate
						/>
					)}
				</Box>
			)}
			<Box align="end">
				<Box pad="medium" border={{ color: "accent-4" }}>
					<Form
						value={formInput}
						onSubmit={(e) => {
							console.log("E.value", e.value);
							setFormInput({ email: "" });
							addEmail(
								{
									input: { email: (e.value as any).email },
								},
								context
							);
						}}
						onChange={setFormInput as any}
					>
						<FormField
							type="email"
							required
							name="email"
							label="Add a new email"
						>
							<TextInput type="email" name="email" />
						</FormField>
						<Button type="submit" label="Add" alignSelf="end" />
					</Form>
				</Box>
			</Box>
		</Box>
	);
};
