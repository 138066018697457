import { format } from "date-and-time";
import { flatMap, get, groupBy, identity, isObject, keyBy, map, memoize, partition, sortBy } from "lodash";
import { createSelector, Selector } from "reselect";

import { IState } from "../store/reducers";
import { IPortsState } from "../store/reducers/ports";
import { IRequirementReducerState } from "../store/reducers/requirements";
import { BatchRequirementItem, ParsedRequirements } from "../store/reducers/requirements/csvToRequirements";
import { Requirement, RequirementAsInput, Trade, TradeAsInput } from "../types/generated/q-fanar-requirements.types";
import { allTerminals, portsSelector } from "./ports.selectors";

export const getRequirementsReducer = (state: IState) => state.requirements;
export const selectRequriementssAsList = createSelector([getRequirementsReducer], (requirements) =>
	map(get(requirements, ["requirements", "byId"]), (r) => r)
);

export const selectVesselById = createSelector(
	[getRequirementsReducer, (_: any, props: { id: string }) => props.id],
	(requirements, id) => {
		console.log({ requirements, id });
		return get(requirements, ["byId", id]);
	}
);

export const requirementBatchState = createSelector<IState, IRequirementReducerState, ParsedRequirements | null>(
	[getRequirementsReducer],
	({ batchUploadState }) => batchUploadState
);

export interface RequirementWithValidationErrors {
	requirement: BatchRequirementItem;
	errors: RequirementValidationError[];
}

/** Returns a combination of all requirements - both already persisted and ones in batch edit state */
export const existingRequirementsWithBatchStateSelector = createSelector(
	(state: IState) => getRequirementsReducer(state).byId,
	(state: IState) => getRequirementsReducer(state).batchUploadState,
	(byId, batch) => [...Object.values(byId), ...(batch !== null ? batch.requirements.map(({ item }) => item) : [])]
);

export const requirementsWithValidationState: Selector<IState, RequirementWithValidationErrors[]> = createSelector(
	requirementBatchState,
	portsSelector,
	existingRequirementsWithBatchStateSelector,
	(state, ports, allRequirements) => {
		if (!state) return [];
		return state.requirements.map((requirement) => ({
			requirement,
			errors: validateRequirement(requirement.item, ports, allRequirements),
		}));
	}
);

export const isBatchEditInProgressSelector = createSelector(requirementBatchState, (state) => state !== null);

export const formattingWarningsSelector: Selector<IState, string[]> = createSelector(requirementBatchState, (state) =>
	state ? state.formattingIssues : []
);

/** Requirements grouped by their validity - [invalid, valid] */
const requirementPartitions = createSelector(requirementsWithValidationState, (list) =>
	partition(list, (item) => item.errors.length > 0 || item.requirement.warnings.length > 0)
);

export const isLoadingSelector = createSelector(
	getRequirementsReducer,
	({ loadingState: { deletion, persistence, requirements, updating } }) =>
		Boolean(deletion || persistence || requirements || updating)
);

export const isPersistingSelector = createSelector(getRequirementsReducer, ({ loadingState: { persistence } }) =>
	Boolean(persistence)
);

export const errorMessageSelector = createSelector(getRequirementsReducer, ({ errorMessage }) => errorMessage);

export const invalidRequirementsSelector = createSelector(requirementPartitions, ([invalid]) =>
	invalid.map(({ requirement: { item } }) => item)
);
export const validRequirementsSelector = createSelector(requirementPartitions, ([, valid]) =>
	valid.map(({ requirement: { item } }) => item)
);
/** Submit button should only be visible if warnings have been dismissed and validation errors resolved */
export const isSubmitPossibleSelector = createSelector(
	requirementPartitions,
	requirementBatchState,
	([invalid, valid], state) =>
		!!(
			valid.length > 0 &&
			invalid.length === 0 &&
			state &&
			state.formattingIssues.length === 0 &&
			!state.isSubmitting
		)
);

export const overrideRequirementsSelector = createSelector(
	requirementPartitions,
	requirementBatchState,
	([invalid, valid], state) => {
		return state?.overrides;
	}
);

export interface RequirementValidationError {
	/** If the error can be fixed by changing a particular key of the requirement object - we could highlight them in the form */
	keys?: string[];
	message: string;
}

interface RequirementValidator {
	(r: Requirement, ports: IPortsState, allRequirements: Requirement[]): RequirementValidationError[];
}

/** Checks if all longs & shorts are in the future */
const validatePastDates: RequirementValidator = ({ longs, shorts }) => {
	let result: RequirementValidationError[] = [];
	let invalidLongsWithKeys = (longs || [])
		.map((long, index) => ({ ...long, key: `longs.${index}` }))
		.filter(
			({ startDate, endDate }) =>
				startDate <= new Date().setHours(0, 0, 0, 0) / 1000 || endDate <= new Date().setHours(0, 0, 0, 0) / 1000
		);

	let invalidShortsWithKeys = (shorts || [])
		.map((short, index) => ({
			...short,
			key: `shorts.${index}`,
		}))
		.filter(
			({ startDate, endDate }) =>
				startDate <= new Date().setHours(0, 0, 0, 0) / 1000 || endDate <= new Date().setHours(0, 0, 0, 0) / 1000
		);

	if (invalidShortsWithKeys.length) {
		result.push({
			message: "Shorts in the past are not permitted",
			keys: invalidShortsWithKeys.map((trade) => trade.key),
		});
	}

	if (invalidLongsWithKeys.length) {
		result.push({
			message: "Longs in the past are not permitted",
			keys: invalidLongsWithKeys.map((trade) => trade.key),
		});
	}

	return result;
};
/** Requires at least 1 long and 1 short */
const validateTradeCount: RequirementValidator = ({ longs, shorts }) =>
	longs && longs.length >= 1 && shorts && shorts.length >= 1
		? []
		: [{ message: "At least 1 long and 1 short is required" }];
/** Rejects state where ship would have negative amount of something on board */
const validateAmountsDuringTrip: RequirementValidator = ({ longs, shorts }) => {
	if (!longs || !shorts) return [];
	return flatMap<[string, Trade[]], RequirementValidationError>(
		Object.entries(
			groupBy(
				[
					...longs,
					...shorts.map((t) => ({
						...t,
						productQuantity: -t.productQuantity,
					})),
				],
				({ product }) => product?.id
			)
		),
		([product, trades]) => {
			let amountOnShip = 0;
			for (const { productQuantity } of sortBy(trades, ({ endDate }) => endDate)) {
				amountOnShip += productQuantity;

				// console.log("amountOnShip: ", amountOnShip);

				if (amountOnShip < 0) {
					return [
						{
							message: `Such sequence of shorts/longs is impossible - the amount of ${product} on ship can't be negative`,
						},
					];
				}
			}
			if (amountOnShip !== 0) {
				return [
					{
						message: `Quantity ${amountOnShip} of ${product} ramaining after completing this requirement`,
					},
				];
			}
			return [];
		}
	);
};

/** Prevents requiring ship to be in multiple places at once */

/*
const validateShipLocations: RequirementValidator = ({ longs, shorts }) => {
	if (!longs || !shorts) return [];
	interface ShipEvent {
		type: "startTrade" | "endTrade";
		time: number;
		trade: Trade;
	}
	type ShipState =
		| {
				tradesInProgress: Set<string>;
				port: string;
		  }
		| "sailing";

	let state: ShipState = "sailing";
	for (const { type, trade } of sortBy(
		flatMap<Trade, ShipEvent>([...longs, ...shorts], (trade) => [
			{ type: "startTrade", time: trade.startDate, trade },
			{ type: "endTrade", time: trade.endDate, trade },
		]),
		[({ time }) => time, ({ type }) => (type === "endTrade" ? 0 : 1)]
	)) {
		if (!trade.port) continue;

		if (type === "startTrade") {
			if (state === "sailing" || state.tradesInProgress.size === 0) {
				state = {
					tradesInProgress: new Set([trade.id]),
					port: trade.port,
				};
			} else if (state.port !== trade.port) {
				// We can't start a trade in a different port while trades are in progress elsewhere
				return [
					{
						message: `Locations for trades are overlapping. A ship can't be in ${state.port} and ${trade.port} simultaneously`,
					},
				];
			} else {
				state.tradesInProgress.add(trade.id);
			}
		} else {
			if (state === "sailing") {
				// This should already be handled by `validateTradeDates` - let's not add a duplicate warning
				return [];
			} else if (state.port !== trade.port) {
				return [
					{
						message: `Locations for trades are overlapping. A ship can't be in ${state.port} and ${trade.port} simultaneously`,
					},
				];
			} else {
				state.tradesInProgress.delete(trade.id);
				if (state.tradesInProgress.size === 0) {
					state = "sailing";
				}
			}
		}
	}
	return [];
};

*/

/** Validate dates */
const validateTradeDates: RequirementValidator = ({ longs, shorts }) => {
	let trades = [
		...(longs || []).map((long, index) => ({
			data: long,
			key: `longs[${index}].dateRange`,
			tradeType: "Long",
		})),
		...(shorts || []).map((short, index) => ({
			data: short,
			key: `shorts[${index}].dateRange`,
			tradeType: "Short",
		})),
	];
	let response = [];

	response.push(
		...trades
			.filter(({ data: { startDate, endDate } }) => endDate < startDate)
			.map(({ key, tradeType, data: { startDate, endDate } }) => ({
				message: `${tradeType} end date should be after the start date.`,
				keys: [key],
			}))
	);

	response.push(
		...trades
			.filter(({ data: { endDate } }) => !endDate)
			.map(({ key, tradeType }) => ({
				message: `${tradeType} end date is invalid. Please populate it.`,
				keys: [key],
			}))
	);

	response.push(
		...trades
			.filter(({ data: { startDate } }) => !startDate)
			.map(({ key, tradeType }) => ({
				message: `${tradeType} start date is invalid. Please populate it.`,
				keys: [key],
			}))
	);

	return response;
};

/** Check if port was specified, and if it could be found */
const validatePortExists: RequirementValidator = ({ longs, shorts }, ports) =>
	[
		...(longs || []).map((long, index) => ({
			data: long,
			key: `longs[${index}].port`,
			tradeType: "Long",
		})),
		...(shorts || []).map((short, index) => ({
			data: short,
			key: `shorts[${index}].port`,
			tradeType: "Short",
		})),
	]
		.filter(({ data: { port } }) => !(port && Object.prototype.hasOwnProperty.call(ports.byId, port)))
		.map(({ key, tradeType, data: { id, port } }) => {
			let message = `The port "${port}" specified for the ${tradeType} was not found. Please check the port name.`;
			if (!port) message = `The port was not specified for the ${tradeType}. Please specify the port.`;
			return {
				message,
				keys: [key],
			};
		});

const getFlatTerminalsFromPorts = memoize((ports: IPortsState) =>
	flatMap(ports.allIds.map((id) => ports.byId[id].terminals))
);
getFlatTerminalsFromPorts.cache = new WeakMap();

/** Gets a dictionary (Record<string, any>) of terminals, keyed by ID, from the ports state object */
const getTerminalsAsDictFromPorts = (ports: IPortsState) => keyBy(getFlatTerminalsFromPorts(ports), "id");

const validateTerminalExists: RequirementValidator = ({ longs, shorts }, ports) => {
	const terminals = getTerminalsAsDictFromPorts(ports);
	return [
		...(longs || []).map((long, index) => ({
			data: long,
			key: `longs[${index}].terminal`,
			tradeType: "Long",
		})),
		...(shorts || []).map((short, index) => ({
			data: short,
			key: `shorts[${index}].terminal`,
			tradeType: "Short",
		})),
	]
		.filter(({ data: { terminal } }) =>
			// * only return true if there is a terminal supplied
			terminal ? !Object.prototype.hasOwnProperty.call(terminals, terminal) : false
		)
		.map(({ key, tradeType, data: { id, terminal, port } }) => ({
			message: `The terminal ${terminal} of port ${port} specified for the ${tradeType} was not found. Please check the terminal name.`,
			keys: [key],
		}));
};

/** check quantities */
const validateQuantity: RequirementValidator = ({ longs, shorts }) => {
	return [
		...(longs || []).map((long, index) => ({
			data: long,
			key: `longs[${index}].productQuantity`,
			tradeType: "Long",
		})),
		...(shorts || []).map((short, index) => ({
			data: short,
			key: `shorts[${index}].productQuantity`,
			tradeType: "Short",
		})),
	]
		.filter(({ data }) => {
			const { productQuantity } = data;
			return !productQuantity || isNaN(productQuantity) || productQuantity < 0;
		})
		.map(({ key, tradeType, data: { productQuantity } }) => ({
			message: `${tradeType} contains incorrect quantity: ${productQuantity}.`,
			keys: [key],
		}));
};

/** Verifies the products at the berths of that terminal */
/*
const validateProductsAtTerminal: RequirementValidator = (
	{ longs, shorts },
	ports
) =>
	flatMap<Trade, RequirementValidationError>(
		[...(longs || []), ...(shorts || [])],
		({ product: { id: product }, port, terminal }) => {
			if (!port) return [];
			const portData = ports.byId[port];
			if (!portData) return [];
			if (!portData.terminals) return [];

			if (terminal) {
				const terminalData = portData.terminals.find(
					({ id }) => id === terminal
				);
				if (!terminalData) {
					return {
						message: `Terminal\`${terminal}\` not found for port \`${port}\``,
					};
				}
				if (
					terminalData.berths &&
					!terminalData.berths.some((berth) =>
						berth.productsHandled?.includes(product)
					)
				) {
					return {
						message: `The terminal \`${terminal}\` does not have a berth that would accept product \`${product}\``,
					};
				}
			} else {
				// If terminal hasn't been specified, we can still check all berths of all terminals
				if (
					!portData.terminals.some((terminal) =>
						terminal.berths?.some((berth) =>
							berth.productsHandled?.includes(product)
						)
					)
				) {
					return {
						message: `Port \`${port}\` does not have a terminal that would accept product \`${product}\``,
					};
				}
			}
			return [];
		}
	);

*/

const validateIdOrNameChanged: RequirementValidator = ({ id, shipmentId }) =>
	[id, shipmentId].includes("New Requirement")
		? [
				{
					message: "Please ensure you've entered a unique shipment name and ID",
				},
		  ]
		: [];
const validateDuplicateTradeIds: RequirementValidator = ({ longs, shorts }) => {
	const allIds = [...(longs ?? []), ...(shorts ?? [])].map(({ id }) => id);
	return allIds.length !== new Set(allIds).size ? [{ message: "Please avoid duplicate ids of shorts/longs" }] : [];
};
/** https://maanainc.atlassian.net/browse/AR-1081 */
const validateTerminalMatchesPort: RequirementValidator = ({ longs, shorts }, ports) => {
	const terminals = allTerminals(ports);
	return [...(longs ?? []), ...(shorts ?? [])].flatMap(({ port, terminal }) => {
		if (!terminal) return [];
		const foundTerminal = terminals.find(({ terminal: { id } }) => id === terminal);
		if (!foundTerminal)
			return [
				{
					message: `Terminal ${terminal} was not found for port ${port}`,
				},
			];
		if (!port) return [];
		if (foundTerminal.port.id !== port)
			return [
				{
					message: `Specified terminal ${terminal} is not matching the specified port ${port}`,
				},
			];
		return [];
	});
};
/** https://maanainc.atlassian.net/browse/AR-1065 */
// const validateShipmentIdForDuplicates: RequirementValidator = (
// 	{ id: thisId, shipmentId: thisShipmentId },
// 	_,
// 	allRequirements
// ) =>
// 	allRequirements.some(
// 		({ id, shipmentId }) => id !== thisId && shipmentId === thisShipmentId
// 	)
// 		? [
// 				{
// 					message: `ShipmentId ${thisShipmentId} already exists`,
// 					keys: ["shipmentId"],
// 				},
// 		  ]
// 		: [];

const validateShortsAndLongsHaveProducts: RequirementValidator = ({ longs, shorts }) => {
	const longsWithoutProductsAsErrors =
		longs
			?.filter((long) => !long.product?.id)
			.map<RequirementValidationError>((longWithoutProduct, index) => ({
				message: `Long does not have a Product Type`,
				keys: [`longs[${index}].product.id`],
			})) ?? [];

	const shortsWithoutProductsAsErrors =
		shorts
			?.filter((short) => !short.product?.id)
			.map<RequirementValidationError>((shortWithoutProduct, index) => ({
				message: `Short does not have a Product Type`,
				keys: [`shorts[${index}].product.id`],
			})) ?? [];

	return longsWithoutProductsAsErrors.concat(shortsWithoutProductsAsErrors);
};

/** Validates a requirement. If the returned array is empty, validation must have passed */
export const validateRequirement = memoize(function validateRequirement(
	requirement: Requirement,
	ports: IPortsState,
	allRequirements: Requirement[]
): RequirementValidationError[] {
	return flatMap<RequirementValidator, RequirementValidationError>(
		[
			validatePastDates,
			validateTradeDates,
			validateTradeCount,
			validateAmountsDuringTrip,
			// validateShipLocations, // https://maanainc.atlassian.net/browse/AR-1082
			validatePortExists,
			validateTerminalExists,
			validateIdOrNameChanged,
			validateDuplicateTradeIds,
			// validateProductsAtTerminal,
			validateTerminalMatchesPort,
			// validateShipmentIdForDuplicates, // https://maanainc.atlassian.net/browse/AR-1153
			validateShortsAndLongsHaveProducts,
			validateQuantity,
		],
		(fn) => fn(requirement, ports, allRequirements)
	);
},
identity);
// Since this function always accepts a requirement object - we can use WeakMap to allow garbage collection
validateRequirement.cache = new WeakMap();

/** Conversion functions used in the form */

/** Since dates in form inputs appear as text, not number, these interfaces better describe the values held in form */
export interface TradeFormValues extends Omit<TradeAsInput, "startDate" | "endDate"> {
	dateRange: [string, string];
}

export interface RequirementFormValues extends Omit<RequirementAsInput, "shorts" | "longs"> {
	longs: TradeFormValues[];
	shorts: TradeFormValues[];
}

function isoToUnixTime(dateStr?: string, isDayEnd: boolean = false): number {
	if (!dateStr) return 0;
	const d = new Date(dateStr);
	d.setUTCHours((isDayEnd ? 23 : 5) - 3, 0, 0, 0);
	return Math.floor(d.valueOf() / 1000);
}

function tradeFormValuesToTrade(trade: TradeFormValues): TradeAsInput {
	const { dateRange, ...values } = trade;
	return {
		...values,
		productQuantity: trade.productQuantity ? +trade.productQuantity : 0,
		startDate: isoToUnixTime(dateRange?.[0]),
		endDate: isoToUnixTime(dateRange?.[1], true),
	};
}

/** Used to convert form dates (which are strings) to unix time
 * (later on the unix time would also depend on the port - which is why the entire trade state is needed to determine the unix time) */
export function formValuesToRequirement(values: RequirementFormValues): RequirementAsInput {
	return {
		...values,
		createdAt: +values.createdAt,
		estimatedCostToSpotCharter: +values.estimatedCostToSpotCharter,
		longs: (values.longs ?? []).filter(isObject).map<TradeAsInput>(tradeFormValuesToTrade),
		shorts: (values.shorts ?? []).filter(isObject).map<TradeAsInput>(tradeFormValuesToTrade),
	};
}

/** Converts to date in Arabian Standard Time */
export function unixTimeToDateStr(time: number): string {
	if (!time) return "";
	return format(new Date((time + 3 * 60 ** 2) * 1000), "YYYY-MM-DD", true);
}

function tradeToFormValues(trade: TradeAsInput): TradeFormValues {
	return {
		...trade,
		dateRange: [unixTimeToDateStr(trade.startDate), unixTimeToDateStr(trade.endDate)],
	};
}

export function requirementToFormValues(req: RequirementAsInput): RequirementFormValues {
	return {
		...req,
		longs: (req.longs ?? []).map<TradeFormValues>(tradeToFormValues),
		shorts: (req.shorts ?? []).map<TradeFormValues>(tradeToFormValues),
	};
}
