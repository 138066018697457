import { vesselScheduleLifecycleClient } from "../../api/vesselScheduleLifecycleClient";
import { getConstants } from "../../constants";
import {
	ASSIGN_REQUIREMENT_FROM_SPOT_TO_VESSEL,
	ASSIGN_REQUIREMENT_FROM_VESSEL_TO_SPOT,
	ASSIGN_REQUIREMENT_FROM_VESSEL_TO_VESSEL,
} from "../../queries/schedules";
import { store } from "../../store";
import { v4 as uuid } from "uuid";

import type { IFleetMachineContext } from "../fleetMachine";
import type { IAssignReqToVesselEvent, IAssignReqVesselToSpotEvent } from "../fleetMachine.events";
import type {
	I_PlannedAndActualVesselScheduleTuple,
	RequirementAsInput,
	AssignResponse,
} from "../../types/generated/q-vessel-schedule-lifecycle-v6.types";
import { recursivelyDeleteTypenameProperty as rmType } from "../../store/util/clean-props";
import { IRequirementReducerState } from "../../store/reducers/requirements";
import { errorMessageFromGraphql } from "../../components/utility/errorMessageFromGraphql";
import { MqttService } from "../../services";
import userContext from "../../context/UserContext";
import { mqttClient } from "../../services/mqtt-service";

async function assignToVessel(
	{ vessels, schedule }: IFleetMachineContext,
	requirements: IRequirementReducerState,
	{ requirementId, vesselId }: IAssignReqToVesselEvent
): Promise<AssignResponse> {
	const targetVessel = vessels.find(({ vessel: { id } }) => id === vesselId);

	if (!targetVessel)
		throw new Error(`Target vessel ${vesselId} was not found. Assignment to spot can be done via context menu.`);

	const requirementToReAssign = requirements.byId[requirementId] as RequirementAsInput;
	if (!requirementToReAssign) throw new Error(`The requirement ${requirementId} to reassign was not found`);

	const targetSchedule: I_PlannedAndActualVesselScheduleTuple = schedule.find(
		({ plannedSchedule: { vesselId: id } }) => id === vesselId
	) ?? {
		id: uuid(),
		plannedSchedule: {
			createdAt: Math.floor(Date.now() / 1000),
			id: uuid(),
			isSpot: false,
			vesselId,
			requirements: [],
			plannedStartDate: Math.min(...requirementToReAssign.longs.map(({ startDate }) => startDate)),
			plannedEndDate: Math.max(...requirementToReAssign.shorts.map(({ endDate }) => endDate)),
		},
	};

	//must only send not started
	const requirementsInPlannedTarget1 = targetSchedule.plannedSchedule.requirements.map((r) => {
		const req = requirements.byId[r.requirementId] as RequirementAsInput;
		return req;
	});
	const filteredReqsInPlannedTarget = requirementsInPlannedTarget1.filter((r) => r?.status.id === "Not Started");

	const sourceSchedule = schedule.find(({ plannedSchedule: { requirements } }) =>
		requirements.some(({ requirementId: id }) => id === requirementId)
	);
	if (!sourceSchedule) throw new Error(`Schedule for requirement ${requirementId} was not found`);

	if (!sourceSchedule.plannedSchedule.isSpot) {
		// This is a vessel to vessel assignment
		const fromVessel = vessels.find(({ vessel: { id } }) => id === sourceSchedule.plannedSchedule.vesselId);
		if (!fromVessel) throw new Error(`Source vessel ${sourceSchedule.plannedSchedule.vesselId} was not found`);

		if (fromVessel.vessel.id === targetVessel.vessel.id) {
			mqttClient.publish(
				MqttService.REASSIGN_REQ_TOPIC,
				Buffer.from(
					JSON.stringify({
						state: MqttService.REASSIGN_REQ_IDLE,
						user: userContext.getUserId(),
					})
				),
				{ qos: 1 }
			);
			throw new Error(`Can't assign requirement to the same vessel`);
		}

		//must only send not started requierments
		const requirementsInPlannedFrom1 = sourceSchedule.plannedSchedule.requirements.map(
			({ requirementId }) => requirements.byId[requirementId] as RequirementAsInput
		);
		const filteredReqsInPlannedFrom = requirementsInPlannedFrom1.filter((r) => r?.status.id === "Not Started");

		const { data, error } = await vesselScheduleLifecycleClient
			.query(
				ASSIGN_REQUIREMENT_FROM_VESSEL_TO_VESSEL,
				rmType({
					targetVessel: targetVessel,
					requirementsInPlannedTarget: filteredReqsInPlannedTarget,
					requirementToReAssign,
					fromVessel,
					requirementsInPlannedFrom: filteredReqsInPlannedFrom,
					constants: await getConstants(),
				}),
				{ requestPolicy: "network-only" }
			)
			.toPromise();
		if (!data?.assignRequirementFromVesselToVessel) {
			mqttClient.publish(
				MqttService.REASSIGN_REQ_TOPIC,
				Buffer.from(
					JSON.stringify({
						state: MqttService.REASSIGN_REQ_IDLE,
						user: userContext.getUserId(),
					})
				),
				{ qos: 1 }
			);
			throw new Error(`Reassignment failed: ${errorMessageFromGraphql(error?.message)}`);
		}

		return {
			newPlan: data.assignRequirementFromVesselToVessel,
			requirementToReassign: requirementToReAssign.id,
			assignType: "v2v",
			sourceScheduleId: sourceSchedule.plannedSchedule.id,
			fromVessel: fromVessel.id,
			targetVessel: targetVessel.id,
		};
	} else {
		// This is a spot to vessel assignment
		const { data, error } = await vesselScheduleLifecycleClient
			.query(
				ASSIGN_REQUIREMENT_FROM_SPOT_TO_VESSEL,
				rmType({
					targetVessel,
					requirementsInPlannedSchedule: filteredReqsInPlannedTarget,
					requirementToReAssign,
					constants: await getConstants(),
				}),
				{ requestPolicy: "network-only" }
			)
			.toPromise();

		if (!data?.assignRequirementFromSpotToVessel)
			throw new Error(`Assignment failed: ${errorMessageFromGraphql(error?.message)}`);

		return {
			newPlan: [...data.assignRequirementFromSpotToVessel],
			requirementToReassign: requirementToReAssign.id,
			assignType: "s2v",
			sourceScheduleId: sourceSchedule.plannedSchedule.id,
			fromVessel: "spot",
			targetVessel: targetVessel.id,
		};
	}
}

// TODO check this
async function assignVesselToSpot(
	{ vessels, schedule }: IFleetMachineContext,
	requirements: IRequirementReducerState,
	{ requirementId }: IAssignReqVesselToSpotEvent
): Promise<AssignResponse> {
	const requirementToReAssign = requirements.byId[requirementId];
	if (!requirementToReAssign) throw new Error(`Requirement ${requirementId} not found`);

	const fromSchedule = schedule.find(({ plannedSchedule: { requirements } }) =>
		requirements.some(({ requirementId: id }) => id === requirementId)
	);
	if (!fromSchedule) throw new Error(`Vessel schedule containing requirement ${requirementId} was not found`);

	const fromVessel = vessels.find(({ vessel: { id } }) => id === fromSchedule.plannedSchedule.vesselId);

	if (!fromVessel) throw new Error(`Vessel ${fromSchedule.plannedSchedule.vesselId} not found`);

	const requirementsInPlannedSchedule1 = fromSchedule.plannedSchedule.requirements.map(
		({ requirementId }) => requirements?.byId[requirementId]
	);
	const filteredReqsInPlannedTarget = requirementsInPlannedSchedule1.filter((r) => r?.status.id === "Not Started");

	console.log(filteredReqsInPlannedTarget);

	const { data, error } = await vesselScheduleLifecycleClient
		.query(
			ASSIGN_REQUIREMENT_FROM_VESSEL_TO_SPOT,
			rmType({
				fromVessel,
				requirementsInPlannedSchedule: filteredReqsInPlannedTarget,
				requirementToReAssign,
				constants: await getConstants(),
			}),
			{ requestPolicy: "network-only" }
		)
		.toPromise();

	if (!data?.assignRequirementFromVesselToSpot) {
		mqttClient.publish(
			MqttService.REASSIGN_REQ_TOPIC,
			Buffer.from(
				JSON.stringify({
					state: MqttService.REASSIGN_REQ_IDLE,
					user: userContext.getUserId(),
				})
			),
			{ qos: 1 }
		);
		throw new Error(`Assignment to spot failed: ${errorMessageFromGraphql(error?.message)}`);
	}

	return {
		newPlan: data.assignRequirementFromVesselToSpot,
		requirementToReassign: requirementToReAssign.id,
		assignType: "v2s",
		sourceScheduleId: fromSchedule.plannedSchedule.id,
		fromVessel: fromVessel.id,
		targetVessel: "spot",
	};
}

export async function assignRequirement(
	context: IFleetMachineContext,
	event: IAssignReqToVesselEvent | IAssignReqVesselToSpotEvent
): Promise<AssignResponse> {
	const requirementsStore = store.getState().requirements;
	if (requirementsStore.loadingState.requirements)
		throw new Error("Please wait for the app to load requirements and try again");

	if (event.type === "eventDrop") {
		mqttClient.publish(
			MqttService.REASSIGN_REQ_TOPIC,
			Buffer.from(
				JSON.stringify({
					state: MqttService.REASSIGN_REQ_TO_VESSEL_IN_PROGRESS,
					user: userContext.getUserId(),
				})
			),
			{ qos: 1 }
		);
		return await assignToVessel(context, requirementsStore, event);
	} else if (event.type === "assignVesselToSpot") {
		mqttClient.publish(
			MqttService.REASSIGN_REQ_TOPIC,
			Buffer.from(
				JSON.stringify({
					state: MqttService.REASSIGN_REQ_TO_SPOT_IN_PROGRESS,
					user: userContext.getUserId(),
				})
			),
			{ qos: 1 }
		);
		return await assignVesselToSpot(context, requirementsStore, event);
	}
	throw new Error(`Invalid call to requirement assignment: ${(event as any).type}`);
}
