import { createRequest } from "@urql/core";
import { produce } from "immer";
import { isEqual, keyBy } from "lodash";
import moment from "moment";
import { from, Subscribable } from "rxjs";
import { distinctUntilChanged, first, map } from "rxjs/operators";
import { v4 as uuid } from "uuid";
import { toObservable } from "wonka";
import {
	assign,
	DoneInvokeEvent,
	ErrorPlatformEvent,
	EventObject,
	interpret,
	Machine,
	MachineConfig,
	send,
	StateNodeConfig,
	StateSchema,
	Subscribable as XSubscribable,
} from "xstate";
import { cancel, pure } from "xstate/lib/actions";

import { requirementsClient } from "../api/reqsClientUrql";
import {
	vesselScheduleLifecycleClient,
	vesselScheduleLifecycleClient_apollo,
} from "../api/vesselScheduleLifecycleClient";
import { errorMessageFromGraphql } from "../components/utility/errorMessageFromGraphql";
import UserContext from "../context/UserContext";
import { GET_PORTS } from "../queries/ports";
import {
	ALL_PRODUCTS,
	GET_REQUIREMENT_BY_ID,
	GET_REQUIREMENTS_BY_ID,
	GET_REQUIREMENTS_BY_DATES,
} from "../queries/requirements";
import { FINISH_SPOT_SCHEDULE, GET_SCHEDULE_B, NEW_CALCULATE } from "../queries/schedules";
import { GET_ALL_VESSELS, GET_VESSEL_BY_ID } from "../queries/vessels";
import { GqlQueryOperationResult } from "../store/epics/requirements.epic";
import { Product, Requirement } from "../types/generated/q-fanar-requirements.types";
import { VesselWithQ88PRandUt } from "../types/generated/q-v2-vessels.types";
import {
	DateRangeAdvanceEvent,
	DateRangeChangeEvent,
	EventClickedEvent,
	FinishSpotScheduleEvent,
	FleetMachineEvent,
	LockRequirementevent,
	PRAddedEvent,
	PRDeleteEvent,
	PromotionProgressEvent,
	RequirementSaveEvent,
	SchedulerInstanceAssignmentEvent,
	ScheduleSaveEvent,
	ScheduleShipmentIdUpdateEvent,
	UTAddedEvent,
	UTDeleteEvent,
	VesselClickedEvent,
	VesselSaveEvent,
	VesselsUpdateEvent,
} from "./fleetMachine.events";
import { assignRequirement } from "./services/assignRequirement.service";
import {
	assignPromoteResultAction,
	promotePlannedToActual,
	getOnlyLatestSchedules,
} from "./services/promotePlannedToActual.service";

import type { IPortsState } from "../store/reducers/ports";
import type { IPort } from "../store/sagas/loadPorts.saga";
import type { NormalizedData } from "../types/common";
import type {
	I_PlannedAndActualVesselScheduleTuple,
	I_PlannedVesselSchedule,
	VesselWithQ88PRandUtOutput,
	Requirement as OtherRequirement,
	I_ActualVesselSchedule,
	AssignResponse,
	I_ActualScheduledRequirement,
	I_PlannedScheduledRequirement,
	S_PlannedVesselActionOutput,
	S_ActualVesselActionOutput,
} from "../types/generated/q-vessel-schedule-lifecycle-v6.types";
import { testSchedules, checkIfActualSchedulesAreGoingWell, OffScheduleRequirementInfo } from "./testSchedules";
import { MqttService } from "../services";
import { mqttClient } from "../services/mqtt-service";
import userContext from "../context/UserContext";

const vesselIsNotASpot = () => {
	return true;
};

interface IRequirementDetailsSchema extends StateSchema<IFleetMachineContext> {
	states: {
		loading: {};
		requirementDetailsLoaded: {};
	};
}

const getFleetSchedule = async (
	startDate: Date,
	endDate: Date
): Promise<Array<I_PlannedAndActualVesselScheduleTuple>> => {
	let startDateSeconds = Math.floor(startDate.valueOf() / 1000);
	const endDateSeconds = Math.floor(endDate.valueOf() / 1000);
	// subtract 45 days to fetch recent schedules before the date range
	// startDateSeconds -= 45 * 24 * 60 * 60;

	//

	const result = await vesselScheduleLifecycleClient_apollo.query({
		query: GET_SCHEDULE_B,
		variables: {
			startDate: startDateSeconds,
			endDate: endDateSeconds,
		},
	});

	const schedule = result?.data?.getFleetSchedule;

	if (!schedule) {
		throw new Error(`Loading Fleet Schedule failed: ${errorMessageFromGraphql(result.error?.message)}`);
	}
	// console.log(schedule);
	testSchedules(schedule, "getFleetSchedule");

	return schedule;
};

const requirementDetailsMachine: MachineConfig<IFleetMachineContext, IRequirementDetailsSchema, any> = {
	initial: "loading",
	states: {
		loading: {
			invoke: {
				id: "fetch-requirement-details",
				src: "getRequirementDetails",
				onDone: {
					target: "requirementDetailsLoaded",
					actions: assign({
						requirementDetails: (_, { data }: DoneInvokeEvent<Requirement>) => data,
					}),
				},
				onError: {
					target: "#ready.idle",
					actions: ["setErrorMessage"],
				},
			},
		},
		requirementDetailsLoaded: {
			type: "atomic",
		},
	},
};

interface IVesselDetailsSchema extends StateSchema<IFleetMachineContext> {
	states: {
		loading: {};
		vesselDetailsLoaded: {};
	};
}

export const SCHEDULE_FILTER_MAP = {
	All: "SET_FILTER_ALL_SCHEDULES",
	Actual: "SET_FILTER_ACTUAL_SCHEDULES",
	Planned: "SET_FILTER_PLANNED_SCHEDULES",
} as const;

export type ScheduleFilter = keyof typeof SCHEDULE_FILTER_MAP;
export interface IFleetMachineContext {
	selectedVesselId?: string;
	/** Vessel details when loaded from BE */
	vesselDetails?: VesselWithQ88PRandUtOutput | undefined;
	/** The date range selected in the date inputs */
	selectedDateRange: [Date, Date];
	/** The data of the requirement that was clicked */
	selectedRequirementId?: { id: string; isActual: boolean };
	/** The details of the clicked requirement loaded from BE */
	requirementDetails?: Requirement | undefined;
	schedule: I_PlannedAndActualVesselScheduleTuple[];
	vessels: VesselWithQ88PRandUtOutput[];
	scheduleFilter: ScheduleFilter; // TODO: Isn't this actually a state machine too? context is for quantitateve data, not qualitative
	/** Content for the snackbar to show error message if something went wrong, undefined if snackboar doesn't need to be shown */
	errrorMessage?: string | undefined;
	/** How far the promotion has progressed */
	promotionProgress: number;
	/** Product list */
	products: NormalizedData<Product>;
	/** Requirement list */
	requirements: NormalizedData<OtherRequirement>;
	/** Missing Spot Requirement list */
	missingFleetRequirements: NormalizedData<OtherRequirement>;
	/** Bryntum Scheduler Instance */
	schedulerInstance?: any;
	/** Metrics for Vessels */
	vesselMetrics?: object | null;
	offScheduleRequirements?: OffScheduleRequirementInfo[];
	needCalculateBeforePromote: boolean;
}

// thoughts about reasons why promote is impossible:
// no calculate yet 															-> need recalc
// calculate done, but edited requirements				-> need recalc
// calculate done on other computer -----------> unblock if other computer fully reloads
// promote started on other computer 						--> block the promote forever
// promote finished on other computer 						-> need recalc
export interface IFleetScheduleSchema extends StateSchema<IFleetMachineContext> {
	states: {
		waitingForAuth: {
			states: {
				pending: {};
				approved: {};
				rejected: {};
			};
		};
		idle: {
			states: {
				loading: {
					states: {
						loadingSchedule: {
							states: {
								loading: {};
								done: {};
								failure: {};
							};
						};
						loadingVessels: {
							states: {
								loading: {};
								done: {};
								failure: {};
							};
						};
						loadingProducts: {
							states: {
								loading: {};
								done: {};
								failure: {};
							};
						};
						loadingRequirements: {
							states: {
								loading: {};
								done: {};
								failure: {};
							};
						};
					};
				};
				loadingScheduleAndRequirements: {
					states: {
						loadingSchedule: {
							states: {
								loading: {};
								done: {};
								failure: {};
							};
						};
						loadingRequirements: {
							states: {
								loading: {};
								done: {};
								failure: {};
							};
						};
					};
				};
				ready: {
					states: {
						idle: {};
						loadingMissingScheduleRequirements: {
							states: {
								loading: {};
								done: {};
								failure: {};
							};
						};
						requirementAssignmentInProgress: {};
						/** When calculate has been pused, and we're waiting for the response */
						calculatingGetFleet: {};
						calculatingGetCalculate: {};
						promotionInProgress: {};
						finishingSpotSchedule: {};
						detailsPanel: {
							states: {
								requirementClicked: IRequirementDetailsSchema;
								vesselClicked: IVesselDetailsSchema;
							};
						};
						gettingFleet: {
							states: {
								loading: {};
								done: {};
							};
						};
						loadRequirements: {
							states: {
								loading: {};
								done: {};
								failure: {};
							};
						};
					};
				};
				failure: {};
			};
		};
	};
}

// reassignment stuff ---------------------------------------------------------------------------------------------------------------------------------------

function extractNewScheduleToApply(event: DoneInvokeEvent<AssignResponse>) {
	let newScheduleToApply: I_PlannedVesselSchedule | undefined;
	const requirementToReassign = event.data.requirementToReassign;
	const assignType = event.data.assignType;
	if (event.data.newPlan?.length && requirementToReassign) {
		newScheduleToApply = event.data.newPlan.find((s) => {
			if (!s.requirements?.length) return false;
			if (!s.requirements.some((r) => r.requirementId === requirementToReassign)) return false;
			return true;
		});
		if (event.data.targetVessel && (assignType === "v2v" || assignType === "s2v")) {
			if (newScheduleToApply?.vesselId !== event.data.targetVessel) {
				newScheduleToApply = undefined;
			}
		} else {
			if (newScheduleToApply?.isSpot !== true) {
				newScheduleToApply = undefined;
			}
		}
	}
	if (!newScheduleToApply?.requirements) {
		throw new Error("newScheduleToApply is empty");
	}

	return newScheduleToApply;
}

function extractScheduleOfFromVessel(event: DoneInvokeEvent<AssignResponse>) {
	let fromVesselUpdatedSchedule: I_PlannedVesselSchedule | undefined;
	let fromVesselId = event.data.fromVessel;
	if (fromVesselId) {
		fromVesselUpdatedSchedule = event.data.newPlan.find((s) => {
			if (!s.requirements?.length) return false;
			if (!(s.vesselId === fromVesselId)) return false;
			return true;
		});
	}
	return fromVesselUpdatedSchedule;
}

function deleteRequirementFromInitialVesselSchedule(
	event: DoneInvokeEvent<AssignResponse>,
	draft: I_PlannedAndActualVesselScheduleTuple[]
) {
	const requirementToReassign = event.data.requirementToReassign;
	const assignType = event.data.assignType;
	if (event.data.fromVessel && (assignType === "v2v" || assignType === "v2s")) {
		const fromSchedule = draft.find((s) => {
			if (s.plannedSchedule?.vesselId !== event.data.fromVessel) return false;
			if (!s.plannedSchedule.requirements?.some?.((r) => r.requirementId === requirementToReassign)) return false;
			return true;
		});
		if (fromSchedule) {
			const newPlannedRequirements = fromSchedule.plannedSchedule.requirements.filter(
				(r) => r.requirementId !== requirementToReassign
			);
			fromSchedule.plannedSchedule.requirements = newPlannedRequirements;
			if (!newPlannedRequirements.length && !fromSchedule.actualSchedule) {
				draft.splice(
					draft.findIndex((s) => s.id === fromSchedule.id),
					1
				);
			}
		}
	} else if (assignType === "s2v" && event.data.sourceScheduleId) {
		const toDelete = draft.find(({ plannedSchedule: { id } }) => id === event.data.sourceScheduleId);
		// if there is actual schedule, remove requirements from planned schedule
		// if no actual schedule, just delete all!
		if (toDelete) {
			if (toDelete.actualSchedule) {
				toDelete.plannedSchedule.requirements = [];
			} else {
				draft.splice(draft.indexOf(toDelete), 1);
			}
		}
	}
}

function computeTargetVesselGrandSchedulesSorted(
	draft: I_PlannedAndActualVesselScheduleTuple[],
	event: DoneInvokeEvent<AssignResponse>
) {
	const requirementToReassign = event.data.requirementToReassign; // for example requirementToReAssign "ATF20220903_832a0749-a753-4d82-b429-793a0ce935e9"
	const assignType = event.data.assignType;

	let targetVesselGrandSchedules: I_PlannedAndActualVesselScheduleTuple[] | undefined = [];
	if (assignType === "v2v" || assignType === "s2v") {
		targetVesselGrandSchedules = draft.filter(
			({ plannedSchedule: { vesselId } }) => vesselId === event.data.targetVessel
		);
	} else {
		targetVesselGrandSchedules = draft.filter(
			({ actualSchedule }) =>
				actualSchedule?.requirements?.some?.((r) => r.requirementId === requirementToReassign) &&
				actualSchedule?.isSpot
		);
		// targetVesselGrandSchedules?.[0].plannedSchedule.isSpot
		if (targetVesselGrandSchedules?.length > 1) {
			throw new Error("too many spot vessel schedules");
		}
	}
	if (targetVesselGrandSchedules.length > 1) {
		targetVesselGrandSchedules.sort(
			(a, b) => b.plannedSchedule.plannedStartDate - a.plannedSchedule.plannedStartDate
		); // sort descending
	}
	return targetVesselGrandSchedules;
}
/////////////////////////////////////
function updatePlannedRequirementsInSchedulesWhereActualOnesNotStarted(
	requirementsToUpdate: I_PlannedScheduledRequirement[] | undefined,
	schedules: I_PlannedAndActualVesselScheduleTuple[]
) {
	if (requirementsToUpdate?.length) {
		requirementsToUpdate.forEach((updatedRequirement) => {
			schedules.forEach((s) => {
				const reqIndex = s.plannedSchedule.requirements.findIndex(
					(sr) => sr.requirementId === updatedRequirement.requirementId
				);
				if (reqIndex !== -1) {
					const requirementInActual = findRequirementInActualScheduleByRequirementId(
						s,
						updatedRequirement.requirementId
					);
					if (requirementInActual && !isActualRequirementNotStarted(requirementInActual)) {
						console.log(`skipped ${updatedRequirement.requirementId}`);
						return;
					}
					if (updatedRequirement.vesselId !== s.plannedSchedule.requirements[reqIndex].vesselId) return;

					s.plannedSchedule.requirements[reqIndex] = updatedRequirement;
					// now updated planned schedule may need actual schedule update
					// and, also, actions need schedule id
					const requirementInPlanned = updatedRequirement;
					const plannedScheduleId = s.plannedSchedule.id;
					if (requirementInActual) {
						updateActualRequirementActionsWithPlannedActions(requirementInActual, requirementInPlanned, s);
					}
					requirementInPlanned.plannedVesselActions?.forEach?.(
						(a) => (a.plannedVesselScheduleId = plannedScheduleId)
					);
				}
			});
		});
	}
}

function findRequirementInActualScheduleByRequirementId(
	grandSchedule: I_PlannedAndActualVesselScheduleTuple | undefined,
	requirementId: string
): I_ActualScheduledRequirement | undefined {
	return grandSchedule?.actualSchedule?.requirements?.find((ar) => ar.requirementId === requirementId);
}

function isActualRequirementNotStarted(requirementInActual: I_ActualScheduledRequirement): boolean {
	return !requirementInActual.actualVesselActions.some((aa) => aa.actionStatus.id !== "Not Started");
}

function deleteRequirementFromActualSchedule(
	grandSchedule: I_PlannedAndActualVesselScheduleTuple,
	requirementId: string
): void {
	const actualSchedule = grandSchedule.actualSchedule;
	if (!actualSchedule) return;
	const index = actualSchedule.requirements.findIndex((ar) => ar.requirementId === requirementId);
	if (index > -1) {
		actualSchedule.requirements.splice(index, 1);
	}
	if (!actualSchedule.requirements.length) {
		delete grandSchedule.actualSchedule;
	}
	return;
}

function updateActualRequirementActionsWithPlannedActions(
	requirementInActual: I_ActualScheduledRequirement,
	requirementInPlanned: I_PlannedScheduledRequirement,
	grandSchedule: I_PlannedAndActualVesselScheduleTuple
): void {
	const plannedVesselActions = requirementInPlanned.plannedVesselActions;
	const actualVesselActions = requirementInActual.actualVesselActions;
	plannedVesselActions.sort((a, b) => a.estimatedStartDate - b.estimatedStartDate);
	actualVesselActions.sort((a, b) => a.actualStartDate - b.actualStartDate);
	if (actualVesselActions.length !== plannedVesselActions.length) {
		if (isActualRequirementNotStarted(requirementInActual)) {
			//console.log(plannedVesselActions, JSON.parse(JSON.stringify(actualVesselActions)));
			deleteRequirementFromActualSchedule(grandSchedule, requirementInActual.requirementId);
			return;
		} else {
			throw new Error(
				`Tried to to update planned actions for In Progress or Completed requirement. Failed to update actual actions for requirement ${requirementInPlanned.requirementId} because of planned-actual action count mismatch`
			);
		}
	}
	let newActualActions: S_ActualVesselActionOutput[] = [];
	let nextPlannedActionIndex = 0;

	actualVesselActions.forEach((aa: S_ActualVesselActionOutput) => {
		const newActualAction = { ...aa };
		const nextPlannedAction = plannedVesselActions[nextPlannedActionIndex];
		if (
			nextPlannedAction &&
			newActualAction.vesselAction.actionType.id === nextPlannedAction.vesselAction.actionType.id &&
			newActualAction.vesselAction.vesselId === nextPlannedAction.vesselAction.vesselId
		) {
			if (newActualAction.endState.port === nextPlannedAction.optimalEndState.port) {
				newActualAction.plannedAction = nextPlannedAction;
				nextPlannedActionIndex++;
			}
		}
		newActualActions.push(newActualAction);
	});

	if (nextPlannedActionIndex !== plannedVesselActions.length) {
		deleteRequirementFromActualSchedule(grandSchedule, requirementInActual.requirementId);
		return;
		// throw new Error(
		// 	`Failed to update actual actions for requirement ${requirementInPlanned.requirementId} because some of planned actions could not be mapped`
		// );
	}

	requirementInActual.actualVesselActions = newActualActions;
	return;
}

// TODO probably setCorrectPlannedScheduleIdForAllActionsAndRelinkActions won't be needed. to be deleted
// function setCorrectPlannedScheduleIdForAllActionsAndRelinkActions(schedules: I_PlannedAndActualVesselScheduleTuple[]) {
// 	if (schedules?.length) {
// 		schedules.forEach((s) => {
// 			const plannedRequirements = s.plannedSchedule.requirements || [];
// 			const plannedScheduleId = s.plannedSchedule.id;
// 			plannedRequirements.forEach((requirementInPlanned) => {
// 				if (requirementInPlanned.plannedVesselActions) {
// 					if (
// 						requirementInPlanned.plannedVesselActions.some(
// 							(a) => a.plannedVesselScheduleId !== plannedScheduleId
// 						)
// 					) {
// 						const requirementInActual = findRequirementInActualScheduleByRequirementId(
// 							s,
// 							requirementInPlanned.requirementId
// 						);
// 						if (requirementInActual) {
// 							updateActualRequirementActionsWithPlannedActions(
// 								requirementInActual,
// 								requirementInPlanned,
// 								s
// 							);
// 							// console.log("actions mapped successfully", requirementInActual, requirementInPlanned); // TODO remove this line
// 						}
// 					}
// 					// ----------------------------------------------------------------------
// 					// ----------------------------------------------------------------------
// 					requirementInPlanned.plannedVesselActions?.forEach?.((a, index) => {
// 						if (a.plannedVesselScheduleId !== plannedScheduleId) {
// 							a.plannedVesselScheduleId = plannedScheduleId;
// 						}
// 					});
// 				}
// 			});
// 		});
// 	}
// }

function updateOtherRequirementSchedules(
	newScheduleToApply: I_PlannedVesselSchedule,
	requirementIdForReassign: string,
	targetVesselGrandSchedules: I_PlannedAndActualVesselScheduleTuple[]
) {
	const requirementsToUpdate = newScheduleToApply.requirements.filter(
		(r) => r.requirementId !== requirementIdForReassign
	);
	updatePlannedRequirementsInSchedulesWhereActualOnesNotStarted(requirementsToUpdate, targetVesselGrandSchedules);
}

let requirementAssignmentInProgressStateNodeConfig: StateNodeConfig<IFleetMachineContext, {}, any> = {
	invoke: {
		id: "assign-requirement-to-vessel",
		src: assignRequirement,
		onDone: {
			target: "idle",
			actions: assign({
				schedule(context, event: DoneInvokeEvent<AssignResponse>) {
					mqttClient.publish(
						MqttService.REASSIGN_REQ_TOPIC,
						Buffer.from(
							JSON.stringify({
								state: MqttService.REASSIGN_REQ_IDLE,
								user: userContext.getUserId(),
							})
						),
						{ qos: 1 }
					);

					return produce(context.schedule, (draft: I_PlannedAndActualVesselScheduleTuple[]) => {
						console.log("assign-requirement-to-vessel event data", event.data);
						const requirementIdForReassign = event.data.requirementToReassign;
						const targetVesselGrandSchedules: I_PlannedAndActualVesselScheduleTuple[] = computeTargetVesselGrandSchedulesSorted(
							draft,
							event
						);
						const newScheduleToApply: I_PlannedVesselSchedule = extractNewScheduleToApply(event);

						if (newScheduleToApply.requirements.length > 1) {
							updateOtherRequirementSchedules(
								newScheduleToApply,
								requirementIdForReassign,
								targetVesselGrandSchedules
							);
							newScheduleToApply.requirements = newScheduleToApply.requirements.filter(
								(r) => r.requirementId === requirementIdForReassign
							);
						}

						const fromVesselUpdatedSchedule = extractScheduleOfFromVessel(event);
						updatePlannedRequirementsInSchedulesWhereActualOnesNotStarted(
							fromVesselUpdatedSchedule?.requirements,
							draft
						);

						if (targetVesselGrandSchedules?.length) {
							const grandScheduleWithActual = targetVesselGrandSchedules.find(
								(g) => !!findRequirementInActualScheduleByRequirementId(g, requirementIdForReassign)
							);
							if (grandScheduleWithActual) {
								const newRequirementForTargetVessel = newScheduleToApply.requirements[0];
								grandScheduleWithActual.plannedSchedule.requirements.push(
									newRequirementForTargetVessel
								);
								updatePlannedRequirementsInSchedulesWhereActualOnesNotStarted(
									newScheduleToApply.requirements,
									[grandScheduleWithActual]
								);
							} else {
								// Maybe, would be nice to implement a check if last schedule is completed and create a new schedule, but looks like it's not needed.
								const newRequirementForTargetVessel = newScheduleToApply.requirements[0];
								const targetPlannedSchedule = targetVesselGrandSchedules[0].plannedSchedule;
								newRequirementForTargetVessel.plannedVesselActions?.forEach?.(
									(a) => (a.plannedVesselScheduleId = targetPlannedSchedule.id)
								);
								targetPlannedSchedule.requirements.push(newRequirementForTargetVessel);
							}
						} else {
							// if no schedule exists for target vessel, just add a new schedule
							draft.push({
								id: uuid(),
								plannedSchedule: newScheduleToApply,
							});
						}
						deleteRequirementFromInitialVesselSchedule(event, draft);
						return;
						// TODO in theory we could additionally update plannedStartDate and plannedEndDate, but it looks like it does not affect anything
					});
				},
			}),
		},
		onError: {
			target: "idle",
			actions: ["setErrorMessage"],
		},
	},
};

export const fleetScheduleMachine = Machine<IFleetMachineContext, IFleetScheduleSchema, any>(
	{
		id: "fleet-machine",
		initial: "waitingForAuth",
		context: {
			needCalculateBeforePromote: true,
			scheduleFilter: "All",
			selectedDateRange: [moment().startOf("month").toDate(), moment().add(1, "month").endOf("month").toDate()],
			schedule: [],
			vessels: [],
			promotionProgress: 0,
			products: {
				allIds: [],
				byId: {},
			},
			requirements: {
				allIds: [],
				byId: {},
			},
			missingFleetRequirements: {
				allIds: [],
				byId: {},
			},
			schedulerInstance: null,
			vesselMetrics: null,
		},
		states: {
			// * wait for authorization header to be populated before moving to main idle state
			waitingForAuth: {
				initial: "pending",
				onDone: {
					target: "#idle",
				},
				states: {
					pending: {
						invoke: {
							onDone: "approved",
							src: async () => await UserContext.token$.pipe(first()).toPromise(),
						},
					},
					approved: {
						type: "final",
					},
					rejected: {},
				},
			},
			idle: {
				id: "idle",
				// * fetch fleet schedule immediately
				initial: "loading",
				states: {
					loading: {
						onDone: "ready",
						type: "parallel",
						states: {
							loadingSchedule: {
								initial: "loading",
								states: {
									loading: {},
									done: { type: "final" },
									failure: {},
								},
								invoke: {
									id: "fetch-fleet-schedule",
									src: "getFleetSchedule",
									onDone: {
										target: "#ready.loadRequirements",
										actions: assign<
											IFleetMachineContext,
											DoneInvokeEvent<Array<I_PlannedAndActualVesselScheduleTuple>>
										>({
											schedule(_, { data }) {
												return data;
											},
											offScheduleRequirements: (_, { data }) => {
												return checkIfActualSchedulesAreGoingWell(data);
											},
										}),
									},
									onError: {
										target: ".failure",
										actions: ["setErrorMessage"],
									},
								},
							},
							loadingVessels: {
								initial: "loading",
								states: {
									loading: {},
									done: { type: "final" },
									failure: {},
								},
								on: {
									vesselsNetworkUpdate: {
										target: ".done",
										actions: ["setVessels"],
									},
								},
							},
							loadingProducts: {
								initial: "loading",
								states: {
									loading: {},
									done: { type: "final" },
									failure: {},
								},
								invoke: {
									id: "fetch-products",
									src: "getProducts",
									onDone: {
										target: ".done",
										actions: assign<IFleetMachineContext, DoneInvokeEvent<NormalizedData<Product>>>(
											{
												products: (context, event) => event.data,
											}
										),
									},
									onError: {
										target: ".failure",
										actions: ["setErrorMessage"],
									},
								},
							},
							loadingRequirements: {
								initial: "loading",
								states: {
									loading: {},
									done: { type: "final" },
									failure: {},
								},
								invoke: {
									id: "fetch-requirements",
									src: "getAllRequirements",
									onDone: {
										target: ".done",
										actions: assign<
											IFleetMachineContext,
											DoneInvokeEvent<NormalizedData<OtherRequirement>>
										>({
											requirements: (context, event) => event.data,
										}),
									},
									onError: {
										target: ".failure",
										actions: ["setErrorMessage"],
									},
								},
							},
						},
					},
					loadingScheduleAndRequirements: {
						onDone: "ready",
						type: "parallel",
						states: {
							loadingSchedule: {
								initial: "loading",
								states: {
									loading: {},
									done: { type: "final" },
									failure: {},
								},
								invoke: {
									id: "fetch-fleet-schedule",
									src: "getFleetSchedule",
									onDone: {
										target: "#ready.loadRequirements",
										actions: assign<
											IFleetMachineContext,
											DoneInvokeEvent<Array<I_PlannedAndActualVesselScheduleTuple>>
										>({
											schedule(_, { data }) {
												return data;
											},
											offScheduleRequirements: (_, { data }) => {
												return checkIfActualSchedulesAreGoingWell(data);
											},
										}),
									},
									onError: {
										target: ".failure",
										actions: ["setErrorMessage"],
									},
								},
							},
							loadingRequirements: {
								initial: "loading",
								states: {
									loading: {},
									done: { type: "final" },
									failure: {},
								},
								invoke: {
									id: "fetch-requirements",
									src: "getAllRequirements",
									onDone: {
										target: ".done",
										actions: assign<
											IFleetMachineContext,
											DoneInvokeEvent<NormalizedData<OtherRequirement>>
										>({
											requirements: (context, event) => event.data,
										}),
									},
									onError: {
										target: ".failure",
										actions: ["setErrorMessage"],
									},
								},
							},
						},
					},

					ready: {
						id: "ready",
						initial: "idle", // "idle",

						on: {
							SET_FILTER_ALL_SCHEDULES: {
								actions: "setScheduleFilter",
							},
							SET_FILTER_ACTUAL_SCHEDULES: {
								actions: "setScheduleFilter",
							},
							SET_FILTER_PLANNED_SCHEDULES: {
								actions: "setScheduleFilter",
							},
							DISMISS_SNACKBAR: {
								actions: [
									assign<IFleetMachineContext, FleetMachineEvent>({ errrorMessage: undefined }),
									cancel("dismiss_error"),
								],
							},
							UT_ADDED: {
								target: ".idle",
								actions: "assignAddedUT",
							},
							PR_ADDED: {
								target: ".idle",
								actions: "assignAddedPR",
							},
							UT_DELETED: {
								actions: "assignDeletedUT",
							},
							PR_DELETED: {
								actions: "assignDeletedPR",
							},
							LOCK_REQUIREMENT_TO_VESSEL: {
								actions: "assignRequirementLock",
							},
							LOCK_REQUIREMENT_TO_SPOT: {
								actions: "assignRequirementSpotLock",
							},
							UNLOCK_REQUIREMENT: {
								actions: "assignRequirementUnlock",
							},
							SET_SCHEDULER_INSTANCE: {
								actions: "assignSchedulerInstance",
							},
							SET_VESSEL_METRICS: {
								actions: "assignVesselMetrics",
							},
							UPDATE_REQUIREMENT: {
								actions: [
									"updateRequirement",
									assign<IFleetMachineContext, RequirementSaveEvent>({
										needCalculateBeforePromote: () => true,
									}),
								],
							},
							RECONSIGN_REQUIREMENT_DONE: {
								actions: "reconsignRequirement",
								target: "#idle",
							},
							UPDATE_REQUIREMENT_DETAILS: {
								actions: [
									"updateRequirementDetails",
									assign<IFleetMachineContext, RequirementSaveEvent>({
										needCalculateBeforePromote: () => true,
									}),
								],
							},
							UPDATE_SCHEDULE: {
								actions: [
									"updateSchedule",
									assign<IFleetMachineContext, RequirementSaveEvent>({
										needCalculateBeforePromote: () => true,
									}),
								],
							},
							UPDATE_SHIPMENT_ID: {
								actions: [
									"updateShipmentId",
									assign<IFleetMachineContext, RequirementSaveEvent>({
										needCalculateBeforePromote: () => true,
									}),
								],
							},
						},

						states: {
							idle: {
								on: {
									vesselClicked: {
										id: "vesselClicked",
										target: "#detailsPanel.vesselClicked",
										cond: vesselIsNotASpot,
									},
									eventClick: {
										target: "#detailsPanel.requirementClicked",
										actions: assign<IFleetMachineContext, EventClickedEvent>({
											selectedRequirementId(_, { eventId, isActual }) {
												return {
													id: eventId,
													isActual,
												};
											},
										}),
									},
									eventDrop: {
										target: "requirementAssignmentInProgress",
									},
									assignVesselToSpot: {
										target: "requirementAssignmentInProgress",
									},
									calculate: {
										target: "calculatingGetFleet",
									},
									promote: {
										target: "promotionInProgress",
									},
									finishSpotSchedule: {
										target: "finishingSpotSchedule",
									},
								},
							},
							loadingMissingScheduleRequirements: {
								initial: "loading",
								states: {
									loading: {},
									done: { type: "final" },
									failure: {},
								},
								invoke: {
									id: "fetch-missing-requirements",
									src: "getMissingScheduleRequirements",
									onDone: {
										target: "idle",
										actions: assign<
											IFleetMachineContext,
											DoneInvokeEvent<NormalizedData<OtherRequirement>>
										>({
											missingFleetRequirements: (_, event) => event.data,
										}),
									},
									onError: {
										target: ".failure",
										actions: ["setErrorMessage"],
									},
								},
							},
							requirementAssignmentInProgress: requirementAssignmentInProgressStateNodeConfig,
							calculatingGetFleet: {
								invoke: {
									src: "getFleetSchedule",
									onDone: {
										target: "calculatingGetCalculate",
										actions: assign<
											IFleetMachineContext,
											DoneInvokeEvent<Array<I_PlannedAndActualVesselScheduleTuple>>
										>({
											schedule(_, { data }) {
												return data;
											},
										}),
									},
									onError: {
										target: "#idle.failure",
										actions: ["setErrorMessage"],
									},
								},
							},
							calculatingGetCalculate: {
								invoke: {
									id: "calculate-progress",
									src: "calculate",
									onDone: {
										target: "loadingMissingScheduleRequirements", // "idle",
										actions: [
											"assignCalculateResult",
											assign<IFleetMachineContext, DoneInvokeEvent<any>>({
												needCalculateBeforePromote: () => false,
											}),
										],
									},
									onError: {
										target: "idle",
										actions: ["setErrorMessage"],
									},
								},
							},
							promotionInProgress: {
								invoke: {
									src: "promotePlannedToActual",
									onDone: {
										target: "loadingMissingScheduleRequirements", // "idle",
										// TODO!!! implement one more target, loadingMissingScheduleRequirements, then reload schedules
										actions: [
											assign<IFleetMachineContext, DoneInvokeEvent<any>>({
												needCalculateBeforePromote: () => true,
											}),
										],
									},
									onError: {
										target: "idle",
										actions: ["setErrorMessage"],
									},
								},
								on: {
									PROMOTE_PROGRESS: {
										actions: assign<IFleetMachineContext, PromotionProgressEvent>({
											promotionProgress(_, { progress }) {
												return progress;
											},
										}),
									},
									PROMOTE_FINISHED: {
										actions: assignPromoteResultAction,
									},
								},
								entry: assign<IFleetMachineContext, EventObject>({
									promotionProgress: 0,
								}),
							},
							finishingSpotSchedule: {
								type: "atomic",
								invoke: {
									async src(_, { requirementId }: FinishSpotScheduleEvent): Promise<string> {
										const { data, error } = await vesselScheduleLifecycleClient
											.mutation(FINISH_SPOT_SCHEDULE, {
												requirementId,
											})
											.toPromise();
										if (!data?.finishSpotRequirement)
											throw new Error(
												`Finishing spot failed: ${errorMessageFromGraphql(error?.message)}`
											);
										return data.finishSpotRequirement;
									},
									onDone: {
										target: "loadingMissingScheduleRequirements", // "idle",
										actions: assign<IFleetMachineContext, DoneInvokeEvent<string>>({
											schedule({ schedule }, { data: id }) {
												return produce(schedule, (draft) => {
													for (const action of draft.flatMap(
														({ actualSchedule }) =>
															actualSchedule?.requirements
																.filter(
																	({ requirementId }: { requirementId: string }) =>
																		requirementId === id
																)
																.flatMap(
																	({ actualVesselActions }) => actualVesselActions
																) ?? []
													)) {
														action.actionStatus.id = "Complete";
													}
												});
											},
										}),
									},
									onError: {
										target: "idle",
										actions: ["setErrorMessage"],
									},
								},
							},
							detailsPanel: {
								id: "detailsPanel",
								on: {
									CLOSE: {
										target: "#ready.idle",
										actions: assign<IFleetMachineContext, EventObject>({
											requirementDetails: undefined,
											vesselDetails: undefined,
										}),
									},
								},

								states: {
									requirementClicked: {
										id: "requirementClicked",
										...requirementDetailsMachine,
									},
									vesselClicked: {
										id: "vesselClicked",
										type: "atomic",
										entry: assign<IFleetMachineContext, VesselClickedEvent>({
											selectedVesselId(_, { vesselId }) {
												return vesselId;
											},
										}),
									},
								},
							},
							gettingFleet: {
								id: "getting-fleet",
								initial: "loading",
								states: {
									loading: {
										invoke: {
											src: "getFleetSchedule",
											onDone: {
												target: "done",
												actions: assign<
													IFleetMachineContext,
													DoneInvokeEvent<Array<I_PlannedAndActualVesselScheduleTuple>>
												>({
													schedule(_, { data }) {
														return data;
													},
												}),
											},
											onError: {
												target: "#idle.failure",
												actions: ["setErrorMessage"],
											},
										},
									},
									done: {
										type: "final",
										onDone: {
											target: "#ready.loadRequirements",
										},
									},
								},
							},
							loadRequirements: {
								initial: "loading",
								states: {
									loading: {},
									done: { type: "final" },
									failure: {},
								},
								invoke: {
									id: "fetch-reqs",
									src: "getMissingScheduleRequirements",
									onDone: {
										target: "idle",
										actions: assign<
											IFleetMachineContext,
											DoneInvokeEvent<NormalizedData<OtherRequirement>>
										>({
											missingFleetRequirements: (_, event) => event.data,
										}),
									},
									onError: {
										target: ".failure",
										actions: ["setErrorMessage"],
									},
								},
							},
						},
					},
					failure: {
						on: {
							RETRY: "loading",
						},
					},
				},
				on: {
					dateRangeChange: {
						target: "#ready.gettingFleet",
						actions: "assignDateRange",
					},
					DATE_RANGE_MOVE: {
						target: "#ready.gettingFleet",
						actions: "assignDateRangeMove",
					},
					DATE_RANGE_RELOAD: {
						target: "#ready.gettingFleet",
						actions: "assignUnchangedDateRange",
					},
					VESSEL_SAVED: {
						actions: "assignSavedVessel",
					},
					vesselsNetworkUpdate: {
						actions: ["setVessels"],
					},
				},
				/** Since this state contains both loading and the app idle state -
				 * we can use this to load from cache -> update loading state + afterwards load from network, and update app state while in idle app state */
				invoke: {
					id: "load_vessels",
					src: (): XSubscribable<VesselsUpdateEvent> =>
						from(
							toObservable(
								vesselScheduleLifecycleClient.executeQuery(createRequest(GET_ALL_VESSELS), {
									requestPolicy: "network-only",
								})
							) as Subscribable<GqlQueryOperationResult<typeof GET_ALL_VESSELS>>
						).pipe(
							map(
								({ data, error }): VesselsUpdateEvent => {
									if (!data?.getAllVesselsAndPRandUT)
										throw new Error(`Loading vessels failed: ${error?.message}`);
									return {
										type: "vesselsNetworkUpdate",
										vessels: data.getAllVesselsAndPRandUT.filter((x): x is VesselWithQ88PRandUt =>
											Boolean(x)
										),
									};
								}
							),
							distinctUntilChanged(isEqual)
						),
					onError: {
						actions: ["setErrorMessage"],
					},
				},
			},
		},
	},
	{
		actions: {
			setScheduleFilter: assign<IFleetMachineContext, EventObject>({
				scheduleFilter: (_, event): ScheduleFilter => {
					switch (event.type) {
						case "SET_FILTER_ALL_SCHEDULES":
							return "All";
						case "SET_FILTER_ACTUAL_SCHEDULES":
							return "Actual";
						case "SET_FILTER_PLANNED_SCHEDULES":
							return "Planned";
					}
					return "All";
				},
			}),

			setVessels: assign<IFleetMachineContext, VesselsUpdateEvent>({
				vessels: (_, { vessels }) => vessels,
			}),

			setClickedRequirementId: assign({
				selectedRequirementId(_, { eventRecord }) {
					return eventRecord;
				},
			}),

			setNeedCalculateBeforePromote: assign({
				needCalculateBeforePromote: (_, { needCalculateBeforePromote }) => needCalculateBeforePromote,
			}),

			setErrorMessage: pure<IFleetMachineContext, ErrorPlatformEvent>(() => {
				return [
					cancel("dismiss_error"),
					assign<IFleetMachineContext, ErrorPlatformEvent>({
						errrorMessage(_, { data }: ErrorPlatformEvent) {
							return data?.message;
						},
					}),
					send<IFleetMachineContext, ErrorPlatformEvent>("DISMISS_SNACKBAR", {
						delay: 100000,
						id: "dismiss_error",
					}),
				];
			}),

			assignCalculateResult: assign<IFleetMachineContext, DoneInvokeEvent<Array<I_PlannedVesselSchedule>>>({
				schedule({ schedule }, { data }: { data: I_PlannedVesselSchedule[] }) {
					const latestSchedules = getOnlyLatestSchedules(schedule);
					//const latestSchedules = schedule;
					console.log("latest");
					console.log(latestSchedules);
					testSchedules(latestSchedules, "Latest Schedules");

					const newPlannedSchedulesByVesselId: any = keyBy(data, ({ vesselId }) => vesselId);

					if (latestSchedules) {
						const newSchedules: I_PlannedAndActualVesselScheduleTuple[] = latestSchedules?.map(
							(scheduleTuple: I_PlannedAndActualVesselScheduleTuple) => {
								const {
									actualSchedule,
									plannedSchedule: currentPlannedSchedule,
								}: I_PlannedAndActualVesselScheduleTuple = scheduleTuple;

								console.log("contstucting new plan");
								// New planned schedule for the vessel
								const newPlannedSchedule =
									actualSchedule?.vesselId &&
									actualSchedule?.vesselId in newPlannedSchedulesByVesselId
										? newPlannedSchedulesByVesselId[actualSchedule?.vesselId]
										: null;

								// Started requirements of current schedule
								// actionStatus.id can be "Not Started" | "Complete" | "In Progress"
								const startedRequirementIds: string[] =
									actualSchedule?.requirements
										?.filter((r) =>
											r.actualVesselActions.some((a) => a.actionStatus.id !== "Not Started")
										)
										.map((r) => r.requirementId) || [];

								// Construction of newly calculated schedule
								const plannedSchedule = currentPlannedSchedule
									? produce(
											currentPlannedSchedule,
											(plannedScheduleDraft: I_PlannedVesselSchedule) => {
												// Pack all started requirements
												plannedScheduleDraft.requirements = plannedScheduleDraft?.requirements?.filter(
													(r) => startedRequirementIds.includes(r.requirementId)
												);
												// Pack all requirements from newly calculated schedule
												if (newPlannedSchedule && newPlannedSchedule?.requirements) {
													/*
													update everything

													//start date
													//end date
													//createdAt
													//createdBy
												*/

													plannedScheduleDraft.id = newPlannedSchedule.id;
													plannedScheduleDraft.plannedStartDate =
														newPlannedSchedule.plannedStartDate;
													plannedScheduleDraft.plannedEndDate =
														newPlannedSchedule.plannedEndDate;
													plannedScheduleDraft.createdAt = newPlannedSchedule.createdAt;
													plannedScheduleDraft.createdBy = newPlannedSchedule.createdBy;
													plannedScheduleDraft.isSpot = newPlannedSchedule.isSpot;
													for (const req of newPlannedSchedule?.requirements) {
														plannedScheduleDraft.requirements.push(req);
													}
													if (
														newPlannedSchedule?.vesselId &&
														newPlannedSchedule?.vesselId in newPlannedSchedulesByVesselId
													) {
														delete newPlannedSchedulesByVesselId[
															newPlannedSchedule?.vesselId
														];
													}
												}
											}
									  )
									: newPlannedSchedulesByVesselId;

								return {
									...scheduleTuple,
									actualSchedule,
									plannedSchedule,
								};
							}
						) as I_PlannedAndActualVesselScheduleTuple[];

						// Pack all requirements that left from newly calculated schedule
						if (newPlannedSchedulesByVesselId) {
							const newUnassignedPlannedSchedules = Object.entries(newPlannedSchedulesByVesselId);

							for (const [, plannedSchedule] of newUnassignedPlannedSchedules) {
								const scheduleTuple = {
									id: uuid(),
									plannedSchedule,
								} as I_PlannedAndActualVesselScheduleTuple;
								newSchedules.push(scheduleTuple);
							}
						}

						console.log(newSchedules);
						testSchedules(newSchedules, "New Schedules");

						return newSchedules;
					} else {
						// Return newly created schedule (empty -> calculate)
						//this never gets executed BTW
						return data.map((plannedSchedule: I_PlannedVesselSchedule) => ({
							id: uuid(),
							plannedSchedule,
						})) as I_PlannedAndActualVesselScheduleTuple[];
					}
				},
			}),

			assignDateRange: assign<IFleetMachineContext, DateRangeChangeEvent>({
				selectedDateRange(_, { dates }) {
					return dates;
				},
			}),

			assignDateRangeMove: assign<IFleetMachineContext, DateRangeAdvanceEvent>({
				selectedDateRange({ selectedDateRange: [start, end] }, { isForward }) {
					return [
						moment(start)
							.add(isForward ? +1 : -1, "month")
							.toDate(),
						moment(end)
							.add(isForward ? +1 : -1, "month")
							.toDate(),
					];
				},
			}),

			// TODO!!!
			// assignUnchangedDateRange is poor implementation, temporary fix. should be done properly
			assignUnchangedDateRange: assign<IFleetMachineContext>({
				selectedDateRange(fleetMachineContext) {
					return [...fleetMachineContext.selectedDateRange];
				},
			}),

			assignAddedUT: assign<IFleetMachineContext, UTAddedEvent>({
				vessels({ vessels }, { data }) {
					return produce(vessels, (draft) => {
						const vessel = draft.find(({ vessel: { id } }) => id === data.vesselId);
						if (vessel) {
							vessel.unavailableTimes?.push(data);
						}
					});
				},
			}),
			assignAddedPR: assign<IFleetMachineContext, PRAddedEvent>({
				vessels({ vessels }, { data }) {
					return produce(vessels, (draft) => {
						const vessel = draft.find(({ vessel: { id } }) => id === data.vesselId);
						if (vessel) {
							vessel.portRestrictions?.push(data);
						}
					});
				},
			}),

			assignDeletedUT: assign<IFleetMachineContext, UTDeleteEvent>({
				vessels({ vessels }, { utId, vesselId }) {
					return produce(vessels, (draft) => {
						const vessel = draft.find(({ vessel: { id } }) => id === vesselId);
						if (vessel?.unavailableTimes)
							vessel.unavailableTimes.splice(
								vessel.unavailableTimes.findIndex((t) => t && t.id === utId),
								1
							);
					});
				},
			}),
			assignDeletedPR: assign<IFleetMachineContext, PRDeleteEvent>({
				vessels({ vessels }, { prId, vesselId }) {
					return produce(vessels, (draft) => {
						const vessel = draft.find(({ vessel: { id } }) => id === vesselId);
						if (vessel?.portRestrictions)
							vessel.portRestrictions.splice(
								vessel.portRestrictions.findIndex((t) => t && t.id === prId),
								1
							);
					});
				},
			}),
			assignRequirementLock: assign<IFleetMachineContext, LockRequirementevent>({
				schedule({ schedule }, { requirementId, lock }) {
					return produce(schedule, (draft) => {
						for (const requirement of draft.flatMap(({ plannedSchedule, actualSchedule }) =>
							[...plannedSchedule.requirements, ...(actualSchedule?.requirements ?? [])].filter(
								({ requirementId: id }) => id === requirementId
							)
						)) {
							requirement.isLocked = !!lock;
						}
					});
				},
			}),
			assignRequirementSpotLock: assign<IFleetMachineContext, LockRequirementevent>({
				schedule({ schedule }, { requirementId, lock }) {
					return produce(schedule, (draft) => {
						for (const requirement of draft.flatMap(({ plannedSchedule, actualSchedule }) =>
							[...plannedSchedule.requirements, ...(actualSchedule?.requirements ?? [])].filter(
								({ requirementId: id }) => id === requirementId
							)
						)) {
							requirement.isLocked = !!lock;
						}
					});
				},
			}),
			assignRequirementUnlock: assign<IFleetMachineContext, LockRequirementevent>({
				schedule({ schedule }, { requirementId, lock }) {
					return produce(schedule, (draft) => {
						for (const requirement of draft.flatMap(({ plannedSchedule, actualSchedule }) =>
							[...plannedSchedule.requirements, ...(actualSchedule?.requirements ?? [])].filter(
								({ requirementId: id }) => id === requirementId
							)
						)) {
							requirement.isLocked = !!lock;
						}
					});
				},
			}),
			assignSavedVessel: assign<IFleetMachineContext, VesselSaveEvent>({
				vessels({ vessels }, { vessel }: VesselSaveEvent) {
					return produce(vessels, (draft) => {
						const existingVessel = draft.find(({ vessel: { id } }) => id === vessel.id);
						if (!existingVessel) {
							console.log("VESSEL WASNT FOUND", vessel);
							draft.push({
								id: uuid(),
								q88Vessel: {} as any,
								vessel,
								portRestrictions: [],
								unavailableTimes: [],
							});
						} else {
							console.log("UPDATING", vessel);
							existingVessel.vessel = vessel;
						}
					});
				},
			}),
			assignSchedulerInstance: assign<IFleetMachineContext, SchedulerInstanceAssignmentEvent>({
				schedulerInstance(_: any, { schedulerInstance }: SchedulerInstanceAssignmentEvent) {
					return schedulerInstance;
				},
			}),
			assignVesselMetrics: assign<IFleetMachineContext, SchedulerInstanceAssignmentEvent>({
				vesselMetrics(_: any, { vesselMetrics }: any) {
					return vesselMetrics;
				},
			}),
			updateRequirement: assign<IFleetMachineContext, RequirementSaveEvent>({
				requirements({ requirements }, { requirement }: RequirementSaveEvent) {
					return produce(requirements, (draft) => {
						const find = draft.byId[requirement.id];
						if (!find) {
							draft.allIds.push(requirement.id);
							draft.byId[requirement!.id] = requirement as any;
						} else {
							draft.byId[requirement.id] = requirement as any;
						}
					});
				},
			}),
			reconsignRequirement: assign<IFleetMachineContext, RequirementSaveEvent>({
				// TODO!!! see how it's done with updateRequirement
			}),
			updateRequirementDetails: assign<IFleetMachineContext, RequirementSaveEvent>({
				requirementDetails({ requirementDetails }, { requirement }: RequirementSaveEvent) {
					return produce(requirementDetails, (draft) => {
						if (requirementDetails?.id === requirement.id) {
							draft = requirement as any;
						}
					});
				},
			}),
			updateSchedule: assign<IFleetMachineContext, ScheduleSaveEvent>({
				schedule({ schedule }, event: ScheduleSaveEvent) {
					const latest = schedule; //getOnlyLatestSchedules(schedule);
					const existingByVessel = keyBy(latest, ({ plannedSchedule: { vesselId } }) => vesselId);

					return schedule.map((f) => {
						const actualSchedule = f.actualSchedule;
						const find = event.schedule.find(
							(y: any) => y?.newSchedule?.vesselId === actualSchedule?.vesselId
						);

						if (find) {
							const matchingExistingTuple = Boolean(actualSchedule?.vesselId)
								? existingByVessel[actualSchedule!.vesselId]
								: null;

							if (matchingExistingTuple) {
								// If we can match by vesselId - we can reuse the planned schedule from that
								return {
									...matchingExistingTuple,
									actualSchedule: find.newSchedule,
								};
							}
						}

						return f;
					});
				},
			}),
			// updateShipmentId is called on shipment rename
			updateShipmentId: assign<IFleetMachineContext, ScheduleShipmentIdUpdateEvent>({
				schedule({ schedule }, event: ScheduleShipmentIdUpdateEvent) {
					const responseData = event?.responseData;

					let isSpotVessel = (responseData?.newVesselId || "").startsWith("spot");
					if (!isSpotVessel) {
						return schedule;
					}

					const newSchedule = schedule.map((f) => {
						const actualSchedule = f?.actualSchedule;
						const plannedSchedule = f?.plannedSchedule;

						let adjustedActualSchedule = actualSchedule;
						let adjustedPlannedSchedule = plannedSchedule;

						if (actualSchedule?.id === responseData?.actualScheduleID) {
							// do for actual schedule that matches actualScheduleID for renamed shipment
							const vesselId = responseData?.newVesselId ?? actualSchedule?.vesselId;

							adjustedActualSchedule = {
								...(actualSchedule ?? f?.actualSchedule),
								vesselId: vesselId,
								requirements: actualSchedule?.requirements.map((r) => {
									return {
										...r,
										vesselId: vesselId,
										actualVesselActions: r.actualVesselActions.map((a) => {
											const updatedAction = JSON.parse(
												JSON.stringify(a)
											) as S_ActualVesselActionOutput;
											if (vesselId) {
												updatedAction.vesselId = vesselId;
												if (updatedAction.plannedAction?.vesselAction) {
													updatedAction.plannedAction.vesselAction.vesselId = vesselId;
												}
												if (updatedAction.vesselAction) {
													updatedAction.vesselAction.vesselId = vesselId;
												}
											}
											return updatedAction;
										}) as S_ActualVesselActionOutput[],
									} as I_ActualScheduledRequirement;
								}),
							} as I_ActualVesselSchedule;
						}

						if (plannedSchedule?.id === responseData?.plannedScheduleID) {
							// do for planned schedule that matches plannedScheduleID for renamed shipment
							const vesselId = responseData?.newVesselId ?? plannedSchedule?.vesselId;

							adjustedPlannedSchedule = {
								...(plannedSchedule ?? f?.plannedSchedule),
								vesselId: vesselId,
								requirements: plannedSchedule?.requirements.map((r) => {
									return {
										...r,
										vesselId: vesselId,
										plannedVesselActions: r.plannedVesselActions.map((a) => {
											return {
												...a,
												vesselAction: {
													...a.vesselAction,
													vesselId: vesselId,
												},
											};
										}) as S_PlannedVesselActionOutput[],
									} as I_PlannedScheduledRequirement;
								}),
							} as I_PlannedVesselSchedule;
						}

						return {
							...f,
							actualSchedule: adjustedActualSchedule,
							plannedSchedule: adjustedPlannedSchedule,
						};
					});

					return newSchedule;
				},
			}),
		},

		services: {
			getAllPorts: async () => {
				const result = await vesselScheduleLifecycleClient
					.query(GET_PORTS, undefined, {
						requestPolicy: "network-only",
					})
					.toPromise();

				const ports: IPort[] = result?.data?.getAllPortsFromCache || [];

				const normalizedPorts = ports.reduce<IPortsState>(
					(acc, current) => {
						acc.byId[current.id] = current;
						acc.allIds.push(current.id);
						return acc;
					},
					{ byId: {}, allIds: [] }
				);

				return {
					ports: normalizedPorts,
				};
			},
			async getAllRequirements({ selectedDateRange: [startDate, endDate] }) {
				// console.warn("getAllRequirements called"); // debug

				const requirementsResult: GqlQueryOperationResult<
					typeof GET_REQUIREMENTS_BY_DATES
				> = await requirementsClient
					.query(GET_REQUIREMENTS_BY_DATES, {
						startDate: Math.floor(startDate.valueOf() / 1000),
						endDate: Math.floor(endDate.valueOf() / 1000),
					})
					.toPromise();

				const normalizedData = requirementsResult.data!.filterRequirementsByDates!.reduce<
					NormalizedData<OtherRequirement>
				>(
					(acc, current) => {
						acc.byId[current!.id] = current as OtherRequirement;
						acc.allIds.push(current!.id);
						return acc;
					},
					{ byId: {}, allIds: [] }
				);

				return normalizedData;
			},
			async getFleetSchedule({
				selectedDateRange: [startDate, endDate],
			}): Promise<Array<I_PlannedAndActualVesselScheduleTuple>> {
				mqttClient.publish(
					MqttService.GET_FLEET_TOPIC,
					Buffer.from(
						JSON.stringify({
							state: MqttService.GET_FLEET_IN_PROGRESS,
							user: userContext.getUserId(),
						})
					),
					{ qos: 1 }
				);
				const result = await getFleetSchedule(startDate, endDate);
				mqttClient.publish(
					MqttService.GET_FLEET_TOPIC,
					Buffer.from(
						JSON.stringify({
							state: MqttService.GET_FLEET_IDLE,
							user: userContext.getUserId(),
						})
					),
					{ qos: 1 }
				);

				return result;
			},
			getMissingScheduleRequirements: async (_: any, event: any) => {
				const { requirements: req, schedule } = _;
				const reqIds: string[] = req?.allIds || [];
				const scheduleReqIds = [
					...(schedule?.flatMap(
						({
							actualSchedule,
							plannedSchedule,
						}: {
							actualSchedule: I_ActualVesselSchedule;
							plannedSchedule: I_PlannedVesselSchedule;
						}) => {
							const reqIds: {
								actual: string[];
								planned: string[];
							} = {
								actual: [],
								planned: [],
							};

							if (actualSchedule?.requirements?.length) {
								reqIds.actual = actualSchedule.requirements.map((r) => r.requirementId);
							}

							if (plannedSchedule?.requirements?.length) {
								reqIds.planned = plannedSchedule.requirements.map((r) => r.requirementId);
							}

							return [...Array.from(new Set([...reqIds.actual, ...reqIds.planned]))];
						}
					) || []),
				];

				const missingRequirementIds: string[] = scheduleReqIds.filter((rId) => !reqIds.includes(rId));

				if (!missingRequirementIds.length) return { byId: {}, allIds: [] };

				const result = await vesselScheduleLifecycleClient
					.query(
						GET_REQUIREMENTS_BY_ID,
						{
							requirementIds: missingRequirementIds,
						},
						{ requestPolicy: "network-only" }
					)
					.toPromise();

				const requirements = result?.data?.requirements;
				if (!requirements) {
					throw new Error(
						`Loading missing schedule requirements failed: ${errorMessageFromGraphql(
							result.error?.message
						)}`
					);
				}

				const normalizedData = requirements!.reduce<NormalizedData<OtherRequirement>>(
					(acc, current) => {
						acc.byId[current!.id] = current as OtherRequirement;
						acc.allIds.push(current!.id);
						return acc;
					},
					{ byId: {}, allIds: [] }
				);

				return normalizedData;
			},
			getRequirementDetails: async (
				_,
				{ eventId: requirementId = "154b51b16de" }: EventClickedEvent
			): Promise<Requirement> => {
				const result = await requirementsClient
					.query(
						GET_REQUIREMENT_BY_ID,
						{
							requirementId,
						},
						{ requestPolicy: "network-only" }
					)
					.toPromise();

				const { data, error } = result;
				if (!data?.requirement)
					throw new Error(`Loading requirement details failed. ${errorMessageFromGraphql(error?.message)}`);

				return data.requirement;
			},
			getVesselDetails: async (_, { vesselId }: VesselClickedEvent): Promise<VesselWithQ88PRandUtOutput> => {
				const result = await vesselScheduleLifecycleClient
					.query(GET_VESSEL_BY_ID, {
						vesselId,
					})
					.toPromise();

				const vessel = result.data?.getVesselWithPRandUT;

				if (!vessel) throw new Error(errorMessageFromGraphql(result.error?.message));

				return vessel;
			},
			async calculate(): Promise<I_PlannedVesselSchedule[]> {
				const { data, error } = await vesselScheduleLifecycleClient
					.query(
						NEW_CALCULATE,
						{
							date: new Date(),
						},
						{ requestPolicy: "network-only" }
					)
					.toPromise();

				if (!data?.NEW_calculate)
					throw new Error(`Calculate failed: ${errorMessageFromGraphql(error?.message)}`);
				return data.NEW_calculate;
			},
			promotePlannedToActual,
			getProducts: async (): Promise<NormalizedData<Product>> => {
				const result = await requirementsClient
					.query(ALL_PRODUCTS, undefined, {
						requestPolicy: "network-only",
					})
					.toPromise();

				const products: Product[] = result?.data?.allProducts || [];

				const normalized: NormalizedData<Product> = {
					allIds: products.map((product) => product.id),
					byId: Object.fromEntries(products.map((product) => [product.id, product])),
				};

				return normalized;
			},
		},
	}
);

const service = interpret(fleetScheduleMachine).onTransition((state) => {
	// console.log("XSTATE:", state.value); // debug
});

service.start();

// export { fleetScheduleMachine };
