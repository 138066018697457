interface WindowWithConfig extends Window {
	ENV: {
		REACT_APP_SHOW_ROLE_SWITCH: boolean;

		REACT_APP_AUTH_PROVIDER: string;
		REACT_APP_PORTAL_AUTH_IDENTIFIER: string;
		REACT_APP_PORTAL_AUTH_CLIENT_ID: string;
		REACT_APP_PORTAL_AUTH_DOMAIN: string;
		AUTH_AUDIENCE: string;
		SILENT_LOGIN_CACHE_BREAKER: string;
		MQTT_ENDPOINT: string;

		FAST_SCHEDULER_URL: string;
		REQUIREMENTS_URL: string;
		REPORTING_URL: string;
		VESSEL_SCHEDULE_LIFECYCLE_URL: string;
		FLEET_OPTIMIZATION_URL: string;
		VESSELS_V2_URL: string;
	};
}

declare let window: WindowWithConfig;

export const Configuration = window.ENV;
