import React, { memo, useCallback, useMemo, useEffect, useState } from "react";
import moment from "moment";
import { useSelector, useService } from "@xstate/react";
import {
	Box,
	Button,
	ButtonType,
	DateInput,
	DateInputProps,
	Grid,
	Header,
	Meter,
	Text,
} from "grommet";
import { FormNext, FormPrevious } from "grommet-icons";
import { escapeRegExp, debounce } from "lodash";
import { Model } from "@bryntum/schedulerpro";
import { makeStyles, withStyles, createStyles } from "@material-ui/core/styles";
import GButton from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

import { FleetMachineContext, IFleetInterpreter } from "../App";

import { ScheduleFilter, SCHEDULE_FILTER_MAP } from "../machines/fleetMachine";
import { Requirement } from "../types/generated/q-vessel-schedule-lifecycle-v6.types";
import { useRoleManager } from "../hooks/useRoleManager";
import { toDateFormat } from "../constants";
import { useMqtt } from "../services/mqtt-service";
import { MqttService } from "../services";
import userContext from "../context/UserContext";
import Alert from "@material-ui/lab/Alert";
import { AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles({
	clickable: {
		cursor: "pointer",
	},
});

const useButtonStyles = makeStyles((theme) =>
	createStyles({
		root: {
			"& > *": {
				margin: theme.spacing(1),
			},
		},
		button: {
			background: "linear-gradient(45deg, #59B5D9 30%, #21cbf3 90%)",
			// boxShadow: "0 3px 5px 2px rgba(33, 203, 243, .30)",
		},
	})
);

interface Props {
	requirements: Requirement[];
	machineService: IFleetInterpreter;
}

type FilterButtonsProps = {
	readonly options: string[];
	readonly value: string;
	readonly onChange: (value: string) => void;
};

const FilterButtons = memo<FilterButtonsProps>(function FilterButtons({
	options,
	value,
	onChange,
}) {
	const classes = useButtonStyles();
	const [selected, setSelected] = React.useState(value || "All");
	const onClick = (_value: FilterButtonsProps["value"]) => {
		setSelected(_value);
		onChange(_value);
	};
	return (
		<div className={classes.root}>
			{options.map((option: string, key) => {
				return option === selected ? (
					<GButton
						key={key}
						variant="contained"
						onClick={() => onClick(option)}
						color="primary"
						className={classes.button}
					>
						{option}
					</GButton>
				) : (
					<GButton
						key={key}
						variant="contained"
						onClick={() => onClick(option)}
					>
						{option}
					</GButton>
				);
			})}
		</div>
	);
});

const SearchField = withStyles({
	root: {
		cursor: "wait",
		"& label.Mui-focused": {
			color: "#59B5D9",
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: "#d0d1d2",
		},
		"& .MuiOutlinedInput-root": {
			"&.Mui-disabled": {
				opacity: 0.65,
			},
			"& fieldset": {
				borderColor: "#d0d1d2",
			},
			"&:hover fieldset": {
				borderColor: "#feac31",
			},
			"&.Mui-focused fieldset": {
				borderColor: "#59B5D9",
			},
			"&.Mui-disabled fieldset": {
				borderColor: "#d0d1d2",
			},
		},
	},
})(TextField);

export const FleetPanelActions = memo<Props>(
	({ requirements, machineService }) => {
		const { clickable } = useStyles();
		const {
			client,
			cwfStatus,
			promotionStatus,
			calculationStatus,
		} = useMqtt();

		const [current] = useService(machineService);
		const needCalculateBeforePromote = current.context.needCalculateBeforePromote;

		const [hasCWFBeenUpdated, setHasCWFBeenUpdated] = useState(false);
		const cwfInProgress = useCallback(() => {
			if (cwfStatus === MqttService.CWF_IN_PRORFESS) {
				setHasCWFBeenUpdated(true);
				return true;
			}
		}, [cwfStatus]);
		// const isCWFInProgress = useMemo(() => cwfInProgress(), [cwfInProgress]);

		const isSomeonePromoting = useCallback(
			() => promotionStatus === MqttService.PROMOTE_IN_PRORFESS,
			[promotionStatus]
		);

		const isSomeoneCalculating = useCallback(
			() => calculationStatus === MqttService.CALCULATE_IN_PRORFESS,
			[calculationStatus]
		);

		const blockUserActions = useMemo(
			() =>
				isSomeonePromoting() ||
				cwfInProgress() ||
				isSomeoneCalculating() ||
				hasCWFBeenUpdated,
			[
				isSomeonePromoting,
				cwfInProgress,
				isSomeoneCalculating,
				hasCWFBeenUpdated,
			]
		);

		const fleetMachineService = React.useContext(FleetMachineContext);

		const selectedFilter = useSelector(
			fleetMachineService,
			({ context: { scheduleFilter } }) => scheduleFilter
		);
		const selectedDateRange = useSelector(
			fleetMachineService,
			({ context: { selectedDateRange } }) => selectedDateRange
		);
		const isPromotionInprogress = useSelector(
			fleetMachineService,
			({ matches }) => matches("idle.ready.promotionInProgress")
		);

		const isCalculateInprogress = useSelector(
			fleetMachineService,
			({ matches }) =>
				matches("idle.ready.calculatingGetFleet") ||
				matches("idle.ready.calculatingGetCalculate")
		);

		const promotionProgress = useSelector(
			fleetMachineService,
			({ context: { promotionProgress } }) => promotionProgress
		);
		const schedulerInstance = useSelector(
			fleetMachineService,
			({ context: { schedulerInstance } }) => schedulerInstance
		);

		const handleCalculateClick = React.useCallback<React.MouseEventHandler>(() => {
			fleetMachineService.send("calculate");

			client.publish(
				MqttService.FLEET_CALC_TOPIC,
				Buffer.from(
					JSON.stringify({
						state: MqttService.CALCULATE_IN_PRORFESS,
						user: userContext.getUserId(),
					})
				),
				{ qos: 1 }
			);
		}, [fleetMachineService, client]);

		const handlePromoteClick = React.useCallback<React.MouseEventHandler>(() => {
			fleetMachineService.send("promote");
			client.publish(
				MqttService.FLEET_TOPIC,
				Buffer.from(
					JSON.stringify({
						state: MqttService.PROMOTE_IN_PRORFESS,
						user: userContext.getUserId(),
					})
				),
				{ qos: 1 }
			);
		}, [fleetMachineService, client]);

		const handleDateChange = useCallback<
			NonNullable<DateInputProps["onChange"]>
		>(
			({ value }) => {
				if (!value[0] || !value[1]) return;
				let [startDate, endDate] = [
					moment(value[0]).startOf("day"),
					moment(value[1]).endOf("day"),
				];
				if (startDate.isSame(endDate, "day"))
					endDate = startDate.clone().add(1, "day").endOf("day");

				fleetMachineService.send({
					type: "dateRangeChange",
					dates: [
						startDate.toDate(), //new Date(value[0]),
						endDate.toDate(),
					],
				});
			},
			[fleetMachineService]
		);

		const scheduler = schedulerInstance!;
		const debauncedSearch = useCallback(
			(value: string) => {
				if (!value) {
					scheduler.eventStore.clearFilters();
					scheduler.resourceStore.clearFilters();
					return;
				}
				const re = new RegExp(escapeRegExp("" + value), "i");
				/** Matches the entered string for both requirement ID and action IDs within the requirement */
				const filterFn = ({
					requirementId,
					actions,
				}: Model & {
					actions: { id: string }[];
					requirementId?: string;
				}) => {
					const find = requirements?.find(
						(requirement: any) => requirement.id === requirementId
					) as Requirement;

					return (
						requirementId &&
						(re.test(`${requirementId}`.toLowerCase()) ||
							(find &&
								re.test(`${find.shipmentId}`.toLowerCase())))
					);
				};
				const matchingRecords = (scheduler.eventStore
					.allRecords as any[]).filter(filterFn);

				scheduler.eventStore.filter({
					replace: true,
					filters: filterFn,
				});
				scheduler.resourceStore.filter({
					replace: true,
					filters: ({ id }: Model) =>
						matchingRecords.some(
							({ resourceId }) => id === resourceId
						),
				});
			},
			[schedulerInstance, requirements] // eslint-disable-line react-hooks/exhaustive-deps
		);
		const handleSearch = debounce(debauncedSearch, 250);

		const dateValue = useMemo<string[]>(
			() => selectedDateRange.map((date) => date.toISOString()),
			[selectedDateRange]
		);

		const dateButtonProps = useMemo<ButtonType>(
			() => ({
				label: `${
					toDateFormat(selectedDateRange[0]) || "not selected"
				} - ${toDateFormat(selectedDateRange[1]) || "not selected"}`,
			}),
			[selectedDateRange]
		);

		const prevClick = useCallback<React.MouseEventHandler>(() => {
			fleetMachineService.send("DATE_RANGE_MOVE", { isForward: false });
		}, [fleetMachineService]);
		const nextClick = useCallback<React.MouseEventHandler>(() => {
			fleetMachineService.send("DATE_RANGE_MOVE", { isForward: true });
		}, [fleetMachineService]);

		const { can } = useRoleManager();
		const canPromoteOrCalculate =
			can("promote plan") || can("calculate plan");

		useEffect(() => {
			if (!isPromotionInprogress) {
				client.publish(
					MqttService.FLEET_TOPIC,
					Buffer.from(
						JSON.stringify({
							state: MqttService.PROMOTE_IDLE,
							user: userContext.getUserId(),
						})
					),
					{ qos: 1 }
				);
			}
		}, [isPromotionInprogress]); // eslint-disable-line react-hooks/exhaustive-deps

		useEffect(() => {
			if (!isCalculateInprogress) {
				client.publish(
					MqttService.FLEET_CALC_TOPIC,
					Buffer.from(
						JSON.stringify({
							state: MqttService.CALCULATE_IDLE,
							user: userContext.getUserId(),
						})
					),
					{ qos: 1 }
				);
			}
		}, [isCalculateInprogress]); // eslint-disable-line react-hooks/exhaustive-deps

		return (
			<Grid fill="vertical" style={{ borderBottom: "1px solid #F2F2F2" }}>
				<Header
					align="center"
					direction="row"
					justify="evenly"
					fill="horizontal"
				>
					{!isPromotionInprogress ? (
						<Box
							fill
							direction="row"
							align="center"
							justify="between"
							pad="small"
						>
							<Box align="center" direction="row" gap="medium">
								<FormPrevious
									className={clickable}
									onClick={prevClick}
								/>
								<DateInput
									value={dateValue}
									onChange={handleDateChange}
									buttonProps={dateButtonProps}
								/>
								<FormNext
									className={clickable}
									onClick={nextClick}
								/>
							</Box>

							<Box>
								<SearchField
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<SearchIcon
													style={{
														color: "#d0d1d2",
														fontSize: 34,
														marginLeft: -10,
													}}
												/>
											</InputAdornment>
										),
									}}
									onChange={(e) =>
										handleSearch(e.target.value)
									}
									disabled={!schedulerInstance}
									placeholder="Find requirements"
									variant="outlined"
									size="small"
								/>
							</Box>

							<Box direction="row" gap="medium">
								<FilterButtons
									options={["All", "Actual", "Planned"]}
									value={selectedFilter}
									onChange={(value: string) => {
										fleetMachineService.send(
											SCHEDULE_FILTER_MAP[
												value as ScheduleFilter
											]
										);
									}}
								/>
							</Box>

							{canPromoteOrCalculate && (
								<Box gap="small" direction="row">
									{can("promote plan") && !needCalculateBeforePromote && (
										<Button
											disabled={blockUserActions}
											label="Promote Plan"
											primary
											onClick={handlePromoteClick}
										/>
									)}
									{can("calculate plan") && (
										<Button
											//color="blue2"
											disabled={blockUserActions}
											primary
											label="Calculate New Plan"
											onClick={handleCalculateClick}
										/>
									)}
								</Box>
							)}
							{hasCWFBeenUpdated ? (
								<Alert severity="warning">
									<AlertTitle>CWF Updated</AlertTitle>
									Reload for latest data
								</Alert>
							) : (
								<Box></Box>
							)}
						</Box>
					) : (
						<Box pad="medium" align="center" fill>
							<Text size="small">
								Promoting new plan, this process takes
								aproximately 5 minutes to complete...
							</Text>
							<Meter
								color="brand"
								type="bar"
								value={3 + promotionProgress * 97}
							/>
						</Box>
					)}
				</Header>
			</Grid>
		);
	}
);
