import Fuse, { FuseOptions } from "fuse.js";
import { memoize } from "lodash";
import { createSelector } from "reselect";
import { PortDropdownOption } from "../components/forms/PortRestrictionForm";

import type { IState } from "../store/reducers";
import type { IPortsState } from "../store/reducers/ports";
import type { IPort } from "../store/sagas/loadPorts.saga";

export function portsSelector({ ports }: IState): IPortsState {
	return ports;
}

export function getPortsAsSelectOptions({
	ports,
}: IState): PortDropdownOption[] {
	return Object.values(ports.byId).map((port) => ({
		name: port.name,
		id: port.id,
	}));
}

const findPortFuse = memoize(function (
	ports: IPortsState
): Fuse<
	IPort,
	FuseOptions<IPort> & {
		includeScore: false;
		includeMatches: false;
	}
> {
	return new Fuse(
		ports.allIds.map((id) => ports.byId[id]),
		{
			caseSensitive: false,
			shouldSort: true,
			includeMatches: false,
			includeScore: false,
			getFn({ name }): string {
				return name;
			},
		}
	);
});
findPortFuse.cache = new WeakMap();

/** Returns the id of the */
export function findPortIdByName(
	ports: IPortsState,
	name?: string
): string | undefined {
	if (!name) return;
	name = name.toLowerCase();
	return ports.allIds.find(
		(id) => ports.byId[id].name.toLowerCase() === name
	);
}

export interface TerminalWithPort {
	terminal: NonNullable<IPort["terminals"]>[number];
	port: IPort;
}

export const allTerminals = memoize(function (
	ports: IPortsState
): TerminalWithPort[] {
	return ports.allIds.flatMap<TerminalWithPort>((id) => {
		const port = ports.byId[id];
		return (port.terminals || []).map<TerminalWithPort>((terminal) => ({
			port,
			terminal,
		}));
	});
});
allTerminals.cache = new WeakMap();

const findTerminalFuse = memoize(function (
	ports: IPortsState
): Fuse<
	TerminalWithPort,
	FuseOptions<TerminalWithPort> & {
		includeScore: false;
		includeMatches: false;
	}
> {
	return new Fuse(allTerminals(ports), {
		caseSensitive: false,
		shouldSort: true,
		includeMatches: false,
		includeScore: false,
		getFn({ terminal: { name } }: TerminalWithPort): string {
			return name ?? "";
		},
	});
});
findTerminalFuse.cache = new WeakMap();

export function findTerminalByName(
	ports: IPortsState,
	terminalName?: string
): TerminalWithPort | undefined {
	if (!terminalName) return;
	terminalName = terminalName.toLowerCase();
	return allTerminals(ports).find(
		({ terminal: { name } }) => name?.toLowerCase() === terminalName
	);
}

/** Finds the terminal with its port by terminal id */
export function findTerminalById(
	ports: IPortsState,
	terminalId: string
): TerminalWithPort | undefined {
	return allTerminals(ports).find(
		({ terminal: { id } }) => id === terminalId
	);
}

export const allTerminalIdsSelector = createSelector(portsSelector, (ports) =>
	allTerminals(ports).map(({ terminal: { id } }) => id)
);
