import {
	Control,
	Controller,
	useFieldArray,
	UseFormMethods,
} from "@maana-io/react-hook-form";
import {
	IconButton,
	Input,
	InputAdornment,
	makeStyles,
	styled,
	Typography,
} from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { capitalCase } from "capital-case";
import { GraphQLInputField } from "graphql";
import { Box, Button } from "grommet";
import React from "react";

import { RecursiveFormFields } from "./_RecursiveFormFields";
import { getSchemaBridgeForType } from "./GraphQLBridge";
import { findTypeOfField } from "./helpers/findTypeOfField";

type FieldArrayProps = {
	control: Control;
	register: UseFormMethods["register"];
	name: string;
	parentFieldName: string;
	schemaString: string;
	field: GraphQLInputField;
	newItemConstructor: any;
	typeOfArray: string;
	flat: boolean;
};

const useStyles = makeStyles({
	root: {
		minWidth: 275,
		display: "flex",
		flexWrap: "wrap",
	},
	bullet: {
		display: "inline-block",
		margin: "0 2px",
		transform: "scale(0.8)",
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginBottom: 12,
	},
});

const FieldWrapper = styled(Box)((props) => ({
	// margin: props.theme.spacing(1),
}));

export const FieldArray = ({
	control,
	register,
	name,
	parentFieldName,
	schemaString,
	field,
	newItemConstructor,
	typeOfArray,
	flat,
}: FieldArrayProps) => {
	const classes = useStyles();
	const { fields, append, remove } = useFieldArray({
		control,
		name,
	});

	// * save a reference to whether we're on the root node
	// * if we are: respect the `flat` property for nested objects (portal them)
	// * if we are not: do not flatten nested objects (do not portal them, nest them inside their parent obj)
	const isRoot = React.useMemo(() => parentFieldName === "root", [
		parentFieldName,
	]);

	if (typeof newItemConstructor !== "function") {
		throw new Error(
			`FieldArray.newItemConstructor must be provided with a constructor function for Kind: "${typeOfArray}"`
		);
	}

	return (
		<div>
			<Typography variant="h6">{capitalCase(name)}</Typography>
			<FieldWrapper className={classes.root}>
				{fields.map((_field, index) => {
					// ? this answers the question of "an array of _what_?"
					switch (typeof _field.value) {
						// * simple scalars
						case "number":
						case "string":
							return (
								<FieldWrapper key={_field.id || index}>
									<Controller
										name={`${parentFieldName}[${index}].value`}
										control={control}
										defaultValue={_field.value}
										render={({ onChange, value, ref }) => (
											<Input
												key={_field.id}
												ref={ref}
												type="text"
												value={value}
												onChange={onChange}
												endAdornment={
													<InputAdornment position="end">
														<IconButton
															onClick={() =>
																remove(index)
															}
														>
															<DeleteIcon />
														</IconButton>
													</InputAdornment>
												}
											/>
										)}
									/>
								</FieldWrapper>
							);

						// * objects and arrays
						case "undefined": // "object"
							const subTypeSchemaBridge = getSchemaBridgeForType(
								schemaString,
								// @ts-ignore
								findTypeOfField(field)
							);

							const subFieldNames = subTypeSchemaBridge.getSubfields();
							const pFieldName = `${parentFieldName}[${index}]`;

							return (
								<FieldWrapper key={_field.id || index}>
									<RecursiveFormFields
										flat={isRoot && flat}
										parentFieldName={pFieldName}
										fieldNames={subFieldNames}
										schemaBridge={subTypeSchemaBridge}
										schemaString={schemaString}
									/>
									<Box pad="medium">
										<Button
											label="Remove"
											color="blue1"
											icon={<DeleteIcon />}
											onClick={() => remove(index)}
										/>
									</Box>
								</FieldWrapper>
							);
						default:
							console.log(
								`FieldArray cannot render: [type: ${_field.type}][${parentFieldName}][${name}]: `,
								_field
							);
							return null;
					}
				})}
			</FieldWrapper>

			<section key="2">
				<Button
					color="blue1"
					style={{ marginBottom: 20 }}
					onClick={() => {
						append(newItemConstructor());
					}}
					label="Add"
				/>
			</section>
		</div>
	);
};
