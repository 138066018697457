import { useState, useEffect } from "react";

import { Text, Select } from "grommet";

type InputDropdownProps = {
	defaultValue: any;
	data: any[];
	onChange: (selection: any) => void;
	editable: boolean;
};

const InputDropdown: React.FC<InputDropdownProps> = ({
	defaultValue,
	data,
	onChange,
	editable,
}) => {
	const [datas, setDatas] = useState(data);

	useEffect(() => {
		setDatas(data);
	}, [data]);

	if (editable) {
		return (
			<div className={"fleet-req-dropdown hover-opacity"}>
				<Select
					options={datas}
					labelKey="name"
					valueLabel={defaultValue.name}
					defaultValue={defaultValue}
					onChange={({ option }) => onChange(option)}
					onSearch={(text) => {
						// The line below escapes regular expression special characters:
						// [ \ ^ $ . | ? * + ( )
						const escapedText = text.replace(
							/[-\\^$*+?.()|[\]{}]/g,
							"\\$&"
						);

						// Create the regular expression with modified value which
						// handles escaping special characters. Without escaping special
						// characters, errors will appear in the console
						const exp = new RegExp(escapedText, "i");
						setDatas(data.filter((o) => exp.test(o?.name)));
					}}
				/>
			</div>
		);
	}
	return <Text>{defaultValue?.name}</Text>;
};
export default InputDropdown;
