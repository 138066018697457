import produce from "immer";
import { NormalizedData } from "../../types/common";
import { Requirement } from "../../types/generated/q-fanar-requirements.types";
import {
	BATCH_PERSIST_FAILED,
	BATCH_PERSIST_SUCCESS,
	DELETE_REQUIREMENT,
	DELETE_REQUIREMENT_FAILURE,
	DELETE_REQUIREMENT_SUCCESS,
	DISMISS_DELETE_PROMPT,
	DISMISS_OVERRIDE_WARNING,
	DISMISS_PARSE_WARNING,
	DISMISS_REQ_WARNING,
	DISMISS_SNACKBAR,
	IRequirementReducerActions,
	LOAD_REQUIREMENTS,
	LOAD_REQUIREMENTS_SUCCESS,
	LOCK_REQUIREMENT_TO_SPOT_ACTION,
	LOCK_REQUIREMENT_TO_VESSEL_ACTION,
	PERSIST_BATCH_REQUIREMENT_LIST,
	PERSIST_REQUIREMENT,
	PERSIST_REQUIREMENT_FAILURE,
	PERSIST_REQUIREMENT_SUCCESS,
	PROMPT_BEFORE_DELETE_REQUIREMENT,
	PROVIDE_REQUIREMENT_CSV,
	REMOVE_A_BATCH_REQUIREMENT,
	UNLOCK_REQUIREMENT_ACTION,
	UPDATE_A_BATCH_REQUIREMENT,
	UPDATE_REQUIREMENT,
	UPDATE_REQUIREMENT_FAILED,
	UPDATE_REQUIREMENT_SUCCESS,
	RECONSIGN_REQUIREMENT,
	RECONSIGN_REQUIREMENT_FAILED,
	RECONSIGN_REQUIREMENT_SUCCESS,
} from "../actions/requirements.actions";
import { ParsedRequirements, csvToRequirements } from "./requirements/csvToRequirements";

export interface IRequirementReducerState extends NormalizedData<Requirement> {
	loadingState: {
		requirements?: boolean;
		deletion?: boolean;
		persistence?: boolean;
		updating?: boolean;
	};
	/** null when batch edit is not in progress */
	batchUploadState: ParsedRequirements | null;
	errorMessage?: string;
	/** Before proceeding with deletion - a confirm dialog would be shown */
	requirementIdToBeDeleted?: string;
	/** To display vessel name */
	vesselById: Record<string, string>;
}

const requirementReducerInitialState: IRequirementReducerState = {
	byId: {},
	allIds: [],
	vesselById: {},
	/** Either of the keys are true while some of the actions are in progress */
	loadingState: {
		requirements: false,
		deletion: false,
		persistence: false,
		updating: false,
	},
	batchUploadState: null,
};

export function requirementReducer(
	state = requirementReducerInitialState,
	action: IRequirementReducerActions
): IRequirementReducerState {
	switch (action.type) {
		case LOAD_REQUIREMENTS_SUCCESS:
			return produce(state, (draftState) => {
				draftState.allIds = action.payload.allIds;
				draftState.byId = action.payload.byId;
				draftState.vesselById = action.payload.vesselById;
				draftState.loadingState.requirements = false;
				draftState.errorMessage = undefined;
			});

		case UPDATE_REQUIREMENT:
			return produce(state, (draftState) => {
				console.log("updating");
				draftState.loadingState.updating = true;
				draftState.errorMessage = undefined;
			});
		case UPDATE_REQUIREMENT_FAILED:
			return produce(state, (draftState) => {
				console.log("update failed");
				draftState.loadingState.updating = false;
				draftState.errorMessage = `Requirement update failed: ${action.payload.message}`;
			});
		//handle update success here
		case UPDATE_REQUIREMENT_SUCCESS:
			return produce(state, (draftState) => {
				console.log("update succeeded");
				console.log(action.payload.updatedRequirement.id);

				const requirement = draftState.byId[action.payload.updatedRequirement.id];
				requirement.id = action.payload.updatedRequirement.id;
				requirement.shipmentId = action.payload.updatedRequirement.shipmentId;

				draftState.loadingState.updating = false;
				draftState.errorMessage = undefined;
			});

		case RECONSIGN_REQUIREMENT:
			return produce(state, (draftState) => {
				console.log("reconsigning");
				draftState.loadingState.updating = true;
				draftState.errorMessage = undefined;
			});
		case RECONSIGN_REQUIREMENT_FAILED:
			return produce(state, (draftState) => {
				console.log("reconsign failed");
				draftState.loadingState.updating = false;
				draftState.errorMessage = `Requirement reconsign failed: ${action.payload.message}`;
			});
		//handle reconsign success here
		case RECONSIGN_REQUIREMENT_SUCCESS:
			return produce(state, (draftState) => {
				console.log("reconsign succeeded");
				console.log(action.payload);

				// TODO!!! update the existing requirement here after successful reconsign
				// see PERSIST_REQUIREMENT_SUCCESS

				draftState.loadingState.updating = false;
				draftState.errorMessage = undefined;
			});

		case PERSIST_REQUIREMENT:
			return produce(state, (draftState) => {
				draftState.loadingState.persistence = true;
				draftState.errorMessage = undefined;
			});
		case PERSIST_REQUIREMENT_SUCCESS:
			return produce(state, (draftState) => {
				draftState.byId[action.payload.id] = action.payload;
				if (!draftState.allIds.includes(action.payload.id)) {
					draftState.allIds.push(action.payload.id);
				}
				draftState.loadingState.persistence = false;
				draftState.errorMessage = undefined;
			});
		case PERSIST_REQUIREMENT_FAILURE:
			return produce(state, (draftState) => {
				draftState.loadingState.persistence = false;
				draftState.errorMessage = `Requirement persistence failed: ${action.payload.message}`;
			});
		case PROMPT_BEFORE_DELETE_REQUIREMENT:
			return produce(state, (draftState) => {
				draftState.requirementIdToBeDeleted = action.payload;
				draftState.errorMessage = undefined;
			});
		case DISMISS_DELETE_PROMPT:
			return produce(state, (draftState) => {
				draftState.requirementIdToBeDeleted = undefined;
			});
		case DELETE_REQUIREMENT:
			return produce(state, (draftState) => {
				draftState.loadingState.deletion = true;
				draftState.requirementIdToBeDeleted = undefined;
				draftState.errorMessage = undefined;
			});
		case DELETE_REQUIREMENT_FAILURE:
			return produce(state, (draftState) => {
				draftState.loadingState.deletion = false;
				draftState.errorMessage = `Deletion failed: ${action.payload}`;
			});
		case DELETE_REQUIREMENT_SUCCESS:
			return produce(state, (draftState) => {
				delete draftState.byId[action.payload];
				draftState.allIds.splice(draftState.allIds.indexOf(action.payload), 1);
				draftState.loadingState.deletion = false;
				draftState.errorMessage = undefined;
			});
		case LOAD_REQUIREMENTS:
			return produce(state, (draftState) => {
				draftState.loadingState.requirements = true;
				draftState.errorMessage = undefined;
			});
		case PROVIDE_REQUIREMENT_CSV:
			const existingRequirements: Requirement[] = state?.allIds?.map((rId) => state?.byId[rId]);
			return produce(state, (draftState) => {
				draftState.batchUploadState = csvToRequirements(
					action.payload.csv,
					action.payload.creator,
					action.payload.ports,
					action.payload.products,
					existingRequirements
				);
				draftState.errorMessage = undefined;
			});
		case DISMISS_PARSE_WARNING:
			return produce(state, (draftState) => {
				draftState.batchUploadState?.formattingIssues.splice(action.payload, 1);
			});
		case REMOVE_A_BATCH_REQUIREMENT:
			return produce(state, (draftState) => {
				draftState.batchUploadState?.requirements.splice(action.payload, 1);
			});
		case UPDATE_A_BATCH_REQUIREMENT:
			return produce(state, (draftState) => {
				if (draftState.batchUploadState) {
					draftState.batchUploadState.requirements[action.payload.id].item = action.payload.requirement;
				}
			});
		case DISMISS_REQ_WARNING:
			return produce(state, (draftState) => {
				if (draftState.batchUploadState) {
					draftState.batchUploadState.requirements[action.payload.requirementId].warnings.splice(
						action.payload.warningId,
						1
					);
				}
			});
		case PERSIST_BATCH_REQUIREMENT_LIST:
			return produce(state, (draftState) => {
				if (draftState.batchUploadState) {
					draftState.batchUploadState.isSubmitting = true;
					draftState.errorMessage = undefined;
				}
			});
		case BATCH_PERSIST_SUCCESS:
			return produce(state, (draftState) => {
				// Add the persisted requirements to list of requirements
				if (state.batchUploadState) {
					for (const { item } of state.batchUploadState.requirements) {
						if (!draftState.allIds.includes(item.id)) {
							draftState.allIds.push(item.id);
						}
						draftState.byId[item.id] = item;
					}
				}
				draftState.batchUploadState = null;
				draftState.errorMessage = undefined;
			});
		case BATCH_PERSIST_FAILED:
			return produce(state, (draftState) => {
				if (draftState.batchUploadState !== null) {
					draftState.batchUploadState.isSubmitting = false;
					draftState.batchUploadState.formattingIssues.push(action.payload);
					draftState.errorMessage = action.payload;
				}
			});
		case LOCK_REQUIREMENT_TO_VESSEL_ACTION:
			return produce(state, (draftState) => {
				const req = draftState.byId[action.payload.requirementID];
				if (!req) return;
				req.locked = action.payload.lock?.toString();
			});
		case LOCK_REQUIREMENT_TO_SPOT_ACTION:
			return produce(state, (draftState) => {
				const req = draftState.byId[action.payload.requirementID];
				if (!req) return;
				req.locked = action.payload.lock?.toString();
			});
		case UNLOCK_REQUIREMENT_ACTION:
			return produce(state, (draftState) => {
				const req = draftState.byId[action.payload.requirementID];
				console.log(req);
				if (!req) return;
				req.locked = action.payload.lock?.toString();
			});
		case DISMISS_SNACKBAR:
			return produce(state, (draftState) => {
				draftState.errorMessage = undefined;
			});
		case DISMISS_OVERRIDE_WARNING:
			return produce(state, (draftState) => {
				draftState.batchUploadState?.overrides?.splice(0, draftState.batchUploadState?.overrides?.length);
			});
		default:
			return state;
	}
}
