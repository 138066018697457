import { createClient } from "urql";

import UserContext from "../context/UserContext";
import { Configuration } from "../util/config";
import { exchangesWithRetry } from "./utils";

const REQUIREMENTS_CLIENT_URL = Configuration.REQUIREMENTS_URL;

export const requirementsClient = createClient({
	url: REQUIREMENTS_CLIENT_URL,
	fetchOptions: () => ({ headers: UserContext.getAuthHeader() }),
	exchanges: exchangesWithRetry,
});
