import { push } from "connected-react-router/immutable";
import { Anchor, Nav, Text, ThemeContext } from "grommet";
import React from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { useRoleManager } from "../../hooks/useRoleManager";

import { routeConfig } from "../../routes";

export function Navigation() {
	const location = useLocation();
	const dispatch = useDispatch();
	const { can, hasRoles } = useRoleManager();

	const getNavClickHandler = (path: string) => () => dispatch(push(path));

	return (
		<ThemeContext.Consumer>
			{(theme) => {
				return (
					<Nav
						align="center"
						justify="center"
						flex={false}
						direction="row"
					>
						{routeConfig
							.filter(
								(route) =>
									// TODO: Refactor passing "isMenuItem" parameter
									route.name.indexOf("Editor") === -1 &&
									route.name.indexOf("Report") === -1 &&
									route.name.indexOf("Scheduler") === -1 &&
									route.name.indexOf("Debug") === -1 &&
									route.name.indexOf(
										"OperationalParameters"
									) &&
									route.name.indexOf("UserActivity") === -1 &&
									route.name.indexOf("404") === -1
							)
							.filter((route) =>
								("access" in route && !can(route.access)) ||
								("roles" in route && hasRoles(route.roles))
									? false
									: true
							)
							.map((route) => (
								<Anchor
									onClick={getNavClickHandler(
										route.link ?? route.path
									)}
									key={route.path}
									color={
										location.pathname.includes(
											route.path
										) ||
										(route.link &&
											location.pathname.includes(
												route.link
											))
											? "blue1"
											: "black1"
									}
								>
									<Text>{route.name}</Text>
								</Anchor>
							))}
					</Nav>
				);
			}}
		</ThemeContext.Consumer>
	);
}
