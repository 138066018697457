import { store } from "../../store/index";
import {
	findTerminalById,
	portsSelector,
} from "../../selectors/ports.selectors";
import { useSelector } from "react-redux";
import { useCallback } from "react";

export function portIdToName(id?: string | null): string | undefined | null {
	if (!id) return id;
	const ports = portsSelector(store.getState());
	return ports?.byId[id]?.name || id;
	/*const port = ports?.byId[id];

	var name = port?.name;

	if (port && port.terminals) {
		for (let i = 0; i < port.terminals.length; i++) {
			const terminal = port.terminals[i];
			if (terminal.name) {
				name = terminal.name;
				break;
			}
		}
	}

	return name || id;
	*/
}

export function usePortIdToName(): (id?: string | null) => string | undefined {
	const ports = useSelector(portsSelector);
	return useCallback(
		(id?: string | null) => (id ? ports.byId[id]?.name ?? id : undefined),
		[ports]
	);
}

export function useTerminalIdToName(): (
	terminalId?: string | null
) => string | undefined {
	const ports = useSelector(portsSelector);

	return useCallback(
		(terminalId?: string | null): string | undefined => {
			if (!terminalId) return;

			const terminal = findTerminalById(ports, terminalId);

			if (!terminal) return;
			return terminal?.terminal?.name ?? undefined;
		},
		[ports]
	);
}
