import { gql } from "urql";
// import { Scalars } from "../types/generated.types";
import type {
	Query,
	Mutation,
	MutationValidateAndPersistRequirementsArgs,
} from "../types/generated/q-fanar-requirements.types";
import {
	Mutation as VSLMutation,
	MutationRemoveARequirementArgs,
} from "../types/generated/q-vessel-schedule-lifecycle-v6.types";
import { allActualScheduleFields } from "./schedules";

const allRequirementFields = `
id
shipmentId
longs {
	id
	port
	product {
		id
		cleanStatus
	}
	terminal
	startDate
	endDate
	productQuantity
}
shorts {
	id
	port
	product {
		id
		cleanStatus
	}
	terminal
	startDate
	endDate
	productQuantity
}
estimatedCostToSpotCharter
locked
createdBy
createdAt
status {
	id
}
`;

export const GET_MINIMAL_REQUIREMENT = gql<Pick<Query, "requirement">, { id: string }>`
	query getRequirmentById($requirementId: ID!) {
		requirement(id: $requirementId) {
			${allRequirementFields}
		}
	}
`;

//update this to filterRequirementsByDates
export const GET_REQUIREMENTS = gql<Pick<Query, "filterCompleteRequirements">>`
	query allRequirements {
		filterCompleteRequirements {
			${allRequirementFields}
		}
	}
`;

export const GET_REQUIREMENTS_BY_DATES = gql<Pick<Query, "filterRequirementsByDates">>`
	query allRequirements($startDate: Float!, $endDate: Float!) {
		filterRequirementsByDates(startDate: $startDate, endDate: $endDate) {
			${allRequirementFields}
		}
	}
`;

export const GET_REQUIREMENT_BY_ID = gql<Pick<Query, "requirement">>`
	query getRequirmentById($requirementId: ID!) {
		requirement(id: $requirementId) {
			${allRequirementFields}
		}
	}
`;

export const GET_REQUIREMENTS_BY_ID = gql<Pick<Query, "requirements">>`
	query getRequirmentsById($requirementIds: [ID!]!) {
		requirements(ids: $requirementIds) {
			${allRequirementFields}
		}
	}
`;

export const PERSIST_REQUIREMENTS = gql<
	Pick<Mutation, "NEW_validateAndPersistRequirements">,
	MutationValidateAndPersistRequirementsArgs
>`
	mutation($requirements: [RequirementAsInput!]!) {
		NEW_validateAndPersistRequirements(requirements: $requirements) {
			id
			newSchedule {
				${allActualScheduleFields}
			}
		}
	}
`;

export const LOCK_REQUIREMENT_TO_VESSEL = gql`
	mutation($vesselID: ID!, $requirementID: ID!) {
		lockRequirementToVessel(vesselID: $vesselID, requirementID: $requirementID)
	}
`;

export const UPDATE_SHIPMENT_ID = gql`
	mutation($updatedRequirement: RequirementAsInput!, $actualScheduleID: ID, $plannedScheduleID: ID) {
		updateShipmentIDOnRequirementAndUpdateSchedule(
			actualScheduleID: $actualScheduleID
			plannedScheduleID: $plannedScheduleID
			updatedRequirement: $updatedRequirement
		) {
			id
			newVesselId
			shipmentId
			actualScheduleID
			plannedScheduleID
		}
	}
`;

export const RECONSIGN_REQUIREMENT_GQL = gql`
	mutation($modifiedRequirementToReconsign: RequirementAsInput!, $vesselID: ID!) {
		reconsignRequirement(vesselID: $vesselID, modifiedRequirementToReconsign: $modifiedRequirementToReconsign) {
			id
		}
	}
`;

export const LOCK_REQUIREMENT_TO_SPOT = gql`
	mutation($requirementID: ID!) {
		lockRequirementToSpot(requirementID: $requirementID)
	}
`;

export const UNLOCK_REQUIREMENT = gql`
	mutation($requirementID: ID!) {
		unlockRequirement(requirementID: $requirementID)
	}
`;

export const DELETE_REQUIREMENT_MUTATION = gql<Pick<VSLMutation, "removeARequirement">, MutationRemoveARequirementArgs>`
	mutation($requirementID: ID!) {
		removeARequirement(requirementID: $requirementID)
	}
`;

export const ALL_PRODUCTS = gql<Pick<Query, "allProducts">>`
	query {
		allProducts {
			id
			cleanStatus
		}
	}
`;
