import React from "react";
import AnchorIco from "../../images/anchor_ico.svg";

type FleetActionWaitingProps = {
	actionStatusClass?: string;
	id?: string;
	width?: number;
	left?: number;
	port?: string | null | undefined;
	isActual?: boolean;
	isNested?: boolean;
};
const FleetActionWaiting: React.FC<FleetActionWaitingProps> = ({
	actionStatusClass,
	id,
	width = "auto",
	left,
	port,
	isActual,
	isNested = true,
}) => {
	return (
		<div
			className={`${isNested ? "nested" : ""} ${actionStatusClass}`}
			data-id={id}
			style={{
				width,
				height: 60,
				backgroundColor: "transparent",
				transform: `translate(${left}px, 0)`,
				zIndex: 3,
				fontSize: 14,
				color: isActual ? "#C0C0C0" : "#e6e6e6",
				fontWeight: 600,
			}}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "flex-start",
					width: "100%",
					height: "100%",
				}}
			>
				<img
					className={"filter-gray"}
					style={{
						margin: "0 4px 0 4px",
						opacity: isActual ? 1 : 0.4,
					}}
					height={24}
					width={24}
					src={AnchorIco}
					alt="fleet-ico"
				/>
				<div
					style={{
						height: "100%",
						flexGrow: 1,
						backgroundColor: "#fff",
						border: `2px dashed ${
							isActual ? "#C0C0C0" : "#e6e6e6"
						}`,
						borderRadius: 6,
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					{`Waiting at ${port}`}
				</div>
			</div>
			<div className="status-indicator" />
		</div>
	);
};

export default FleetActionWaiting;
