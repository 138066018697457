import { gql } from "urql";
import type {
	ConstantsAsInput,
	Query,
	QueryGetFleetScheduleArgs,
	QueryAssignRequirementFromVesselToVesselArgs,
	QueryAssignRequirementFromVesselToSpotArgs,
	QueryNew_CalculateArgs,
	QueryAssignRequirementFromSpotToVesselArgs,
	QueryComputeFleetScheduleMetricsArgs,
	MutationPromotePlannedVesselSchedulesToActualsArgs,
	MutationFinishSpotRequirementArgs,
	Mutation,
	QueryGetRequirementVesselMapArgs,
} from "../types/generated/q-vessel-schedule-lifecycle-v6.types";

const allPlannedScheduleFields = `#graphql
	id
	requirements {
		id
		requirementId
		vesselId
		plannedVesselActions {
			id
			vesselAction {
				id
				vesselId
				actionType {
					id
				}
			}
			estimatedCost
			estimatedDuration
			optimalEndState {
				id
				vesselActionId
				logDate
				port
				fuelRemaining
			}
			estimatedStartDate
			estimatedEndDate
			originalRequirementId
			createdAt
			createdBy
			plannedVesselScheduleId
			speed
		}
		startDate
		endDate
		isLocked
	}
	plannedStartDate
	plannedEndDate
	vesselId
	isSpot
	createdAt
	createdBy
`;

export const allActualScheduleFields = `#graphql
	id
	actualStartDate
	actualEndDate
	vesselId
	isSpot
	createdAt
	createdBy
	requirements {
		id
		requirementId
		vesselId
		actualVesselActions {
			id
			plannedAction {
				id
				vesselAction {
					id
					vesselId
					actionType {
						id
					}
				}
				estimatedCost
				estimatedDuration
				optimalEndState {
					id
					vesselActionId
					logDate
					port
					fuelRemaining
				}
				estimatedStartDate
				estimatedEndDate
				originalRequirementId
				createdAt
				createdBy
				plannedVesselScheduleId
				speed
			}
			endState {
				id
				vesselActionId
				logDate
				port
				fuelRemaining
			}
			actualCost
			actualDuration
			scheduledRequirementId
			vesselAction {
				id
				vesselId
				actionType {
					id
				}
			}
			actualStartDate
			actualEndDate
			actionStatus {
				id
			}
			actualVesselScheduleId
			vesselId
			previousActualVesselActionId
			speed
		}
		startDate
		endDate
		isLocked
	}
	plannedVesselScheduleId
`;

export const GET_SCHEDULE_B = gql<
	Pick<Query, "getFleetSchedule">,
	QueryGetFleetScheduleArgs
>`
	query getFleetSchedule($startDate: Float, $endDate: Float) {
		getFleetSchedule(startDate: $startDate, endDate: $endDate) {
			id
			plannedSchedule {
				${allPlannedScheduleFields}
			}
			actualSchedule {
				${allActualScheduleFields}
			}
		}
	}
`;

export const ASSIGN_REQUIREMENT_FROM_VESSEL_TO_VESSEL = gql<
	Pick<Query, "assignRequirementFromVesselToVessel">,
	QueryAssignRequirementFromVesselToVesselArgs
>`
	query assignRequirementFromVesselToVessel(
		$targetVessel: VesselWithQ88PRandUTAsInput!
		$requirementsInPlannedTarget: [RequirementAsInput!]!
		$requirementToReAssign: RequirementAsInput!
		$fromVessel: VesselWithQ88PRandUTAsInput!
		$requirementsInPlannedFrom: [RequirementAsInput!]!
		$constants: ConstantsAsInput
	) {
		assignRequirementFromVesselToVessel(
			targetVessel: $targetVessel
			requirementsInPlannedTarget: $requirementsInPlannedTarget
			requirementToReAssign: $requirementToReAssign
			fromVessel: $fromVessel
			requirementsInPlannedFrom: $requirementsInPlannedFrom
			constants: $constants
		) {
			${allPlannedScheduleFields}
		}
	}
`;

export const ASSIGN_REQUIREMENT_FROM_VESSEL_TO_SPOT = gql<
	Pick<Query, "assignRequirementFromVesselToSpot">,
	QueryAssignRequirementFromVesselToSpotArgs
>`
	query assignRequirementFromVesselToSpot(
		$fromVessel: VesselWithQ88PRandUTAsInput!
		$requirementsInPlannedSchedule: [RequirementAsInput!]!
		$requirementToReAssign: RequirementAsInput!
		$constants: ConstantsAsInput!
	) {
		assignRequirementFromVesselToSpot(
			fromVessel: $fromVessel
			requirementsInPlannedSchedule: $requirementsInPlannedSchedule
			requirementToReAssign: $requirementToReAssign
			constants: $constants
		) {
			${allPlannedScheduleFields}
		}
	}
`;

export const ASSIGN_REQUIREMENT_FROM_SPOT_TO_VESSEL = gql<
	Pick<Query, "assignRequirementFromSpotToVessel">,
	QueryAssignRequirementFromSpotToVesselArgs
>`
	query assignRequirementFromSpotToVessel(
		$targetVessel: VesselWithQ88PRandUTAsInput!
		$requirementsInPlannedSchedule: [RequirementAsInput!]!
		$requirementToReAssign: RequirementAsInput!
		$constants: ConstantsAsInput!
	) {
		assignRequirementFromSpotToVessel(
			targetVessel: $targetVessel
			requirementsInPlannedSchedule: $requirementsInPlannedSchedule
			requirementToReAssign: $requirementToReAssign
			constants: $constants
		) {
			${allPlannedScheduleFields}
		}
	}
`;

export const GET_DEFAULT_CONSTANTS = gql<{ constants: ConstantsAsInput }>`
	query constants {
		constants(id: "defaults") {
			id
			defaultFuelPrice
			defaultDieselPrice
			refuelThreshold
			criticalRefuelThreshold
			operationalOverhead
		}
	}
`;

export const NEW_CALCULATE = gql<
	Pick<Query, "NEW_calculate">,
	QueryNew_CalculateArgs
>`
	query($date: DateTime!) {
		NEW_calculate(date: $date) {
			${allPlannedScheduleFields}
		}
	}
`;

export const PROMOTE_MUTATION = gql<
	Pick<Mutation, "promotePlannedVesselSchedulesToActuals">,
	MutationPromotePlannedVesselSchedulesToActualsArgs
>`
	mutation promotePlannedVesselSchedulesToActuals(
		$iPlannedAndActualScheduleTuples: [I_PlannedAndActualVesselScheduleTupleAsInput!]!
	) {
		promotePlannedVesselSchedulesToActuals(
			iPlannedAndActualScheduleTuples: $iPlannedAndActualScheduleTuples
		) {
			newSchedule {
				${allActualScheduleFields}
			}
		}
	}
`;

export const FLEET_SCHEDULE_METRICS = gql<
	Pick<Query, "computeFleetScheduleMetrics">,
	QueryComputeFleetScheduleMetricsArgs
>`
	query computeFleetScheduleMetrics(
		$plannedAndActualSchedules: [I_PlannedAndActualVesselScheduleTupleAsInput!]!
		$startDate: Float!
		$endDate: Float!
	) {
		computeFleetScheduleMetrics(
			plannedAndActualSchedules: $plannedAndActualSchedules
			startDate: $startDate
			endDate: $endDate
		) {
			id
			plannedMetrics {
				id
				id
				vesselsMetrics {
					id
					vesselId
					metrics {
						id
						totalCost
						utilization
						totalVolume
						costPerBBL
					}
				}
				metrics {
					id
					totalCost
					utilization
					totalVolume
					costPerBBL
				}
			}
			actualMetrics {
				id
				vesselsMetrics {
					id
					vesselId
					metrics {
						id
						totalCost
						utilization
						totalVolume
						costPerBBL
					}
				}
				metrics {
					id
					totalCost
					utilization
					totalVolume
					costPerBBL
				}
			}
		}
	}
`;

export const FINISH_SPOT_SCHEDULE = gql<
	Pick<Mutation, "finishSpotRequirement">,
	MutationFinishSpotRequirementArgs
>`
	mutation($requirementId: ID!) {
		finishSpotRequirement(requirementId: $requirementId)
	}
`;

export const GET_REQUIREMENT_VESSEL_MAP = gql<
	Pick<Query, "getRequirementVesselMap">,
	QueryGetRequirementVesselMapArgs
>`
	query getRequirementVesselMap($requirementIDs: [ID!]!) {
		getRequirementVesselMap(requirementIDs: $requirementIDs) {
			id
			vesselId
		}
	}
`;
