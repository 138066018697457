import React from "react";
import { Heading, Main, Grid, Box } from "grommet";

import { FleetReportEmailRecpientsEditor } from "../components/FleetReportEmailRecpientsEditor";
import { PerformanceReportEmailRecpientsEditor } from "../components/PerformanceReportEmailRecpientsEditor";

export const ReportRecipientsPage: React.FC = () => {
	return (
		<Main pad="large">
			<Heading level="2">Manage Report Recipients</Heading>

			<Grid
				columns={{
					count: 2,
					size: "auto",
				}}
				gap="small"
			>
				<Box border={{ color: "blue1" }} pad="small">
					<Heading level="3">Fleet Report Recipients</Heading>
					<Box pad="small">
						<FleetReportEmailRecpientsEditor />
					</Box>
				</Box>
				<Box border={{ color: "blue1" }} pad="small">
					<Heading level="3">Performance Report Recipients</Heading>
					<Box pad="small">
						<PerformanceReportEmailRecpientsEditor />
					</Box>
				</Box>
			</Grid>
		</Main>
	);
};
