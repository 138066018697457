import {
	I_ActualScheduledRequirement,
	I_PlannedAndActualVesselScheduleTupleOutput,
	I_PlannedScheduledRequirement,
} from "../types/generated/q-vessel-schedule-lifecycle-v6.types";
type ProgressState = "Not Started" | "In Progress" | "Complete";
const plannedRequirementDateRange = (plannedRequirement: I_PlannedScheduledRequirement) => {
	const { endTime, startTime } = plannedRequirement.plannedVesselActions.reduce<any>(
		(acc, act) => {
			return {
				endTime: Math.max(acc.endTime, act.estimatedEndDate),
				startTime: Math.min(acc.startTime, act.estimatedStartDate),
			};
		},
		{ endTime: 0, startTime: 99999999999 }
	);
	const startDate = new Date(startTime * 1000);
	const endDate = new Date(endTime * 1000);
	return `(${startDate.toISOString().substring(5, 10)}..${endDate.toISOString().substring(5, 10)})`;
};
const actualRequirementDateRange = (actualRequirement: I_ActualScheduledRequirement) => {
	const { endTime, startTime, plannedEndTime, plannedStartTime } = actualRequirement.actualVesselActions.reduce<any>(
		(acc, act) => {
			return {
				endTime: Math.max(acc.endTime, act.actualEndDate),
				startTime: Math.min(acc.startTime, act.actualStartDate),
				plannedEndTime: act.plannedAction?.estimatedEndDate
					? Math.max(acc.plannedEndTime, act.plannedAction.estimatedEndDate)
					: acc.plannedEndTime,
				plannedStartTime: act.plannedAction?.estimatedStartDate
					? Math.min(acc.plannedStartTime, act.plannedAction.estimatedStartDate)
					: acc.plannedStartTime,
			};
		},
		{
			endTime: 0,
			startTime: 99999999999,
			plannedEndTime: 0,
			plannedStartTime: 99999999999,
		}
	);
	const startDate = new Date(startTime * 1000);
	const endDate = new Date(endTime * 1000);
	const plannedStartDate = new Date(plannedStartTime * 1000);
	const plannedEndDate = new Date(plannedEndTime * 1000);
	return `(${startDate.toISOString().substring(5, 10)}..${endDate
		.toISOString()
		.substring(5, 10)}, est. ${plannedStartDate
		.toISOString()
		.substring(5, 10)}..${plannedEndDate.toISOString().substring(5, 10)})`;
};
const actualRequirementProgressState = (actualRequirement: I_ActualScheduledRequirement): ProgressState => {
	const actionCount = actualRequirement.actualVesselActions.length;
	const actionStateCounts = actualRequirement.actualVesselActions.reduce<number[]>(
		(acc, action) => {
			switch (action.actionStatus.id) {
				case "Complete":
					acc[0]++;
					break;
				case "In Progress":
					acc[1]++;
					break;
				case "Not Started":
					acc[2]++;
					break;
				default:
					acc[3]++;
					break;
			}
			return acc;
		},
		[0, 0, 0, 0]
	);
	let state: ProgressState;
	if (actionStateCounts[0] === actionCount) state = "Complete";
	else if (actionStateCounts[2] === actionCount) state = "Not Started";
	else state = "In Progress";
	return state;
};
const compareStrings = (a: string, b: string) => (a === b ? 0 : a > b ? 1 : -1);
const requirementMismatchLog = (schedule: I_PlannedAndActualVesselScheduleTupleOutput, message: string) => {
	const actualSchedule = schedule.actualSchedule;
	const plannedSchedule = schedule.plannedSchedule;
	const actualScheduleRequirements = actualSchedule?.requirements;
	const plannedScheduleRequirements = plannedSchedule.requirements;

	console.log("----------------------------------------------");
	console.log(`${message} for vessel ${plannedSchedule.vesselId}`);
	console.log("  Planned schedule requirements:");
	plannedScheduleRequirements.forEach((r) => {
		console.log(`    ${r.requirementId}, ${r.plannedVesselActions.length}: ${plannedRequirementDateRange(r)}`);
	});
	console.log("  Actual schedule requirements:");
	actualScheduleRequirements?.forEach((r) => {
		console.log(
			`    ${r.requirementId}, ${r.actualVesselActions.length}: ${actualRequirementDateRange(
				r
			)} (${actualRequirementProgressState(r)}) `
		);
	});
};

export const simplifySchedules = (
	full: I_PlannedAndActualVesselScheduleTupleOutput[]
): I_PlannedAndActualVesselScheduleTupleOutput[] => {
	const ss: I_PlannedAndActualVesselScheduleTupleOutput[] = JSON.parse(JSON.stringify(full));
	ss.forEach((s) => {
		delete (s as any).__typename;
		delete (s.plannedSchedule as any).__typename;

		s.id = String(s.id).slice(43, -37);
		s.plannedSchedule.id = s.id;
		s.plannedSchedule.requirements.forEach((r) => {
			delete (r as any).__typename;
			r.plannedVesselActions.forEach((a) => {
				delete (a as any).__typename;
				a.plannedVesselScheduleId = s.id;
			});
		});
		if (s.actualSchedule) {
			delete (s.actualSchedule as any).__typename;
			s.actualSchedule.id = s.id;
			s.actualSchedule.createdAt = 0; //
			s.actualSchedule.plannedVesselScheduleId = s.id;
			s.actualSchedule.requirements.forEach((r) => {
				delete (r as any).__typename;
				r.actualVesselActions.forEach((aa) => {
					delete (aa as any).__typename;
					aa.actualVesselScheduleId = s.id;
					if (aa.plannedAction) {
						aa.plannedAction.plannedVesselScheduleId = s.id;
					}
					if (aa.endState) {
						aa.endState.id = "-"; //
					}
					if (aa.vesselAction) {
						aa.vesselAction.id = "-"; //
					}
				});
			});
		}
	});
	ss.sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0));
	return ss;
};

let vesselScheduleHash: {
	[key: string]: I_PlannedAndActualVesselScheduleTupleOutput[];
} = {};

const checkLatestScheduleContent = () => {
	console.log(`Checking that in progress requirements moved to latest schedules...`);
	let failed = false;
	Object.keys(vesselScheduleHash).forEach((v) => {
		const schs = vesselScheduleHash[v];
		if (schs.length === 1) {
			delete vesselScheduleHash[v];
		} else if (schs.length !== 2) {
			console.log(`${v}: Too many schedules: ${schs.length}`);
			// TODO: support this type
		} else {
			let olderSchedule = schs[0].actualSchedule;
			let newerSchedule = schs[1].actualSchedule;
			if (olderSchedule && newerSchedule) {
				if (newerSchedule?.actualStartDate < olderSchedule?.actualStartDate) {
					[olderSchedule, newerSchedule] = [newerSchedule, olderSchedule];
				}
				const oldRequirementStatuses = olderSchedule?.requirements.map((r) =>
					actualRequirementProgressState(r)
				);
				const newRequirementStatuses = newerSchedule?.requirements.map((r) =>
					actualRequirementProgressState(r)
				);
				if (
					oldRequirementStatuses.includes("In Progress") ||
					oldRequirementStatuses.includes("Not Started") ||
					newRequirementStatuses.includes("Complete")
				) {
					failed = true;
					console.log(`${v} old: ${oldRequirementStatuses}`);
					console.log(`${v} new: ${newRequirementStatuses}`);
				}
			}
		}
	});
	console.log(`Latest schedules test ${failed ? "FAILED" : "ok"}.`);
};

export const testSchedules = (schedules: I_PlannedAndActualVesselScheduleTupleOutput[], schedulesName: string) => {
	console.log(`${new Date().toISOString()}: Testing ${schedulesName}...`);
	vesselScheduleHash = {};
	try {
		const schedulesLength = schedules.length;
		const sortedSchedules = [...schedules].sort((a, b) =>
			compareStrings(a.plannedSchedule.vesselId, b.plannedSchedule.vesselId)
		);
		if (schedulesLength) {
			let goodSchedules = 0;
			sortedSchedules.forEach((schedule, index) => {
				const actualSchedule = schedule.actualSchedule;
				const plannedSchedule = schedule.plannedSchedule;
				if (!actualSchedule || !plannedSchedule) {
					requirementMismatchLog(schedule, "Missing schedule");
					return;
				}
				if (actualSchedule?.vesselId !== plannedSchedule?.vesselId) {
					requirementMismatchLog(schedule, "Vessel id mismatch");
					return;
				}
				const actualScheduleRequirements = actualSchedule?.requirements;
				const plannedScheduleRequirements = plannedSchedule?.requirements;

				//-------------------
				const vesselFromHash = vesselScheduleHash[plannedSchedule.vesselId];
				if (Array.isArray(vesselFromHash)) {
					vesselFromHash.push(schedule);
				} else {
					vesselScheduleHash[plannedSchedule.vesselId] = [schedule];
				}
				//-------------------------------

				if (actualScheduleRequirements?.length !== plannedScheduleRequirements?.length) {
					requirementMismatchLog(schedule, "Requirement count mismatch");
					return;
				}
				if (!actualScheduleRequirements?.length) {
					console.log(index, " empty requirements ");
					return;
				}
				const stringifiedActual = JSON.stringify(
					[...actualScheduleRequirements]
						.sort((a, b) => compareStrings(a.requirementId, b.requirementId))
						.map((r) => [r.requirementId])
				);
				const stringifiedPlanned = JSON.stringify(
					[...plannedScheduleRequirements]
						.sort((a, b) => compareStrings(a.requirementId, b.requirementId))
						.map((r) => [r.requirementId])
				);
				if (stringifiedActual !== stringifiedPlanned) {
					requirementMismatchLog(schedule, "Requirement IDs mismatch");
					return;
				}
				goodSchedules++;
			});
			const errorCount = schedulesLength - goodSchedules;
			if (errorCount) {
				console.log("----------------------------------------------");
				console.log(`Found ${errorCount} mismatches in ${schedulesName}.`);
			} else {
				console.log(`${schedulesName} is OK!`);
			}
		}
		// output simplified schedules
		console.log("Schedules with IDs stripped: ", simplifySchedules(schedules));

		// // output tree of lengths of actions
		// schedules.forEach((s) => {
		// 	const actualLength = s.actualSchedule?.requirements.length;
		// 	const plannedLength = s.plannedSchedule.requirements.length;
		// 	console.log(`${s.plannedSchedule.vesselId}: ${actualLength}<-->${plannedLength}`);
		// 	if (actualLength) {
		// 		s.actualSchedule?.requirements.forEach((ar) => {
		// 			const aa = ar.actualVesselActions;
		// 			const pa = s.plannedSchedule.requirements.find((pr) => pr.requirementId === ar.requirementId)
		// 				?.plannedVesselActions;
		// 			console.log(` * ${ar.requirementId}: ${aa.length}<-->${pa?.length}`);
		// 		});
		// 	}
		// });

		checkLatestScheduleContent();
	} catch (e) {
		console.log(`Test failed for ${schedulesName}`);
		console.log(e);
	}
};

type ProgressStateAndTotal = ProgressState | "Total";
type ProgressStateInfo = {
	[key in ProgressStateAndTotal]: number;
};

const actualRequirementActionCountByState = (requirementActual: I_ActualScheduledRequirement): ProgressStateInfo => {
	let stateInfo: ProgressStateInfo = {
		Total: 0,
		"In Progress": 0,
		Complete: 0,
		"Not Started": 0,
	};
	stateInfo.Total = requirementActual.actualVesselActions.length;
	stateInfo = requirementActual.actualVesselActions.reduce<ProgressStateInfo>((acc, action) => {
		acc[action.actionStatus.id as ProgressState]++;
		return acc;
	}, stateInfo);
	return stateInfo;
};

export interface OffScheduleRequirementInfo {
	requirementId: string;
	vesselId: string;
	type: "IN_PROGRESS_MISSING" | "LATE";
	message: string;
	days?: number;
}

export const checkIfActualSchedulesAreGoingWell = (
	scheduleTuples: I_PlannedAndActualVesselScheduleTupleOutput[] = []
): OffScheduleRequirementInfo[] => {
	const infoLog: OffScheduleRequirementInfo[] = [];
	scheduleTuples.forEach((tuple) => {
		if (tuple.actualSchedule) {
			tuple.actualSchedule.requirements.forEach((requirementActual) => {
				infoLog.push(...checkIfInProgressRequirementHasInProgressActions(requirementActual));
				infoLog.push(...checkIfRequirementRunningPastSchedule(requirementActual));
			});
		}
	});
	return infoLog;
};

const checkIfInProgressRequirementHasInProgressActions = (
	requirementActual: I_ActualScheduledRequirement
): OffScheduleRequirementInfo[] => {
	const infoLog: OffScheduleRequirementInfo[] = [];
	const stateInfo = actualRequirementActionCountByState(requirementActual);
	if (stateInfo.Complete && stateInfo.Complete < stateInfo.Total) {
		if (!stateInfo["In Progress"]) {
			const message = `In progress requirement ${requirementActual.requirementId} on vessel ${requirementActual.vesselId} does not have in progress actions`;
			console.warn(message);
			infoLog.push({
				requirementId: requirementActual.requirementId,
				vesselId: requirementActual.vesselId,
				type: "IN_PROGRESS_MISSING",
				message,
			});
		}
	}
	return infoLog;
};

const checkIfRequirementRunningPastSchedule = (
	requirementActual: I_ActualScheduledRequirement
): OffScheduleRequirementInfo[] => {
	const infoLog: OffScheduleRequirementInfo[] = [];
	const currentTimeInSeconds = Math.floor(new Date().valueOf() / 1000);
	let actions = requirementActual.actualVesselActions || [];
	for (const action of actions) {
		let actionState: ProgressState = action.actionStatus.id as ProgressState;
		const startDifferenceInDays = Math.floor((currentTimeInSeconds - action.actualStartDate) / 60 / 60 / 24);
		const endDifferenceInDays = Math.floor((currentTimeInSeconds - action.actualEndDate) / 60 / 60 / 24);
		if (actionState === "Not Started" && startDifferenceInDays > 1) {
			const message = `Requirement ${requirementActual.requirementId} on vessel ${requirementActual.vesselId} is ${startDifferenceInDays} days past schedule`;
			console.warn(message);
			infoLog.push({
				requirementId: requirementActual.requirementId,
				vesselId: requirementActual.vesselId,
				type: "LATE",
				message,
				days: startDifferenceInDays,
			});
			break;
		}
		if (actionState === "In Progress" && endDifferenceInDays > 1) {
			const message = `Requirement ${requirementActual.requirementId} on vessel ${requirementActual.vesselId} is ${endDifferenceInDays} days past schedule`;
			console.warn(message);
			infoLog.push({
				requirementId: requirementActual.requirementId,
				vesselId: requirementActual.vesselId,
				type: "LATE",
				message,
				days: endDifferenceInDays,
			});
			break;
		}
	}
	return infoLog;
};
