import {
	Box,
	BoxProps,
	ColumnConfig,
	DataTable,
	DataTableProps,
	Text,
} from "grommet";
import { uniq } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { toDateFormat } from "../constants";
import { Requirement } from "../types/generated/q-fanar-requirements.types";

interface IProps extends BoxProps {
	onRowClick?: DataTableProps<RequirementsTableItem>["onClickRow"];
	requirements: Requirement[];
	vesselNameMap?: any[];
	selectedId?: string;
}

interface RequirementsTableItem {
	id: string;
	shipmentId: string;
	firstLoad: Date;
	lastDischarge: Date;
	products: string[];
	status: string;
	vessel: string;
}

type SearchState = Record<
	Extract<
		keyof RequirementsTableItem,
		| "shipmentId"
		| "firstLoad"
		| "lastDischarge"
		| "products"
		| "status"
		| "vessel"
	>,
	string
>;

export const RequirementsTable = ({
	onRowClick,
	requirements,
	vesselNameMap,
	selectedId,
	...rest
}: IProps) => {
	const [searchValues, setSearch] = useState<SearchState>({
		shipmentId: "",
		firstLoad: "",
		lastDischarge: "",
		products: "",
		status: "",
		vessel: "",
	});

	const selectedShipmentId = useMemo<String>(() => {
		return requirements?.find((f) => f.id === selectedId)?.shipmentId || "";
	}, [selectedId, requirements]);

	const getVesselName = useCallback(
		(requirementId: string): string =>
			vesselNameMap?.find((f) => f.id === requirementId)?.name || "-",
		[vesselNameMap]
	);

	const dataTableProps = useMemo<
		Omit<DataTableProps<RequirementsTableItem>, "placeholder">
	>(
		() => ({
			primaryKey: true,
			rowProps: {
				[`${selectedShipmentId}`]: {
					background: "#72B082",
				},
			},
			columns: [
				{
					property: "shipmentId",
					primary: true,
					header: "Shipment",
					search: true,
					sortable: true,
					render: (datum) => (
						<Box align="center">
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									fontSize: 14,
									fontWeight: 500,
								}}
							>
								{datum.shipmentId}
							</div>
						</Box>
					),
				},
				{
					property: "vessel",
					header: "Vessel",
					search: true,
					sortable: true,
					render: (datum) => (
						<Box>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									fontSize: 14,
									fontWeight: 500,
								}}
							>
								{datum.vessel.toUpperCase()}
							</div>
						</Box>
					),
				},
				{
					property: "firstLoad",
					render: ({ firstLoad }) => (
						<Box>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									fontSize: 14,
									fontWeight: 500,
								}}
							>
								{/* {firstLoad.toLocaleDateString()} */}
								{firstLoad ? toDateFormat(firstLoad) : " - "}
							</div>
						</Box>
					),
					header: "First Load",
					search: true,
					sortable: true,
				},
				{
					property: "lastDischarge",
					render: ({ lastDischarge }) => (
						<Box>
							{" "}
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									fontSize: 14,
									fontWeight: 500,
								}}
							>
								{/* {lastDischarge.toLocaleDateString()} */}
								{lastDischarge
									? toDateFormat(lastDischarge)
									: " - "}
							</div>
						</Box>
					),
					header: "Last Discharge",
					search: true,
					sortable: true,
				},
				{
					property: "products",
					render: ({ products }) => (
						<Box direction="row">
							{products.map((id) => (
								<Box
									key={id}
									background="light-4"
									round
									pad="xxsmall"
								>
									<Text size="small" alignSelf="center">
										{id}
									</Text>
								</Box>
							))}
						</Box>
					),
					header: "Product(s)",
					search: true,
					sortable: true,
				},
				{
					property: "status",
					header: "Status",
					search: true,
					sortable: true,
					render: (datum) => (
						<Box align="center">
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									fontSize: 14,
									fontWeight: 500,
								}}
							>
								{datum.status}
							</div>
						</Box>
					),
				},
			] as ColumnConfig<RequirementsTableItem>[],
			sortable: true,
			paginate: true,
			step: 15,
			fill: false,
			pad: { horizontal: "medium", vertical: "xsmall" },
			background: {
				header: {
					color: "blue1",
				},
				body: ["light-1", "light-3"],
				footer: "dark-3",
			},
			onSearch(s: any) {
				setSearch({
					shipmentId: s.shipmentId.toLowerCase(),
					firstLoad: s.firstLoad.toLowerCase(),
					lastDischarge: s.lastDischarge.toLowerCase(),
					products: s.products.toLowerCase(),
					status: s.status.toLowerCase(),
					vessel: s.vessel.toLowerCase(),
				});
			},
		}),
		[selectedShipmentId]
	);

	const items = useMemo<RequirementsTableItem[]>(
		() =>
			requirements
				?.map<RequirementsTableItem>(
					({
						id,
						shipmentId,
						shorts,
						longs,
						status: { id: status },
					}) => ({
						id,
						shipmentId,
						firstLoad: new Date(
							Math.min(
								...(longs ?? []).map(
									({ startDate }) => startDate
								)
							) * 1000
						),
						lastDischarge: new Date(
							Math.min(
								...(shorts ?? []).map(({ endDate }) => endDate)
							) * 1000
						),
						products: uniq(
							(longs ?? []).map(({ product: { id } }) => id)
						).sort(),
						status,
						vessel: getVesselName(id),
					})
				)
				.filter(
					({
						shipmentId,
						firstLoad,
						lastDischarge,
						products,
						status,
						vessel,
					}) => {
						if (
							!!searchValues.shipmentId &&
							shipmentId &&
							!shipmentId
								.toLowerCase()
								.includes(searchValues.shipmentId)
						) {
							return false;
						}

						if (
							!!searchValues.vessel &&
							vessel &&
							!vessel.toLowerCase().includes(searchValues.vessel)
						) {
							return false;
						}

						if (
							!!searchValues.firstLoad &&
							firstLoad &&
							!toDateFormat(firstLoad)
								?.toLowerCase()
								?.includes(searchValues.firstLoad)
						) {
							return false;
						}

						if (
							!!searchValues.lastDischarge &&
							lastDischarge &&
							!toDateFormat(lastDischarge)
								?.toLowerCase()
								?.includes(searchValues.lastDischarge)
						) {
							console.log(lastDischarge.toLocaleDateString());
							return false;
						}

						if (
							!!searchValues.products &&
							!!products?.length &&
							!products.some((product) =>
								product
									.toLowerCase()
									.includes(searchValues.products)
							)
						) {
							return false;
						}

						if (
							!!searchValues.status &&
							status &&
							!status.toLowerCase().includes(searchValues.status)
						) {
							return false;
						}

						return true;
					}
				),
		[requirements, searchValues, getVesselName]
	);

	return (
		<Box {...rest}>
			{requirements && (
				<DataTable<RequirementsTableItem>
					{...dataTableProps}
					data={items}
					onClickRow={onRowClick}
				/>
			)}
		</Box>
	);
};
