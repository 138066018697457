import { vesselScheduleLifecycleClient } from "../../api/vesselScheduleLifecycleClient";
import { PROMOTE_MUTATION } from "../../queries/schedules";
import { recursivelyDeleteTypenameProperty } from "../../store/util/clean-props";
import {
	I_ActualVesselScheduleTuple,
	I_PlannedAndActualVesselScheduleTuple,
} from "../../types/generated/q-vessel-schedule-lifecycle-v6.types";
import type { IFleetMachineContext } from "../fleetMachine";
import type { PromotionFinishedEvent, PromotionProgressEvent } from "../fleetMachine.events";
import { groupBy, sortBy, last as _last, chunk, keyBy } from "lodash";
import { v4 as uuid } from "uuid";
import { merge, Observable, Subject, Subscribable } from "rxjs";
import { scan, map, multicast, last, take } from "rxjs/operators";
import { toObservable } from "wonka";
import { GqlQueryOperationResult } from "../../store/epics/requirements.epic";
import { errorMessageFromGraphql } from "../../components/utility/errorMessageFromGraphql";
import { assign } from "xstate";

const PARALLEL_REQUESTS = 1;
const SCHEDULES_PER_CHUNK = 10;

/*function getEndDate({
	plannedSchedule: { plannedEndDate },
}: I_PlannedAndActualVesselScheduleTuple) {
	return plannedEndDate;
}*/

//this uses the created at date to get the latest schedule.
function getEndDate({ plannedSchedule: { createdAt } }: I_PlannedAndActualVesselScheduleTuple) {
	return createdAt;
}

function getLatestScheduleOfSingleVessel(schedules: I_PlannedAndActualVesselScheduleTuple[]) {
	return _last(sortBy(schedules, getEndDate))!;
}

/** Filters the given schedule tuples such that only 1 schedule per vessel is returned */
export function getOnlyLatestSchedules(schedule: I_PlannedAndActualVesselScheduleTuple[]) {
	// const grouped = Object.values(
	// 	groupBy(schedule, ({ plannedSchedule: { vesselId } }) => vesselId)
	// );
	return Object.values(groupBy(schedule, ({ plannedSchedule: { vesselId } }) => vesselId)).map(
		getLatestScheduleOfSingleVessel
	);
}

type PromoteResult = GqlQueryOperationResult<typeof PROMOTE_MUTATION>;

interface ScanState {
	completedRequests: number;
	schedules: I_ActualVesselScheduleTuple[];
}

// should filter out complete ones, leaving not started and in progress
export function promotePlannedToActual({
	schedule,
}: IFleetMachineContext): Observable<PromotionProgressEvent | PromotionFinishedEvent> {
	const latest = getOnlyLatestSchedules(schedule);

	console.log(latest);
	const filteredRequirements = latest
		.map((f) => {
			const completeAndInProgressRequirements = f.actualSchedule?.requirements.filter((requriement) => {
				const action = requriement.actualVesselActions.find((a) => {
					return a.actionStatus.id === "Complete" || a.actionStatus.id === "In Progress";
				});

				return action !== null && action !== undefined;
			});

			return completeAndInProgressRequirements?.map((f) => f.id);
		})
		.flatMap((f) => f);

	console.log("filteredRequirements :: " + JSON.stringify(filteredRequirements));

	//so I need to filter out plannedschedule requirements that are started or completed
	const mapped = latest.map((f) => {
		const filteredPlanned = f.plannedSchedule.requirements.filter((plannedRequirement) => {
			return !filteredRequirements.includes(plannedRequirement.id);
		});

		return {
			...f,
			plannedSchedule: {
				...f.plannedSchedule,
				requirements: filteredPlanned,
			},
		};
	});

	console.log("latest length :: " + latest.length);
	console.log("filtered length :: " + mapped.length);
	console.log("Schedules going to be promoted:", mapped);
	const chunks = chunk(mapped, SCHEDULES_PER_CHUNK);
	const len = chunks.length;

	return merge(
		...chunks.map(
			(chunk) =>
				toObservable(
					vesselScheduleLifecycleClient.mutation(PROMOTE_MUTATION, {
						iPlannedAndActualScheduleTuples: recursivelyDeleteTypenameProperty(chunk),
					})
				) as Subscribable<PromoteResult>
		),
		PARALLEL_REQUESTS
	).pipe(
		map(({ data, error }) => {
			if (!data?.promotePlannedVesselSchedulesToActuals)
				throw new Error(`Promotion failed: ${errorMessageFromGraphql(error?.message)}`);
			return data.promotePlannedVesselSchedulesToActuals;
		}),
		scan<I_ActualVesselScheduleTuple[], ScanState>(
			({ completedRequests, schedules }, schedulesInResponse) => ({
				completedRequests: completedRequests + 1,
				schedules: [...schedules, ...schedulesInResponse],
			}),
			{
				completedRequests: 0,
				schedules: [],
			}
		),
		// Split the request status stream into 2 event streams:
		multicast(
			() => new Subject<ScanState>(),
			(s$) =>
				merge(
					// promotion progress events triggered on every finished request to update progress bar
					s$.pipe(
						take(len - 1),
						map(
							({ completedRequests }): PromotionProgressEvent => ({
								type: "PROMOTE_PROGRESS",
								progress: completedRequests / len,
							})
						)
					),
					// promote finished event dispatched only at the end, when all requests have finished, with all the accumulated schedules
					s$.pipe(
						last(),
						map(
							({ schedules }): PromotionFinishedEvent => ({
								type: "PROMOTE_FINISHED",
								schedules,
							})
						)
					)
				)
		)
	);
}

export const assignPromoteResultAction = assign<IFleetMachineContext, PromotionFinishedEvent>({
	schedule({ schedule }, { schedules }) {
		//this removes schedules if the vessel has more than 1
		//and returns only 1 schedule per vessel
		//this is what we sent to promote
		const latestState = getOnlyLatestSchedules(schedule);

		const existingScheduleByVesselId = keyBy(latestState, ({ plannedSchedule: { vesselId } }) => vesselId);

		const latestPlannedScheduleIDs = latestState.map((s) => s.plannedSchedule.id);
		const oldState = schedule.filter((s) => !latestPlannedScheduleIDs.includes(s.plannedSchedule.id));

		//1. replace latestState.actualSchedules with schedulesFromPromote.oldUpdatedSchedule
		const updateLatest = schedules
			.map<I_PlannedAndActualVesselScheduleTuple>((s) => {
				const matchingExistingTuple = existingScheduleByVesselId[s.newSchedule.vesselId];
				if (matchingExistingTuple) {
					// If we can match by vesselId - we can reuse the planned schedule from that
					// except that the planned schedule now should contain ONLY complete requirements
					const completeRequirementIDs = matchingExistingTuple.actualSchedule?.requirements
						.filter((r) => !r.actualVesselActions.find((a) => a.actionStatus.id !== "Complete"))
						.map((r) => r.requirementId);
					return {
						id: matchingExistingTuple.id,
						plannedSchedule: {
							...matchingExistingTuple.plannedSchedule,
							requirements: matchingExistingTuple.plannedSchedule.requirements.filter((r) =>
								completeRequirementIDs?.find((c) => c === r.requirementId)
							),
						},
						actualSchedule: s.updatedOldSchedule, //only complete requirements
					};
				} else
					return {
						id: uuid(),
						plannedSchedule: {
							id: uuid(),
							createdAt: Math.floor(Date.now() / 1000),
							isSpot: s.newSchedule.isSpot,
							vesselId: s.newSchedule.vesselId,
							plannedStartDate: s.newSchedule.actualStartDate,
							plannedEndDate: s.newSchedule.actualEndDate,
							requirements: [],
						},
					};
			})
			.filter(Boolean);

		//2. newly created schedules
		const newSchedules = schedules
			.map<I_PlannedAndActualVesselScheduleTuple>((s) => {
				const matchingExistingTuple = existingScheduleByVesselId[s.newSchedule.vesselId];
				if (matchingExistingTuple) {
					// If we can match by vesselId - we can reuse the planned schedule from that
					// except that the planned schedule now should NOT contain complete requirements
					const completeRequirementIDs = matchingExistingTuple.actualSchedule?.requirements
						.filter((r) => !r.actualVesselActions.find((a) => a.actionStatus.id !== "Complete"))
						.map((r) => r.requirementId);
					return {
						id: matchingExistingTuple.id,
						plannedSchedule: {
							...matchingExistingTuple.plannedSchedule,
							requirements: matchingExistingTuple.plannedSchedule.requirements.filter(
								(r) => !completeRequirementIDs?.find((c) => c === r.requirementId)
							),
						},
						actualSchedule: s.newSchedule, //carried over requirements and newly planned requirements
					};
				} else
					return {
						id: uuid(),
						plannedSchedule: {
							id: uuid(),
							createdAt: Math.floor(Date.now() / 1000),
							isSpot: s.newSchedule.isSpot,
							vesselId: s.newSchedule.vesselId,
							plannedStartDate: s.newSchedule.actualStartDate,
							plannedEndDate: s.newSchedule.actualEndDate,
							requirements: [],
						},
					};
			})
			.filter(Boolean);

		return oldState.concat(updateLatest).concat(newSchedules);
	},
});
