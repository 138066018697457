import React, { Dispatch, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, BoxProps, Heading, DataTableProps } from "grommet";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import {
	invalidRequirementsSelector,
	overrideRequirementsSelector,
	validRequirementsSelector,
} from "../../selectors/requirement.selectors";
import { RequirementsTable } from "../RequirementsTable";
import {
	DISMISS_OVERRIDE_WARNING,
	IRequirementReducerActions,
} from "../../store/actions/requirements.actions";

interface BatchEditLeftPaneProps
	extends BoxProps,
		Omit<React.HTMLAttributes<HTMLDivElement>, "onClick"> {
	children?: undefined;
	onSelectRequirement?(id: string): void;
}

export const BatchEditLeftPane: React.FC<BatchEditLeftPaneProps> = function ({
	onSelectRequirement,
	...rest
}) {
	const invalidRequirements = useSelector(invalidRequirementsSelector);
	const validRequirements = useSelector(validRequirementsSelector);
	const overrideRequirementsData = useSelector(overrideRequirementsSelector);
	const dispatch = useDispatch<Dispatch<IRequirementReducerActions>>();

	const handleRowClick = useCallback<
		NonNullable<DataTableProps<{ id: string }>["onClickRow"]>
	>(
		({ datum: { id } }) => {
			onSelectRequirement?.(id);
		},
		[onSelectRequirement]
	);

	return (
		<Box {...rest}>
			{invalidRequirements.length > 0 && (
				<>
					<Heading level="4">
						Invalid Requirements ({invalidRequirements.length})
					</Heading>
					<RequirementsTable
						requirements={invalidRequirements}
						onRowClick={handleRowClick}
						pad="none"
					/>
				</>
			)}
			{Array.isArray(overrideRequirementsData) &&
				overrideRequirementsData?.length > 0 && (
					<div>
						<Alert
							severity="warning"
							onClose={() => {
								dispatch({
									type: DISMISS_OVERRIDE_WARNING,
								});
							}}
						>
							<AlertTitle>CSV Override Warning</AlertTitle>
							{`In total ${
								overrideRequirementsData?.length
							} requiremenets with ids: ${overrideRequirementsData?.toString()} will be overriden.`}
						</Alert>
					</div>
				)}
			<Heading level="4">
				Valid Requirements{" "}
				{validRequirements && validRequirements.length > 0 && (
					<span>({validRequirements.length})</span>
				)}
			</Heading>
			<RequirementsTable
				requirements={validRequirements}
				onRowClick={handleRowClick}
				pad="none"
			/>
		</Box>
	);
};
