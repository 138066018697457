import Alert from "@material-ui/lab/Alert";
import { Box, Button, Grid, Stack } from "grommet";
import { map } from "lodash";
import moment from "moment";
import React, { memo, useContext } from "react";
import { ErrorOption, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useSelector as useXSelector } from "@xstate/react";
import { DeepNullable } from "ts-essentials";
import { useMutation } from "urql";
import { FleetMachineContext } from "../../../App";

import { PERSIST_PORT_RESTRICTION } from "../../../queries/vessels";
import { portsSelector } from "../../../selectors/ports.selectors";
import { IPort } from "../../../store/sagas/loadPorts.saga";
import { Rc6PortRestriction } from "../../../types/generated/q-vessel-schedule-lifecycle-v6.types";
import { LoadingIndicator } from "../../LoadingIndicator";
import {
	ControlledAutocomplete,
	ControlledAutocompleteProps,
} from "../ControlledAutocomplete";
import { ControlledTextInput } from "../ControlledTextInput";
import { VesselWithQ88PRandUt } from "../../../types/generated/q-v2-vessels.types";

export interface PortDropdownOption {
	id: string;
	name: string;
}

type OptionalPortRestriction = DeepNullable<
	Omit<Rc6PortRestriction, "startDate" | "endDate">
> & {
	endDate: string;
	startDate: string;
	port?: string;
	terminal?: string;
	port_terminal?: string;
};

const defaultValues: OptionalPortRestriction = {
	id: "",
	vesselId: "",
	port: "",
	terminal: "",
	endDate: moment().startOf("day").add(1, "day").format("YYYY-MM-DDThh:mm"),
	startDate: moment().startOf("day").format("YYYY-MM-DDThh:mm"),
	reason: "",
};

function mapSelectedOptionToFormValue({
	id: value,
}: PortDropdownOption): string {
	return value;
}

const getColorByType = (type: string) => (type === "port" ? "blue" : "red");
const renderPortTerminalDropdownOption: ControlledAutocompleteProps<any>["optionRenderer"] = (
	{ name: label, id: value, __formField },
	_options,
	_state,
	{ selected }
) =>
	selected ? (
		<span>
			{label}
			<sup style={{ color: getColorByType(__formField) }}>
				{__formField === "port" ? "Port" : "Terminal"}
			</sup>
		</span>
	) : (
		<Box pad="small" direction="row">
			<strong>{value}:</strong> ({label})
			<sup
				style={{
					color: getColorByType(__formField),
					lineHeight: "0.2em",
				}}
			>
				{__formField === "port" ? "Port" : "Terminal"}
			</sup>
		</Box>
	);

const searchKeys = ["id", "name"] as const;

interface IPortRestrictionFormProps {
	vesselId: string;
	onCancel: () => void;
}

type CustomError = ErrorOption & {
	name: keyof Rc6PortRestriction;
};

function validatePR(
	{
		id,
		startDate,
		endDate,
		port,
		terminal,
		reason,
		vesselId,
	}: DeepNullable<Rc6PortRestriction>,
	vessel?: VesselWithQ88PRandUt
): CustomError[] {
	const errors: CustomError[] = [];

	if (!id) {
		errors.push({
			name: "id",
			type: "manual",
			message: "ID must be specified",
		});
	}

	if (!startDate) {
		errors.push({
			name: "startDate",
			type: "manual",
			message: "Start Date must be specified",
		});
	}

	if (!endDate) {
		errors.push({
			name: "startDate",
			type: "manual",
			message: "End Date must be specified",
		});
	}

	if (!vessel) {
		errors.push({
			name: "vesselId",
			type: "manual",
			message: `Vessel ${vesselId} was not found`,
		});
	}

	if (!port && !terminal) {
		errors.push({
			name: "port",
			type: "manual",
			message: "Port or terminal must be specified",
		});
	}

	if (!reason) {
		errors.push({
			name: "reason",
			type: "manual",
			message: "Reason cannot be empty",
		});
	}

	if (!startDate || !endDate || !vessel) return errors;

	if (startDate > endDate) {
		// * set specific field error
		errors.push({
			name: "endDate",
			type: "manual",
			message: "End Date must occur after Start Date",
		});
		errors.push({
			name: "startDate",
			type: "manual",
		});
	}

	// Let's try to find an existing UT for this vessel that overlaps with the new one
	//this is wrong for port restrictions so removing and will add in a different check at a later date.
	/*const overlappingExistingUTorPR = [
		//...(vessel.unavailableTimes || []),
		...(vessel.portRestrictions || []),
	].find((utOrPr) => {
		if (!utOrPr || !utOrPr.startDate || !utOrPr.endDate) return false;
		return (
			Math.min(endDate, utOrPr.endDate) -
				Math.max(startDate, utOrPr.startDate) >=
			0
		);
	});

	if (overlappingExistingUTorPR)
		errors.push({
			name: "startDate",
			type: "manual",
			message: `The time range for Port Restriction overlaps with ${`Other Port restriction at ${overlappingExistingUTorPR.port}`}; At dates ${new Date(
				overlappingExistingUTorPR.startDate! * 1000
			).toLocaleDateString()} → ${new Date(
				overlappingExistingUTorPR.endDate! * 1000
			).toLocaleDateString()}`,
		});
	*/

	return errors;
}

export const PortRestrictionForm = memo(function PortRestrictionForm({
	vesselId,
	onCancel,
}: IPortRestrictionFormProps) {
	const fleetMachine = useContext(FleetMachineContext);
	const vessel = useXSelector(fleetMachine, ({ context: { vessels } }) =>
		vessels.find(({ vessel: { id } }) => id === vesselId)
	);

	const {
		clearErrors,
		control,
		errors,
		formState,
		handleSubmit,
		setError,
		setValue,
		register,
		watch,
	} = useForm<OptionalPortRestriction>({
		defaultValues: {
			...defaultValues,
			vesselId,
			id: `PR-${vesselId}-${Math.floor(Date.now() / 1000)}`,
			port: "",
			terminal: "",
			port_terminal: "",
		},
		criteriaMode: "all",
	});

	// * mutation for saving Port Restriction
	const [
		{
			fetching: loading,
			error: savePortRestrictinError,
			data: portRestrictionResult,
		},
		savePortRestriction,
	] = useMutation<
		{ persistPortRestriction: string },
		{ input: Rc6PortRestriction }
	>(PERSIST_PORT_RESTRICTION);

	// * get port data
	const ports = useSelector(portsSelector);
	const allPorts = React.useMemo(() => Object.values(ports.byId), [ports]);
	const portsAndTerminals = React.useMemo(
		() =>
			allPorts.reduce((curr: any[], acc: IPort) => {
				const { terminals, ...port } = acc;
				curr.push({
					...port,
					__formField: "port",
				});
				if (terminals)
					for (let t of terminals)
						curr.push({
							...t,
							// port,
							__formField: "terminal",
						});
				return curr;
			}, []),
		[allPorts]
	);

	const portKey = "port";
	const terminalKey = "terminal";
	const selectedPortTerminal = watch("port_terminal");

	const portTerminalMatchingSelection = React.useMemo(
		() =>
			selectedPortTerminal
				? portsAndTerminals.find(
						({ id }) => id === selectedPortTerminal
				  )
				: undefined,
		[selectedPortTerminal, portsAndTerminals]
	);

	React.useEffect(() => {
		register({ name: "port" });
		register({ name: "terminal" });
	});

	React.useEffect(() => {
		// * Set port and terminal values
		if (portTerminalMatchingSelection) {
			switch (portTerminalMatchingSelection.__formField) {
				case "port":
					setValue("port", selectedPortTerminal, {
						shouldValidate: true,
						shouldDirty: true,
					});
					setValue("terminal", undefined);
					break;
				case "terminal":
					setValue("terminal", selectedPortTerminal, {
						shouldValidate: true,
						shouldDirty: true,
					});
					setValue("port", undefined);
					// if (
					// 	portTerminalMatchingSelection &&
					// 	portTerminalMatchingSelection.port
					// ) {
					// 	setValue(
					// 		"port",
					// 		portTerminalMatchingSelection.port.id,
					// 		{
					// 			shouldValidate: true,
					// 			shouldDirty: true,
					// 		}
					// 	);
					// }
					break;
				default:
			}
		}
	}, [
		portTerminalMatchingSelection,
		selectedPortTerminal,
		setValue,
		terminalKey,
		portKey,
	]);

	const onSubmit = React.useCallback(
		(data: OptionalPortRestriction) => {
			delete data.port_terminal;
			let outgoingData: DeepNullable<Rc6PortRestriction> = {
				...data,
				startDate: moment(data.startDate).unix(),
				endDate: moment(data.endDate).unix(),
			};
			clearErrors();

			const fieldErrors = validatePR(outgoingData, vessel);

			if (fieldErrors.length > 0) {
				console.error("form has errors", fieldErrors);
				fieldErrors.forEach(({ name, ...rest }) =>
					setError(name, rest)
				);
				return;
			}

			savePortRestriction({
				input: outgoingData as Rc6PortRestriction,
			}).then(() => {
				fleetMachine.send({
					type: "PR_ADDED",
					data: outgoingData as Rc6PortRestriction,
				});
			});
		},
		[clearErrors, savePortRestriction, setError, fleetMachine, vessel]
	);

	const hasSuccessfulSave = React.useMemo(
		() =>
			(portRestrictionResult?.persistPortRestriction?.length ?? -1 > 0) &&
			formState.isSubmitSuccessful &&
			formState.isSubmitted,
		[
			formState.isSubmitSuccessful,
			formState.isSubmitted,
			portRestrictionResult?.persistPortRestriction?.length,
		]
	);

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="form">
			{savePortRestrictinError ? (
				<Alert severity="error">
					{savePortRestrictinError.message}
				</Alert>
			) : null}

			{errors
				? map(errors, (err, index) =>
						err?.message ? (
							<Alert severity="error" key={index}>
								{err?.message}
							</Alert>
						) : null
				  )
				: null}

			{hasSuccessfulSave ? (
				<Alert severity="success" onClose={onCancel}>
					Port Restriction saved successfully!
				</Alert>
			) : (
				<Stack>
					<Grid
						fill
						rows={["auto", "auto", "auto", "auto"]}
						columns={["auto", "auto"]}
						gap="small"
						areas={[
							["vesselId", "portRestrictionId"],
							["port_terminal", "port_terminal"],
							["port", "terminal"],
							["startDate", "endDate"],
							["reason", "reason"],
							["submit", "cancel"],
						]}
					>
						<Box fill gridArea="vesselId">
							<ControlledTextInput
								name="vesselId"
								label="Vessel ID"
								control={control}
								disabled
							/>
						</Box>

						<Box fill gridArea="portRestrictionId">
							<ControlledTextInput
								name="id"
								label="Port Restriction ID"
								control={control}
								disabled
							/>
						</Box>

						{/* Port or terminal */}
						<Box fill gridArea="port_terminal">
							<ControlledAutocomplete
								name="port_terminal"
								label="Port/Terminal"
								errorMessage={
									errors.port ? "Required" : undefined
								}
								options={portsAndTerminals}
								control={control}
								mapSelectedOptionToFormValue={
									mapSelectedOptionToFormValue
								}
								optionRenderer={
									renderPortTerminalDropdownOption
								}
								searchKeys={searchKeys}
							/>
						</Box>

						{/* Start date */}
						<Box fill gridArea="startDate">
							<ControlledTextInput
								name="startDate"
								label="Start Date"
								type="datetime-local"
								control={control}
							/>
						</Box>

						{/* End date */}
						<Box fill gridArea="endDate">
							<ControlledTextInput
								name="endDate"
								label="End Date"
								type="datetime-local"
								control={control}
							/>
						</Box>

						<Box fill gridArea="reason">
							{/* Reason */}
							<ControlledTextInput
								name="reason"
								label="Reason"
								errorMessage={
									errors.reason ? "Required" : undefined
								}
								control={control}
							/>
						</Box>

						<Box fill gridArea="cancel">
							<Button
								type="button"
								secondary
								label="Cancel"
								disabled={loading}
								onClick={onCancel}
							/>
						</Box>
						<Box fill gridArea="submit">
							<Button
								type="submit"
								primary
								label="Submit"
								disabled={loading}
							/>
						</Box>
					</Grid>

					{loading ? (
						<Box
							fill
							background="dark-1"
							style={{ opacity: 0.2 }}
							justify="center"
							align="center"
						>
							<LoadingIndicator size={42} color="white" />
						</Box>
					) : null}
				</Stack>
			)}
		</form>
	);
});
