/**
 * The purpose of this file is to contain the constructors for various
 * types within the GraphQL Schema. These are manually created until we
 * can find a way to generate them dynamically from a Type.
 */
import { DeepNullable } from "ts-essentials";

import {
	PortRestrictionAsInput,
	ProductAsInput,
	RequirementAsInput,
	RequirementStatusAsInput,
	TradeAsInput,
	UnavailableTimeAsInput,
	VesselAsInput,
	VesselDetailsAsInput,
} from "../../../types/generated.types";
import {
	BunkerRequirementsOutput,
	Terminal as ITerminal,
} from "../../../types/generated/q-vessel-schedule-lifecycle-v6.types";

import moment from "moment";
export function Requirement(): DeepNullable<RequirementAsInput> {
	return {
		id: "",
		shipmentId: "",
		longs: [],
		shorts: [],
		estimatedCostToSpotCharter: 0,
		status: RequirementStatus(),
		locked: null,
	};
}

export function RequirementStatus(): DeepNullable<RequirementStatusAsInput> {
	return {
		id: "Not Started",
	};
}

export function Trade(): DeepNullable<TradeAsInput> {
	return {
		id: "",
		port: "",
		product: Product(),
		terminal: "",
		startDate: 1609920000,
		endDate: 1610006400,
	};
}

export function Product(): DeepNullable<ProductAsInput> {
	return {
		id: "",
		quantity: 0,
		unit: "bbl",
	};
}

export function Vessel(): DeepNullable<VesselAsInput> {
	return {
		id: "",
		isClean: false,
		isParked: false,
		name: "",
		details: VesselDetails(""),
		portRestrictions: [],
		unavailableTimes: [],
		bunkerRequirements: BunkerRequirementsA(""),
	};
}

export function BunkerRequirementsA(id: string): BunkerRequirementsOutput {
	return {
		id: id,
		laden_speed_11: 0,
		laden_speed_12: 0,
		laden_speed_12_5: 0,
		laden_speed_13: 0,
		laden_speed_13_5: 0,
		laden_speed_14: 0,
		laden_speed_14_5: 0,
		laden_speed_15: 0,
		ballast_speed_11: 0,
		ballast_speed_12: 0,
		ballast_speed_12_5: 0,
		ballast_speed_13: 0,
		ballast_speed_13_5: 0,
		ballast_speed_14: 0,
		ballast_speed_14_5: 0,
		ballast_speed_15: 0,
		no_eca_cold_cleaning: 0,
		no_eca_hot_cleaning: 0,
	};
}

export function VesselDetails(id: string): DeepNullable<VesselDetailsAsInput> {
	return {
		id: id,
		charteringCost: 0,
		cleaningTimeMultiplier: 0,
		contractExpiration: moment().add(1, "year").endOf("year").toDate(),
		dateNextSurvey: moment().add(1, "year").toDate(),
		sizeCategory: "",
	};
}

export function PortRestriction(): DeepNullable<PortRestrictionAsInput> {
	return {
		id: "",
		startDate: 0,
		endDate: 0,
		port: "",
		reason: "",
		terminal: "",
	};
}

export function UnavailableTime(): DeepNullable<UnavailableTimeAsInput> {
	return {
		id: "",
		startPort: "",
		endPort: "",
		startDateAsFloat: 1609920000,
		endDateAsFloat: 1610006400,
	};
}

export function Terminal(): DeepNullable<ITerminal> {
	return {
		id: "",
		berths: null,
		name: "",
		terminalID: null,
	};
}

export const ConstructorStore: Record<string, Function> = {
	Vessel,
	VesselDetails,
	PortRestriction,
	UnavailableTime,
	BunkerRequirementsA,
	Requirement,
	Trade,
	RequirementStatus,
	Product,
	Terminal,
};
