import React, { Component } from "react";

/**
 *- React Scheduler wrapper
 */
import ReactDOM from "react-dom";

import {
	SchedulerPro,
	ObjectHelper,
	Widget,
	SchedulerConfig,
	EventModel,
	ResourceModel,
} from "@bryntum/schedulerpro";
import type { ISchedulerItem } from "../mapIScheduleTuplesToBryntumEvent";

export interface BryntumSchedulerProps
	extends Omit<Partial<SchedulerConfig>, "ref"> {
	schedulerClass?: typeof SchedulerPro;
	syncDataOnLoad?: boolean;
	resourcesVersion?: number;
	eventsVersion?: number;
	ref?: React.Ref<BryntumSchedulerPro>;
	// startDate: string;
	// endDate: string;
}

interface BryntumSchedulerState {
	portals: Set<React.ReactPortal>;
	generation: number;
}

export interface EventSchedulerChangeEvent {
	action: "update" | "dataset";
	batch: boolean;
	changes: Record<string, { value: string; oldValue: string }>;
	isAssign: boolean;
	type: "change";
	record: EventModel;
}

export interface BeforeEventDropFinalizeEvent {
	context: {
		async: boolean;
		finalize(result: boolean): void;
		draggedRecords: ISchedulerItem[];
		newResource: ResourceModel;
	};
	event: MouseEvent;
	type: "beforeeventdropfinalize";
	source: SchedulerPro;
}

export class BryntumSchedulerPro extends Component<
	BryntumSchedulerProps,
	BryntumSchedulerState
> {
	public schedulerInstance?: SchedulerPro;
	private el?: HTMLDivElement | null;
	private featureRe = /Feature$/;

	/* #region Features */
	private features = [
		"cellEditFeature",
		"cellMenuFeature",
		"cellTooltipFeature",
		"columnAutoWidthFeature",
		"columnDragToolbarFeature",
		"columnLinesFeature",
		"columnPickerFeature",
		"columnReorderFeature",
		"columnResizeFeature",
		"dependenciesFeature",
		"dependencyEditFeature",
		"eventMenuFeature",
		"eventDragCreateFeature",
		"eventDragFeature",
		"eventDragSelectFeature",
		"eventEditFeature",
		"eventFilterFeature",
		"eventResizeFeature",
		"eventTooltipFeature",
		"filterBarFeature",
		"filterFeature",
		"groupFeature",
		"groupSummaryFeature",
		"headerMenuFeature",
		"headerZoomFeature",
		"labelsFeature",
		"nonWorkingTimeFeature",
		"panFeature",
		"pdfExportFeature",
		"percentBarFeature",
		"quickFindFeature",
		"recurringEventsFeature",
		"recurringTimeSpansFeature",
		"regionResizeFeature",
		"resourceNonWorkingTimeFeature",
		"resourceTimeRangesFeature",
		"rowReorderFeature",
		"scheduleMenuFeature",
		"scheduleTooltipFeature",
		"searchFeature",
		"simpleEventEditFeature",
		"sortFeature",
		"stickyCellsFeature",
		"stripeFeature",
		"summaryFeature",
		"taskEditFeature",
		"timeAxisHeaderMenuFeature",
		"timeRangesFeature",
		"treeFeature",
	];
	/* #endregion */

	/* #region skipUpdateProps */
	skipUpdateProps = [
		"adopt",
		"appendTo",
		"columns",
		"features",
		"html",
		"id",
		"insertBefore",
		"insertTo",
		"listeners",
		"ref",
		"timeRanges",
		"title",
	];
	/* #endregion */

	state: BryntumSchedulerState = {
		portals: new Set(),
		generation: 0,
	};

	releaseReactCell(cellElement: any) {
		const { state } = this,
			cellElementData = cellElement._domData;

		// Cell already has a react component in it, remove
		if (cellElementData.reactPortal) {
			state.portals.delete(cellElementData.reactPortal);

			this.setState({
				portals: state.portals,
				generation: state.generation + 1,
			});

			cellElementData.reactPortal = null;
		}
	}

	// React component rendered to DOM, render scheduler to it
	componentDidMount() {
		const { props } = this;
		const config: SchedulerConfig = {
			// Use this element as our encapsulating element
			adopt: this.el,
			callOnFunctions: true,
			features: {},

			// Hook called by schedulerInstance when requesting a cell editor
			processCellEditor: ({ editor, field }: any) => {
				// String etc handled by feature, only care about fns returning React components here
				if (typeof editor !== "function") {
					return;
				}

				// Wrap React editor in an empty widget, to match expectations from CellEdit/Editor and make alignment
				// etc. work out of the box
				const wrapperWidget = new Widget({
					name: field, // For editor to be hooked up to field correctly
				} as any);

				// Ref for accessing the React editor later
				(wrapperWidget as any).reactRef = React.createRef();

				// column.editor is expected to be a function returning a React component (can be JSX). Function is
				// called with the ref from above, it has to be used as the ref for the editor to wire things up
				const reactComponent = editor((wrapperWidget as any).reactRef);
				if (reactComponent.$$typeof !== Symbol.for("react.element")) {
					throw new Error("Expect a React element");
				}

				let editorValidityChecked = false;

				// Add getter/setter for value on the wrapper, relaying to getValue()/setValue() on the React editor
				Object.defineProperty(wrapperWidget, "value", {
					enumerable: true,
					get: function () {
						return (wrapperWidget as any).reactRef.current.getValue();
					},
					set: function (value) {
						const component = (wrapperWidget as any).reactRef
							.current;

						if (!editorValidityChecked) {
							const misses = [
								"setValue",
								"getValue",
								"isValid",
								"focus",
							].filter((fn) => !(fn in component));

							if (misses.length) {
								throw new Error(`
                    Missing function${
						misses.length > 1 ? "s" : ""
					} ${misses.join(", ")} in ${component.constructor.name}.
                    Cell editors must implement setValue, getValue, isValid and focus
                  `);
							}

							editorValidityChecked = true;
						}

						const context = (wrapperWidget.owner as any)
							.cellEditorContext;
						component.setValue(value, context);
					},
				});

				// Add getter for isValid to the wrapper, mapping to isValid() on the React editor
				Object.defineProperty(wrapperWidget, "isValid", {
					enumerable: true,
					get: function () {
						return (wrapperWidget as any).reactRef.current.isValid();
					},
				});

				// Override widgets focus handling, relaying it to focus() on the React editor
				wrapperWidget.focus = () => {
					(wrapperWidget as any).reactRef.current.focus &&
						(wrapperWidget as any).reactRef.current.focus();
				};

				// Create a portal, making the React editor belong to the React tree although displayed in a Widget
				const portal = ReactDOM.createPortal(
					reactComponent,
					wrapperWidget.element
				);
				(wrapperWidget as any).reactPortal = portal;

				const { state } = this;
				// Store portal in state to let React keep track of it (inserted into the Grid component)
				state.portals.add(portal);
				this.setState({
					portals: state.portals,
					generation: state.generation + 1,
				});

				return { editor: wrapperWidget };
			},

			// Hook called by schedulerInstance when rendering cells, creates portals for JSX supplied by renderers
			processCellContent: ({
				cellContent,
				cellElement,
				cellElementData,
				record,
			}: any) => {
				let shouldSetContent = cellContent != null;

				// Release any existing React component
				this.releaseReactCell(cellElement);

				// Detect React component
				if (
					cellContent &&
					cellContent.$$typeof === Symbol.for("react.element")
				) {
					// Excluding special rows for now to keep renderers simpler
					if (!record.meta.specialRow) {
						// Clear any non-react content
						const firstChild = cellElement.firstChild;
						if (!cellElementData.reactPortal && firstChild) {
							firstChild.data = "";
						}

						// Create a portal, belonging to the existing React tree but render in a cell
						const portal = ReactDOM.createPortal(
							cellContent,
							cellElement
						);
						cellElementData.reactPortal = portal;

						const { state } = this;
						// Store in state for React to not loose track of the portals
						state.portals.add(portal);
						this.setState({
							portals: state.portals,
							generation: state.generation + 1,
						});
					}
					shouldSetContent = false;
				}

				return shouldSetContent;
			},

			// Disabling automatic date range adjustment
			autoAdjustTimeAxis: false,
		} as any;

		// console.log("props: ", props);
		// console.log("this.props.startDate 2: ", this.props.startDate);
		// console.log("this.props.startDate 2: ", this.props.endDate);
		// console.log("\n");

		// relay properties with names matching this.featureRe to features
		this.features.forEach((featureName) => {
			if (featureName in props) {
				(config.features as any)[
					featureName.replace(this.featureRe, "")
				] = (props as any)[featureName];
			}
		});

		// Handle config (relaying all props except those used for features to scheduler)
		Object.keys(props).forEach((propName) => {
			if (
				!propName.match(this.featureRe) &&
				undefined !== (props as any)[propName]
			) {
				(config as any)[propName] = (props as any)[propName];
			}
		});

		// console.log(config);

		// Create the actual scheduler, used as schedulerInstance for the wrapper
		const schedulerInstance = (this.schedulerInstance = props.schedulerClass
			? new props.schedulerClass(config)
			: new SchedulerPro(config));

		// console.log(
		// 	"schedulerInstance@BryntumSchedulerWrapper: ",
		// 	schedulerInstance
		// );

		// Release any contained React components when a row is removed
		(schedulerInstance as any).rowManager.on({
			removeRow: ({ row }: any) =>
				row.cells.forEach((cell: any) => this.releaseReactCell(cell)),
		});

		// Map all features from schedulerInstance to scheduler to simplify calls
		Object.keys(schedulerInstance.features).forEach((key) => {
			const featureName = key + "Feature";
			if (!(this as any)[featureName]) {
				(this as any)[
					featureName
				] = (schedulerInstance.features as any)[key];
			}
		});

		// const stDate = new Date(Date.parse(this.props.startDate));
		// const enDate = new Date(Date.parse(this.props.endDate));

		// schedulerInstance.setTimeSpan(stDate, enDate);
	}

	// React component removed, destroy schedulerInstance
	componentWillUnmount() {
		this.schedulerInstance?.destroy();
	}

	// Component about to be updated, from changing a prop using state. React to it depending on what changed and
	// prevent react from re-rendering our component.
	shouldComponentUpdate(
		nextProps: BryntumSchedulerProps,
		nextState: BryntumSchedulerState
	): boolean {
		const { props, schedulerInstance, features, skipUpdateProps } = this;
		// These props are ignored or has special handling below
		const excludeProps = [
			...skipUpdateProps,
			...features,
			// @todo the following might be necessary
			// ...features.map(feature => feature.replace(this.featureRe, ''))
		];

		// Reflect configuration changes. Since most scheduler configs are reactive the scheduler will update automatically
		Object.keys(props).forEach((propName) => {

			if (
				!excludeProps.includes(propName) &&
				!ObjectHelper.isEqual(
					(props as any)[propName],
					(nextProps as any)[propName]
				)
			) {
				(schedulerInstance as any)[propName] = (nextProps as any)[propName]
			}
		});

		// Reflect feature config changes
		features.forEach((featureName) => {
			const currentProp = (props as any)[featureName],
				nextProp = (nextProps as any)[featureName];

			if (
				featureName in props &&
				!ObjectHelper.isDeeplyEqual(currentProp, nextProp)
			) {
				(schedulerInstance as any).features[
					featureName.replace(this.featureRe, "")
				].setConfig(nextProp);
			}
		});

		// Reflect JSX cell changes
		return nextState.generation !== this.state.generation;
	}

	render() {
		return (
			<div
				className={"b-react-scheduler-container"}
				ref={(el) => (this.el = el)}
			>
				{this.state.portals}
			</div>
		);
	}
}
