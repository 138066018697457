import {
	Control,
	useFieldArray,
	useFormContext,
	UseFormMethods,
} from "@maana-io/react-hook-form";
import { makeStyles, TextField } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { GraphQLInputField } from "graphql";
import { Box, Button } from "grommet";
import { get } from "lodash";
import React from "react";

import { FormStateContext } from "../../context/FormStateContext";
import { findTypeOfField } from "./helpers/findTypeOfField";

type FieldArrayProps = {
	control: Control;
	register: UseFormMethods["register"];
	name: string;
	parentFieldName: string;
	schemaString: string;
	field: GraphQLInputField;
	newItemConstructor: any;
};

const useStyles = makeStyles((theme) => ({
	root: {},
}));

export const FieldArrayOfScalars = ({
	control,
	register,
	name,
	parentFieldName,
	schemaString,
	field,
	newItemConstructor,
}: FieldArrayProps) => {
	const classes = useStyles({ name });
	const { fields, append, remove } = useFieldArray({
		control,
		name: parentFieldName,
	});

	const { getValues } = useFormContext();
	const currentValues = getValues();

	const { defaultValues } = React.useContext(FormStateContext);

	const required = React.useMemo(
		() => field.astNode?.type.kind === "NonNullType",
		[field.astNode?.type.kind]
	);

	const typeOfField = findTypeOfField(field);

	return (
		<div data-component-id="FieldArrayOfScalars" className={classes.root}>
			{/* <Typography variant="h6">{capitalCase(name)}</Typography> */}
			<ul>
				{fields.map((_field, index) => {
					// ? this answers the question of "an array of _what_?"
					const fieldName = `${parentFieldName}[${index}].value`;
					const theValue =
						get(currentValues, fieldName) ||
						get(defaultValues, fieldName) ||
						_field.value;
					switch (typeOfField) {
						// * simple scalars
						case "Float":
						case "Int":
						case "String":
							return (
								<li key={_field.id || index}>
									<Box
										align="center"
										justify="start"
										direction="row"
										gap="small"
									>
										<TextField
											name={fieldName}
											placeholder={typeOfField}
											inputRef={control.register({
												// valueAsNumber:
												// 	typeOfField !== "String",
												setValueAs: (value) => {
													if (value === "") {
														return null;
													}

													return typeOfField ===
														"String"
														? String(value)
														: parseFloat(value);
												},
											})}
											defaultValue={theValue}
											required={required}
											type={
												typeOfField === "String"
													? "text"
													: "number"
											}
										/>

										<Button
											icon={<Delete />}
											onClick={() => remove(index)}
											label="Delete"
										/>
									</Box>
								</li>
							);
						default:
							console.log(
								`FieldArray cannot render: [type: ${_field.type}][${parentFieldName}][${name}]: `,
								_field
							);
							return null;
					}
				})}
			</ul>

			<section>
				<Button
					onClick={() => {
						append({ value: "" });
					}}
					label="Add"
				/>
			</section>
		</div>
	);
};
