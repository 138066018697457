import React, { memo } from "react";
import { useHistory } from "react-router";
import { Box, Menu, Text } from "grommet";

import SettingsIcon from "@material-ui/icons/Settings";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

import { useMutation, gql } from "urql";
import { REPORTING_WS } from "../../api/UrqlClientProvider";
import { Snackbar } from "@material-ui/core";
import { LoadingIndicator } from "../LoadingIndicator";
import { getUserAuthClient } from "../../util/auth";
import { makeStyles } from "@material-ui/core";
import { useRoleManager } from "../../hooks/useRoleManager";

const GENERATE_FLEET_REPORT = gql<{
	generateAndSendFleetReport: boolean;
}>`
	mutation {
		generateAndSendFleetReport
	}
`;

const GENERATE_PERFORMANCE_REPORT = gql<{
	generateAndSendPerformanceReport: boolean;
}>`
	mutation {
		generateAndSendFleetPerformanceReport
	}
`;

const useStyles = makeStyles(() => ({
	colorPrimary: {
		color: "#000",
	},
}));

export const ActionMenu = memo(() => {
	const history = useHistory();
	const { can, hasRoles } = useRoleManager();

	const [
		{ fetching: sendingFleetReport, error: fleetReportError },
		generateFleetReport,
	] = useMutation(GENERATE_FLEET_REPORT);

	const [
		{ fetching: sendingPerformanceReport, error: performanceReportError },
		generatePerformanceReport,
	] = useMutation(GENERATE_PERFORMANCE_REPORT);

	const classes = useStyles();

	return (
		<Box align="end">
			<Snackbar
				open={Boolean(fleetReportError)}
				anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
			>
				<Alert severity="warning">
					<AlertTitle>Failed to Send Fleet Report</AlertTitle>
					{fleetReportError && fleetReportError.message}
				</Alert>
			</Snackbar>
			<Snackbar
				open={Boolean(performanceReportError)}
				anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
			>
				<Alert severity="warning">
					<AlertTitle>Failed to Send Performance Report</AlertTitle>
					{performanceReportError && performanceReportError.message}
				</Alert>
			</Snackbar>

			{sendingFleetReport ? (
				<Box direction="row" pad="small">
					<LoadingIndicator />
					<Text size="small">Sending fleet report...</Text>
				</Box>
			) : sendingPerformanceReport ? (
				<Box direction="row" pad="small">
					<LoadingIndicator />
					<Text size="small">Sending VP report...</Text>
				</Box>
			) : (
				<Menu
					icon={
						<SettingsIcon
							color="primary"
							classes={{ colorPrimary: classes.colorPrimary }}
						/>
					}
					items={[
						{
							label: "Generate Fleet Report",
							onClick: () => {
								generateFleetReport(undefined, {
									url: REPORTING_WS,
								});
							},
							access: "generate fleet report",
						},
						{
							label: "Generate VP Report",
							onClick: () => {
								generatePerformanceReport(undefined, {
									url: REPORTING_WS,
								});
							},
							access: "generate vp report",
						},
						{
							label: "Manage Reports",
							onClick() {
								history.push("/reportRecipients");
							},
							access: "manage reports",
						},
						{
							label: "Operational Parameters",
							onClick() {
								history.push("/operationalParameters");
							},
							access: "view operational parameters page",
						},
						{
							label: "User Activity",
							onClick() {
								history.push("/userActivity");
							},
						},
						{
							label: "Log out",
							onClick() {
								getUserAuthClient().logout?.();
								window.location.reload(); // We don't have proper handling for changing sessions - therefore safest is to restart the webapp
							},
						},
					].filter((item: any) =>
						("access" in item && !can(item.access)) ||
						("roles" in item && hasRoles(item.roles))
							? false
							: true
					)}
				/>
			)}
		</Box>
	);
});
