import React, { useCallback, Dispatch } from "react";

import { Box, BoxProps, Button, Heading, Text } from "grommet";
import { Select } from "grommet-icons";
import Alert from "@material-ui/lab/Alert";
import { useDispatch, useSelector } from "react-redux";

import {
	requirementsWithValidationState,
	RequirementWithValidationErrors,
} from "../../selectors/requirement.selectors";
import { IState } from "../../store/reducers";
import { RequirementEditForm } from "./RequirementEditForm";
import {
	DISMISS_REQ_WARNING,
	IRequirementReducerActions,
	REMOVE_A_BATCH_REQUIREMENT,
	UPDATE_A_BATCH_REQUIREMENT,
} from "../../store/actions/requirements.actions";
import produce from "immer";
import { useMachineSelector } from "../../selectors/machine.selectors";

interface BatchEditRightpaneProps extends BoxProps {
	selectedRequirementId: string | null;
	children?: undefined;
	onCancel: any;
}

export const BatchEditRightPane: React.FC<BatchEditRightpaneProps> = function ({
	selectedRequirementId,
	onCancel,
	...rest
}) {
	const products = useMachineSelector((context) => context.products);
	const selectActiveRequirement = useCallback(
		(state: IState): [number, RequirementWithValidationErrors] | null => {
			const allRequirements = requirementsWithValidationState(state);
			const id = allRequirements.findIndex(
				({
					requirement: {
						item: { id },
					},
				}) => id === selectedRequirementId
			);
			return id === -1 ? null : [id, allRequirements[id]];
		},
		[selectedRequirementId]
	);
	const selectedRequirement: any = useSelector(selectActiveRequirement);
	const dispatch = useDispatch<Dispatch<IRequirementReducerActions>>();
	const hasErrors =
		selectedRequirement !== null &&
		(selectedRequirement[1].errors.length > 0 ||
			selectedRequirement[1].requirement.warnings.length > 0);

	const updateBatchRequirement = useCallback(
		(req: any) => {
			const requirement = {
				...req,
				longs: produce(req.longs, (draft: any) => {
					draft.forEach((long: any) => {
						// * ensure that product has `cleanStatus` field
						long.product = products?.byId[long.product.id];
					});
				}),
				shorts: produce(req.shorts, (draft: any) => {
					draft.forEach((short: any) => {
						// * ensure that product has `cleanStatus` field
						short.product = products?.byId[short.product.id];
					});
				}),
			};

			// console.log("requirement: ", requirement);

			dispatch({
				type: UPDATE_A_BATCH_REQUIREMENT,
				payload: {
					id: selectedRequirement[0],
					requirement,
				},
			});
		},
		[dispatch, selectedRequirement, products?.byId]
	);

	// console.log("products: ", products);

	return (
		<Box {...rest}>
			{selectedRequirement === null ? (
				<Box justify="center" gap="large" align="center">
					<h3>
						Select a Requirement To View Details
						<hr></hr>
					</h3>
					<Select color="blue1" size="xlarge"></Select>
				</Box>
			) : (
				<>
					<Text>
						Requirement:
						<Text color="blue1">{` ${selectedRequirement[1].requirement.item.id}`}</Text>
						(
						{!hasErrors ? (
							<Text color="status-ok">Valid</Text>
						) : (
							<Text color="status-error">Invalid</Text>
						)}
						)
					</Text>
					{/* <pre>{JSON.stringify(selectedRequirement, null, 2)}</pre> */}
					{hasErrors &&
						selectedRequirement[1].requirement.warnings.length >
							0 && (
							<>
								<Heading level="5">Warnings</Heading>
								{selectedRequirement[1].requirement.warnings.map(
									(message: any, id: any) => (
										<Alert
											style={{ marginBottom: 4 }}
											severity="warning"
											key={`warn_${id}`}
											onClose={() =>
												dispatch({
													type: DISMISS_REQ_WARNING,
													payload: {
														requirementId:
															selectedRequirement[0],
														warningId: id,
													},
												})
											}
										>
											{message}
										</Alert>
									)
								)}
							</>
						)}
					<hr style={{ width: "100%" }} />
					<Button
						color="status-critical"
						label="Remove from list"
						primary
						onClick={() =>
							dispatch({
								type: REMOVE_A_BATCH_REQUIREMENT,
								payload: selectedRequirement[0],
							})
						}
					/>
					<RequirementEditForm
						requirement={selectedRequirement[1].requirement.item}
						onCancel={() => {
							onCancel();
						}}
						onSubmitRequirement={updateBatchRequirement}
					/>
				</>
			)}
		</Box>
	);
};
