import React, { useCallback, useRef } from "react";

import { Button, ButtonProps } from "grommet";
import { DocumentCsv } from "grommet-icons";
import { CircularProgress } from "@material-ui/core";

interface CSVRequirementsUploadProps
	extends ButtonProps,
		Omit<React.HTMLAttributes<HTMLButtonElement>, "color"> {
	onFileAdded?: (files: FileList) => void;
}

export const CSVRequirementsUpload: React.FC<CSVRequirementsUploadProps> = ({
	onFileAdded,
	disabled,
	...rest
}) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const onClick = useCallback<React.MouseEventHandler>((e) => {
		inputRef.current?.click();
	}, []);
	const onInputChange = useCallback<
		React.ChangeEventHandler<HTMLInputElement>
	>(
		(e) => {
			if (e.target.files?.length) {
				onFileAdded?.(e.target.files);
			}
		},
		[onFileAdded]
	);
	return (
		<>
			<Button
				{...rest}
				onClick={onClick}
				icon={
					disabled ? (
						<CircularProgress
							color="primary"
							className="color white"
							size="24px"
						/>
					) : (
						<DocumentCsv className="color white" />
					)
				}
				primary
				// color="brand"
				disabled={disabled}
				label="Add requirements via CSV Upload"
			/>
			<input
				style={{ display: "none" }}
				ref={inputRef}
				type="file"
				accept="text/csv"
				data-testid="file_input"
				onChange={onInputChange}
			/>
		</>
	);
};
