import { makeGetSchema, createApolloClient } from "./apolloClient";
import { APIClientConfig } from "./types";
import { createClient } from "urql";
import UserContext from "../context/UserContext";
import { Configuration } from "../util/config";

const FAST_SCHEDULER_WS = Configuration.FAST_SCHEDULER_URL;

const fastSchedulerConfig: APIClientConfig = {
	cacheKey: "fast-scheduler",
	uri: FAST_SCHEDULER_WS,
	fetchPolicy: "network-only",
};

export const fastSchedulerClient = createClient({
	url: FAST_SCHEDULER_WS,
	fetchOptions: () => {
		return {
			headers: {
				...UserContext.getAuthHeader(),
			},
		};
	},
});

export const fastSchedulerApolloClient = createApolloClient(
	fastSchedulerConfig
);

export const getFastSchedulerSchema = makeGetSchema(fastSchedulerConfig);
