import React, { memo, useContext } from "react";
import { Box, Text } from "grommet";
import { useObservableState } from "observable-hooks";
import { pipe, toObservable, concat, fromValue } from "wonka";
import { from, of, Subscribable } from "rxjs";
import { useSelector } from "@xstate/react";
import { distinctUntilChanged, switchMap } from "rxjs/operators";
import { OperationResult } from "urql";
import {
	I_PlannedAndActualVesselScheduleTuple,
	PerformanceMetrics,
	Query,
	QueryComputeFleetScheduleMetricsArgs,
} from "../../types/generated/q-vessel-schedule-lifecycle-v6.types";
import { FleetMachineContext, IFleetMachineState } from "../../App";
import { vesselScheduleLifecycleClient } from "../../api/vesselScheduleLifecycleClient";
import { FLEET_SCHEDULE_METRICS } from "../../queries/schedules";
import { formatCurrency, formatNumber } from "../../util/numberFormatter";
import { recursivelyDeleteTypenameProperty as rmType } from "../../store/util/clean-props";
import { VesselWithQ88PRandUt } from "../../types/generated/q-v2-vessels.types";
import { keyBy } from "lodash";
import { errorMessageFromGraphql } from "../utility/errorMessageFromGraphql";
import { SCHEDULE_FILTER_MAP } from "../../machines/fleetMachine";

/** Excludes schedules of spots and inactive vessels, adds empty schedules for active vessels that don't have a schedule */
function schedulesOfActiveVessels(
	vessels: VesselWithQ88PRandUt[],
	schedules: I_PlannedAndActualVesselScheduleTuple[]
): I_PlannedAndActualVesselScheduleTuple[] {
	//this just allows filtering of schedules by vessels that are to be excluded
	const schedulesByVessel = keyBy(
		schedules,
		({ plannedSchedule: { vesselId } }) => vesselId
	);
	//vessels to exclude -> this seems to be always empty (probably because vessels that are parked should not have schedules)
	const v = vessels.filter(
		({ vessel: { isParked, id: vesselId } }) =>
			isParked && schedulesByVessel[vesselId]
	);
	/*
	const s = vessels
		.filter(
			({ vessel: { isParked, id: vesselId } }) =>
				!isParked && schedulesByVessel[vesselId]
		)
		.map(({ vessel: { id: vesselId } }) => schedulesByVessel[vesselId]);
	*/

	//this filters out schedules where there are vessels in the list to exclude
	const sch = schedules.filter(
		(s) => !v.find((v) => s.plannedSchedule.vesselId === v.vessel.id)
	);

	return sch;
}

type MetricsResponse = Pick<Query, "computeFleetScheduleMetrics">;

/*
id
vesselsMetrics{
	id
	vesselId
	metrics{
		id
		totalCost
		utilization
		totalVolume
		costPerBBL
	}
}
metrics{
	id
	totalCost
	utilization
	totalVolume
	costPerBBL
}
*/

function MetricsContent({
	totalCost,
	totalVolume,
	costPerBBL,
	utilization,
	name,
}: PerformanceMetrics & {
	name: string;
}): React.ReactElement {
	return (
		<Box direction="row" align="stretch" justify="between">
			<Text size="small" color="#000">
				<b>{name}:</b>
			</Text>
			<Text size="small" color="#444">
				Total Cost:&nbsp;
				<b>{formatCurrency(totalCost)}</b>
			</Text>
			<Text size="small" color="#444">
				Total Volume:&nbsp;
				<b>{formatNumber(totalVolume)} bbl</b>
			</Text>
			<Text size="small" color="#444">
				Cost Per bbl:&nbsp;
				<b>{formatCurrency(costPerBBL)}</b>
			</Text>
			<Text size="small" color="#444">
				Utilization:&nbsp;
				<b>{(utilization * 100).toFixed(0)}%</b>
			</Text>
		</Box>
	);
}

export const FleetMetrics = memo<object>(function FleetMetrics() {
	const fleetMachineService = useContext(FleetMachineContext);

	const [state] = useObservableState(
		() =>
			from(fleetMachineService as Subscribable<IFleetMachineState>).pipe(
				distinctUntilChanged(
					(a, b) =>
						a.context.schedule === b.context.schedule &&
						a.context.selectedDateRange ===
							b.context.selectedDateRange &&
						a.context.vessels === b.context.vessels
				),
				switchMap(
					({ context: { schedule, selectedDateRange, vessels } }) => {
						if (
							!schedule ||
							!schedule.length ||
							!vessels ||
							!vessels.length
						)
							return of("idle" as const);
						const [
							startDate,
							endDate,
						] = selectedDateRange.map((d) =>
							Math.floor(d.valueOf() / 1000)
						);

						fleetMachineService.send("SET_VESSEL_METRICS", {
							vesselMetrics: null,
						});

						const requestPipe = pipe(
							concat<
								| OperationResult<
										MetricsResponse,
										QueryComputeFleetScheduleMetricsArgs
								  >
								| "loading"
							>([
								fromValue("loading"),
								vesselScheduleLifecycleClient.query(
									FLEET_SCHEDULE_METRICS,
									{
										startDate,
										endDate,
										plannedAndActualSchedules: rmType(
											schedulesOfActiveVessels(
												vessels,
												schedule
												//getOnlyLatestSchedules(schedule)
											)
										),
									},
									{ requestPolicy: "network-only" }
								),
							]),
							toObservable
						);

						if (requestPipe?.subscribe) {
							let requestKey = 0;
							// @ts-ignore
							requestPipe.subscribe((result) => {
								if (!result?.error && result?.data) {
									if (
										result?.operation &&
										result.operation.key === requestKey
									)
										return false;
									const {
										computeFleetScheduleMetrics: {
											actualMetrics: {
												vesselsMetrics: actualVesselMetrics,
											},
											plannedMetrics: {
												vesselsMetrics: plannedVesselMetrics,
											},
										},
									} = result.data;
									const vesselMetrics: any = {};
									if (actualVesselMetrics)
										for (const metric of actualVesselMetrics) {
											if (
												!(
													metric.vesselId in
													vesselMetrics
												)
											)
												vesselMetrics[
													metric.vesselId
												] = {};
											vesselMetrics[
												metric.vesselId
											].actual = metric.metrics;
										}
									if (plannedVesselMetrics)
										for (const metric of plannedVesselMetrics) {
											if (
												!(
													metric.vesselId in
													vesselMetrics
												)
											)
												vesselMetrics[
													metric.vesselId
												] = {};
											vesselMetrics[
												metric.vesselId
											].planned = metric.metrics;
										}

									fleetMachineService.send(
										"SET_VESSEL_METRICS",
										{
											vesselMetrics,
										}
									);
									requestKey = result.operation.key;
								}
							});
						}

						return requestPipe as Subscribable<
							| OperationResult<
									MetricsResponse,
									QueryComputeFleetScheduleMetricsArgs
							  >
							| "loading"
						>;
					}
				)
			),
		"idle"
	);

	const selectedFilter = useSelector(
		fleetMachineService,
		({ context: { scheduleFilter } }) => scheduleFilter
	);

	const currentFilter = SCHEDULE_FILTER_MAP[selectedFilter]
		? SCHEDULE_FILTER_MAP[selectedFilter]
		: SCHEDULE_FILTER_MAP.All;

	return (
		<Box
			direction="column"
			align="start"
			justify="between"
			className="metrix-bar"
		>
			{state === "idle" ? (
				<Box pad="small">
					<Text size="small" color="#444">
						No metrics available
					</Text>
				</Box>
			) : state === "loading" ? (
				<Box pad="small">
					<Text size="small" color="#444">
						Calculating Fleet Metrics...
					</Text>
				</Box>
			) : state.data?.computeFleetScheduleMetrics ? (
				<>
					{(currentFilter === SCHEDULE_FILTER_MAP.All ||
						currentFilter === SCHEDULE_FILTER_MAP.Actual) && (
						<MetricsContent
							{...state.data.computeFleetScheduleMetrics
								.actualMetrics.metrics}
							name="Actual"
						/>
					)}
					{(currentFilter === SCHEDULE_FILTER_MAP.All ||
						currentFilter === SCHEDULE_FILTER_MAP.Planned) && (
						<MetricsContent
							{...state.data.computeFleetScheduleMetrics
								.plannedMetrics.metrics}
							name="Planned"
						/>
					)}
				</>
			) : (
				<Box direction="row" alignContent="center">
					Loading metrics failed:
					{` ${errorMessageFromGraphql(state.error?.message)}`}
				</Box>
			)}
		</Box>
	);
});
