import moment from "moment-timezone";
import { fastSchedulerClient } from "./api/fastSchedulerClient";
import { GET_DEFAULT_CONSTANTS } from "./queries/schedules";
import { ConstantsAsInput } from "./types/generated/q-vessel-schedule-lifecycle-v6.types";

export const DATE_FORMAT = "MM/DD/YYYY";
export const TIME_FORMAT = "hh:mm";
export const DATE_TIME_FORMAT = "MM/DD/YYYY hh:mm A";
export const TIMEZONE = "UTC";

export const toDateFormat = (
	date: string | Date | undefined,
	defaultReturnValue: any = null
) => {
	return date ? moment(date!).format(DATE_FORMAT) : defaultReturnValue;
};

export const toDateTimeFormat = (
	dateTime: string | Date | undefined,
	defaultReturnValue: any = null
) => {
	return dateTime
		? moment(dateTime!).format(DATE_TIME_FORMAT)
		: defaultReturnValue;
};

const datetime = {
	DATE_FORMAT,
	TIME_FORMAT,
	DATE_TIME_FORMAT,
	TIMEZONE,
	toDateFormat,
	toDateTimeFormat,
};

export const constants = {
	id: "constants",
	defaultFuelPrice: 400,
	defaultDieselPrice: 650,
	refuelThreshold: 400,
	criticalRefuelThreshold: 300,
	operationalOverhead: 15,
	datetime,
};

export async function getConstants(): Promise<ConstantsAsInput> {
	const response = await fastSchedulerClient
		.query(GET_DEFAULT_CONSTANTS)
		.toPromise();
	if (!response.data?.constants)
		throw new Error("Unable to load default constants");

	return response.data.constants;
}
