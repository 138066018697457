const errRe = /error List\((.+)\)/;

/** Since messages in graphql are in the following form:
 * eg: [GraphQL] CKGError: Call to function [Maana Fast Scheduler:validateAndOrderSchedules] failed with error List(Requirement completes after contract expiration on 2021-04-07T00:00:00.000Z)
 * This helps to extract the human readable part
 */
export function errorMessageFromGraphql(original: string = ""): string {
	const match = errRe.exec(original);
	return match ? match[1] : original;
}
