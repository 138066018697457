import React, { useCallback, useMemo } from "react";
import { memoize } from "lodash";
import { useController, UseControllerOptions } from "react-hook-form";
import {
	Box,
	Stack,
	Tip,
	Button,
	Text,
	TextInput,
	FormField,
	FormFieldProps,
	TextInputProps,
} from "grommet";
import { CircleInformation } from "grommet-icons";

import { useFormErrors } from "../Requirements/useFormErrors";

/** Converts the suggestion select event to the form change event */
const onSuggestionSelect = memoize(
	(changeHandler: React.ChangeEventHandler) => (e: {
		suggestion?: string;
	}): void => {
		if (e.suggestion) {
			changeHandler(e.suggestion as any);
		}
	}
);
onSuggestionSelect.cache = new WeakMap();

interface ControlledTextInputProps
	extends Pick<UseControllerOptions, "control" | "name" | "defaultValue">,
		Pick<FormFieldProps, "label">,
		Pick<TextInputProps, "icon" | "placeholder">,
		Pick<
			React.InputHTMLAttributes<HTMLInputElement>,
			"type" | "disabled" | "max" | "min"
		> {
	errorMessage?: string;
	/** A function that allows validating input values directly - to avoid user entering invalid data */
	resolver?(value: string): unknown;
	step?: number;
	dynamicSteps?: boolean | undefined;
}

export function ControlledTextInput({
	label,
	icon,
	placeholder,
	type,
	disabled,
	resolver,
	min,
	max,
	step,
	dynamicSteps,
	...rest
}: ControlledTextInputProps): React.ReactElement {
	const {
		field: { name, onChange, value, onBlur },
	} = useController(rest);
	const changeHandler = useCallback<
		React.ChangeEventHandler<HTMLInputElement>
	>(({ target: { value } }) => onChange(resolver ? resolver(value) : value), [
		onChange,
		resolver,
	]);

	const errors = useFormErrors(rest.control);

	const thisFieldsErrors = errors?.filter((err) => err.keys?.includes(name));

	const errorMessage = thisFieldsErrors?.[0]?.message || rest.errorMessage;

	const labelMarkup = (
		<Stack anchor="top-right">
			<Box flex justify="start">
				<Text
					margin="none"
					color={!!errorMessage ? "status-error" : "dark-1"}
				>
					{label}
				</Text>
			</Box>
			{!!errorMessage ? (
				<Tip
					content="Required"
					dropProps={{ align: { right: "left" } }}
				>
					<Button
						plain
						size="small"
						icon={<CircleInformation color="status-error" />}
					/>
				</Tip>
			) : null}
		</Stack>
	);

	const valLength = `${value}`.length - 1;
	const inputStep = useMemo(() => {
		if (dynamicSteps && step) {
			const zeros = valLength > 0 ? "0".repeat(valLength - 1) : "";
			return Number(`1${zeros}`) * step;
		}
		return step;
	}, [valLength, dynamicSteps, step]);

	return (
		<FormField label={labelMarkup}>
			<TextInput
				disabled={disabled}
				icon={icon}
				placeholder={placeholder}
				type={type}
				onBlur={onBlur}
				onChange={changeHandler}
				value={value}
				step={inputStep}
				name={name}
				min={min}
				max={max}
			/>
		</FormField>
	);
}
