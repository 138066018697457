import { LOCATION_CHANGE } from "connected-react-router";
import { take } from "redux-saga/effects";

export function* watchForNavigationChangeSaga(): any {
	while (true) {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const action = yield take(LOCATION_CHANGE);

		// * do stuff on location change
	}
}
