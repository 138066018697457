import React from "react";
import AnchorIco from "../../images/anchor_ico.svg";
import LoadIco from "../../images/load_ico.svg";
import UnloadIco from "../../images/unload_ico.svg";
import ArrowIcoBlack from "../../images/arrow_ico_black.svg";
import dayjs from "dayjs";

type FleetActionProps = {
	actionStatusClass?: string;
	id?: string;
	width?: number;
	color?: any;
	left?: number;
	port?: string | null | undefined;
	product?: string | null | undefined;
	productQty?: string | null | undefined;
	actionType?: string | null | undefined;
	fromDate?: Date;
	toDate?: Date;
	isActual?: boolean;
	isNested?: boolean;
};
const FleetAction: React.FC<FleetActionProps> = ({
	actionStatusClass,
	id,
	width = "auto",
	color,
	left,
	port,
	product,
	productQty,
	actionType,
	fromDate,
	toDate,
	isActual,
	isNested = true,
}) => {
	const ico = () => {
		if (actionType) {
			switch (actionType) {
				case "load":
					return LoadIco;
				case "unload":
					return UnloadIco;
			}
		}

		return AnchorIco;
	};

	return (
		<div
			className={`${isNested ? "nested" : ""} ${actionStatusClass}`}
			data-id={id}
			style={{
				display: "flex",
				flexDirection: "column",
				width,
				height: 60,
				backgroundColor: isActual
					? color?.active?.shade
					: color?.disabled?.shade,
				transform: `translate(${left}px, 0)`,
				zIndex: 3,
				borderRadius: 6,
				fontSize: 14,
				fontWeight: 700,
				color: "#fff",
				paddingLeft: 0,
			}}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					width: "100%",
					height: 25,
					backgroundColor: isActual
						? color?.active?.base
						: color?.disabled?.base,
					borderRadius: 6,
				}}
			>
				<img
					style={{ margin: "0 4px 0 4px" }}
					height={20}
					width={20}
					src={ico()}
					alt="fleet-ico"
				/>
				<div>{port}</div>

				<div></div>
			</div>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "flex-start",
					width: "100%",
					flexGrow: 1,
					color: "#000",
					paddingLeft: 5,
					paddingBottom: 2,
					fontWeight: 400,
					opacity: isActual ? 1 : 0.4,
				}}
			>
				<div style={{ fontWeight: 700, paddingRight: 5 }}>
					{product}
				</div>
				<div>{productQty}</div>
			</div>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "flex-start",
					width: "100%",
					flexGrow: 1,
					color: "#000",
					fontWeight: 400,
					paddingBottom: 2,
					paddingLeft: 5,
					opacity: isActual ? 1 : 0.4,
				}}
			>
				<div>{dayjs(fromDate).format("MMM DD")}</div>
				<img
					style={{
						margin: "0 4px 0 4px",
						fill: "#000",
						opacity: isActual ? 1 : 0.4,
					}}
					width={28}
					src={ArrowIcoBlack}
					alt="fleet-ico"
				/>
				<div>{dayjs(toDate).format("MMM DD")}</div>
			</div>
			<div className="status-indicator" />
		</div>
	);
};

export default FleetAction;
