import React, { Dispatch } from "react";
import { Paragraph } from "grommet";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

import { formattingWarningsSelector } from "../../selectors/requirement.selectors";
import {
	DISMISS_PARSE_WARNING,
	IRequirementReducerActions,
} from "../../store/actions/requirements.actions";

export const CSVParseWarnings: React.FC = function () {
	const warnings = useSelector(formattingWarningsSelector);
	const dispatch = useDispatch<Dispatch<IRequirementReducerActions>>();
	if (!(warnings && warnings.length)) return null;
	return (
		<>
			<Paragraph>Dismiss these warnings before proceeding:</Paragraph>
			{warnings.map((text, id) => (
				<Alert
					severity="warning"
					key={text}
					onClose={() => {
						dispatch({
							type: DISMISS_PARSE_WARNING,
							payload: id,
						});
					}}
				>
					<AlertTitle>Issue with the provided CSV</AlertTitle>
					{text}
				</Alert>
			))}
		</>
	);
};
