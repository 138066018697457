import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import cx from "classnames";
import React, { CSSProperties } from "react";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		marginRight: theme.spacing(2),
		"& > * + *": {
			marginLeft: theme.spacing(2),
		},
	},
	colorPrimary: {
		color: (props: { colorPrimary: string }) =>
			props.colorPrimary ? props.colorPrimary : "#000",
	},
}));

interface LoadingIndicatorProps {
	className?: string;
	styles?: CSSProperties;
	size?: number;
	color?: string;
}

export const LoadingIndicator = (props: LoadingIndicatorProps) => {
	const classes = useStyles({ colorPrimary: props.color || "#f00" });

	return (
		<div className={cx(classes.root, props.className)} style={props.styles}>
			<CircularProgress
				size={props.size}
				classes={{ colorPrimary: classes.colorPrimary }}
			/>
		</div>
	);
};

LoadingIndicator.defaultProps = {
	size: 24,
};
