import React from "react";
import { Dialog } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

type CommonErrorModalProps = {
	errorMessage?: string | undefined | null;
	isOpen: boolean;
	onClose?: any;
};

const CommonErrorModal: React.FC<CommonErrorModalProps> = ({ errorMessage, isOpen, onClose }) => {
	return (
		<Dialog open={isOpen} maxWidth={"lg"}>
			<Alert severity="error" onClose={() => onClose()}>
				<div style={{ width: "100%" }}>{errorMessage && errorMessage.slice(0, 1500)}</div>
			</Alert>
		</Dialog>
	);
};

export default CommonErrorModal;
