import type {
	ISchedulerItem,
	ItemOrUT,
} from "../../components/mapIScheduleTuplesToBryntumEvent";

function doEventsOverlap(a: ItemOrUT, b: ItemOrUT): boolean {
	const left = Math.max(a.startDate.valueOf(), b.startDate.valueOf());
	const right = Math.min(a.endDate.valueOf(), b.endDate.valueOf());
	return right - left > 0;
}

/** Forces actual requirements always appear on top of scheduled requirements */
export function horizontalEventSorter(a: ItemOrUT, b: ItemOrUT): number {
	// UT vs Requirement
	if (a.eventType !== b.eventType) {
		if (doEventsOverlap(a, b)) {
			// IF the events overlap - UT should always be below other events
			return Number(a.eventType === "ut") - Number(b.eventType === "ut");
		} else {
			// If they don't overlap - they are compared +- as equals
			return a.startDate.valueOf() - b.startDate.valueOf();
		}
	} else if (a.eventType === "ut") {
		// UT vs UT are equals
		return a.startDate.valueOf() - b.startDate.valueOf();
	} else {
		// Requirement vs Requirement
		const ar = a as ISchedulerItem,
			br = b as ISchedulerItem;
		if (ar.isActual !== br.isActual) {
			// Actual are above planned
			return Number(br.isActual) - Number(ar.isActual);
		} else {
			// Similar events are compared by startdate
			return a.startDate.valueOf() - b.startDate.valueOf();
		}
	}
}
