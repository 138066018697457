import { memo } from "react";
import { Box, DropButton } from "grommet";

import SettingsIcon from "@material-ui/icons/HelpOutline";

import FleetAction from "../Fleet/FleetAction";
import FleetActionSmall from "../Fleet/FleetActionSmall";
import FleetActionWaiting from "../Fleet/FleetActionWaiting";
import FleetActionVoyage from "../Fleet/FleetActionVoyage";

import { getEventColorForProductNew } from "../../components/utility/EventActionStyles";

import CleanIco from "../../images/clean_ico.svg";
import RefuelIco from "../../images/refuel_ico.svg";
import LockIco from "../../images/lock_ico.svg";

export const LegendMenu = memo(() => {
	const renderElement = (child: React.ReactNode, title: string) => {
		return (
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					width: 400,
					marginTop: 10,
				}}
			>
				<div
					style={{
						width: 200,
						display: "flex",
						justifyContent: "center",
					}}
				>
					{child}
				</div>
				<div
					style={{
						flexGrow: 1,
						alignItems: "center",
						justifyContent: "center",
						display: "flex",
					}}
				>
					<div
						style={{
							alignSelf: "center",
							marginTop: 4,
							height: 2,
							border: "1px solid #C4C4C4",
							width: 10,
						}}
					></div>
				</div>
				<div style={{ width: 150, fontSize: 14 }}>{title}</div>
			</div>
		);
	};

	const productColorGO = getEventColorForProductNew("GASOLINE");
	const productColorMTBE = getEventColorForProductNew("MTBE");

	return (
		<Box align="end">
			<DropButton
				icon={<SettingsIcon />}
				dropAlign={{ top: "bottom", right: "right" }}
				dropContent={
					<Box pad="small" background="#fff" gap="small">
						<div
							style={{
								display: "flex",
								flexDirection: "column",

								justifyContent: "space-between",
							}}
						>
							{renderElement(
								<FleetAction
									width={175}
									color={productColorGO}
									left={0}
									port={"YANBU"}
									product={""}
									actionType={"load"}
									fromDate={new Date()}
									toDate={new Date()}
									isActual={true}
									isNested={false}
								/>,
								"Loading"
							)}
							{renderElement(
								<FleetAction
									width={175}
									color={productColorMTBE}
									left={0}
									port={"YANBU"}
									product={""}
									actionType={"unload"}
									fromDate={new Date()}
									toDate={new Date()}
									isActual={true}
									isNested={false}
								/>,
								"Discharging"
							)}
							{renderElement(
								<FleetActionWaiting
									width={175}
									left={0}
									port={"YANBU"}
									isActual={true}
									isNested={false}
								/>,
								"Waiting"
							)}
							{renderElement(
								<FleetActionVoyage
									width={175}
									color={"#C4C4C4"}
									left={0}
									isLaden={true}
									icon={"arrow_right_alt"}
									isLocked={false}
									isActual={true}
									position={"none"}
								/>,
								"Sailing, Laden"
							)}
							{renderElement(
								<FleetActionVoyage
									width={175}
									color={"#C4C4C4"}
									left={0}
									isLaden={false}
									icon={"arrow_right_alt"}
									isLocked={false}
									isActual={true}
									position={"none"}
								/>,
								"Sailing, Ballast"
							)}
							{renderElement(
								<FleetActionSmall
									ico={RefuelIco}
									isActual={true}
								/>,
								"Refueling"
							)}
							{renderElement(
								<FleetActionSmall
									ico={CleanIco}
									isActual={true}
								/>,
								"Cleaning"
							)}

							{renderElement(
								<img
									height={24}
									width={24}
									src={LockIco}
									alt="lock-ico"
								/>,
								"Locked for calculation"
							)}
						</div>
					</Box>
				}
			/>
		</Box>
	);
});
