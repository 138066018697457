import { store } from "../../store/index";
import { portsSelector } from "../../selectors/ports.selectors";

export function portOrTerminalInAction(
	id?: string | null,
	terminalId?: string | null
): string | undefined | null {
	if (!id) return id;

	//get the port
	const ports = portsSelector(store.getState());
	//return ports?.byId[id]?.name || id;
	const port = ports?.byId[id];

	//we have a port name in case there is no terminal
	var name = port?.name;
	//if we have a terminal Id and there is a port with terminals

	if (terminalId) {
		if (port && port.terminals) {
			//find the terminal
			const terminal = port.terminals.find((t) => t.id === terminalId);

			//update the name with the terminal name
			if (terminal?.name) {
				name = terminal.name;
			}
		}
	}
	return name;
}
