import {
	EventTooltip,
	GridFeatureManager,
	StringHelper,
} from "@bryntum/schedulerpro";
import {
	ISchedulerItem,
	ISchedulerItemAction,
	ItemOrUT,
} from "../mapIScheduleTuplesToBryntumEvent";
import { getEventColorForProduct } from "./EventActionStyles";
import { portIdToName } from "./portIdToName";
// The bryntum scheduler has rather incorrect type definitions - hence the ts-ignore and any

interface DataArg {
	requirementRecord: ISchedulerItem;
	actionRecord: ISchedulerItemAction;
	//startDate: Date;
	//endDate: Date;
	//startText: string;
	//endText: string;
	//startClockHtml: string;
	//endClockHtml: string;
}

export class CustomSchedulerRequirementTooltip extends EventTooltip {
	getTipHtml({ tip, activeTarget }: any) {
		// const { client } = this as any;
		const timeSpanRecord: ItemOrUT = (this as any).resolveTimeSpanRecord(
			activeTarget
		);

		if (!timeSpanRecord) return null;

		if (timeSpanRecord.eventType === "ut") {
			const { startPort, endPort } = timeSpanRecord;
			const portText =
				startPort || endPort
					? startPort === endPort
						? ` at ${portIdToName(startPort!)}` // If both ports are equal no need to show both
						: !(startPort && endPort)
						? ` at ${portIdToName((startPort || endPort)!)}` // If only one port specified, show only the one specified
						: ` from ${portIdToName(startPort)} → ${portIdToName(
								endPort
						  )}` // Show both
					: ""; // If no ports are specified, don't show port info
			return `<div style="padding:10px">
			Vessel unavailable due to ${timeSpanRecord.reason}${portText}
			</div>`;
		}

		if (timeSpanRecord?.startDate instanceof Date) {
			const actionId = activeTarget.getAttribute("data-id");
			const itemData = timeSpanRecord.actions.find(
				({ id }) => id === actionId
			);

			//its a cleaning action
			if (!itemData) {
				return "";
				// throw new Error(`What is this? ${actionId}`);
			}

			/*const startDate = new Date(itemData.startDate);
			const endDate = new Date(itemData.endDate);
			const startText = client.getFormattedDate(startDate),
				endDateValue = client.getDisplayEndDate(endDate, startDate),
				endText = client.getFormattedDate(endDateValue);*/

			tip.eventRecord = itemData;
			return (this as any).template({
				requirementRecord: timeSpanRecord,
				actionRecord: itemData,
				//startDate,
				//endDate,
				//startText,
				//endText,
				/*startClockHtml: (this as any).clockTemplate.template({
					date: startDate,
					text: startText,
					cls: "b-sch-tooltip-startdate",
				}),
				endClockHtml: (timeSpanRecord as any).isMilestone
					? ""
					: (this as any).clockTemplate.template({
							date: endDateValue,
							text: endText,
							cls: "b-sch-tooltip-enddate",
					  }),*/
			});
		} else {
			tip.hide();
			return "";
		}
	}

	static get $name() {
		return "CustomSchedulerRequirementTooltip";
	}

	static get defaultConfig() {
		const ignoreSelector = [
			".b-dragselecting",
			".b-eventeditor-editing",
			".b-taskeditor-editing",
			".b-resizing-event",
			".b-dragcreating",
			".b-dragging-event",
			".b-creating-dependency",
			".b-dragproxy",
		]
			.map((cls) => `:not(${cls})`)
			.join("");
		return {
			template: async (data: DataArg) => {
				const tradeRecord: any = data?.actionRecord?.trade || null;
				const trades: any = data?.requirementRecord?.originalData?.actions
					?.map((a) => a.trade)
					?.filter((t) => !!t);
				const vesselAction =
					data.actionRecord.vesselAction?.actionType.id;

				if (vesselAction === "load") {
					const long =
						trades?.find((t: any) => t.id === tradeRecord.id) ||
						undefined;

					const productColor = getEventColorForProduct(
						long?.product.id || ""
					);

					const tooltipContent = long
						? `Loading ${long?.productQuantity} bbl of ${
								long?.product.id
						  } @ ${
								portIdToName(long?.port) ||
								long?.port ||
								"UNKNOWN PORT"
						  }`
						: "Data is not available";

					return (StringHelper.xss as any)`<div id="trade-${long?.id}" style=" border:2px solid ${productColor}; margin:0px">
										<div style="padding:5px">
											${tooltipContent}
										</div>
									</div>`; //+
					//data.startClockHtml +
					//data.endClockHtml
				} else if (vesselAction === "unload") {
					const shorts =
						trades?.filter((t: any) => t.id === tradeRecord.id) ||
						undefined;

					const short =
						Array.isArray(shorts) && shorts.length > 0
							? shorts[shorts.length - 1]
							: undefined;

					const productColor = getEventColorForProduct(
						short?.product.id || ""
					);

					const tooltipContent = short
						? `Discharging ${short?.productQuantity} bbl of ${
								short?.product.id
						  } @ ${
								portIdToName(short?.port) ||
								short?.port ||
								"UNKNOWN PORT"
						  }`
						: "Data is not available";

					return (StringHelper.xss as any)`<div style="border:2px solid ${productColor}; margin:0px">
								<div style="padding:10px">
									${tooltipContent}
								</div>

							</div>`; //+
					//data.startClockHtml +
					//data.endClockHtml
				}
			},

			bodyCls: "b-sch-event-tooltip",
			monitorRecordUpdate: true,
			align: "t-b",
			anchorToTarget: true,
			trackMouse: true,
			forSelector: `.b-timelinebase${ignoreSelector} .b-sch-event-content > div, .b-timelinebase${ignoreSelector} .UTEvent`,
		};
	}

	public tipId = "custom-scheduler-event-tip";
}

GridFeatureManager.registerFeature(
	CustomSchedulerRequirementTooltip as any,
	true,
	"Scheduler"
);
