import React, { useCallback } from "react";

import { DateInput } from "grommet";
import { useController, UseControllerOptions } from "react-hook-form";
import { toDateFormat } from "../../constants";

interface ControlledDateInputProps
	extends Pick<UseControllerOptions, "control" | "name" | "defaultValue"> {
	children?: never;
	format?: string;
	isRangeInput?: boolean;
}

/** Grommet Date input wrapped in the react-hook-form controller */
export const ControlledDateInput: React.FC<ControlledDateInputProps> = ({
	format,
	isRangeInput,
	...props
}) => {
	const {
		field: { name, onChange, value },
	} = useController(props);
	const handleChange = useCallback(
		({ value }) => {
			const now = toDateFormat(new Date().toLocaleDateString()); //toDateFormat(Date.now().toLocaleDateString());
			//console.log("now: " + now);
			//console.log(new Date().setHours(0, 0, 0, 0) / 1000);
			//console.log(Date.parse(value[0]));
			if (isRangeInput) {
				const [start, end] = Array.isArray(value)
					? value ?? now
					: [value ?? now, value];
				onChange([start, end ?? start]);
			} else {
				onChange(value);
			}
		},
		[onChange, isRangeInput]
	);
	return (
		<DateInput
			format={format}
			name={name}
			onChange={handleChange}
			value={value}
		/>
	);
};
