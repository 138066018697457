import { makeGetSchema } from "./apolloClient";
import { APIClientConfig } from "./types";
import { createClient, dedupExchange, fetchExchange } from "urql";
import UserContext from "../context/UserContext";
import { Configuration } from "../util/config";

const REQUIREMENTS_WS = Configuration.REQUIREMENTS_URL;

const requirementsClientConfig: APIClientConfig = {
	cacheKey: "fanar-requirements",
	uri: REQUIREMENTS_WS,
	fetchPolicy: "network-only",
};

export const requirementsClient = createClient({
	url: REQUIREMENTS_WS,
	fetchOptions: () => {
		return {
			headers: {
				...UserContext.getAuthHeader(),
			},
		};
	},
	exchanges: [
		dedupExchange, // dedupExchange Ensures no duplicate requests are sent
		fetchExchange  // fetchExchange Makes sure every request bypasses cache
	],
});

export const getRequirementsSchema = makeGetSchema(requirementsClientConfig);
