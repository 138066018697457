import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "@xstate/react";
import { Box, Button, Grid, Heading } from "grommet";
import React, { useContext } from "react";
import { FleetMachineContext } from "../App";
import { LoadingIndicator } from "../components/LoadingIndicator";
import { Q88Modal } from "../components/Q88Modal";
import { VesselDetails } from "../components/VesselDetailsPanel";
import { VesselTable } from "../components/VesselTable";
import { useRoleManager } from "../hooks/useRoleManager";

const useStyles = makeStyles((theme) => ({
	header: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		position: "relative",
		padding: "0 20px",
		marginTop: 30,
	},

	addVesselButton: {
		display: "flex",
		marginLeft: 30,
		height: 45,
		borderRadius: 45,
	},

	q88AsideText: {
		margin: theme.spacing(2, 0),
	},
}));

export const VesselsPage: React.FC = () => {
	const fleetMachine = useContext(FleetMachineContext);
	const isLoading = useSelector(fleetMachine, ({ matches }) =>
		matches("idle.loading.loadingVessels.loading")
	);
	const classes = useStyles();
	const [isOpen, setIsOpen] = React.useState(false);

	const [vesselId, setVesselId] = React.useState("");

	const handleModalClose = React.useCallback(() => {
		setIsOpen(false);
	}, []);

	const addNewVesselClick = React.useCallback(() => {
		setIsOpen(true);
	}, []);

	const { can } = useRoleManager();
	const canManageVessel = can("manage vessels");

	return (
		<div style={{ position: "relative" }}>
			<Box direction="row" pad="medium" justify="between">
				<Heading level={2} size="medium" margin="small">
					Vessel Administration
				</Heading>
				{canManageVessel && (
					<div className={classes.addVesselButton}>
						<Button
							//color="blue1"
							primary
							onClick={addNewVesselClick}
							label="Add New Vessel"
						/>
					</div>
				)}
			</Box>

			{isLoading ? (
				<Box pad="xlarge">
					<LoadingIndicator size={48} />
				</Box>
			) : (
				<Grid
					fill
					columns={["2/3", "1/3"]}
					areas={[
						{ name: "vesselTable", start: [0, 0], end: [0, 0] },
						{ name: "vesselDetails", start: [1, 0], end: [1, 0] },
					]}
					rows={["flex"]}
					gap="none"
				>
					<Box gridArea="vesselTable" margin="xsmall">
						<VesselTable
							onRowClick={setVesselId}
							vesselId={vesselId}
						/>
					</Box>
					<Box gridArea="vesselDetails" margin="xsmall">
						<VesselDetails vesselId={vesselId} can={can} />
					</Box>
				</Grid>
			)}

			<Q88Modal isOpen={isOpen} onClose={handleModalClose} />
		</div>
	);
};
