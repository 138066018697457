import React, { useMemo } from "react";

import { createClient, Provider } from "urql";
import UserContext from "../context/UserContext";
import { Configuration } from "../util/config";

/**
 * Since we have different workspaces, we shall reuse the same client, but pass multiple urls, when using the `useQuery` hook.
 * @example
 * const [{data}] = useQuery(MY_QUERY, { url: VESSEL_WS })
 */

export const REPORTING_WS = Configuration.REPORTING_URL;
export const VESSEL_WS = Configuration.VESSELS_V2_URL;
export const FLEET_OPTIMIZATION_WS =
	Configuration.VESSEL_SCHEDULE_LIFECYCLE_URL;
export const REQUIREMENTS_WS = Configuration.REQUIREMENTS_URL;
export const VESSEL_SCHEDULE_LIFECYCLE_URL =
	Configuration.VESSEL_SCHEDULE_LIFECYCLE_URL;

export const FAST_SCHEDULER_WS = Configuration.FAST_SCHEDULER_URL;

/** The urql client provider must only be rendered when/if user is authenticated */
export const UrqlReportingClientProvider: React.FC = ({ children }) => {
	const client = useMemo(
		() =>
			createClient({
				url: REPORTING_WS,
				fetchOptions() {
					return { headers: UserContext.getAuthHeader() };
				},
			}),
		[]
	);
	return <Provider value={client}>{children}</Provider>;
};
