import React, { useMemo } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router";

import { UrqlReportingClientProvider } from "../api/UrqlClientProvider";
import { vesselScheduleLifecycleClient } from "../api/vesselScheduleLifecycleClient";
import { useAuthContext } from "../context/AuthContext";
import { FleetPage } from "../pages/Fleet.page";
import { ReportRecipientsPage } from "../pages/ReportRecipients.page";
import { RequirementBatchEditPage } from "../pages/RequirementBatchEdit.page";
import { RequirementsPage } from "../pages/Requirements.page";
import { VesselEditorPage } from "../pages/VesselEditor.page";
import { VesselReportPage } from "../pages/VesselReport.page";
import { PageNotFound } from "../pages/PageNotFound.page";
import { UnauthorizedPage } from "../pages/Unauthorized.page";
import { OperationalParametersPage } from "../pages/OperationalParameters.page";
import { UserActivityPage } from "../pages/UserActivityPage";
import { VesselsPage } from "../pages/Vessels.page";
import { CallbackRoute, LoginRoute, LogoutRoute } from "./auth.routes";
import { Provider as UrqlProvider } from "urql";
import { DebugPage } from "../pages/Debug.page";
import {
	Roles,
	UserRole,
	AccessActionsKeys,
	useRoleManager,
} from "../hooks/useRoleManager";

export const routeConfig: {
	path: string;
	/** Link is used to specify the url for the nav buttons. If omitted, path is used */
	link?: string;
	name: string;
	component: React.ComponentType;
	roles?: UserRole[];
	access?: AccessActionsKeys | AccessActionsKeys[];
}[] = [
	// Fleet page
	{
		name: "Fleet",
		path: "/fleet",
		link: "/fleet",
		component: FleetPage,
		access: "view fleet page",
		// roles: [Roles.FFA, Roles.OPT, Roles.READ],
	},
	// Vessel pages
	{
		name: "Vessels",
		path: "/vessels",
		link: "/vessels",
		component: VesselsPage,
		access: "view vessels page",
		// roles: [Roles.FFA],
	},
	{
		name: "Vessel Editor",
		path: "/vessels/edit/:id/:name?",
		component: VesselEditorPage,
		access: "manage vessels",
		// roles: [Roles.FFA],
	},
	{
		name: "Vessel Report",
		path: "/vessels/report/:id/:name?",
		component: VesselReportPage,
		access: "view vessel report",
		// roles: [Roles.FFA, Roles.OPT],
	},
	// Requirement pages
	{
		name: "Requirement Batch Editor",
		path: "/requirements/batchEdit",
		component: RequirementBatchEditPage,
		access: "view requirements page",
		// roles: [Roles.FFA, Roles.OPT, Roles.REQ],
	},
	{
		name: "Requirements",
		path: "/requirements/:id?/:edit?",
		link: "/requirements",
		component: RequirementsPage,
		access: "manage requirements",
		// roles: [Roles.FFA, Roles.OPT, Roles.REQ],
	},
	// Report pages
	{
		name: "Report recipients",
		path: "/reportRecipients",
		component: React.memo(() => (
			// * create urql client override for specific page
			<UrqlReportingClientProvider>
				<ReportRecipientsPage />
			</UrqlReportingClientProvider>
		)),
		access: "view reports page",
		// roles: [Roles.FFA, Roles.OPT],
	},
	{
		name: "OperationalParameters",
		path: "/operationalParameters",
		link: "/operationalParameters",
		component: OperationalParametersPage,
		access: "view operational parameters page",
		// roles: [Roles.FFA, Roles.OPT],
		// * create urql client override for specific page
	},
	{
		name: "UserActivity",
		path: "/UserActivity",
		link: "/userActivity",
		component: UserActivityPage,
		//access: "view operational parameters page",
		// roles: [Roles.FFA, Roles.OPT],
		// * create urql client override for specific page
	},
	// Other pages
	{
		name: "Debug",
		path: "/debug",
		link: "/debug",
		component: DebugPage,
		roles: [Roles.FFA],
	},
	{
		name: "404 - Page Not Found",
		path: "/404",
		link: "/404",
		component: PageNotFound,
	},
];

export const Routes = () => {
	const location = useLocation();
	// @ts-ignore
	const { authClient } = useAuthContext();
	const isActive = authClient.isActive();
	const isAuthenticated = authClient.isAuthenticated();
	const { can, hasRoles } = useRoleManager();

	const homeLink: string = useMemo(() => {
		return routeConfig?.find((route) => {
			if (!route.link) return false;
			if (!route.access && !route.roles) return true;
			const withAccess = route.access && can(route.access);
			const withRole = route.roles && hasRoles(route.roles);
			return withAccess || withRole;
		})?.link as string;
	}, [can, hasRoles]);

	return isActive && isAuthenticated ? (
		// Authenticated routes
		<UrqlProvider value={vesselScheduleLifecycleClient}>
			<Switch>
				{routeConfig.map((route) => {
					if (route.access || route.roles) {
						const withAccess =
							"access" in route && can(route.access);
						const withRole =
							"roles" in route && hasRoles(route.roles);
						if (!withAccess && !withRole)
							return (
								<Route
									key={route.path}
									path={route.path}
									component={UnauthorizedPage}
									exact
								/>
							);
						// // Or do the redirect
						// return (
						// 	<Redirect
						// 		key={route.path}
						// 		from={route.path}
						// 		to={homeLink?.substring(1)}
						// 		exact
						// 	/>
						// );
					}
					return (
						<Route
							key={route.path}
							exact
							path={route.path}
							component={route.component}
						/>
					);
				})}
				<Redirect exact from="/" to={homeLink?.substring(1) || "404"} />
				<Route path="/callback" component={CallbackRoute} />
				<Route path="/logout" component={LogoutRoute} />
				<Route component={PageNotFound} />
			</Switch>
		</UrqlProvider>
	) : (
		// Unauthenticated routes
		<Switch>
			<Route path="/callback" component={CallbackRoute} />
			<Route path="/login" component={LoginRoute} />
			<Route path="/logout" component={LogoutRoute} />
			<Redirect
				to={{
					pathname: "/login",
					state: { from: location.pathname },
				}}
			/>
		</Switch>
	);
};
