import React, { useCallback, useContext, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useService } from "@xstate/react";
import { Box } from "grommet";
import produce from "immer";
import moment from "moment";
import { useSelector as useReduxSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { gql, useQuery } from "urql";
import { v4 as uuid } from "uuid";

import {
	// REQUIREMENTS_WS,
	VESSEL_SCHEDULE_LIFECYCLE_URL,
} from "../api/UrqlClientProvider";
import { getVesselsSchema, vesselsClient } from "../api/vesselsClient";
import { FleetMachineContext } from "../App";
import {
	GraphQLSchemaForm,
	OverrideItem,
} from "../components/forms/GraphQLSchemaForm";
import { normalizePositiveNumber } from "../components/forms/helpers/normalizePositiveNumber";
import { getCleanTimeMultiplierFromSizeCategory } from "../components/utility/CleanTimeMultTable";
import { ALL_SIZE_CATEGORIES } from "../queries/vessels";
import { portsSelector } from "../selectors/ports.selectors";
import { Product } from "../types/generated/q-fanar-requirements.types";
import {
	BaseVessel,
	Mutation,
	MutationValidateAndPersistBaseVesselArgs,
} from "../types/generated/q-v2-vessels.types";
import {
	BunkerRequirements,
	InitialStatusOutput,
	VesselDetails,
} from "../types/generated/q-vessel-schedule-lifecycle-v6.types";

import { ConstructorStore } from "../components/forms/helpers/ConstructorStore";
import { useMachineSelector } from "../selectors/machine.selectors";

import type { Q88VesselInHistory } from "../components/Q88Modal";
const PERSIST_VESSEL = gql<
	Pick<Mutation, "validateAndPersistBaseVessel">,
	MutationValidateAndPersistBaseVesselArgs
>`
	mutation validateAndPersistBaseVessel($input: BaseVesselAsInput!) {
		validateAndPersistBaseVessel(baseVessel: $input)
	}
`;

const useStyles = makeStyles({
	header: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		position: "relative",
		padding: "0 20px",
		marginTop: 30,
	},
});

// const productQueryContext = {
// 	url: REQUIREMENTS_WS,
// };

const sizeCategoryQueryContext = {
	url: VESSEL_SCHEDULE_LIFECYCLE_URL,
};

const requiredVesselFields: string[] = [
	"id",
	"name",
	"details.charteringCost",
	"details.contractExpiration",
	"details.sizeCategory",
	"details.cleaningTimeMultiplier",
];

const normalize = normalizePositiveNumber();
/** just a shorthand for normalizer to use for formatting input fields in vessel editor page */
const n = (v: string) => (v ? parseFloat(normalize(v)) : 0);

const vesselFieldNormalizers: Record<string, (v: string) => number> = {
	"details.charteringCost": n,
	"details.cleaningTimeMultiplier": n,
	"bunkerRequirements.laden_speed_11": n,
	"bunkerRequirements.laden_speed_12": n,
	"bunkerRequirements.laden_speed_12_5": n,
	"bunkerRequirements.laden_speed_13": n,
	"bunkerRequirements.laden_speed_13_5": n,
	"bunkerRequirements.laden_speed_14": n,
	"bunkerRequirements.laden_speed_14_5": n,
	"bunkerRequirements.laden_speed_15": n,
	"bunkerRequirements.ballast_speed_11": n,
	"bunkerRequirements.ballast_speed_12": n,
	"bunkerRequirements.ballast_speed_12_5": n,
	"bunkerRequirements.ballast_speed_13": n,
	"bunkerRequirements.ballast_speed_13_5": n,
	"bunkerRequirements.ballast_speed_14": n,
	"bunkerRequirements.ballast_speed_14_5": n,
	"bunkerRequirements.ballast_speed_15": n,
	"bunkerRequirements.no_eca_cold_cleaning": n,
	"bunkerRequirements.no_eca_hot_cleaning": n,
	"initialStatus.startingFuel": n,
};

const fieldLabels = {
	"details.charteringCost": "Chartering Cost (USD)",
	"bunkerRequirements.laden_speed_11": "Laden Speed 11 (knots)",
	"bunkerRequirements.laden_speed_12": "Laden Speed 12 (knots)",
	"bunkerRequirements.laden_speed_12_5": "Laden Speed 12.5 (knots)",
	"bunkerRequirements.laden_speed_13": "Laden Speed 13 (knots)",
	"bunkerRequirements.laden_speed_13_5": "Laden Speed 13.5 (knots)",
	"bunkerRequirements.laden_speed_14": "Laden Speed 14 (knots)",
	"bunkerRequirements.laden_speed_14_5": "Laden Speed 14.5 (knots)",
	"bunkerRequirements.laden_speed_15": "Laden Speed 15 (knots)",
	"bunkerRequirements.ballast_speed_11": "Balast Speed 11 (knots)",
	"bunkerRequirements.ballast_speed_12": "Balast Speed 12 (knots)",
	"bunkerRequirements.ballast_speed_12_5": "Balast Speed 12.5 (knots)",
	"bunkerRequirements.ballast_speed_13": "Balast Speed 13 (knots)",
	"bunkerRequirements.ballast_speed_13_5": "Balast Speed 13.5 (knots)",
	"bunkerRequirements.ballast_speed_14": "Balast Speed 14 (knots)",
	"bunkerRequirements.ballast_speed_14_5": "Balast Speed 14.5 (knots)",
	"bunkerRequirements.ballast_speed_15": "Balast Speed 15 (knots)",
	"initialStatus.startingFuel": "Starting Fuel (mt)",
	isParked: "Laid up",
	canCarryProducts: "Assigned Products",
};

export const VesselEditorPage = React.memo(() => {
	const fleetMachineService = useContext(FleetMachineContext);
	const [current] = useService(fleetMachineService);

	const history = useHistory();
	const classes = useStyles();
	const { id: urlId } = useParams<{ id: string }>();
	const { state } = useLocation<Q88VesselInHistory | undefined>();
	let q88vessel = state?.q88vessel;

	let imo = state?.imo;

	const { context } = current;
	const { vessels = [], products } = context;

	/** You are either editing an existing vessel or adding new. isNew is true when adding a new vessel */
	const isNew = urlId === "new";

	const q88vesselMemo = useMemo(
		() =>
			isNew
				? undefined
				: vessels.find(
						({ vessel: { id: vesselId } }) => vesselId === urlId
				  )?.q88Vessel || undefined,
		[vessels, isNew, urlId]
	);

	if (!q88vessel) {
		q88vessel = q88vesselMemo;
	}

	if (!imo && q88vessel) {
		imo = q88vessel.id;
	}

	const availableProducts = useMachineSelector(
		(context) => context.products.allIds
	);

	const vessel = useMemo(
		() =>
			isNew
				? {
						id: imo ?? uuid(),
						name: q88vessel?.name ?? "",
						bunkerRequirements: ConstructorStore.BunkerRequirementsA(
							uuid()
						) as BunkerRequirements,
						details: ConstructorStore.VesselDetails(
							uuid()
						) as VesselDetails,
						initialStatus: {
							id: uuid(),
							startingFuel: 1000,
							availableFrom: new Date(),
						} as InitialStatusOutput,
						isClean: false,
						isParked: false,
						canCarryProducts: availableProducts,
				  }
				: vessels.find(
						({ vessel: { id: vesselId } }) => vesselId === urlId
				  )?.vessel,
		[vessels, isNew, urlId, q88vessel, imo, availableProducts]
	);

	const [isClean, setIsClean] = useState(vessel?.isClean || false);
	const ports = useReduxSelector(portsSelector);

	const allProducts: Product[] = useMemo(
		() => products?.allIds.map((productId) => products?.byId[productId]),
		[products]
	);

	const [{ data: sizeCategoriesData }] = useQuery({
		query: ALL_SIZE_CATEGORIES,
		context: sizeCategoryQueryContext,
		requestPolicy: "cache-first",
	});

	const nestedKindData = useMemo(
		() => ({
			"initialStatus.lastKnownPort": Object.values(ports.byId).map(
				({ id, name }) => ({
					label: name,
					value: id,
				})
			),
			"initialStatus.lastProduct": allProducts
				.filter((f) => (f.cleanStatus === "C") === isClean)
				.map<{
					label: string;
					value: string;
				}>(({ id }) => ({
					label: id,
					value: id,
				})),
			"details.sizeCategory": (
				sizeCategoriesData?.allSizeCategorys ?? []
			).map<OverrideItem<string>>(({ id }) => ({
				label: id,
				value: id,
			})),
		}),
		[ports, allProducts, sizeCategoriesData, isClean]
	);

	const fieldValidationRules = useMemo(
		() => ({
			"initialStatus.startingFuel": (_val: any, getValues: any) => ({
				validate: () => {
					const fuelCapacity: number | string | null | undefined =
						q88vessel?.carryingCapacity?.fuelCapacity;
					const value = getValues("initialStatus.startingFuel");
					if (
						value &&
						!!fuelCapacity &&
						Number(value) > Number(fuelCapacity)
					) {
						return `Starting fuel should not exceed tanker max capacity (${fuelCapacity})!`;
					} else if (Number(value) < 0) {
						return `Starting fuel can not be negative number!`;
					}
					return true;
				},
			}),
		}),
		[q88vessel]
	);

	const handleSuccess = useCallback(
		(vessel) => {
			console.log("SENDING", vessel);
			fleetMachineService.send({ type: "VESSEL_SAVED", vessel });
			history.push("/vessels");
		},
		[fleetMachineService, history]
	);

	return (
		<div style={{ position: "relative" }}>
			<div className={classes.header}>
				<h3>Add / Edit Vessel</h3>
			</div>
			<div>
				<Box pad="medium">{!vessel && "Fetching Vessel"} </Box>
				{vessel && (
					<GraphQLSchemaForm<BaseVessel>
						kindName="BaseVessel"
						mutation={PERSIST_VESSEL}
						defaultValues={vessel}
						overrideFieldLabels={fieldLabels}
						fieldValidationRules={fieldValidationRules}
						client={vesselsClient}
						getSchema={getVesselsSchema}
						onCancel={() => {
							history.goBack();
						}}
						hideFields={[
							"details.cleaningTimeMultiplier",
							"details.dateNextSurvey",
							"details.id",
							"initialStatus.id",
							"bunkerRequirements.id",
						]}
						disableFields={[
							"id",
							"name",
							"details.id",
							"details.dateNextSurvey",
							"bunkerRequirements.id",
							"initialStatus.id",
							"details.cleaningTimeMultiplier",
						]}
						nestedKindData={nestedKindData}
						onBeforeSubmit={(data: BaseVessel) => {
							return produce(data, (draft) => {
								if (
									draft.initialStatus &&
									draft.initialStatus.availableFrom
								) {
									draft.initialStatus.availableFrom = moment(
										draft.initialStatus?.availableFrom
									).toISOString();
								}
								if (draft.details.contractExpiration) {
									draft.details.contractExpiration = moment(
										draft.details.contractExpiration
									).format("YYYY-MM-DD");
								}
								if (draft.details.dateNextSurvey) {
									draft.details.dateNextSurvey = moment(
										draft.details.dateNextSurvey
									).format("YYYY-MM-DD");
								}
								//if size category choose a cleaning time multiplier
								if (draft.details.sizeCategory) {
									draft.details.cleaningTimeMultiplier = getCleanTimeMultiplierFromSizeCategory(
										draft.details.sizeCategory
									);
								}
							});
						}}
						onSuccess={handleSuccess}
						extraFieldNormalizers={vesselFieldNormalizers}
						requiredFields={requiredVesselFields}
						watchFields={["isClean"]}
						onChange={(values) => {
							if (
								values.isClean !== undefined &&
								values.isClean !== isClean
							) {
								setIsClean(values.isClean);
							}
						}}
						externalValidator={(values) => {
							if (values.isClean) {
								//cannot take fuel oil
								if (
									values.canCarryProducts?.includes(
										"FUEL OIL"
									)
								) {
									return {
										canCarryProducts:
											"Clean Vessels cannot carry Fuel Oil",
									};
								} else {
									return undefined;
								}
							} else {
								if (
									values.canCarryProducts?.length === 1 &&
									values.canCarryProducts[0] === "FUEL OIL"
								) {
									return undefined;
								} else {
									return {
										canCarryProducts:
											" Dirty Vessels can only carry Fuel Oil",
									};
								}
							}
						}}
					/>
				)}
			</div>
		</div>
	);
});
