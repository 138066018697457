import { Port } from "../../types/generated/q-vessel-schedule-lifecycle-v6.types";
import { IPortsState } from "../reducers/ports";

export const LOAD_PORTS = "LOAD_REQUIRLOAD_PORTSEMENTS";
export interface ILoadPortsAction {
	type: typeof LOAD_PORTS;
}
export const LOAD_PORTS_SUCCESS = "LOAD_PORTS_SUCCESS";
export interface ILoadPortsSuccessAction {
	type: typeof LOAD_PORTS_SUCCESS;
	payload: IPortsState;
}

export const PERSIST_PORT_SUCCESS = "PERSIST_PORT_SUCCESS";
export interface IPersistPortSuccessAction {
	type: typeof PERSIST_PORT_SUCCESS;
	payload: Port;
}

export const PERSIST_PORT_FAILURE = "PERSIST_PORT_FAILURE";
export interface IPersistPortFailureAction {
	type: typeof PERSIST_PORT_FAILURE;
	payload: Port;
}

export const SET_FOCUSED_PORT = "SET_FOCUSED_PORT";
export interface ISetFocusedPortAction {
	type: typeof SET_FOCUSED_PORT;
	payload: string;
}

export type IportReducerActions =
	| ILoadPortsAction
	| ILoadPortsSuccessAction
	| IPersistPortSuccessAction
	| ISetFocusedPortAction;
