import { Box, Heading, Main, Text } from "grommet";
import React from "react";
import { useCallback, useState } from "react";
import { gql, useQuery } from "urql";

import {
	fastSchedulerApolloClient,
	getFastSchedulerSchema,
} from "../api/fastSchedulerClient";
import { VESSEL_SCHEDULE_LIFECYCLE_URL } from "../api/UrqlClientProvider";
import { GraphQLSchemaForm } from "../components/forms/GraphQLSchemaForm";
import { LoadingIndicator } from "../components/LoadingIndicator";

//query to get the default constants
const GET_DEFAULTS = `#graphql
	query {
		getConstants(id: "defaults") {
			id
			defaultFuelPrice
			defaultDieselPrice
			refuelThreshold
			criticalRefuelThreshold
			operationalOverhead
		}
	}
`;

const PERSIST_CONSTANTS = gql`
	mutation updateConstants($input: UpdateConstantsInput!) {
		updateConstants(input: $input)
	}
`;

const context = {
	url: VESSEL_SCHEDULE_LIFECYCLE_URL,
};

const requiredFields = [
	"defaultFuelPrice",
	"defaultDieselPrice",
	"criticalRefuelThreshold",
	"refuelThreshold",
	"operationalOverhead",
];

export const OperationalParametersPage: React.FC = () => {
	const [{ data, fetching }] = useQuery<{ getConstants: any }, object>({
		query: GET_DEFAULTS,
		requestPolicy: "network-only",
		context,
	});

	const [constantsInState, setConstants] = useState(data?.getConstants);

	const handleSuccess = useCallback((constants) => {
		//console.log(constants);
		setConstants(constants);
	}, []);
	return (
		<Main pad="large">
			<Heading level="2">Operational Parameters</Heading>

			<Box border={{ color: "blue1" }} pad="small">
				{fetching && (
					<Box pad="medium">
						Fetching parameters <LoadingIndicator />
					</Box>
				)}

				{constantsInState && (
					<Box>
						<Box pad="small" gap="small" direction="row">
							<Text>Default Fuel Price: </Text>
							<Text color="#59B5D9" weight="bold">
								{" "}
								{constantsInState.defaultFuelPrice}
							</Text>
						</Box>
						<Box pad="small" gap="small" direction="row">
							<Text>Default Diesel Price: </Text>
							<Text color="#59B5D9" weight="bold">
								{" "}
								{constantsInState.defaultDieselPrice}
							</Text>
						</Box>
						<Box pad="small" gap="small" direction="row">
							<Text>Critical Refuel Threshold: </Text>
							<Text color="#59B5D9" weight="bold">
								{" "}
								{constantsInState.criticalRefuelThreshold}
							</Text>
						</Box>
						<Box pad="small" gap="small" direction="row">
							<Text> Refuel Threshold: </Text>
							<Text color="#59B5D9" weight="bold">
								{" "}
								{constantsInState.refuelThreshold}
							</Text>
						</Box>
						<Box pad="small" gap="small" direction="row">
							<Text> Operational Overhead </Text>
							<Text color="#59B5D9" weight="bold">
								{" "}
								{constantsInState.operationalOverhead}
							</Text>
						</Box>
					</Box>
				)}

				{data?.getConstants && (
					<Box>
						<Heading level="4">
							Amend the Operational Parameters
						</Heading>
						<Box pad="small">
							<GraphQLSchemaForm
								kindName="Constants"
								mutation={PERSIST_CONSTANTS}
								defaultValues={data?.getConstants}
								disableFields={["id"]}
								client={fastSchedulerApolloClient}
								getSchema={getFastSchedulerSchema}
								onSuccess={handleSuccess}
								requiredFields={requiredFields}
							/>
						</Box>
					</Box>
				)}
			</Box>
		</Main>
	);
};
