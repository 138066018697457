import { gql } from "urql";

import {
	Mutation,
	MutationPersistPortRestrictionArgs,
	MutationPersistUnavailableTimeArgs,
	Query,
	QueryVesselExistsInQ88Args,
} from "../types/generated/q-vessel-schedule-lifecycle-v6.types";

const vesselFields = `#graphql
			id
			q88Vessel {
				id
				dimensions {
					id
					beam
					overallLength
					aftParallelBodyDistance
					forwardParallelBodyDistance
				}
				speedCapabilities {
					id
					ladenEconomicSpeed
					ladenMaxSpeed
					ballastEconomicSpeed
					ballastMaxSpeed
				}
				carryingCapacity {
					id
					fuelCapacity
					dieselCapacity
					gasOilCapacity
				}
				scnt
				cargoPumpingRateM3PerS
				imoClass
				totalProductCapacity
				name
			}
			vessel {
				id
				name
				isClean
				isParked
				details {
					id
					charteringCost
					contractExpiration
					sizeCategory
					dateNextSurvey
					cleaningTimeMultiplier
				}
				bunkerRequirements {
					id
					laden_speed_11
					laden_speed_12
					laden_speed_12_5
					laden_speed_13
					laden_speed_13_5
					laden_speed_14
					laden_speed_14_5
					laden_speed_15
					ballast_speed_11
					ballast_speed_12
					ballast_speed_12_5
					ballast_speed_13
					ballast_speed_13_5
					ballast_speed_14
					ballast_speed_14_5
					ballast_speed_15
					no_eca_cold_cleaning
					no_eca_hot_cleaning
				}
				initialStatus {
					id
					availableFrom
					lastKnownPort
					lastProduct
					startingFuel
				}
				canCarryProducts
			}
			portRestrictions {
				id
				port
				terminal
				reason
				startDate
				endDate
				vesselId
			}
			unavailableTimes {
				id
				startPort
				endPort
				startDate
				endDate
				reason
				vesselId
			}

	`;

export const GET_ALL_VESSELS = gql<Pick<Query, "getAllVesselsAndPRandUT">>`
	query getAllVessels {
		getAllVesselsAndPRandUT(take: null, offset: null) {
			${vesselFields}
		}
	}
`;

export const GET_VESSEL_BY_ID = gql<
	Pick<Query, "getVesselWithPRandUT">,
	{ vesselId: string }
>`
	query getVesselById($vesselId: ID!) {
		getVesselWithPRandUT(id: $vesselId) {
			${vesselFields}
		}
	}
`;

export const CHECK_VESSEL = gql<
	Pick<Query, "vesselExistsInQ88">,
	QueryVesselExistsInQ88Args
>`
	query vesselExistsInQ88($imo: ID!) {
		vesselExistsInQ88(imo: $imo) {
			id
			name
			scnt
			totalProductCapacity
			imoClass
			cargoPumpingRateM3PerS
			dimensions {
				beam
				overallLength
				aftParallelBodyDistance
				forwardParallelBodyDistance
			}
			carryingCapacity {
				fuelCapacity
				dieselCapacity
				gasOilCapacity
			}
			speedCapabilities {
				ladenEconomicSpeed
				ladenMaxSpeed
				ballastEconomicSpeed
				ballastMaxSpeed
			}
		}
	}
`;

export const ALL_SIZE_CATEGORIES = gql<Pick<Query, "allSizeCategorys">>`
	query {
		allSizeCategorys {
			id
		}
	}
`;

export const PERSIST_PORT_RESTRICTION = gql<
	Pick<Mutation, "persistPortRestriction">,
	MutationPersistPortRestrictionArgs
>`
	mutation persistPortRestriction($input: RC6PortRestrictionAsInput!) {
		persistPortRestriction(portRestriction: $input)
	}
`;

export const PERSIST_UNAVAILABLE_TIME = gql<
	Pick<Mutation, "persistUnavailableTime">,
	MutationPersistUnavailableTimeArgs
>`
	mutation persistUnavailableTime($input: V2UnavailableTimeAsInput!) {
		persistUnavailableTime(unavailableTime: $input)
	}
`;
